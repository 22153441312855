import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {ReportSnapshots} from "../../services/ReportSnapshots";
import {ISnapshotsListItem} from "../../interfaces/ISnapshotsListItem";

@Component({
  templateUrl: './snapshots.html',
  selector: 'report-snapshots-list',
})
export class ReportSnapshotsListCmp {
  public loading = false;
  public items:ISnapshotsListItem[];

  constructor(private srv:ReportSnapshots, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.loading = true;
    this.srv.getSnapshotsList().subscribe((items)=> {
      this.loading = false;
      this.items = items;
    }, ()=> {
      this.loading = false;
    });
  }
}
