import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
declare var $:any;

@Component({
  selector: 'location-impl-other',
  templateUrl: './other.html',
})
export class LocationImplOtherCmp {
  @Input() locationID;
  @Output() update = new EventEmitter();
  public location:ILocation;

  constructor(public locSrv:Locations, public session:Session) {}

  ngOnChanges() {
    this.location = null;
    this.locSrv.getLocation(this.locationID).then((loc)=> {
      this.location = loc;
    });
  }

  changeDate(date:Date, field: string) {
    this.location[field] = date.toISOString().substring(0, 19).replace('T',' ');
    this.locSrv.updateLocation(this.location).then(()=> {
      this.update.emit();
      let notify = new Notify();
      notify.success('Saved');
    }, (err)=> {
      console.error(err);
      let notify = new Notify();
      notify.error(JSON.stringify(err), 'Error');
    });
  }
}
