import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from "rxjs";

@Injectable()
export class AdminQueries {
  private config: Config;
  private session: Session;
  private http: HttpClient;
  private list = [];
  private typesLoadingPromise;

  constructor(config: Config, http: HttpClient, session: Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  runGrades() {
    return this.http.post(this.config.apiUrl + '/_log_group_grades', '');
  }

  createReportSnapshot(reportName: string): Observable<number> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/report_snapshot/' + reportName, '', {headers: headers, responseType: 'text'}).pipe(map(t => parseInt(t)));
  }

  resetRxSession(code: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/rx/session/' + code, {headers: headers, responseType: 'text', observe: 'response'});
  }
}
