import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {IPendingLocationReport} from "../interfaces/IPendingLocationReport";
import {IGlobalReport} from "../interfaces/IGlobalReport";
import {ILocRosterNonMedReport} from "../interfaces/ILocRosterNonMedReport";
import {IChannelsEmails} from "../interfaces/IChannelsEmails";
import {IRosterReport} from "../interfaces/IRosterReport";
import {IGroupSalesReport} from "../interfaces/IGroupSalesReport";
import {IGroupTopVendorsReport} from "../interfaces/IGroupTopVendorsReport";
import {ITop10SalesReport} from "../interfaces/ITop10SalesReport";
import {ITopSalesByCM} from "../interfaces/ITopSalesByCM";
import {ITopSalesByDistrRep} from "../interfaces/ITopSalesByDistrRep";
import {ITopSalesUnknownDistrRep} from "../interfaces/ITopSalesUnknownDistrRep";
import {ICMLocGroupStats} from "../interfaces/ICMLocGroupStats";
import {ICMSales} from "../interfaces/ICMSales";
import {ICmRegionsReport} from "../interfaces/ICmRegionsReport";
import {ISalesFeeSupplierReport} from "../interfaces/ISalesFeeSupplierReport";
import {IConnectionsReport} from "../interfaces/IConnectionsReport";
import {IStaplesInterested} from "../interfaces/IStaplesInterested";
import {ISalesFeeContractReport, ISfcReport} from "../interfaces/ISalesFeeContractReport";
import {IFailingOffReport} from "../interfaces/IFailingOffReport";
import {IEmailCampaignReport} from "../interfaces/IEmailCampaignReport";
import {IEmailCampaignData} from "../interfaces/IEmailCampaignData";
import {IChannelLocGroupStats} from "../interfaces/IChannelLocGroupStats";
import {ILocGPO} from "../interfaces/ILocGPO";
import {IAbsent} from "../interfaces/IAbsent";
import {ISalesTracing} from "../interfaces/ISalesTracing";
import {ISalesMashupReport} from "../interfaces/ISalesMashupReport";
import {IContractChartReport} from "../interfaces/IContractChart";
import {ILocsEmptyPharmaDataReport, ILocNewPharmaData} from "../interfaces/IPharmaCheck";
import {IPhonesReport} from "../interfaces/IPhonesReport";
import {IHPG} from "../interfaces/IHPG";
import {ICDiff} from "../interfaces/ICDiff";
import {IMembersReport} from "../interfaces/IMembersReport";
import {IRxTasksReport} from "../components/report-rx-tasks/ReportRxTasksCmp";
import {ISt2Summary} from "../interfaces/IStSummary";
import {IStContract} from "../interfaces/IStContract";
import {ISalesReportingSummary} from "../interfaces/ISalesReportingSummary";
import {IMissingLocationsReport} from "../interfaces/IMissingLocationsReport";
import {INotIncludedContractsReport} from "../interfaces/INotIncludedContractsReport";
import {IRxConnectionsReport} from "../interfaces/IRxConnections";
import {IPocSuppressedReport} from "../interfaces/IPocSuppressed";
import {IHisciSyncReport} from "../interfaces/IHisciSyncReport";
import {IAADReport, IAmpContactsReport, IDistrProcessFlowReport, IDistrUploadReport} from "../interfaces/ReportsInterfaces";
import {ILocsByMedsurgEffDateReport} from "../interfaces/ILocsByMedsurgEffDateReport";
import {IPaByDateReport} from "../interfaces/IPaByDateReport";
import {IFeedsImportStats} from "../interfaces/IFeedsImportStats";
import {IRosterSearchReq, IRosterSearchResp} from "../interfaces/IRosterSearch";
import {HttpCache} from "./HttpCache";

export interface ISummaryTablesUpdateDates {
  sales_fee: string;
  sales_tracing: string;
}

export interface IReqContractDiff {
  contract_number: string;
  include_non_ate: boolean;
  currently_active: boolean;
  sales_date_from: string;
  sales_date_to: string;
  hpg_date_from: string;
  hpg_date_to: string;
  membership_date_after: string;
  membership_date_before: string;
}

export interface IContractDifference {
  gpoids: string[];
  req_token: string;
}

@Injectable()
export class Reports {
  constructor(private http: HttpCache, private session: Session, private config: Config) {}

  getPendingLocsReport(): Observable<Array<IPendingLocationReport>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<Array<IPendingLocationReport>>(this.config.apiUrl + '/report/pending_locations', {headers: headers});
  }

  getGlobalReport(): Observable<IGlobalReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IGlobalReport>(this.config.apiUrl + '/report/crm_global', {headers: headers});
  }

  getPendingLocsExportUrl() {
    return this.config.apiUrl + '/report/pending_locations/csv/' + this.session.getToken();
  }

  getLocRosterNonMedExportUrl() {
    return this.config.apiUrl + '/report/loc_roster_non_med/csv/' + this.session.getToken();
  }

  markZimmerSent(pc_ids): Observable<any> {
    const pc_string_ids = pc_ids.map(id => id.toString());
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/report/zimmer_sent', JSON.stringify(pc_string_ids), {headers: headers});
  }

  getLocRosterNonMed(): Observable<Array<ILocRosterNonMedReport>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<Array<ILocRosterNonMedReport>>(this.config.apiUrl + '/report/loc_roster_non_med', {headers: headers});
  }

  getRxTasks(): Observable<IRxTasksReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IRxTasksReport>(this.config.apiUrl + '/report/rx_tasks', {headers: headers});
  }

  getRxOrphans(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/rx_orphans', {headers: headers});
  }

  getRebateChart(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/rebate_chart', {headers: headers});
  }

  getMissingLocations(): Observable<IMissingLocationsReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IMissingLocationsReport>(this.config.apiUrl + '/report/missing_locations', {headers: headers});
  }

  getRxConnections(): Observable<IRxConnectionsReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IRxConnectionsReport>(this.config.apiUrl + '/report/rx_connections', {headers: headers});
  }

  getPocSuppressed(): Observable<IPocSuppressedReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IPocSuppressedReport>(this.config.apiUrl + '/report/poc_suppressed', {headers: headers});
  }

  getHisciSync_HinDea_Report(): Observable<IHisciSyncReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IHisciSyncReport>(this.config.apiUrl + '/report/hisci_sync/hin_dea', {headers: headers});
  }

  getHisciSync_RxEffDate_Report(): Observable<IHisciSyncReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IHisciSyncReport>(this.config.apiUrl + '/report/hisci_sync/rx_eff_date', {headers: headers});
  }

  getNotIncludedContracts(): Observable<INotIncludedContractsReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<INotIncludedContractsReport>(this.config.apiUrl + '/report/not_included_contracts', {headers: headers});
  }

  getUnsentReports(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/unsent_reports', {headers: headers});
  }

  getChannelsEmails(): Observable<IChannelsEmails> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IChannelsEmails>(this.config.apiUrl + '/report/channels', {headers: headers});
  }

  getChannelsEmailsUrl() {
    return this.config.apiUrl + '/report/channels/csv/' + this.session.getToken();
  }

  getAbsent(months: number): Observable<IAbsent[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IAbsent[]>(this.config.apiUrl + '/report/absent/' + months, {headers: headers});
  }

  getAbsentUrl(months: number) {
    return this.config.apiUrl + '/report/absent/csv/' + months + '/' + this.session.getToken();
  }

  getAbsentEarningCycle(months: number): Observable<IAbsent[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IAbsent[]>(this.config.apiUrl + '/report/absent_earning_cycle/' + months, {headers: headers});
  }

  getAbsentEarningCycleUrl(months: number) {
    return this.config.apiUrl + '/report/absent_earning_cycle/csv/' + months + '/' + this.session.getToken();
  }

  getNewlyReporting(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/newly_reporting', {headers: headers});
  }

  getNewlyReportingUrl() {
    return this.config.apiUrl + '/report/newly_reporting/csv/' + this.session.getToken();
  }

  getNewlyReportingEarningCycle(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/newly_reporting_earning_cycle', {headers: headers});
  }

  getNewlyReportingEarningCycleUrl() {
    return this.config.apiUrl + '/report/newly_reporting_earning_cycle/csv/' + this.session.getToken();
  }

  getTrendsBySupplier(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/trends_by_supplier', {headers: headers});
  }

  getTrendsBySupplierUrl() {
    return this.config.apiUrl + '/report/trends_by_supplier/csv/' + this.session.getToken();
  }

  getTrendsBySupplierEarningCycle(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/trends_by_supplier_earning_cycle', {headers: headers});
  }

  getTrendsBySupplierEarningCycleUrl() {
    return this.config.apiUrl + '/report/trends_by_supplier_earning_cycle/csv/' + this.session.getToken();
  }

  getRosterReport(): Observable<IRosterReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IRosterReport>(this.config.apiUrl + '/report/roster', {headers: headers});
  }

  getRosterReportDate(): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/report/roster/date', {headers: headers, responseType: 'text'});
  }

  getRosterPcReportQuery(): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/roster_pc/query', {headers: headers, responseType: 'text'});
  }

  getRosterReportUrl() {
    return this.config.apiUrl + '/report/roster/csv/' + this.session.getToken();
  }

  getSalesFeeSupplierReport(filterSpecs?: string[], filterCots?: string[], filterChannels?: string[]): Observable<ISalesFeeSupplierReport[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let body = '';
    if (filterSpecs || filterCots || filterChannels) {
      let f: any = {};
      if (filterSpecs && filterSpecs.length > 0) {
        f.specialties = filterSpecs;
      }
      if (filterCots && filterCots.length > 0) {
        f.cots = filterCots;
      }
      if (filterChannels && filterChannels.length > 0) {
        f.channels = filterChannels;
      }
      body = JSON.stringify(f);
      return this.http.put<ISalesFeeSupplierReport[]>(this.config.apiUrl + '/report/sales_fee_supplier', body, {headers: headers});
    }
    return this.http.getc<ISalesFeeSupplierReport[]>(this.config.apiUrl + '/report/sales_fee_supplier', {headers: headers});
  }

  getSalesFeeContractReport(filterSpecs?: string[], filterCots?: string[], filterTypes?: string[], dateMin?: string, dateMax?: string): Observable<ISfcReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let body = '';
    if (filterSpecs || filterCots || filterTypes) {
      let f: any = {};
      if (filterSpecs && filterSpecs.length > 0) {
        f.specialties = filterSpecs;
      }
      if (filterCots && filterCots.length > 0) {
        f.cots = filterCots;
      }
      if (filterTypes && filterTypes.length > 0) {
        f.types = filterTypes;
      }
      if (dateMin) {
        f.date_min = dateMin;
      }
      if (dateMax) {
        f.date_max = dateMax;
      }
      body = JSON.stringify(f);
      return this.http.put<ISfcReport>(this.config.apiUrl + '/report/sales_fee_contract', body, {headers: headers});
    }
    return this.http.getc<ISfcReport>(this.config.apiUrl + '/report/sales_fee_contract', {headers: headers});
  }

  getSalesFeeSupplierUrl() {
    return this.config.apiUrl + '/report/sales_fee_supplier/csv/' + this.session.getToken();
  }

  getSalesFeeContractUrl() {
    return this.config.apiUrl + '/report/sales_fee_contract/csv/' + this.session.getToken();
  }

  getSalesWithoutDistributorUrl(year) {
    return this.config.apiUrl + '/report/sales_without_distributor/' + year.toString() + '/csv/' + this.session.getToken();
  }

  getSalesWithoutDistributor(year): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/sales_without_distributor/' + year.toString(), {headers: headers});
  }

  getPaWithoutSalesUrl(year) {
    return this.config.apiUrl + '/report/pa_without_sales/' + year.toString() + '/csv/' + this.session.getToken();
  }

  getPaWithoutSales(year): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/pa_without_sales/' + year.toString(), {headers: headers});
  }

  getSalesFeeSupplierReportAT(filterSpecs?: string[], filterCots?: string[], filterChannels?: string[]): Observable<ISalesFeeSupplierReport[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let body = '';
    if (filterSpecs || filterCots || filterChannels) {
      let f: any = {};
      if (filterSpecs && filterSpecs.length > 0) {
        f.specialties = filterSpecs;
      }
      if (filterCots && filterCots.length > 0) {
        f.cots = filterCots;
      }
      if (filterChannels && filterChannels.length > 0) {
        f.channels = filterChannels;
      }
      body = JSON.stringify(f);
      return this.http.put<ISalesFeeSupplierReport[]>(this.config.apiUrl + '/report/sales_fee_supplier_at', body, {headers: headers});
    }
    return this.http.getc<ISalesFeeSupplierReport[]>(this.config.apiUrl + '/report/sales_fee_supplier_at', {headers: headers});
  }

  getSalesFeeContractReportAT(filterSpecs?: string[], filterCots?: string[], filterTypes?: string[], dateMin?: string, dateMax?: string): Observable<ISfcReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let body = '';
    if (filterSpecs || filterCots || filterTypes) {
      let f: any = {};
      if (filterSpecs && filterSpecs.length > 0) {
        f.specialties = filterSpecs;
      }
      if (filterCots && filterCots.length > 0) {
        f.cots = filterCots;
      }
      if (filterTypes && filterTypes.length > 0) {
        f.types = filterTypes;
      }
      if (dateMin) {
        f.date_min = dateMin;
      }
      if (dateMax) {
        f.date_max = dateMax;
      }
      body = JSON.stringify(f);
      return this.http.put<ISfcReport>(this.config.apiUrl + '/report/sales_fee_contract_at', body, {headers: headers});
    }
    return this.http.getc<ISfcReport>(this.config.apiUrl + '/report/sales_fee_contract_at', {headers: headers});
  }

  getSalesFeeSupplierUrlAT() {
    return this.config.apiUrl + '/report/sales_fee_supplier_at/csv/' + this.session.getToken();
  }

  getSalesFeeContractUrlAT() {
    return this.config.apiUrl + '/report/sales_fee_contract_at/csv/' + this.session.getToken();
  }

  getRosterReportPCUrl() {
    return this.config.apiUrl + `/report/roster_pc/csv/${this.session.getToken()}`;
  }

  getCostStudiesReportUrl() {
    return this.config.apiUrl + '/report/cost_study_files/csv/' + this.session.getToken();
  }

  getCostStudiesRunUrl() {
    return this.config.apiUrl + '/report/cost_study_run/csv/' + this.session.getToken();
  }

  getCAPReportUrl() {
    return this.config.apiUrl + '/report/cap/csv/' + this.session.getToken();
  }

  getGroupFinDetailsUrl(id: number) {
    return this.config.apiUrl + '/report/group_fin_details/csv/' + this.session.getToken() + '/' + id.toString();
  }

  getGroupFinDetailsRebatesUrl(id: number) {
    return this.config.apiUrl + '/report/group_fin_details_rebates/csv/' + this.session.getToken() + '/' + id.toString();
  }

  getGroupSalesReport(id: number): Observable<IGroupSalesReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IGroupSalesReport>(this.config.apiUrl + '/report/group_sales/' + id, {headers: headers});
  }

  getGroupRebatesReport(id: number): Observable<IGroupSalesReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IGroupSalesReport>(this.config.apiUrl + '/report/group_rebates/' + id, {headers: headers});
  }

  getGroupTopVendorsReport(id: number): Observable<IGroupTopVendorsReport[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IGroupTopVendorsReport[]>(this.config.apiUrl + '/report/group_top_vendors/' + id, {headers: headers});
  }

  getTop10Sales(): Observable<ITop10SalesReport[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ITop10SalesReport[]>(this.config.apiUrl + '/report/top_10_sales', {headers: headers});
  }

  getTopSalesByDistrRep(): Observable<ITopSalesByDistrRep[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ITopSalesByDistrRep[]>(this.config.apiUrl + '/report/top_sales_dr', {headers: headers});
  }

  getTopSalesWithUnknownRep(): Observable<ITopSalesUnknownDistrRep[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ITopSalesUnknownDistrRep[]>(this.config.apiUrl + '/report/top_sales_nr', {headers: headers});
  }

  getTopSalesByCM(cmID: number): Observable<ITopSalesByCM[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ITopSalesByCM[]>(this.config.apiUrl + '/report/top_sales_cm/' + cmID, {headers: headers});
  }

  getCmLocGroupStats(): Observable<ICMLocGroupStats[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ICMLocGroupStats[]>(this.config.apiUrl + '/report/cm_loc_groups', {headers: headers});
  }

  getChannelLocGroupStats(): Observable<IChannelLocGroupStats[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IChannelLocGroupStats[]>(this.config.apiUrl + '/report/channel_loc_groups', {headers: headers});
  }

  getCmSales(): Observable<ICMSales[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ICMSales[]>(this.config.apiUrl + '/report/cm_sales', {headers: headers});
  }

  getCmRegions(): Observable<ICmRegionsReport[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ICmRegionsReport[]>(this.config.apiUrl + '/report/cm_loc_regions', {headers: headers});
  }

  getStaplesInterested(): Observable<IStaplesInterested[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IStaplesInterested[]>(this.config.apiUrl + '/report/staples_interested', {headers: headers});
  }

  getStaplesInterestedNotSent(): Observable<IStaplesInterested[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IStaplesInterested[]>(this.config.apiUrl + '/report/staples_interested_not_sent', {headers: headers});
  }

  getStaplesInterestedNotReporting(): Observable<IStaplesInterested[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IStaplesInterested[]>(this.config.apiUrl + '/report/staples_interested_not_reporting', {headers: headers});
  }

  getHSNotConnected(): Observable<ILocGPO> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ILocGPO>(this.config.apiUrl + '/report/hs_not_connected', {headers: headers});
  }

  getMcKNotConnected(): Observable<ILocGPO> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ILocGPO>(this.config.apiUrl + '/report/mck_not_connected', {headers: headers});
  }

  getHSConnected(): Observable<ILocGPO> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ILocGPO>(this.config.apiUrl + '/report/hs_connected', {headers: headers});
  }

  getMcKConnected(): Observable<ILocGPO> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ILocGPO>(this.config.apiUrl + '/report/mck_connected', {headers: headers});
  }

  getConnectionsReport(contractNumber: number): Observable<IConnectionsReport[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IConnectionsReport[]>(this.config.apiUrl + '/report/connected_interested/' + contractNumber, {headers: headers});
  }

  getConnectionsReportUrl(contractNumber: number): string {
    return this.config.apiUrl + '/report/connected_interested/' + contractNumber + '/csv/' + this.session.getToken();
  }

  getReportingByDistributor(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/reporting_distr', {headers: headers});
  }

  getFailingOff(): Observable<IFailingOffReport[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IFailingOffReport[]>(this.config.apiUrl + '/report/failing_off', {headers: headers});
  }

  getEmailCampaign(campaign_id: number): Observable<IEmailCampaignReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IEmailCampaignReport>(this.config.apiUrl + '/report/email_campaign/' + campaign_id, {headers: headers});
  }

  setEmailCampaignPause(campaign_id: number, pause: boolean): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/report/email_campaign/pause',
      JSON.stringify({campaign_id: campaign_id, pause: pause}),
      {headers: headers, responseType: 'text'});
  }

  removeCacheKey(key: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/cache_key/' + key, {headers: headers, responseType: 'text'});
  }

  getLocGroupSalesTracing(locGroupID: number): Observable<ISalesTracing> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ISalesTracing>(this.config.apiUrl + '/report/sales_tracing/' + locGroupID, {headers: headers});
  }

  getLocGroupSalesDataUrl(locGroupID: number): string {
    return this.config.apiUrl + '/report/sales_tracing/' + locGroupID + '/csv/' + this.session.getToken();
  }

  getSalesMashupReport(): Observable<ISalesMashupReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ISalesMashupReport>(this.config.apiUrl + '/report/sales_mashup', {headers: headers});
  }

  getContractChartReport(id: number): Observable<IContractChartReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IContractChartReport>(this.config.apiUrl + '/report/contract_chart/' + id, {headers: headers});
  }

  getPharmaCheckReport(): Observable<ILocsEmptyPharmaDataReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ILocsEmptyPharmaDataReport>(this.config.apiUrl + '/report/pharma_empty_locs', {headers: headers});
  }

  setPharmaEmptyLocs(data: ILocNewPharmaData[]): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/report/pharma_empty_locs', JSON.stringify(data), {headers: headers, responseType: 'text'});
  }

  getPhonesReport(): Observable<IPhonesReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IPhonesReport>(this.config.apiUrl + '/report/phones', {headers: headers});
  }

  getHPGReport(contract: string): Observable<IHPG> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IHPG>(this.config.apiUrl + '/report/hpg/' + contract, {headers: headers});
  }

  getCDiffReport(filter1?: boolean): Observable<ICDiff> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + '/report/cdiff';
    if (filter1) {
      url = url + "/filter/1";
    }
    return this.http.getc<ICDiff>(url, {headers: headers});
  }

  getImportedFeedsStats(): Observable<IFeedsImportStats[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IFeedsImportStats[]>(this.config.apiUrl + '/feeds_import_stats', {headers: headers});
  }

  getMembersReport(): Observable<IMembersReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IMembersReport>(this.config.apiUrl + '/report/members', {headers: headers});
  }

  getLocsByMedsurgEffDateReport(): Observable<ILocsByMedsurgEffDateReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ILocsByMedsurgEffDateReport>(this.config.apiUrl + '/report/locsbymedsurgeffdate', {headers: headers});
  }

  getPaByDateReport(n_days: string): Observable<IPaByDateReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IPaByDateReport>(this.config.apiUrl + '/report/pabydate/' + n_days, {headers: headers});
  }

  getSummaryTablesUpdateDates(): Observable<ISummaryTablesUpdateDates> {
    return this.http.getc<ISummaryTablesUpdateDates>(this.config.apiUrl + '/summary_tables/dates');
  }

  updateSummaryTables(): Observable<string> {
    return this.http.post(this.config.apiUrl + '/summary_tables', '', {responseType: 'text'});
  }

  getContractDifference(req: IReqContractDiff): Observable<IContractDifference> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put<IContractDifference>(this.config.apiUrl + '/report/contract_difference', JSON.stringify(req), {headers: headers});
  }

  getContractDifferenceCsvUrl(token: string): string {
    if (!token) {
      return '';
    }
    return this.config.apiUrl + '/report/contract_difference/csv/' + token;
  }

  getMGMASignups(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/mgma_signups', {headers: headers});
  }

  getRxSignups(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/rx_signups', {headers: headers});
  }

  getMgmaSignupsCsvUrl(): string {
    return this.config.apiUrl + '/report/mgma_signups/csv/' + this.session.getToken();
  }

  getRxSignupsCsvUrl(): string {
    return this.config.apiUrl + '/report/rx_signups/csv/' + this.session.getToken();
  }

  getAoaUsers(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/aoa_users', {headers: headers});
  }

  getAoaUsersCsvUrl(): string {
    return this.config.apiUrl + '/report/aoa_users/csv/' + this.session.getToken();
  }

  getAadUsers(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/aad_users', {headers: headers});
  }

  getAadUsersCsvUrl(): string {
    return this.config.apiUrl + '/report/aad_users/csv/' + this.session.getToken();
  }

  getRxXrefs(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/report/pharma_xref', {headers: headers});
  }

  getRxXrefsCsvUrl(): string {
    return this.config.apiUrl + '/report/pharma_xref/csv/' + this.session.getToken();
  }

  getSt2Contracts(): Observable<IStContract[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IStContract[]>(this.config.apiUrl + '/report/sales_tracing_2/contracts', {headers: headers});
  }

  getSt2LocsCount(contracts: number[], interval: number, active: number): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let contractsArr = contracts.join(',');
    return this.http.getc(this.config.apiUrl + `/report/sales_tracing_2/locs_cnt/${contractsArr}/${interval}/${active}`, {headers: headers, responseType: 'text'});
  }

  getSt2Summary(contracts: number[], interval: number, active: number): Observable<ISt2Summary> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let contractsArr = contracts.join(',');
    return this.http.getc<ISt2Summary>(this.config.apiUrl + `/report/sales_tracing_2/summary/${contractsArr}/${interval}/${active}`, {headers: headers});
  }

  getSt2CsvUrl(contracts: number[], interval: number, active: number): string {
    let contractsArr = contracts.join(',');
    return this.config.apiUrl + `/report/sales_tracing_2/csv/${contractsArr}/${interval}/${active}/` + this.session.getToken();
  }

  getSalesReportingLocsCount(contracts: number[], interval: number, active: number): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let contractsArr = contracts.join(',');
    return this.http.getc(this.config.apiUrl + `/report/sales_reporting/locs_cnt/${contractsArr}/${interval}/${active}`, {headers: headers, responseType: 'text'});
  }

  getSalesReportingSummary(contracts: number[], interval: number, active: number): Observable<ISalesReportingSummary> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let contractsArr = contracts.join(',');
    return this.http.getc<ISalesReportingSummary>(this.config.apiUrl + `/report/sales_reporting/run/${contractsArr}/${interval}/${active}`, {headers: headers});
  }

  getSalesReportingCsvUrl(contracts: number[], interval: number, active: number): string {
    let contractsArr = contracts.join(',');
    return this.config.apiUrl + `/report/sales_reporting/csv/${contractsArr}/${interval}/${active}/` + this.session.getToken();
  }

  getSalesReportingGpoidsCsvUrl(contracts: number[], interval: number, active: number): string {
    let contractsArr = contracts.join(',');
    return this.config.apiUrl + `/report/sales_reporting_gpoid/csv/${contractsArr}/${interval}/${active}/` + this.session.getToken();
  }

  getLocSdCsvUrl(): string {
    return this.config.apiUrl + `/report/locsd/` + this.session.getToken();
  }

  getCsTrackingReport(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<any>(this.config.apiUrl + `/report/cs_tracking`, {headers: headers});
  }

  getAmpContacts(): Observable<IAmpContactsReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IAmpContactsReport>(this.config.apiUrl + '/report/amp_contacts', {headers: headers});
  }

  getAadReport(): Observable<IAADReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IAADReport>(this.config.apiUrl + '/report/aad', {headers: headers});
  }

  getDistrProcessFlowReport(): Observable<IDistrProcessFlowReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IDistrProcessFlowReport>(this.config.apiUrl + '/report/distr_processflow', {headers: headers});
  }

  getDistrUploadsReport(distrID: number, tags?: string, time_after?: string, time_before?: string): Observable<IDistrUploadReport> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + '/report/distr_uploads?distr_id=' + (distrID.toString());
    if (tags) {
      url = url + '&tags=' + tags;
    }
    if (time_after) {
      url = url + '&time_after=' + time_after;
    }
    if (time_before) {
      url = url + '&time_before=' + time_before;
    }
    return this.http.getc<IDistrUploadReport>(url, {headers: headers});
  }

  searchRoster(req: IRosterSearchReq): Observable<IRosterSearchResp> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put<IRosterSearchResp>(this.config.apiUrl + '/report/roster_search', JSON.stringify(req), {headers: headers});
  }
}
