import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";
import {IDistrUploadReport} from "../../interfaces/ReportsInterfaces";
import {UrlParser} from "../../services/UrlParser";
import {Tags} from "../../services/Tags";

@Component({
  templateUrl: 'report.html',
  selector: 'report-distr-uploads'
})
export class ReportDistrUploadsCmp {
  public report: IDistrUploadReport;
  public loading = false;
  @ViewChild('tbl') tbl: ElementRef;
  public page = 1;
  public itemsLimit = 70;
  private itemsLimitPre = 70;
  public exporting = false;
  public rendering = false;
  public distrID = 2;
  public possibleTagIDs: number[] = [];
  public tagsMap = {};
  public tagsList = [];
  public selectedTags = [];
  public timeAfter;
  public timeBefore;
  private n = new Notify();

  constructor(private session: Session, private reports: Reports, private urlParser: UrlParser, private h: Helpers, private tagsSrv: Tags) {
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.setPage(parseInt(this.urlParser.getParameter('page')));
    this.session.redirectIfNotAuth();
    this.tagsSrv.loadToList(this.tagsList);
    this.tagsSrv.loadToMap(this.tagsMap);
  }

  reload() {
    setTimeout(() => {
      this.load();
    }, 1);
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.reports.getDistrUploadsReport(this.distrID, this.selectedTags.join(','), this.timeAfter, this.timeBefore).subscribe((report) => {
      this.report = report;
      this.fillPossibleTagIDs();
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  fillPossibleTagIDs() {
    if (this.report && this.report.possible_tags) {
      let tags = this.report.possible_tags.split(',');
      if (tags && tags.length) {
        this.possibleTagIDs = [];
        let fillSelected = (this.selectedTags.length == 0);
        tags.forEach((t) => {
          let tagId = parseInt(t);
          if (tagId) {
            this.possibleTagIDs.push(tagId);
            if (fillSelected) {
              this.selectedTags.push(tagId);
            }
          }
        });
      }
    }
  }

  exportCSV() {
    if (!this.report || !this.report.records || !this.report.records.length || this.exporting) {
      return;
    }
    this.rendering = true;
    this.exporting = true;
    let itemsLimitPre = this.itemsLimit;
    let prePage = this.page;
    this.page = 1;
    this.itemsLimit = 100000;
    setTimeout(() => {
      this.h.exportCsv(this.tbl, 'distr_' + this.distrID + '_uploads.csv', () => {
        this.exporting = false;
        this.itemsLimit = itemsLimitPre;
        this.page = prePage;
        this.rendered();
      }, (celltext) => {
        return celltext.replace('check_circle', 'X');
      });
    }, 120);
  }

  rendered() {
    setTimeout(() => {
      this.rendering = false;
    }, 60);
  }

  setPage($event) {
    let newPage = Math.max(0, parseInt($event) || 1);
    this.urlParser.setParameter('page', newPage);
    if (this.page !== newPage) {
      this.page = newPage;
    }
  }

  toggleTag(tagID) {
    let t = parseInt(tagID);
    let i = this.selectedTags.indexOf(t);
    if (i > -1) {
      this.selectedTags.splice(i, 1);
    } else {
      this.selectedTags.push(t);
    }
    this.reload();
  }
}
