import {Component} from '@angular/core';
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {Session} from "../../services/Session";

@Component({
  selector: 'create-contact',
  templateUrl: './create-contact.html',
})
export class CreateContactCmp {
  public user: IUser = <IUser>{};
  public locations: Array<ILocation> = [];

  constructor(public userService: User, public locationService: Locations, public session: Session) {
    this.session.redirectIfNotAuth();
  }

  removeLoc(loc) {
    let i = this.locations.indexOf(loc);
    if (i > -1) {
      this.locations.splice(i, 1);
    }
  }

  addLoc(loc?: ILocation) {
    if (!loc || !loc.id) {
      loc = <ILocation>{};
    }
    if (this.user.id) {
      loc.primary_contact_user_id = this.user.id;
    }
    if (this.locations[0] && this.locations[0].__rep_user_id) {
      loc.__rep_user_id = this.locations[0].__rep_user_id;
    }
    if (!this.user.theme_id && loc.theme_id) {
      this.user.theme_id = loc.theme_id;
    }
    this.locations.unshift(loc);
  }

  updateUser() {
    if (!this.user.id || !this.locations.length) {
      return;
    }
    if (this.locations.length === 1 && this.locations[0].theme_id && !this.user.theme_id) {
      this.user.theme_id = this.locations[0].theme_id;
    }
    this.locations.forEach((loc: ILocation)=> {
      if (loc.primary_contact_user_id !== this.user.id) {
        loc.primary_contact_user_id = this.user.id;
        if (loc.id) {
          this.locationService.updateLocation(loc);
        }
      }
    });
  }

  updateLoc() {
    let to_update = false;
    if (this.locations.length === 1 && this.locations[0].theme_id && !this.user.theme_id) {
      this.user.theme_id = this.locations[0].theme_id;
      to_update = true;
    }
    if (this.locations.length === 1 && this.locations[0].id && !this.user.location_id) {
      this.user.location_id = this.locations[0].id;
      to_update = true;
    }
    if (this.user.id && to_update) {
      this.userService.updateUser(this.user);
    }
  }
}
