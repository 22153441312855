import {Component, Input} from '@angular/core';
import {ILocLinkedTableRecordField,ILocLinkedTableRecord} from "../../interfaces/LocationsMerge";

@Component({
  selector: 'lm-fv',
  template: `
  <span [ngSwitch]="ftype">
    <span *ngSwitchCase="'date'">
      {{field.value|utc2et|date:'shortDate'}}
    </span>
    <span *ngSwitchCase="'url'">
      <a href="{{linkUrl}}" target="_blank" rel="noopener">{{field.value}}</a>
    </span>
    <span *ngSwitchDefault>
      <popup-list [text]="field.friendly_value||field.value" [length]="35" [saveSpaces]="!!1"></popup-list>
    </span>
  </span>
  `,
})
export class LocsMergeFieldValueCmp {
  @Input() field:ILocLinkedTableRecordField;
  @Input() record:ILocLinkedTableRecord;
  public ftype:string;
  public linkText:string;
  public linkUrl:string;

  ngOnChanges() {
    this.ftype = '';
    if (!this.field.value) {
      return;
    }
    if (this.field.friendly_name && this.field.friendly_name.indexOf('Date') > -1 && this.field.value.indexOf('-') > 0 && this.field.value.indexOf('20') > -1) {
      this.ftype = 'date';
    } else {
      if (this.field.name == 'title' && this.record.fields.length > 1) {
        for (let i = 0; i < this.record.fields.length; i++) {
          let f = this.record.fields[i];
          if (f.name == 'url') {
            this.ftype = 'url';
            this.linkUrl = f.value;
            break;
          }
        }
      }
    }
  }
}
