export class ILocationImplementationVendor {
  id: number;
  location_id: number;
  impl_vendor_id: number;
  date_connected: string;
  account_number: string;
  user_id: number;
  is_dont_market: boolean;
  date_interested: string;
  is_already_member: boolean;
  sent_date: string;
}
