import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Implementations} from "../../services/Implementations";
import {IImplementationStep} from "../../interfaces/IImplementationStep";
import {ILocationImplementationStep} from "../../interfaces/ILocationImplementationStep";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {Config} from "../../services/Config";
import {Cots} from "../../services/Cots";

declare var _: any;
declare var $: any;

@Component({
  selector: 'location-impl-steps',
  templateUrl: './steps.html',
})
export class LocationImplStepsCmp {
  @Input() locationID;
  @Output() update = new EventEmitter();
  @ViewChild('removeStepModal') removeStepModal: ElementRef;
  public steps: Array<ILocationImplementationStep>;
  public allSteps: Array<IImplementationStep>;
  public stepsMap = {};
  public cotsInternalMap = {};
  public saving = false;
  public loading = false;
  public location: ILocation = <ILocation>{};
  public savingLocation = false;
  public removeStepReason = '';
  public stepToRemove: ILocationImplementationStep;
  public stepToRemoveCheckbox;

  constructor(public implSrv: Implementations, public session: Session,
    public locations: Locations, public config: Config, private cots: Cots) {
    this.cots.loadInternalToMap(this.cotsInternalMap);
  }

  ngOnInit() {
    if (this.locationID && !this.steps) {
      this.loadSteps();
    }
  }

  ngOnChanges() {
    this.loadSteps();
    this.loadLocation();
  }

  loadSteps() {
    if (this.loading) {
      return;
    }
    let locSteps;
    this.loading = true;
    let merge = () => {
      this.loading = false;
      if (!this.allSteps || !locSteps) {
        return;
      }
      this.steps = [];
      this.allSteps.forEach((v: IImplementationStep) => {
        this.stepsMap[v.id] = v;
        let e = _.find(locSteps, {impl_step_id: v.id});
        if (e) {
          this.steps.push(e);
        } else {
          let nv = new ILocationImplementationStep();
          nv.location_id = this.locationID;
          nv.impl_step_id = v.id;
          if (this.session && this.session.user && this.session.user.id) {
            nv.user_id = this.session.user.id;
          }
          this.steps.push(nv);
        }
      });
    };
    if (!this.allSteps) {
      this.implSrv.getSteps().subscribe((steps) => {
        this.allSteps = steps;
        merge();
      }, (response) => {
        this.loading = false;
        console.error(response);
      });
    }
    this.implSrv.getLocationSteps(this.locationID).subscribe((steps) => {
      locSteps = steps;
      merge();
    }, (response) => {
      console.error(response);
    });
  }

  saveSteps() {
    if (!this.steps || !this.steps.length) {
      return;
    }
    this.saving = true;
    this.implSrv.setLocationSteps(this.steps).subscribe(() => {
      this.saving = false;
      let notify = new Notify();
      notify.success('Saved');
    }, () => {
      this.saving = false;
      let notify = new Notify();
      notify.error('Error');
    });
  }

  setStep(step: ILocationImplementationStep, element?) {
    let setTimeAndSave = (step) => {
      let d = new Date();
      step.datetime = d.toISOString().substring(0, 19).replace('T', ' ');
      this.saveSteps();
    };
    switch (step.impl_step_id) {
      case this.config.implStepDoesntUseEthicon:
        if (step.datetime) {
          this.removeStep(step);
        } else {
          setTimeAndSave(step);
        }
        return;
      case this.config.implStepNotValidatedID:
        if (step.datetime) {
          this.removeStep(step);
        } else {
          setTimeAndSave(step);
        }
        return;
      case this.config.implStepConnected:
        if (step.datetime) {
          this.showModalStepRemove(step, element);
        } else {
          setTimeAndSave(step);
        }
        return;
      case this.config.implStepSent:
        if (step.datetime) {
          this.showModalStepRemove(step, element);
        } else {
          setTimeAndSave(step);
        }
        return;
      default:
        setTimeAndSave(step);
    }
  }

  removeStep(step: ILocationImplementationStep) {
    this.saving = true;
    this.implSrv.removeLocationStep(step.location_id, step.impl_step_id, this.removeStepReason).subscribe(() => {
      this.saving = false;
      step.datetime = null;
      let notify = new Notify();
      notify.success('Saved');
      this.stepToRemove = null;
      this.removeStepReason = '';
    }, () => {
      this.saving = false;
      let notify = new Notify();
      notify.error('Error');
      this.stepToRemove = null;
    });
  };

  loadLocation() {
    if (!this.locationID) {
      return;
    }
    this.locations.getLocation(this.locationID).then((l) => {
      this.location = <ILocation>l;
    }, () => {});
  }

  updateLocation() {
    if (!this.location) {
      return false;
    }
    this.savingLocation = true;
    this.locations.updateLocation(this.location).then(() => {
      this.update.emit(this.location);
      this.savingLocation = false;
      let n = new Notify();
      n.success('Saved');
    }, (err) => {
      let n = new Notify();
      n.error('Error');
      this.savingLocation = false;
      console.error(err);
    });
  }

  changeDate(date: Date, field) {
    if (!date || !date.setHours) {
      return;
    }
    this.location[field] = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  stepRemoveCancel() {
    this.removeStepReason = '';
    this.hideModalStepRemove();
    if (this.stepToRemoveCheckbox) {
      this.stepToRemoveCheckbox.checked = true;
    }
  }

  hideModalStepRemove() {
    if (this.removeStepModal && this.removeStepModal.nativeElement) {
      $(this.removeStepModal.nativeElement).modal('hide');
    }
  }

  showModalStepRemove(step: ILocationImplementationStep, element?) {
    this.stepToRemove = step;
    if (element) {
      this.stepToRemoveCheckbox = element;
    }
    if (this.removeStepModal && this.removeStepModal.nativeElement) {
      $(this.removeStepModal.nativeElement).modal('show');
    }
  }

  stepRemoveConfirm() {
    this.hideModalStepRemove();
    this.removeStep(this.stepToRemove);
  }
}
