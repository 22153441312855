// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .filter-badge {
        margin-right: .3rem;
        cursor: pointer;
        font-size: 0.85rem;
      }
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkZpbHRlclRhZ3NMaW5lQ21wLnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7TUFDTTtRQUNFLG1CQUFtQjtRQUNuQixlQUFlO1FBQ2Ysa0JBQWtCO01BQ3BCIiwiZmlsZSI6IkZpbHRlclRhZ3NMaW5lQ21wLnRzIiwic291cmNlc0NvbnRlbnQiOlsiXG4gICAgICAuZmlsdGVyLWJhZGdlIHtcbiAgICAgICAgbWFyZ2luLXJpZ2h0OiAuM3JlbTtcbiAgICAgICAgY3Vyc29yOiBwb2ludGVyO1xuICAgICAgICBmb250LXNpemU6IDAuODVyZW07XG4gICAgICB9XG4gICAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/components/filter-tags-line/FilterTagsLineCmp.ts"],"names":[],"mappings":";MACM;QACE,mBAAmB;QACnB,eAAe;QACf,kBAAkB;MACpB;;AAEN,4bAA4b","sourcesContent":["\n      .filter-badge {\n        margin-right: .3rem;\n        cursor: pointer;\n        font-size: 0.85rem;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
