export class ITicket {
  id:number = 0;
  type_id:number = 0;
  status_id:number = 0;
  parent_id:number = 0;
  requestor_user_id:number = 0;
  cm_user_id:number = 0;
  date_created:string;
  last_updated:string;
  subject:string;
  content:string;
  location_id:number = 0;
  freshdesk_version:string;
  freshdesk_ticket:number;
  freshdesk_sync_md5:string;
  freshdesk_note_id:number;
  task_id:number;
  cc_email:string;
  __type:string = '';
  __status:string = '';
  __req_user_fname:string = '';
  __req_user_lname:string = '';
  __req_user_email:string = '';
  __cm_user_fname:string = '';
  __cm_user_lname:string = '';
  __cm_user_email:string = '';
  __pc_id:string = '';
  __zimmer_id:string = '';
}
