import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {ILocationContact} from "../../interfaces/ILocationContact";
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Notify} from "../../services/Notify";
import {Config} from "../../services/Config";
import {ClipboardHelper} from "../../services/ClipboardHelper";

@Component({
  selector: 'location-contacts',
  templateUrl: './location_contacts.html',
})
export class LocationContactsCmp {
  @Input() location: ILocation;
  @Output() update = new EventEmitter();
  @ViewChild('copyRxUrl') copyRxUrlBtn: ElementRef;
  @ViewChild('copySiimUrl') copySiimUrlBtn: ElementRef;
  public contacts: ILocationContact[];
  public loading = false;
  public rxCode = '';
  public rxURL = '';
  public rxLoading = false;
  public siimCode = '';
  public siimURL = '';
  public siimLoading = false;
  public hideAddPocBtn = false;
  public addingPoc = false;
  public newPocUser: IUser;
  private copyHelper = new ClipboardHelper();

  constructor(public locationsSrv: Locations, private config: Config, private usrSrv: User) {
    this.reload();
  }

  reload() {
    if (!this.location || !this.location.location_group_id) {
      return;
    }
    this.loading = true;
    this.contacts = [];
    this.locationsSrv.getContactsOfGroup(this.location.location_group_id).subscribe((contacts) => {
      this.contacts = contacts;
      if (this.location && this.location.primary_contact_user_id) {
        contacts.forEach((c) => {
          if (c.id === this.location.primary_contact_user_id) {
            this.hideAddPocBtn = true;
          }
        });
      }
      this.loading = false;
    }, (response) => {
      console.error(response);
      this.loading = false;
    });
    if (this.location.primary_contact_user_id) {
      this.rxLoading = true;
      this.usrSrv.getRxCodeOfUser(this.location.primary_contact_user_id).subscribe((code) => {
        this.rxLoading = false;
        this.rxCode = code;
        this.rxURL = this.config.getRxUrl(code, this.location.theme_id);
        setTimeout(() => {
          this.copyHelper.initCopyBtn(this.rxURL, this.copyRxUrlBtn);
        }, 1000);
      }, () => {
        this.rxLoading = false;
      });
    }
  }

  ngOnChanges() {
    this.reload();
  }

  emitUpdate() {
    this.update.emit(true);
  }

  toggleEmailSuppressed(contact: ILocationContact, $event?) {
    if (contact && contact.id) {
      contact.is_email_suppressed = !contact.is_email_suppressed;
      let user = <IUser>{};
      user.id = contact.id;
      user.is_email_suppressed = contact.is_email_suppressed;
      let n = new Notify();
      contact.__updating = true;
      this.usrSrv.updateUser(user).then(() => {
        contact.__updating = false;
      }, (err) => {
        contact.__updating = false;
        contact.is_email_suppressed = !contact.is_email_suppressed;
        n.error('Error');
        console.error(err);
      });
    }
    if ($event && $event.stopPropagation) {
      $event.stopPropagation();
    }
    return false;
  }

  removeContact(contact: ILocationContact, $event?) {
    if (!this.location || !this.location.id || contact.__updating) {
      return;
    }
    let n = new Notify();
    if (contact && contact.id) {
      contact.__updating = true;
      this.usrSrv.getUserInfo(contact.id).then((user) => {
        if (user.location_id == this.location.id) {
          user.location_id = 0;
          this.usrSrv.updateUser(user).then(() => {
            if (this.location.primary_contact_user_id == contact.id) {
              this.location.primary_contact_user_id = 0;
              this.locationsSrv.updateLocation(this.location).then(() => {
                contact.__updating = false;
                n.success('Contact removed');
                this.emitUpdate();
              }, () => {
                n.error(`Can't update location data`);
                contact.__updating = false;
              });
            } else {
              contact.__updating = false;
              n.success('Contact removed');
              this.emitUpdate();
            }
          }, () => {
            n.error(`Can't update user data`);
            contact.__updating = false;
          });
        }
        if (this.location.primary_contact_user_id == contact.id) {
          this.location.primary_contact_user_id = 3;
          this.locationsSrv.updateLocation(this.location).then(() => {
            this.hideAddPocBtn = false;
            contact.__updating = false;
            n.success('Contact removed');
            this.emitUpdate();
          }, () => {
            n.error(`Can't update location data`);
            contact.__updating = false;
          });
        }
      }, () => {
        contact.__updating = false;
        n.error(`Can't find user`);
      });
      let user = <IUser>{};
      user.id = contact.id;
      user.is_email_suppressed = contact.is_email_suppressed;
      this.usrSrv.updateUser(user).then(() => {
        contact.__updating = false;
      }, (err) => {
        contact.__updating = false;
        contact.is_email_suppressed = !contact.is_email_suppressed;
        n.error('Error');
        console.error(err);
      });
    }
    if ($event && $event.stopPropagation) {
      $event.stopPropagation();
    }
    return false;
  }

  generateRxCode() {
    if (this.location && this.location.primary_contact_user_id) {
      this.rxLoading = true;
      this.usrSrv.generateRxCodeForUser(this.location.primary_contact_user_id).subscribe((code) => {
        this.rxLoading = false;
        this.rxCode = code;
        this.rxURL = this.config.getRxUrl(code, this.location.theme_id);
        setTimeout(() => {
          this.copyHelper.initCopyBtn(this.rxURL, this.copyRxUrlBtn);
        }, 500);
      }, () => {
        this.rxLoading = false;
        let n = new Notify();
        n.error("Code has not been generated, server error. Please reload this page and try again.");
      });
    }
  }

  copyRxUrlClick($event) {
    this.copyHelper.copyClick($event);
  }

  generateSiimCode() {
    if (this.location && this.location.primary_contact_user_id) {
      this.siimLoading = true;
      this.usrSrv.generateSiimCodeForUser(this.location.primary_contact_user_id).subscribe((code) => {
        this.siimLoading = false;
        this.siimCode = code;
        this.siimURL = this.config.getSiimUrl(code, this.location.theme_id);
        setTimeout(() => {
          this.copyHelper.initCopyBtn(this.siimURL, this.copySiimUrlBtn);
        }, 500);
      }, () => {
        this.siimLoading = false;
        let n = new Notify();
        n.error("Code has not been generated, server error. Please reload this page and try again.");
      });
    }
  }

  copySiimUrlClick($event) {
    this.copyHelper.copyClick($event);
  }

  addPoc() {
    this.addingPoc = true;
    if (this.location && this.location.id) {
      this.newPocUser = <IUser>{location_id: this.location.id};
    }
  }

  setPoc(user) {
    let id;
    if (user && user.id) {
      id = user.id;
    } else {
      if (this.newPocUser && this.newPocUser.id) {
        id = this.newPocUser.id;
      }
    }
    if (id) {
      this.location.primary_contact_user_id = id;
      this.locationsSrv.updateLocation(this.location).then(() => {
        this.update.emit(this.location);
        this.addingPoc = false;
        this.newPocUser = null;
      }, (err) => {
        let n = new Notify();
        n.httpErr(err);
        this.addingPoc = false;
        this.newPocUser = null;
      });
    }
  }

  cancelNewPoc() {
    this.newPocUser = null;
    this.addingPoc = false;
  }
}
