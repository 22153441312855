import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { Session } from "./services/Session";
import { Specialties } from "./services/Specialties";
import { Distributors } from "./services/Distributors";
import { User } from "./services/User";
import { Notify } from "./services/Notify";
import { Config } from "./services/Config";

import { UrlParser } from "./services/UrlParser";
import { Locations } from "./services/Locations";
import { ItemsRange } from "./services/ItemsRange";
import { Cots } from "./services/Cots";
import { Tickets } from "./services/Tickets";
import { Manufacturers } from "./services/Manufacturers";
import { Questions } from "./services/Questions";
import { TaxStatuses } from "./services/TaxStatuses";
import { JobTypes } from "./services/JobTypes";
import { Tags } from "./services/Tags";
import { Survey } from "./services/Survey";
import { YearlySpendTypes } from "./services/YearlySpendTypes";
import { Reports } from "./services/Reports";
import { Implementations } from "./services/Implementations";
import { Themes } from "./services/Themes";
import { Formatters } from "./services/Formatters";
import { Lifecycles } from "./services/Lifecycles";
import { Helpers } from "./services/Helpers";
import { FilterPipe } from "./components/FilterPipe";
import { TimeAgo } from "./services/TimeAgo";
import { SaverLanding } from "./services/SaverLanding";
import { DeaSearch } from "./services/DeaSearch";
import { Tasks } from "./services/Tasks";
import { ITask } from "./interfaces/ITask";
import { OrderByPipe } from "./services/OrderByPipe";
import { EditRequests } from "./services/EditRequests";
import { Testimonials } from "./services/Testimonials";
import { ArrayJoinNotEmptyPipe } from "./services/ArrayJoinNotEmptyPipe";
import { Newsletter } from "./services/Newsletter";
import { AdminQueries } from "./services/AdminQueries";
import { ReportSnapshots } from "./services/ReportSnapshots";
import { Vendors } from "./services/Vendors";
import { FDTickets } from "./services/FDTickets";
import { CostStudy } from "./services/CostStudy";
import { ContractConnections } from "./services/ContractConnections";
import { TypeaheadHelper } from "./services/TypeaheadHelper";
import { ContractRequirements } from "./services/ContractRequirements";
import { DataChangeRequests } from "./services/DataChangeRequests";
import { HttpCache } from "./services/HttpCache";

@Component({
  selector: "crm-app",
  templateUrl: "./app.component.html",
  providers: [
    AdminQueries,
    Config,
    Cots,
    Distributors,
    DeaSearch,
    EditRequests,
    FilterPipe,
    OrderByPipe,
    Formatters,
    Helpers,
    Implementations,
    ItemsRange,
    ArrayJoinNotEmptyPipe,
    Newsletter,
    ReportSnapshots,
    JobTypes,
    Lifecycles,
    Locations,
    Manufacturers,
    Notify,
    Questions,
    Reports,
    Session,
    Specialties,
    Survey,
    Tags,
    Tasks,
    TaxStatuses,
    Themes,
    Tickets,
    TimeAgo,
    UrlParser,
    User,
    YearlySpendTypes,
    SaverLanding,
    Testimonials,
    Vendors,
    FDTickets,
    CostStudy,
    ContractConnections,
    TypeaheadHelper,
    ContractRequirements,
    DataChangeRequests,
    HttpCache,
  ],
})
export class AppComponent {
  public tasksCount = 0;
  private reps = [];
  private client_managers = [];
  private notify = new Notify();
  private fibVersionStream;
  private fibMessagesStream;
  private fibMessagesInitialized = false;
  public editReqsCount = 0;
  public repsCount = 0;
  public year = 2018;

  constructor(
    public session: Session,
    public locations: Locations,
    private specialties: Specialties,
    private distributors: Distributors,
    private usersService: User,
    private router: Router,
    public config: Config,
    private tasks: Tasks,
    private editReqSrv: EditRequests,
  ) {
    this.year = new Date().getFullYear();
    session.addOnLoadHandler(() => {
      this.checkTotp();
      if (this.router.url === "/") {
        this.loadCounters();
      } else {
        setTimeout(() => {
          this.loadCounters();
        }, 2000);
      }
      setTimeout(() => {
        this.preload();
      }, 3000);
    });
  }

  ngAfterViewInit() {
    this.checkTotp();
  }

  checkTotp() {
    if (this.session.user && !this.session.user.__is_totp) {
      setTimeout(() => {
        this.checkTotp();
      }, 1000);
    }
    if (
      this.router.url !== "/settings" &&
      this.session.user &&
      this.session.user.id &&
      this.session.user.__is_totp === false
    ) {
      this.router.navigateByUrl("/settings");
    }
  }

  loadCounters() {
    this.tasks.getTasks().subscribe(
      (results) => {
        if (results && results.length) {
          if (this.session && this.session.user && this.session.user.id) {
            this.tasksCount = 0;
            results.forEach((t: ITask) => {
              if (t.user_id == this.session.user.id && this.tasks.getBoxOfTask(t) == "INBOX") {
                this.tasksCount++;
              }
            });
          }
        }
      },
      () => {},
    );
    this.editReqSrv.getRequests().subscribe(
      (i) => (this.editReqsCount = i.length),
      () => {},
    );
    this.usersService.getPossibleRepsList().subscribe(
      (reps) => {
        if (reps && reps.length) {
          this.repsCount = reps.length;
        }
      },
      () => {},
    );
    this.locations.getLocationsCount();
  }

  preload() {
    if (location.host === "localhost:4200") {
      return "don`t run locally to let protractor run tests";
    }
    this.locations.getPending(0, 100);
    this.locations.getMembers(0, 100);
    this.locations.getOnHold(0, 100);
    this.locations.getIneligible(0, 100);
    this.specialties.load();
    this.distributors.load();
  }
}
