import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {ITask} from "../../interfaces/ITask";
import {Tasks} from "../../services/Tasks";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {ITaskEmail} from "../../interfaces/ITaskEmail";
import {IUser} from "../../interfaces/IUser";
import {IUserFromEmail} from "../../interfaces/IUserFromEmail";
import {ITicket} from "../../interfaces/ITicket";
import {Tickets} from "../../services/Tickets";
import {Formatters} from "../../services/Formatters";
import {Config} from "../../services/Config";
import {ILocationContact} from "../../interfaces/ILocationContact";
import {ISignatureReq, User} from "../../services/User";
import {ITaskFileUploaded} from "../../interfaces/ITaskFileUploaded";
import {Locations} from "../../services/Locations";

declare var $: any;
declare var doT: any;
declare var ClipboardJS: any;

@Component({
  selector: '[location-task]',
  templateUrl: './task.html',
  host: {"(click)": "click($event)"},
  providers: [DatePipe]
})
export class LocationTaskCmp {
  @Input() task: ITask;
  @Input() fromEmails: IUserFromEmail[];
  @Output() update = new EventEmitter();
  @ViewChild('task_modal') taskModal: ElementRef;
  @ViewChild('mbody') mbody: ElementRef;
  @ViewChild('copy') copyBtn: ElementRef;

  public saving = false;
  public loading = false;
  public askDelete = false;
  public fromUsersHandler;
  public assignUsersHandler;
  public addingNote = false;
  public note: string = '';
  public placeholders;
  public themes;
  public notes: ITicket[] = [];
  public reason: string;
  public addFileLineVisible = false;
  public selectCopy = false;
  public isActive = false;
  public newAssignedUser: IUser;
  private movedToBody = false;
  private emailsToUser: ITaskEmail[];

  constructor(public taskService: Tasks, public session: Session, public tickets: Tickets, public fmt: Formatters,
    public config: Config, public router: Router, public usrSrv: User, private locSrv: Locations, private datePipe: DatePipe) {
    this.fromUsersHandler = (users) => {
      this.fromUsersHandlerF(users);
    };
    this.assignUsersHandler = (users) => {
      this.assignUsersHandlerF(users);
    };
  }

  ngOnChanges() {
    // don't call renderModal() from this function to avoid recursion
    if (this.task && this.isActive) {
      if (!this.task.emails || this.task.emails.length < 1) {
        if (!this.task.is_cancelled && !this.task.is_completed && this.task.meta) {
          let defaultFromUserID = this.session.user.id;
          if (this.task.__facility_theme_id && this.config.themesMap[this.task.__facility_theme_id] && this.config.themesMap[this.task.__facility_theme_id].task_from_user_id) {
            defaultFromUserID = this.config.themesMap[this.task.__facility_theme_id].task_from_user_id
          }
          if (this.task.meta.is_rep_owner_preferable_assignee && this.task.__rep_cm_user_id) {
            defaultFromUserID = this.task.__rep_cm_user_id;
          }
          if (this.task.meta.assign_to_user_id > 0) {
            defaultFromUserID = this.task.meta.assign_to_user_id;
          }
          if (this.task.meta.default_from_email_by_theme_id && this.config.themesMap[this.task.meta.default_from_email_by_theme_id] && this.config.themesMap[this.task.meta.default_from_email_by_theme_id].task_from_user_id) {
            defaultFromUserID = this.config.themesMap[this.task.meta.default_from_email_by_theme_id].task_from_user_id;
          }
          this.setDotDescriptionHandler();
          let replacePlaceholdersInEmail = null;
          if (this.task.meta.email_template) {
            let email: ITaskEmail = {
              body: this.task.meta.email_template,
              from_user_id: defaultFromUserID,
              to_user_id: this.task.meta.to_user_id || this.task.__facility_contact_id,
              subject: this.task.meta.email_subject,
              task_queue_id: this.task.id,
              cc_users_list: this.task.meta.cc_users_list,
            };
            if (email.from_user_id == this.session.user.id) {
              email.__from_user = <ILocationContact>this.session.user;
            }
            if (this.task.meta.is_rep_preferable_contact && this.task.__facility_rep_user_id) {
              email.to_user_id = this.task.__facility_rep_user_id;
            }
            if (this.task.__preferable_contact_id) {
              email.to_user_id = this.task.__preferable_contact_id;
            }
            this.setToUserID(email, email.to_user_id);
            if (doT && doT.template) {
              try {
                doT.templateSettings.strip = false;
                email.__template_handler = doT.template(email.body);
                email.__subject_handler = doT.template(email.subject);
                replacePlaceholdersInEmail = email;
              } catch (e) {
                console.error(e);
              }
            }
            this.task.emails = [email];
          }
          this.setPlaceholders(replacePlaceholdersInEmail);
        }
      }
    }
  }

  setDotDescriptionHandler() {
    if (doT && doT.template && this.task.description) {
      try {
        doT.templateSettings.strip = false;
        this.task.__description_handler = doT.template(this.task.description);
      } catch (e) {
        console.error(e);
      }
    }
  }

  click() {
    if (!this.isActive) {
      this.isActive = true;
      console.info(this.task);
      setTimeout(() => {
        if (!this.movedToBody && this.taskModal && this.taskModal.nativeElement) {
          document.body.appendChild(this.taskModal.nativeElement);
          this.movedToBody = true;
        }
        setTimeout(() => {
          this.renderModal();
        }, 1);
      }, 1);
    } else {
      this.renderModal();
    }
  }

  renderModal() {
    if (this.taskModal && this.taskModal.nativeElement) {
      if (this.task) {
        $(this.taskModal.nativeElement).modal('show');
        this.loadNotes();
        this.loadContacts();
        if (this.task.meta.task_type_id == 2) {
          this.initCopyBtn();
        }
        this.loadEmailsToUserID();
        setTimeout(() => {
          this.ngOnChanges();
        }, 1000);
      }
    }
  }

  loadEmailsToUserID() {
    if (this.task && this.task.emails && this.task.emails[0] && this.task.emails[0].to_user_id) {
      this.emailsToUser = [];
      this.taskService.getTaskEmailsToUser(this.task.emails[0].to_user_id).subscribe((r) => {
        this.emailsToUser = r;
        this.setPlaceholders(this.task.emails[0]);
      }, () => {});
    }
  }

  loadContacts() {
    if (!this.task || (this.task.__contacts && this.task.__contacts.length > 0) || this.task.is_cancelled || this.task.is_completed || !this.task.__facility_group_id) {
      return;
    }
    this.locSrv.getContactsOfGroup(this.task.__facility_group_id).subscribe((c) => {
      this.task.__contacts = c;
    }, () => {});
  }

  submit(markAsCompleted?: boolean) {
    if (!this.task || this.task.is_completed) {
      return;
    }
    if (this.task.emails && this.task.emails.length > 0) {
      let email = this.task.emails[0];
      if (email && email.body) {
        if (email.body.indexOf('undefined') > -1 || email.body.indexOf('NaN') > -1 || email.body.toLowerCase().indexOf('null') > -1) {
          let notify = new Notify();
          notify.error('Some of template merge fields was not replaced. Please do not edit this task and create task/card for developers.');
          return;
        }
      }
    }
    ///////////
    // ALL NOTIFICATIONS WITH "RETURN" SHOULD BE THROWN BEFORE THIS LINE
    ///////////
    this.saving = true;
    let notify = new Notify();
    let prev_compl_st = this.task.is_completed;
    let prev_compl_date = this.task.date_completed;
    if (this.task.meta.task_type_id == this.config.TASK_TYPE_DONE && !this.task.delayed_until_date) {
      if (!this.task.date_completed) {
        this.setDateCompleted(new Date());
      }
      this.task.is_completed = true;
    }
    this.taskService.updateTask(this.task).subscribe(() => {
      this.saving = false;
      if (markAsCompleted) {
        this.task.is_completed = true;
      }
      notify.success('Action successful');
      this.update.emit();
      this.hide();
    }, (error) => {
      this.saving = false;
      notify.error('Error');
      this.task.is_completed = prev_compl_st;
      this.task.date_completed = prev_compl_date;
      console.error(error);
    })
  }

  saveEdits(cb?) {
    if (!this.task || !this.task.id) {
      return;
    }
    this.saving = true;
    let notify = new Notify();
    this.taskService.updateTask(this.task).subscribe(() => {
      this.saving = false;
      notify.success('Saved');
      if (cb) { cb();}
      this.hide();
    }, (error) => {
      this.saving = false;
      notify.error('Error');
      console.error(error);
    })
  }

  markAsCompletedWithoutEmail() {
    if (!this.task || this.task.is_completed) {
      return;
    }
    this.saving = true;
    let notify = new Notify();
    let prev_compl_st = this.task.is_completed;
    let date = new Date();
    let completed_date = date.toISOString().substring(0, 19).replace('T', ' ');
    if (!this.task.date_completed) {
      this.task.date_completed = completed_date;
    }
    this.task.is_completed = true;
    if (this.task.emails && this.task.emails.length) {
      let newEmails = [];
      this.task.emails.forEach((e: ITaskEmail) => {
        if (!e.id) {
          return;
        }
        if (!e.sent_date) {
          e.sent_date = completed_date;
        }
        newEmails.push(e);
      });
      this.task.emails = newEmails;
    }
    this.taskService.updateTask(this.task).subscribe(() => {
      this.saving = false;
      notify.success('Task has been marked as complete, email will not be sent.', 'Action successful');
      this.update.emit();
      this.hide();
    }, (error) => {
      this.saving = false;
      notify.error('Error');
      this.task.is_completed = prev_compl_st;
      console.error(error);
    })
  }

  resendEmail(email: ITaskEmail) {
    let n = new Notify();
    this.saving = true;
    this.taskService.resendTaskEmail(email).subscribe(() => {
      this.saving = false;
      this.task.is_completed = false;
      n.success('Success!');
      this.update.emit();
      this.hide();
    }, (err) => {
      this.saving = false;
      console.error(err);
      n.error('Error', err._body);
    });
  }

  sendEmail(email: ITaskEmail, offset?: string) {
    if (email.sending_error && email.sent_date) {
      this.resendEmail(email);
      return;
    }
    if (this.task.is_completed) {
      return;
    }
    if (!email.to_user_id) {
      let notify = new Notify();
      notify.warning('Please select recipient');
      return;
    }
    if (!email.body) {
      let notify = new Notify();
      notify.error('Email is empty');
      return;
    }
    if (email.body.indexOf('undefined') > -1 || email.body.indexOf('NaN') > -1 || email.body.toLowerCase().indexOf('null') > -1) {
      let notify = new Notify();
      notify.error('Some of template merge fields was not replaced. Please do not edit this task and create a task/card for developers.');
      return;
    }
    if (email && this.task && this.task.id) {
      let dat = new Date();
      if (offset) {
        switch (offset) {
          case 'tomorrow_morning':
            dat.setDate(dat.getDate() + 1);
            dat.setUTCHours(11, 0); // 6+5
            break;
          case 'tomorrow_day':
            dat.setDate(dat.getDate() + 1);
            dat.setUTCHours(16, 0); // 11+5
            break;
          case 'tomorrow_evening':
            dat.setDate(dat.getDate() + 1);
            dat.setUTCHours(21, 0); // 16+5
            break;
        }
      }
      email.send_on_date = dat.toISOString();
      email.task_queue_id = this.task.id;
      this.submit(true);
    }
  }

  removeEmail(email: ITaskEmail) {
    if (email) {
      if (email.id) {
        this.saving = true;
        let notify = new Notify();
        this.taskService.deleteTaskEmail(email.id).subscribe(() => {
          this.saving = false;
          notify.success('Removed');
          try {
            this.task.emails.splice(this.task.emails.indexOf(email), 1);
          } catch (e) {}
        }, (error) => {
          this.saving = false;
          notify.error('Error');
          console.error(error);
        });
      } else {
        try {
          this.task.emails.splice(this.task.emails.indexOf(email), 1);
        } catch (e) {}
      }
    }
  }

  delay(days) {
    let dat = new Date();
    dat.setDate(dat.getDate() + days);
    this.task.delayed_until_date = dat.toISOString();
    this.submit();
  }

  hide() {
    if (this.taskModal && this.taskModal.nativeElement) {
      $(this.taskModal.nativeElement).modal('hide');
    }
  }

  remove() {
    this.saving = true;
    let notify = new Notify();
    this.taskService.deleteTask(this.task.id).subscribe(() => {
      this.saving = false;
      this.task.is_cancelled = true;
      notify.success('Action successful');
      //this.update.emit();
      this.hide();
      if (this.reason && this.reason.length > 0 && this.task.cancellation_reason != this.reason) {
        this.task.cancellation_reason = this.reason;
        this.taskService.updateTask(this.task).subscribe(() => {}, () => {});
      }
    }, (error) => {
      this.saving = false;
      notify.error('Error');
      console.error(error);
    });
  }

  fromUsersHandlerF(users: IUser[]) {
    if (this.task && this.task.__facility_theme_id > 0 && users && users.length > 0 && this.fromEmails && this.fromEmails.length) {
      this.fromEmails.every((f) => {
        if (f.theme_id === this.task.__facility_theme_id) {
          users.every((u: IUser) => {
            if (f.user_id === u.id) {
              u.email = f.from_email;
              return false;
            }
            return true;
          });
        }
        return true;
      });
    }
  }

  assignUsersHandlerF(users: IUser[]) {
    users.every((u: IUser) => {
      return (u.cm_access_level > 19);
    });
  }

  fromUserDisplay(user: IUser) {
    return `${user.fname} ${user.lname} <${user.email}>`;
  }

  assignUserDisplay(user: IUser) {
    return `${user.fname} ${user.lname}`;
  }

  replacePlaceholders(email?: ITaskEmail) {
    if (email && email.__template_handler && this.placeholders) {
      try {
        //noinspection TypeScriptValidateTypes
        let body = email.__template_handler(this.placeholders);
        email.body = body;
      } catch (e) {
        console.error(e);
      }
    }
    if (email && email.__subject_handler && this.placeholders) {
      try {
        //noinspection TypeScriptValidateTypes
        let subject = email.__subject_handler(this.placeholders);
        email.subject = subject;
      } catch (e) {
        console.error(e);
      }
    }
    if (this.task && this.task.description) {
      if (!this.task.__description_handler) {
        this.setDotDescriptionHandler();
      }
      if (this.task.__description_handler) {
        try {
          //noinspection TypeScriptValidateTypes
          let description = this.task.__description_handler(this.placeholders);
          this.task.description = description;
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  setFromUser(user: IUser, email: ITaskEmail) {
    email.from_user_id = user.id;
    this.setPlaceholders(email);
  }

  setUser(user: IUser) {
    this.newAssignedUser = user;
  }

  changeAssignedUser() {
    if (this.newAssignedUser && this.newAssignedUser.id && this.task.user_id != this.newAssignedUser.id) {
      this.task.user_id = this.newAssignedUser.id;
      this.saveEdits(() => {
        this.update.emit();
      });
    }
  }

  setPlaceholders(replaceInEmail?: ITaskEmail) {
    if (!this.placeholders) {
      this.placeholders = {
        MEDSURG_EFF_DATE: '',
        MEDSURG_EFF_DATE_ISO: '',
        PHARMA_EFF_DATE: '',
        PHARMA_EFF_DATE_ISO: '',
      };
    }
    let t = this.task;
    if (!this.placeholders.MEDSURG_EFF_DATE) {
      if (this.task.__facility_medsurg_eff_date && this.task.__facility_medsurg_eff_date !== '0000-00-00') {
        this.placeholders.MEDSURG_EFF_DATE = this.fmt.getFormattedDate(new Date(this.task.__facility_medsurg_eff_date), '[MMMM] [d], [YYYY]');
        this.placeholders.MEDSURG_EFF_DATE_ISO = this.task.__facility_medsurg_eff_date;
      }
    }
    if (!this.placeholders.PHARMA_EFF_DATE) {
      if (this.task.__facility_pharma_eff_date && this.task.__facility_pharma_eff_date !== '0000-00-00') {
        this.placeholders.PHARMA_EFF_DATE = this.fmt.getFormattedDate(new Date(this.task.__facility_pharma_eff_date), '[MMMM] [d], [YYYY]');
        this.placeholders.PHARMA_EFF_DATE_ISO = this.task.__facility_pharma_eff_date;
      }
    }
    if (!this.placeholders.POC_RX_SIGNUP_DATE) {
      if (this.task.__poc_rx_signup_date && this.task.__poc_rx_signup_date !== '0000-00-00 00:00:00' && this.task.__poc_rx_signup_date !== '0000-00-00') {
        this.placeholders.POC_RX_SIGNUP_DATE = this.fmt.getFormattedDate(new Date(this.task.__poc_rx_signup_date), '[MMMM] [d], [YYYY]');
        this.placeholders.POC_RX_SIGNUP_DATE_ISO = this.task.__poc_rx_signup_date;
      }
    }
    this.placeholders.GPOID = this.task.__facility_gpo_id;
    this.placeholders.COID = this.task.__facility_gpo_co_id;
    this.placeholders.CLIENT_MANAGER = this.session.user.fname + ' ' + this.session.user.lname;
    this.placeholders.CLIENT_MANAGER_EMAIL = this.session.user.email;
    this.placeholders.CLIENT_MANAGER_TEL = this.session.user.phone;
    this.placeholders.THEME_ID = this.task.__facility_theme_id;
    this.placeholders.THEME_NAME = this.task.__facility_theme_name;
    this.placeholders.THEME_DOMAIN = this.task.__facility_theme_landing_domain;
    if (!this.placeholders.FACILITY_NAME && this.task && this.task.__facility_name) {
      this.placeholders.FACILITY_NAME = this.task.__facility_name;
    }
    this.placeholders.CLIENT_MANAGER = this.session.user.fname + ' ' + this.session.user.lname;
    this.placeholders.CLIENT_MANAGER_EMAIL = this.task.__user_email || this.session.user.email;
    this.placeholders.CLIENT_MANAGER_TEL = this.task.__user_phone || this.session.user.phone;
    if (this.task && this.task.__user_name) {
      this.placeholders.CLIENT_MANAGER = this.task.__user_name;
    }
    if (t) {
      this.placeholders.GPOID = t.__facility_gpo_id;
      this.placeholders.COID = t.__facility_gpo_co_id;
      this.placeholders.DBA = t.__facility_dba;
      this.placeholders.DEA = t.__facility_dea;
      this.placeholders.DEA_NAME = t.__facility_dea_name;
      this.placeholders.DEA_ADDRESS = t.__facility_dea_address;
      this.placeholders.HIN = t.__facility_hin;
      this.placeholders.PHONE = t.__facility_phone;
      this.placeholders.ADDRESS = t.__facility_address;
      this.placeholders.ADDRESS2 = t.__facility_address2;
      this.placeholders.CITY = t.__facility_city;
      this.placeholders.STATE = t.__facility_state;
      this.placeholders.ZIP = t.__facility_zip;
      this.placeholders.JJHCS = t.__facility_jjhcs;
      this.placeholders.REP_ID = t.__facility_rep_user_id;
      this.placeholders.POC_ID = t.__facility_primary_contact_user_id;
      this.placeholders.REP_FNAME = t.__rep_fname;
      this.placeholders.REP_LNAME = t.__rep_lname;
      this.placeholders.REP_EMAIL = t.__rep_email;
      this.placeholders.REPMGR_FNAME = t.__rep_cm_user_fname;
      this.placeholders.REPMGR_LNAME = t.__rep_cm_user_lname;
      this.placeholders.REPMGR_EMAIL = t.__rep_cm_user_email;
      this.placeholders.REPMGR_PHONE = t.__rep_cm_user_phone;
      this.placeholders.REPMGR_JOB_TITLE = t.__rep_cm_user_job_title;
      this.placeholders.CLIENT_MANAGER_JOB_TITLE = t.__cm_job_title;
      this.placeholders.POC_FNAME = t.__poc_fname;
      this.placeholders.POC_LNAME = t.__poc_lname;
      this.placeholders.POC_EMAIL = t.__poc_email;
      this.placeholders.POC_RX_LINK = t.__poc_rx_link;
      this.placeholders.SANOFI_PDF_LIST = t.__sanofi_form_pdfs_list;
      this.placeholders.GSK_PDF_LIST = t.__gsk_form_pdfs_list;
      this.placeholders.REP_WELCOME_FILES_LIST = t.__rep_welcome_attachment_list;
      this.placeholders.SANOFI_REJECTION_REASON = t.__sanofi_rejection_reason;
      this.placeholders.SUB_THEME_NAME = t.__sub_theme_name;
      this.placeholders.SUB_THEME_LABEL = t.__sub_theme_label;
      this.placeholders.SUB_THEME_LOGO_URL = t.__sub_theme_logo_url;
      this.placeholders.SUB_THEME_MESSAGE = t.__sub_theme_message;
      this.placeholders.PA_DATE = t.__pa_date;
      this.placeholders.PA_DATE_FORMAT = this.datePipe.transform(t.__pa_date, 'longDate');

      //EMAIL_HISTORY
      if (this.emailsToUser && this.emailsToUser.length > 0) {
        try {
          for (let k in this.placeholders) {
            if (k.indexOf('EMAIL_HISTORY_') === 0) {
              this.placeholders[k] = null;
            }
          }
          this.emailsToUser.forEach((m: ITaskEmail) => {
            if (m.sent_date) {
              let key = 'EMAIL_HISTORY_' + (m.__task_id).toString();
              let dt = this.datePipe.transform(t.__pa_date, 'medium');
              let quote = (m.body || '').split("\n").join("\n> ");
              this.placeholders[key] = `On ${dt}, ${m.__from_user.fname} ${m.__from_user.lname} wrote:\n> ${quote}`;
            }
          });
        } catch (e) {
          console.error('Error: ', e)
        }
      }

      // SIGNATURE
      let req: ISignatureReq = {
        signature_target: "TEXT",
        cm_id: this.session.user.id,
        theme_id: t.__facility_theme_id,
        location_id: t.location_id,
      };
      if (replaceInEmail && replaceInEmail.to_user_id) {
        req.recipient_user_id = replaceInEmail.to_user_id;
      }
      if (replaceInEmail && replaceInEmail.from_user_id) {
        req.cm_id = replaceInEmail.from_user_id;
      }
      this.usrSrv.getSignature(req).subscribe((s) => {
        this.placeholders.SIGNATURE = s;
        this.replacePlaceholders(replaceInEmail);
      }, (err) => {
        console.error(err);
      });
    }
    this.replacePlaceholders(replaceInEmail);
  }

  addNote() {
    if (!this.task || !this.task.id || !this.task.location_id || !this.note) {
      return false;
    }
    let ticket = new ITicket();
    ticket.type_id = 6;
    ticket.location_id = this.task.location_id;
    ticket.cm_user_id = this.session.user.id;
    ticket.task_id = this.task.id;
    ticket.requestor_user_id = this.session.user.id;
    if (this.task.emails && this.task.emails[0] && this.task.emails[0].to_user_id) {
      ticket.requestor_user_id = this.task.emails[0].to_user_id;
    }
    ticket.content = this.note;
    ticket.subject = 'Note';
    this.saving = true;
    let n = new Notify();
    this.tickets.save(ticket).subscribe(() => {
      this.saving = false;
      this.note = '';
      this.addingNote = false;
      this.loadNotes();
      n.success('Action successful');
    }, (error) => {
      this.saving = false;
      console.error(error);
      n.error('Error');
    });
  }

  loadNotes() {
    if (!this.task || !this.task.id || !this.task.location_id) {
      return;
    }
    this.tickets.loadByLocationID(this.task.location_id).subscribe((notes) => {
      if (notes && notes.length) {
        this.notes = [];
        notes.forEach((n: ITicket) => {
          if (n.task_id === this.task.id) {
            this.notes.push(n);
          }
        });
      }
    });
  }

  openLocationNotes() {
    if (!this.task.location_id) {
      return;
    }
    this.hide();
    this.router.navigateByUrl('/location/' + this.task.location_id + '?tab=4');
  }

  getContactTagClass(c: ILocationContact): string {
    if (!this.task) {
      return '';
    }
    switch (this.getContactLabel(c)) {
      case 'POC':
        return 'badge-success';
      case 'Rep':
        return 'badge-primary';
      default:
        return 'badge-default';
    }
  }

  getContactLabel(c: ILocationContact): string {
    if (!this.task) {
      return '';
    }
    if (c.id == this.task.__facility_contact_id) {
      return 'POC';
    }
    if (c.id == this.task.__facility_primary_contact_user_id) {
      return 'POC';
    }
    if (c.id == this.task.__facility_rep_user_id) {
      return 'Rep';
    }
    return 'Contact';
  }

  setToUser(email: ITaskEmail, c: ILocationContact) {
    email.to_user_id = c.id;
    email._toUser = c;
  }

  setToUserID(email: ITaskEmail, id: number) {
    email.to_user_id = id;
    email._toUser = null;
    if (this.task) {
      let t = this.task;
      if (t.__contacts) {
        t.__contacts.forEach((c) => {
          if (c.id == id) {
            email._toUser = c;
          }
        });
      } else {
        if (email.__to_user && id == email.__to_user.id) {
          email._toUser = email.__to_user;
        }
      }
      this.loadEmailsToUserID();
    }
    if (!email._toUser) {
      this.usrSrv.getUserInfo(id).then((i) => {
        email._toUser = {
          id: i.id,
          fname: i.fname,
          lname: i.lname,
          email: i.email,
          phone: i.phone,
          type_id: i.type_id,
          contact_note: i.contact_note,
          is_email_suppressed: i.is_email_suppressed,
        };
      }, () => {});
    }
  }

  fileUploaded(file: ITaskFileUploaded) {
    if (file.event && file.event.currentTarget && file.event.currentTarget.response) {
      let response = JSON.parse(file.event.currentTarget.response);
      if (this.task.emails && this.task.emails[0]) {
        let n = file.filename || 'File';
        this.task.emails[0].body = this.task.emails[0].body + `\n\n${n}: ${response.url}`;
        let notify = new Notify();
        notify.success('Link has been added to the body of email', 'Uploaded');
        if (this.mbody && this.mbody.nativeElement) {
          setTimeout(() => {
            try {
              this.mbody.nativeElement.scrollTop = this.mbody.nativeElement.scrollHeight;
            } catch (e) {
              console.error(e);
            }
          }, 1);
        }
      }
    }
  }

  setDateCompleted(date: Date) {
    this.task.date_completed = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  setDueDate(date) {
    if (!date) {
      return;
    }
    let due_date = date;
    if (this.isTimeInPast(due_date)) {
      let n = new Notify();
      n.error('Please check that Due Date is not in the past');
      return;
    }
    this.task.due_date = due_date;
  }

  isTimeInPast(d: string): boolean {
    try {
      if (!d) {
        return true;
      }
      let today = new Date();
      let that_day = new Date(d);
      if (that_day.getTime() < today.getTime()) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  initCopyBtn() {
    setTimeout(() => {
      try {
        if (this.copyBtn && ClipboardJS) {
          let clipboard = new ClipboardJS(this.copyBtn.nativeElement, {
            container: this.taskModal.nativeElement
          });
          clipboard.on('success', (e) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clipboard.on('error', () => {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
        }
      } catch (e) {}
    }, 60);
  }

  copyBtnClick($event) {
    $event.stopPropagation();
    setTimeout(() => {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e) => {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(() => {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }
}
