import {Component, Input} from '@angular/core';
import {IReviewResult} from "../../interfaces/IReviewResult";

@Component({
  selector: 'answer-radio',
  templateUrl: './answer_radio.html'
})
export class AnswerRadioCmp {
  @Input() answers:Array<IReviewResult>;
}
