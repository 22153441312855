import {Component, Input, Output, EventEmitter} from '@angular/core';
import {IVaccine, IVaccinesTable} from "../../interfaces/ISaverLandingVaccines";

@Component({
  selector: 'vaccines-cell',
  templateUrl: 'cell.html',
})
export class VaccinesCellCmp {
  @Input() catID:number;
  @Input() manID:number;
  @Input() vtable: IVaccinesTable;
  @Input() selectedList: string;
  @Output() selectedListChange = new EventEmitter();

  public vaccines:IVaccine[] = [];

  ngOnInit() {
    this.vaccines = [];
    for (let v of this.vtable.vaccines) {
      if (v.vaccine_category_id == this.catID && v.vaccine_manufacturer_id == this.manID) {
        this.vaccines.push(v);
      }
    }
  }

  isSelected(id:number):boolean {
    if (this.selectedList) {
      let l = this.selectedList.split(",");
      if (l.indexOf(id.toString()) > -1) {
        return true;
      }
    }
    return false;
  }

  toggle(id:number) {
    if (!id) { return false;}
    let arr = [];
    if (this.selectedList) {
      arr = this.selectedList.split(',');
    }
    let i = arr.indexOf(id.toString());
    if (i > -1) {
      arr.splice(i, 1);
    } else {
      arr.push(id.toString());
    }
    this.selectedList = arr.join(',').trim();
    if (this.selectedList[0] == ',') {
      this.selectedList = this.selectedList.substring(1);
    }
    this.selectedListChange.emit(this.selectedList);
  }
}
