import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Session} from "../../services/Session";
import {DeaSearch} from "../../services/DeaSearch";
import {IDeaSearchResult} from "../../interfaces/IDeaSearchResult";
import {Notify} from "../../services/Notify";
import {ILocation} from "../../interfaces/ILocation";
import {Observable} from 'rxjs';

@Component({
  selector: 'dea-search',
  templateUrl: './dea-search.html',
})
export class DeaSearchCmp {
  public results:IDeaSearchResult[] = [];
  public loading = false;
  public expand = false;
  public cache_date:string;
  public cache_id:number;
  public multiSearch = '';
  public addressMultiple = '';
  public tab = 1;
  public searchDone = false;
  @Input() dea:string = '';
  @Input() address:string = '';
  @Input() company:string = '';
  @Input() zip:string = '';
  @Input() state:string = '';
  @Input() location:ILocation;
  @Output() selected = new EventEmitter();

  constructor(public session:Session, public deaSearch:DeaSearch) {}

  ngOnInit() {
    if (!this.dea || this.dea.length !== 9 || this.company || this.zip || this.state) { // 9 - length of valid DEA number
      this.expand = true;
    }
  }

  search() {
    let notify = new Notify();
    if (!this.dea && !this.company && !this.zip && !this.state) {
      notify.warning('At least one of fields: DEA, Company, State or ZIP should be filled.');
      return;
    }
    this.loading = true;
    this.searchDone = false;
    this.results = [];
    this.cache_date = null;
    this.cache_id = null;
    let company = this.expand ? this.company : null;
    let zip = this.expand ? this.zip : null;
    let state = this.expand ? this.state : null;
    this.deaSearch.search(this.dea, company, zip, state).subscribe((result) => {
      this.loading = false;
      this.searchDone = true;
      this.cache_date = result.cache_date;
      this.cache_id = result.cache_id;
      if (!this.address || !this.expand) {
        this.results = result.response;
      } else {
        if (result.response.length > 0) {
          let addrlw = this.address.toLowerCase().trim();
          result.response.every((r) => {
            let addr = [r.address1, r.address2, r.address3, r.address4].join(' ').toLowerCase();
            if (addr.indexOf(addrlw) > -1) {
              this.results.push(r);
            }
            return true;
          });
        }
      }
    }, (error) => {
      this.loading = false;
      this.searchDone = true;
      console.error(error);
    });
  }

  searchMultiple() {
    let notify = new Notify();
    if (!this.multiSearch) {
      notify.warning('Names List should be filled.');
      return;
    }
    let names = this.multiSearch.split(/\r?\n/);
    if (!names || names.length < 1) {
      return;
    }
    this.loading = true;
    this.searchDone = false;
    this.results = [];
    this.cache_date = null;
    this.cache_id = null;
    let handled = 0;
    let d = ()=> {
      handled++;
      if (handled == names.length) {
        this.loading = false;
        this.searchDone = true;
      }
    };
    let addrlw = (this.addressMultiple || '').toLowerCase().trim();
    names.forEach((name)=> {
      this.deaSearch.search('', name.trim(), this.zip, this.state).subscribe((result)=> {
        d();
        if (result && result.response && result.response.length > 0) {
          result.response.forEach((r) => {
            let addr = [r.address1, r.address2, r.address3, r.address4].join(' ').toLowerCase();
            if (addrlw) {
              if (addr.indexOf(addrlw) > -1) {
                this.results.push(r);
              }
            } else {
              this.results.push(r);
            }
          });
        }
      }, ()=> {
        d();
      });
    });
  }

  removeCache() {
    if (!this.cache_id) {
      return false;
    }
    let n = new Notify();
    this.loading = true;
    this.deaSearch.removeCache(this.cache_id).subscribe(() => {
      this.loading = false;
      n.success('Cache cleaned');
      this.cache_date = null;
      this.cache_id = null;
      this.search();
    }, (error) => {
      this.loading = false;
      console.error(error);
      n.error('Error');
    });
  }

  select(r:IDeaSearchResult) {
    this.selected.emit(r);
  }
}
