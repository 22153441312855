import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Session} from "../../services/Session";

@Component({
  template: ''
})
export class LogoutCmp {
  constructor(public session:Session, public router:Router) {
    this.session.logout();
    this.router.navigate(['login']);
  }
}
