import {Component, Input} from '@angular/core';
import {Newsletter} from "../../services/Newsletter";
import {Session} from "../../services/Session";
import {IRecommendedContract} from "../../services/Newsletter";

@Component({
  selector: 'location-marketing',
  templateUrl: './marketing.html',
})
export class LocationMarketingCmp {
  @Input() locationID;
  public recommendedContracts:IRecommendedContract[] = [];

  constructor(private newsletterSrv:Newsletter, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.loadRecommendations();
    });
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    this.loadRecommendations();
  }

  loadRecommendations() {
    if (!this.locationID) {
      return;
    }
    this.newsletterSrv.getRecommendedContracts(this.locationID).subscribe((response)=> {
      if (response && response.contracts && response.contracts.length > 0) {
        this.recommendedContracts = response.contracts;
      } else {
        this.recommendedContracts = [];
      }
      this.recommendedContracts.push({vendor_name: 'Staples Business Advantage'});
      this.recommendedContracts.push({vendor_name: 'FedEx'});
      this.recommendedContracts.push({vendor_name: 'Enterprise'});
    }, ()=> {});
  }
}
