import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {User} from "../../services/User";
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {IDupField} from "../../interfaces/IDupField";

@Component({
  templateUrl: './disable-user.html',
  selector: 'disable-user',
})
export class DisableUserCmp {
  public emails:string[] = [];
  public selectedEmail = '';
  public disabling = false;

  constructor(private route:ActivatedRoute, private usersSrv:User, private session:Session) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params)=> {
      if (params && params['email']) {
        let allEmails = params['email'].split('|');
        if (allEmails && allEmails.length > 0) {
          this.emails = [];
          allEmails.forEach((e:string)=> {
            if (e.indexOf("@purchaseclinic.com") < 0 && this.emails.indexOf(e) < 0) {
              this.emails.push(e);
            }
          });
        }
      }
    }, ()=> {});
  }

  selectEmail(email:string) {
    this.selectedEmail = email;
  }

  disableUserLogin() {
    if (!this.selectedEmail) {
      return false;
    }
    let n = new Notify();
    this.disabling = true;
    this.usersSrv.disableEmailLogin(this.selectedEmail).subscribe(()=> {
      n.success('Action successful');
      this.disabling = false;
      let i = this.emails.indexOf(this.selectedEmail);
      if (i > -1) {
        this.emails.splice(i, 1);
      }
      this.selectedEmail = '';
    }, (err)=> {
      this.disabling = false;
      if (err && err.status && err.status == 404) {
        n.error('User not found');
      } else {
        n.error(JSON.stringify(err), 'Error');
        console.error(err);
      }
    });
  }
}
