import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Vendors} from "../../services/Vendors";
import {IVendorData} from "../../interfaces/IVendor";
import {UrlParser} from "../../services/UrlParser";
import {IVendorReport} from "../../interfaces/IVendor";
import {Session} from "../../services/Session";
import {IPopupQuery} from "../../interfaces/IPopupQuery";

@Component({
  templateUrl: './vendor.html',
})
export class VendorPageCmp {
  public id = 0;
  public data:IVendorData;
  public loading:boolean = false;
  public name:string = '';
  public orderField;
  public sortDesc;
  public tab = 1;
  public companyAtr = '';
  public queries:IPopupQuery[];

  constructor(public vendors:Vendors, public params:ActivatedRoute, public router:Router, public urlParser:UrlParser, private session:Session) {}

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    if (!this.id) {
      this.id = parseInt(params['id']);
    }
    if (!this.id) {
      this.router.navigate(['']);
      return;
    }
    this.parseUrlParams();
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.loading = true;
    this.vendors.getVendorData(this.id, this.companyAtr).subscribe((data)=> {
      this.data = data;
      if (data.vendors) {
        data.vendors.forEach((v)=> {
          if (v.name && v.is_friendly_name) {
            this.name = v.name;
          }
          if (!this.name) {
            this.name = v.name;
          }
        })
      }
      if (data.report && data.report.length > 0) {
        data.report.forEach((r:IVendorReport)=> {
          if (r.contract_numbers) {
            let c = r.contract_numbers.split(',');
            if (c.length > 1) {
              c.sort();
              r.contract_numbers = c.join(', ');
            }
          }
          if (r.sf_contract_numbers) {
            let cf = r.sf_contract_numbers.split(',');
            if (cf.length > 1) {
              cf.sort();
              r.sf_contract_numbers = cf.join(', ');
            }
          }
          if (data.reach && data.reach.length > 0) {
            data.reach.forEach((z)=> {
              if (z.quarter == r.invoice_q && z.reach > 0) {
                r.__reach_st = Math.round((r.st_gpo / z.reach) * 100);
                r.__reach_sf = Math.round((r.sf_gpo / z.reach) * 100);
              }
            });
          }
        });
      }
      if (data.report_at && data.report_at.length > 0) {
        data.report_at.forEach((r:IVendorReport)=> {
          if (r.contract_numbers) {
            let c = r.contract_numbers.split(',');
            if (c.length > 1) {
              c.sort();
              r.contract_numbers = c.join(', ');
            }
          }
          if (r.sf_contract_numbers) {
            let cf = r.sf_contract_numbers.split(',');
            if (cf.length > 1) {
              cf.sort();
              r.sf_contract_numbers = cf.join(', ');
            }
          }
          if (data.reach_at && data.reach_at.length > 0) {
            data.reach_at.forEach((z)=> {
              if (z.quarter == r.invoice_q && z.reach > 0) {
                r.__reach_st = Math.round((r.st_gpo / z.reach) * 100);
                r.__reach_sf = Math.round((r.sf_gpo / z.reach) * 100);
              }
            });
          }
        });
      }
      this.queries = [
        {name: 'Report PC', query: data.query_report},
        {name: 'Report AT', query: data.query_report_at, commentary: 'Select additional report to get query for additional reports'},
        {name: 'Reach', query: data.query_reach},
        {name: 'Reach AT', query: data.query_reach_at},
      ];
      this.loading = false;
    }, ()=> {
      this.loading = false;
    });
  }

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  setTab(i) {
    this.tab = Math.max(parseInt(i), 1);
    this.urlParser.setParameter('tab', this.tab);
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.tab) {
      this.tab = Math.max(parseInt(urlParams.tab), 1);
    }
    if (urlParams.companyAtr) {
      this.companyAtr = urlParams.companyAtr;
    }
  }

  setCompanyAtr(company) {
    this.companyAtr = company;
    this.urlParser.setParameter('companyAtr', this.companyAtr);
    this.load();
  }
}
