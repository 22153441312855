import {startWith, map, debounceTime} from 'rxjs/operators';
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Locations} from "../../services/Locations";
import {ILocationSearchResult} from "../../interfaces/ILocationSearchResult";
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'header-search-box',
  templateUrl: './search.html',
  styleUrls: ['./tag.css'],
})
export class HeaderSearchBoxCmp {
  public searchInput: UntypedFormControl;
  public searchTerm: string;
  public results: ILocationSearchResult[] = [];
  public listVisible = false;
  public kIndex = 0;
  public loaded = false;
  public searchGroup = new UntypedFormGroup({
    searchInput: new UntypedFormControl()
  });

  constructor(public locSrv: Locations, public router: Router) {
    this.searchInput = <UntypedFormControl>this.searchGroup.controls['searchInput'];
    this.searchInput.valueChanges.pipe(debounceTime(150),map((val) => {
      return val.toLowerCase();
    }),startWith('')).subscribe((val)=> {
      if (!this.searchTerm && !val) {
        return false;
      }
      this.searchTerm = val;
      this.showSearchResults(this.searchTerm);
    }, ()=> {});
    this.loaded = true;
  }

  preventBubbling($event?) {
    if ($event) {
      if ($event.stopPropagation) {
        $event.stopPropagation();
      }
      if ($event.preventDefault) {
        $event.preventDefault();
      }
    }
  }

  showSearchResults(searchTerm) {
    this.kIndex = 0;
    this.loaded = false;
    this.results = [];
    this.locSrv.find(searchTerm, 0, 10).subscribe((locations)=> {
      this.results = locations;
      this.listVisible = (this.results.length > 0);
      this.loaded = true;
    }, ()=> {
      this.loaded = true;
    });
  }

  selectLocation(loc: ILocationSearchResult, $event?) {
    this.removeList();
    this.results = [];
    this.searchTerm = '';
    this.searchInput.setValue('');
    setTimeout(()=> {
      if (loc.is_rep_user && loc.rep_user_id) {
        this.router.navigate(['user', loc.rep_user_id]);
      } else {
        this.router.navigate(['location', loc.id]);
      }
    }, 0);
    this.preventBubbling($event);
    return false;
  }

  removeList() {
    this.listVisible = false;
  };

  blurSearch($event?) {
    setTimeout(()=> {
      if (this.listVisible) {
        this.removeList();
      }
    }, 250);
    this.preventBubbling($event);
    return false;
  }

  searchKey($event) {
    if (!this.results || !this.results.length) {
      return;
    }
    if (($event.keyCode === 38 || $event.code === 'ArrowUp')) {
      if (this.kIndex > 0) {
        this.kIndex--;
      } else {
        this.kIndex = this.results.length;
      }
    }
    if (($event.keyCode === 40 || $event.code === 'ArrowDown')) {
      if (this.kIndex < this.results.length) {
        this.kIndex++;
      } else {
        this.kIndex = 0;
      }
    }
    if ($event.keyCode === 13 || $event.code === 'Enter') {
      if (this.kIndex === 0) {
        this.showAllResults();
      } else {
        let c = this.results[(this.kIndex - 1)];
        if (c) {
          this.selectLocation(c);
        } else {
          this.showAllResults();
        }
      }
    }
  }

  public showAllResults($event?) {
    if (this.searchTerm) {
      this.removeList();
      this.router.navigate(['locations', 'total', this.searchTerm]);
    }
    this.preventBubbling($event);
  }
}
