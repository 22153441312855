import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Helpers} from "../../services/Helpers";

@Component({
  templateUrl: 'popup.html',
  selector: 'popup-list',
})
export class PopupListCmp {
  @Input() text:string = '';
  @Input() length:number = 0;
  @Input() saveSpaces;
  @Input() modalClass;
  @Input() containerIsBody;
  @ViewChild("modal") modal:ElementRef;
  public shortenedText:string = '';
  private movedToBody = false;

  constructor(private h: Helpers) {}

  openModal($event?) {
    if (this.modal && this.modal.nativeElement) {
      if (this.containerIsBody && !this.movedToBody) {
        document.body.appendChild(this.modal.nativeElement);
        this.movedToBody = true;
      }
      this.h.showModal(this.modal, $event);
    }
  }

  hideModal($event?) {
    this.h.hideModal(this.modal, $event);
    return false;
  }

  ngOnChanges() {
    if (!this.text) {
      return;
    }
    this.shortenedText = this.text;
    if (this.length > 0 && this.length < this.text.length) {
      this.shortenedText = this.text.substr(0, this.length) + '…';
    }
  }
}
