import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from "rxjs";
import {IEditRequest} from "../interfaces/IEditRequest";
import {HttpCache} from "./HttpCache";

@Injectable()
export class EditRequests {
  private config:Config;
  private session:Session;
  private http:HttpCache;

  constructor(config:Config, http:HttpCache, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return headers;
  }

  getRequests():Observable<IEditRequest[]> {
    return this.http.get<IEditRequest[]>(this.config.apiUrl + '/edit_request', {headers: this.getHeaders()});
  }

  getUserLogRequests(userID:number):Observable<IEditRequest[]> {
    return this.http.get<IEditRequest[]>(this.config.apiUrl + `/edit_request/log/${userID}`, {headers: this.getHeaders()});
  }

  getGroupLogRequests(groupID:number):Observable<IEditRequest[]> {
    return this.http.get<IEditRequest[]>(this.config.apiUrl + `/edit_request/log/${groupID}/group`, {headers: this.getHeaders()});
  }

  approveRequest(requestID:number, old_value?:string, new_value?:string) {
    return this.http.post(this.config.apiUrl + `/edit_request/${requestID}/approve`, JSON.stringify({
      old_value: old_value,
      new_value: new_value
    }), {headers: this.getHeaders(), responseType: 'text'});
  }

  declineRequest(requestID:number) {
    return this.http.post(this.config.apiUrl + `/edit_request/${requestID}/decline`, '', {headers: this.getHeaders(), responseType: 'text'});
  }
}
