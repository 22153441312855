import {Pipe} from '@angular/core';

function isRowHidden(objectWords, filterWords) {
  if (!filterWords || !filterWords.length || !objectWords || !objectWords.length) {
    return false;
  }
  let isHidden = true;
  objectWords.every((c) => {
    if (filterWords.indexOf(c.trim()) > -1) {
      isHidden = false;
      return false;
    }
    return true;
  });
  return isHidden;
}

@Pipe({
  name: 'filterBy',
  pure: false,
})
export class FilterByWordPipe {
  transform(list:Array<any>, field:string, filterWords:string[], splitBy:string, inverse:boolean = false, addIfEmpty = false):any {
    if (list && list.length && filterWords && filterWords.length) {
      let newList = [];
      list.forEach((r) => {
        if (r[field]) {
          let objectWords;
          if (typeof r[field] === 'string') {
            if (splitBy) {
              objectWords = r[field].split(splitBy);
            } else {
              objectWords = [r[field]];
            }
          } else {
            objectWords = r[field];
          }
          if (!isRowHidden(objectWords, filterWords)) {
            if (!inverse) {
              newList.push(r);
            }
          } else {
            if (inverse) {
              newList.push(r);
            }
          }
        } else {
          if (addIfEmpty) {
            newList.push(r);
          } else {
            if (inverse) {
              newList.push(r);
            }
          }
        }
      });
      return newList;
    } else {
      return list;
    }
  }
}
