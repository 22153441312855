import {Component, ViewChild, ElementRef, Input} from '@angular/core';

@Component({
  template: '<span #t style="width: 1em; max-width: 1em; line-height: 1em; min-width: 1em; display: inline-block; overflow: hidden; text-align: center"></span>',
  selector: 'text-spinner'
})
export class TextSpinnerCmp {
  @ViewChild('t') t:ElementRef;
  @Input() sprite:string;
  private currentSprite = 0;
  private sprites = {
    'Slash': ['|', '/', '&mdash;', '\\'],
    'Dots': ['⣾', '⣽', '⣻', '⢿', '⡿', '⣟', '⣯', '⣷'],
    'Arrows': ['←', '↖', '↑', '↗', '→', '↘', '↓', '↙'],
    'B': ['b', 'ᓂ', 'q', 'ᓄ'],
    'D': ['d', 'ᓇ', 'p', 'ᓀ'],
    'X': ['x', '+'],
    'SqInSq': ['◰', '◳', '◲', '◱'],
    'SqInCr': ['◴', '◷', '◶', '◵'],
    'MediaCr': ['◐', '◓', '◑', '◒'],
    'BarH': ['▁', '▃', '▅', '▆', '▇', '█', '▇', '▆', '▅', '▃'],
    'BarV': ['▉', '▊', '▋', '▍', '▎', '▏', '▎', '▍', '▋', '▊', '▉']
  };

  ngAfterViewInit() {
    if (!this.sprite) {
      this.sprite = 'Dots';
    }
    this.tick(this.sprites[this.sprite]);
  }

  tick(sprites) {
    if (this.currentSprite > sprites.length) {
      this.currentSprite = 0;
    }
    this.setValue(sprites[this.currentSprite]);
    this.currentSprite++;
    if (this.currentSprite >= sprites.length) {
      this.currentSprite = 0;
    }
    setTimeout(()=> {this.tick(sprites);}, 175);
  }

  setValue(v) {
    if (this.t && this.t.nativeElement) {
      this.t.nativeElement.innerHTML = v;
    }
  }
}
