import {Component} from "@angular/core";
import {Implementations} from "../../services/Implementations";
import {User} from "../../services/User";
import {Session} from "../../services/Session";

@Component({
  templateUrl: "./staples-report.html",
  selector: "staples-report"
})
export class StaplesReportCmp {
  public report;
  public loading = false;

  constructor(public implSrv:Implementations, public userService:User, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.loading = true;
    this.report = {emails: [], tickets: null};
    this.userService.getClientManagers().subscribe((managers) => {
      if (managers && managers.length > 0) {
        managers.forEach((manager) => {
          this.implSrv.getStaplesSent(manager.id).subscribe((r) => {
            this.loading = false;
            if (r.emails && r.emails.length > 0) {
              r.emails.forEach((emstring) => {
                if (emstring === "[]") {
                  return;
                }
                try {
                  let emails = JSON.parse(emstring);
                  if (emails && emails.length > 0) {
                    emails.forEach((email) => {
                      let m = {parts: [], subject: "", to: "", from: "", cc: "", date: ""};
                      let bodyMain = email.body.data;
                      if (bodyMain) {
                        m.parts.push(this.decodeWebSafe(bodyMain));
                      }
                      if (email.parts && email.parts.length > 0) {
                        email.parts.forEach((p) => {
                          if (p.body && p.body.data) {
                            m.parts.push(this.decodeWebSafe(p.body.data));
                          }
                        });
                      }
                      if (email.headers && email.headers.length > 0) {
                        email.headers.forEach((h) => {
                          if (h.name === "Subject" && h.value) {
                            m.subject = h.value;
                          }
                          if (h.name === "To" && h.value) {
                            m.to = h.value;
                          }
                          if (h.name === "From" && h.value) {
                            m.from = h.value;
                          }
                          if (h.name === "Cc" && h.value) {
                            m.cc = h.value;
                          }
                          if (h.name === "Date" && h.value) {
                            m.date = h.value;
                          }
                        });
                      }
                      this.report.emails.push(m);
                    });
                  }
                } catch (e) {
                  console.error(e);
                }
              });
            }
          }, () => {
            this.loading = false;
          });
        });
      }
    }, () => {
      this.loading = false;
    });
    this.implSrv.getStaplesSent(1).subscribe((r) => {
      this.loading = false;
      if (r && r.fd_tickets && r.fd_tickets.length > 0) {
        this.report.tickets = r.fd_tickets;
      }
    }, () => {});
  }

  decodeWebSafe(a:string):string {
    if (!a) {
      return '';
    }
    try {
      let x = a.replace(new RegExp('-', 'g'), '+').replace(new RegExp('_', 'g'), '/');
      let b = atob(x);
      return b;
    } catch (e) {
      console.error(e);
      return a;
    }
  }
}
