import {startWith, map, debounceTime} from 'rxjs/operators';
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
declare var _:any;

@Component({
  selector: 'location-select',
  templateUrl: './location-select.html',
  styleUrls: ['./tag.css'],
})
export class LocationSelectCmp {
  @Output() locationSelect = new EventEmitter();
  private _id:number;
  public locations;
  public isOpen = false;
  public searchInput:UntypedFormControl;
  public loading = false;
  public searchGroup = new UntypedFormGroup({
    searchInput: new UntypedFormControl()
  });

  constructor(public locationService:Locations) {
    this.searchInput = <UntypedFormControl>this.searchGroup.controls['searchInput'];
    this.searchInput.valueChanges.pipe(debounceTime(200), map((val) => {
      return val.toLowerCase();
    }), startWith('')).subscribe((val)=> {
      this.search(val);
    }, ()=> {});
  }

  @Input() set id(value:any) {
    this._id = parseInt(value);
    this.checkInputId();
  }

  get id() {
    return this._id;
  }

  ngOnChanges() {
    this.checkInputId();
  }

  ngAfterViewInit() {
    this.checkInputId();
  }

  checkInputId() {
    if (this._id > 0) {
      this.locationService.getLocation(this._id).then((location:ILocation)=> {
        this.searchInput.setValue(this.getLocTxt(location), {emitEvent: false});
      }, ()=> {});
    }
  }

  search(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.loading = true;
      this.locations = [];
      this.showDropdown();
      this.locationService.load('any', 0, 15, searchTerm).then((response)=> {
        this.locations = _.filter(response.items, (l)=> {return l.facility_name || false;});
        this.loading = false;
        if (this.locations.length < 1) {
          this.hideDropdown();
        }
      }, ()=> {
        this.locations = [];
        this.loading = false;
        this.hideDropdown();
      });
    } else {
      this.hideDropdown();
    }
  }

  getLocTxt(location) {
    let txt = location.facility_name;
    if (location.pc_id) {
      txt += ' (' + location.pc_id + ')';
    }
    return txt;
  }

  selectLocation(location) {
    this.searchInput.setValue(this.getLocTxt(location), {emitEvent: false});
    this.hideDropdown();
    this._id = location.id;
    this.locationSelect.emit(location);
  }

  showDropdown() {
    if (!this.isOpen) {
      this.isOpen = true;
    }
  }

  hideDropdown() {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }
}
