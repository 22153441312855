
import {startWith, map, debounceTime} from 'rxjs/operators';
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {User} from "../../services/User";
import {IAspsInfo} from "../../interfaces/IAspsInfo";
import {IUser} from "../../interfaces/IUser";

@Component({
  selector: 'asps-user-select',
  templateUrl: './asps-user-select.html',
})
export class AspsUserSelectCmp {
  @Input() id;
  @Input() typeID: string;
  @Input() tags = '';
  @Output() userSelect = new EventEmitter();
  public search_results;
  public isOpen = false;
  public searchInput: UntypedFormControl;
  public loading = false;
  public limit = 10;
  public searchGroup = new UntypedFormGroup({
    searchInput: new UntypedFormControl()
  });

  constructor(public userService: User) {
    this.searchInput = <UntypedFormControl>this.searchGroup.controls['searchInput'];
    this.searchInput.valueChanges.pipe(debounceTime(200),map((val) => {
      return val.toLowerCase();
    }),startWith('')).subscribe((val)=> {
      this.search(val);
    }, ()=> {});
  }

  ngOnChanges() {
    this.id = parseInt(this.id);
    if (this.id > 0) {
      this.userService.getUserInfo(this.id).then((user)=> {
        let info: IAspsInfo = {user: <IUser>user, location: null};
        this.selectUser(info);
      }, ()=> {});
    } else {
      this.reset();
    }
  }

  reset() {
    this.id = 0;
    this.searchInput.setValue('', {emitEvent: false});
    this.isOpen = false;
  }

  search(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.loading = true;
      this.search_results = [];
      this.showDropdown();
      this.userService.searchAspsInfo(searchTerm, this.tags).subscribe((results)=> {
        this.search_results = results;
        this.loading = false;
      }, ()=> {
        this.loading = false;
        if (this.search_results.length < 1) {
          this.hideDropdown();
        }
      });
    } else {
      this.hideDropdown();
    }
  }

  selectUser(info: IAspsInfo) {
    this.searchInput.setValue(info.user.fname + ' ' + info.user.lname, {emitEvent: false});
    this.hideDropdown();
    this.id = info.user.id;
    this.userSelect.emit(info);
  }

  showDropdown() {
    if (!this.isOpen) {
      this.isOpen = true;
    }
  }

  hideDropdown() {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }
}
