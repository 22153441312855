import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {IPopupQuery} from "../../interfaces/IPopupQuery";
import {ILocsByMedsurgEffDateReportRecord} from "../../interfaces/ILocsByMedsurgEffDateReport";

declare var Chart: any;

@Component({
  templateUrl: 'report.html',
  selector: 'locs-medsurg-date-chart'
})
export class LocsMedsurgDateChartCmp {
  @ViewChild('chart') chart: ElementRef;
  public loading = false;
  public csv = '';
  public report: ILocsByMedsurgEffDateReportRecord[];
  public queries: IPopupQuery[] = [];
  public labels = [];
  public sets = [];
  private n = new Notify();

  constructor(private session: Session, private reports: Reports) {
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    if (!this.loading && this.session.user && this.session.user.id) {
      this.load();
    }
  }

  load() {
    this.loading = true;
    this.report = null;
    this.reports.getLocsByMedsurgEffDateReport().subscribe((r) => {
      this.loading = false;
      this.report = r.report;
      this.queries = [{query: r.query, name: 'Query'}];
      setTimeout(() => {
        this.drawChart();
      }, 200);
    }, (err) => {
      this.loading = false;
      this.n.error(JSON.stringify(err), 'Error');
    });
  }

  drawChart() {
    if (this.report) {
      let dataset = {label: 'Locations', data: [], color: '#1E88E5', hidden: false, column: 0};
      this.labels = [];
      this.report.forEach((r) => {
        this.labels.push(r.eff_date);
        dataset.data.push(r.locs);
      });
      this.sets = [dataset];
    }
  }
}
