import {Component, Input} from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.html',
  styleUrls: ['./dots.css', './ball.css', './circles.css', './flower.css', './inner-circles.css', './plus.css', './pong.css', './timer.css', './throbber.css',],
})
export class Spinner {
  @Input() spinnerType:string;

  constructor() {
    /**
     * @see http://www.css-spinners.com/
     */
    if (!this.spinnerType) {
      if (!window || !window.localStorage) {
        this.spinnerType = 'dots-loader';
      } else {
        let spinners = ['dots-loader', 'ball-loader', 'circles-loader', 'flower-loader',
          'inner-circles-loader', 'plus-loader', 'pong-loader', 'timer-loader',
          'throbber-loader'];
        let i = parseInt(window.localStorage.getItem('spinnerIndex'));
        if (i > 0) {
          this.spinnerType = spinners[i-1];
          if (i >= spinners.length) {
            i = 0;
          }
          window.localStorage.setItem('spinnerIndex', (i+1).toString());
        } else {
          window.localStorage.setItem('spinnerIndex', '2');
          this.spinnerType = spinners[0];
        }
      }
    }
  }
}
