import {Component,Input, ViewChild, ElementRef} from '@angular/core';
import {IContractChart} from "../../interfaces/IContractChart";
declare var Chart:any;

@Component({
  template: '<canvas #canvas></canvas>',
  selector: 'contract-chart'
})
export class ContractChartCmp {
  @Input() report:IContractChart[];
  @ViewChild('canvas') canvas:ElementRef;

  ngOnChanges() {
    setTimeout(()=> {
      this.drawChart();
    }, 1);
  }

  drawChart() {
    if (!this.canvas || !this.canvas.nativeElement || !this.report || !this.report.length || !Chart) {
      return;
    }
    let canvas = this.canvas.nativeElement;
    let ctx = canvas.getContext('2d');
    let labels = [];
    let dataset_distr_pc = [];
    let dataset_ht_pc = [];
    let dataset_distr_at = [];
    let dataset_ht_at = [];
    this.report.forEach((r:IContractChart)=> {
      labels.push(r.quarter);
      dataset_distr_pc.push(r.distr_pc);
      dataset_ht_pc.push(r.ht_pc);
      dataset_distr_at.push(r.distr_at);
      dataset_ht_at.push(r.ht_at);
    });

    let data = {
      labels: labels,
      datasets: [
        {
          label: "Distributor (PC)",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "blue",
          borderColor: "blue",
          borderCapStyle: 'round',
          borderJoinStyle: 'round',
          data: dataset_distr_pc,
          spanGaps: true,
        },
        {
          label: "HealthTrust (PC)",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "orange",
          borderColor: "orange",
          borderCapStyle: 'square',
          borderJoinStyle: 'miter',
          data: dataset_ht_pc,
          spanGaps: true,
        },
        {
          label: "Distributor (AT)",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "gray",
          borderColor: "gray",
          borderCapStyle: 'square',
          borderJoinStyle: 'miter',
          data: dataset_distr_at,
          spanGaps: true,
        },
        {
          label: "HealthTrust (AT)",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "yellow",
          borderColor: "yellow",
          borderCapStyle: 'square',
          borderJoinStyle: 'miter',
          data: dataset_ht_at,
          spanGaps: true,
        }
      ]
    };

    let options = {
      responsive: true,
      legend: {
        usePointStyle: true
      }
    };

    let myBarChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options
    });
  }
}
