import {Component} from "@angular/core";
import {Session} from "../../services/Session";
import {CostStudy} from "../../services/CostStudy";

@Component({
  selector: 'cs-vendor-groups',
  templateUrl: 'vendor_groups.html',
})
export class CsVendorGroupsCmp {
  public vendorGroups;
  public loading = false;
  private defaultMinLength = 7;

  constructor(private session:Session, private cs:CostStudy) {
    this.session.redirectIfNotAuth();
    this.loading = true;
    this.cs.getMultiVendorGroups().subscribe((groups)=> {
      this.loading = false;
      this.vendorGroups = groups;
    }, ()=> {
      this.loading = false;
    });
  }

  openGroup(g) {
    if (g.__expanded) {
      return;
    }
    g.__expanded = true;
    if (!g.__minLength) {
      g.__minLength = this.defaultMinLength;
    }
    this.loadSimilar(g);
  }

  updateGroup(g) {
    if (!g || g.__updating) {
      return;
    }
    g.__updating = true;
    this.cs.updateManufacturerGroup(g).subscribe(()=> {
      g.__updating = false;
    }, ()=> {
      g.__updating = false;
    });
  }

  loadSimilar(g) {
    if (!g || g.__finding_similar) {
      return;
    }
    if (!g.__minLength) {
      g.__minLength = this.defaultMinLength;
    }
    g.__finding_similar = true;
    g.__similar = [];
    this.cs.getManufacturerGroupInfo(g.label, g.__minLength).subscribe((result)=> {
      g.__manufacturers = result.__manufacturers;
      if (result.__similar) {
        g.__similar = result.__similar;
      }
      g.__finding_similar = false;
    }, ()=> {
      g.__finding_similar = false;
    });
  }

  moveSimilar(g) {
    if (!g || !g.__similar || !g.__similar.length || g.__moving_similar) {
      return;
    }
    let l = [];
    g.__similar.forEach((s)=> {
      if (s.__selected) {
        l.push(s.id);
      }
    });
    if (l.length < 1) {
      return;
    }
    g.__moving_similar = true;
    this.cs.moveToVendorGroup(l, g.id).subscribe(()=> {
      g.__moving_similar = false;
      this.loadSimilar(g);
    }, ()=> {
      g.__moving_similar = false;
    });
  }
}
