import {Component} from '@angular/core';
import {User} from "../../services/User";
import {IRep} from "../../interfaces/IRep";
import {IUser} from "../../interfaces/IUser";
import {Config} from "../../services/Config";
import {Session} from "../../services/Session";
declare var _:any;

@Component({
  selector: 'reps-list',
  templateUrl: './reps-list.html',
})
export class RepsListCmp {
  public reps:IRep[] = [];
  public repsRaw:IRep[] = [];
  public sort_order = '';
  public sort_field = '';
  public itemsLimit = 15;
  public page = 1;
  public loading = false;
  public distributors:string[] = [];
  public filteringDistributor = '';
  public filterByDistributor = false;
  public filterByLinks = false;
  public newRep:IUser;
  public searchTerm = '';

  constructor(public userService:User, public config:Config, private session: Session) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.loading = true;
    this.userService.getReps(this.sort_field, this.sort_order !== 'desc').subscribe((reps) => {
      this.repsRaw = reps;
      this.loading = false;
      this.renderWithFilters(reps);
    }, () => {
      this.loading = false;
    });
  }

  renderWithFilters(reps:IRep[]) {
    this.reps = [];
    if (!reps || !reps.length) {
      reps = this.repsRaw;
    }
    let new_distributors_list = [];
    let s = (this.searchTerm || '').toLowerCase().trim();
    reps.forEach((rep:IRep)=> {
      if (rep.distributor_name && new_distributors_list.indexOf(rep.distributor_name) < 0) {
        new_distributors_list.push(rep.distributor_name);
      }
      if (this.filterByDistributor && !rep.distributor_name) {
        return;
      }
      if (this.filteringDistributor && rep.distributor_name !== this.filteringDistributor) {
        return false;
      }
      if (s) {
        let name = (rep.fname + ' ' + rep.lname).toLowerCase();
        if (name.indexOf(s) < 0 &&
          (rep.fname || '').toLowerCase().indexOf(s) < 0 &&
          (rep.lname || '').toLowerCase().indexOf(s) < 0 &&
          (rep.email || '').toLowerCase().indexOf(s) < 0 &&
          (rep.distributor_name || '').toLowerCase().indexOf(s) < 0) {
          return;
        }
      }
      this.reps.push(rep);
    });
    this.distributors = new_distributors_list;
  }

  setFilterDistributor(name) {
    this.filteringDistributor = name;
    this.renderWithFilters(this.repsRaw);
  }

  clearFilteringDistributor() {
    this.filteringDistributor = null;
    this.renderWithFilters(this.repsRaw);
  }

  toggleFilterDistributor() {
    this.filterByDistributor = !this.filterByDistributor;
    this.renderWithFilters(this.repsRaw);
  }

  sort(field) {
    if (this.sort_field === field) {
      if (this.sort_order === 'asc') {
        this.sort_order = 'desc';
      } else {
        this.sort_order = '';
        this.sort_field = '';
      }
    } else {
      this.sort_order = 'asc';
      this.sort_field = field;
    }
    this.reload();
  }

  setPage(page) {
    this.page = page;
    this.renderWithFilters(this.repsRaw);
  }

  addRep() {
    this.newRep = <IUser>{};
    this.newRep.type_id = this.config.userTypeRep;
  }

  cancelNewRepSave() {
    this.newRep = null;
  }

  repCreated() {
    this.newRep = null;
    this.reload();
  }
}
