import {Component, Input} from '@angular/core';

@Component({
  template: `<div style="height: 80px; width: 80px; display: inline-block; vertical-align: middle;margin: 1em;border-radius: 100%;overflow: hidden;" [ngStyle]="{'background': bgColor, 'fill': valueColor}">
     <svg><path attr.d="M{{r}},{{r}} L{{r}},0 A{{r}},{{r}} 0 1,1 {{(r+y*r)}},{{(r-x*r)}} z"></path></svg></div>`,
  selector: 'pie-chart',
})
export class PieChartCmp {
  @Input() value: number;
  @Input() r: number = 40;
  @Input() bgColor = "rgb(31, 119, 180)";
  @Input() valueColor = "rgb(199, 221, 237)";
  public x: number;
  public y: number;

  ngOnChanges() {
    if (this.value > 0 && this.value < 100) {
      this.x = Math.cos((2 * Math.PI) / (100 / this.value));
      this.y = Math.sin((2 * Math.PI) / (100 / this.value));
    } else {
      this.x = Math.cos((2 * Math.PI)/1.0000001);
      this.y = Math.sin((2 * Math.PI)/1.0000001);
    }
  }
}
