import {Component, Input} from '@angular/core';

export interface IRxOrphansReportLine {
  id: number;
  user_id: number;
  code: string;
  signature: string;
}

export interface IRxOrphansReport {
  query: string;
  records: IRxOrphansReportLine[];
}

@Component({
  templateUrl: 'rx_orphans.html',
  selector: 'rx-orphans-report',
})
export class ReportRxOrphansCmp {
  @Input('report') report:IRxOrphansReport;
  public sortDesc = false;
  public orderField:string = '';

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }
}
