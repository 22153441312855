import {Routes} from '@angular/router';
import {HomeCmp} from './components/home/home';
import {LoginCmp} from './components/login/login';
import {RegisterCmp} from './components/register/register';
import {SettingsCmp} from "./components/settings/settings";
import {UserCmp} from "./components/user/user";
import {LocationsCmp} from "./components/locations/locations";
import {LocationCmp} from "./components/location/location";
import {RepManagerLocGroupsCmp} from "./components/rep_manager_loc_groups/groups";
import {LocationsGroupCmp} from "./components/locations_group/locations_group";
import {CreateContactCmp} from "./components/create-contact/CreateContactCmp";
import {LogoutCmp} from "./components/logout/LogoutCmp";
import {TicketsCmp} from "./components/tickets/TicketsCmp";
import {TicketCmp} from "./components/ticket/TicketCmp";
import {ManufacturersCmp} from "./components/manufacturers/ManufacturersCmp";
import {RepsListCmp} from "./components/reps-list/reps-list";
import {ClientManagersListCmp} from "./components/client-managers-list/ClientManagersListCmp";
import {AllUsersCmp} from "./components/all-users/AllUsersCmp";
import {ReportsCmp} from "./components/reports/ReportsCmp";
import {CreateZimmerLifecyclesCmp} from "./components/create-zimmer-lifecycles/CreateZimmerLifecyclesCmp";
import {WriteImplementationStepsCmp} from "./components/write-implemenation-steps/WriteImplementationStepsCmp";
import {NotificationsListCmp} from "./components/notifications-list/NotificationsListCmp";
import {ReportsGlobalCmp} from "./components/reports-global/ReportsGlobalCmp";
import {DistributorPageCmp} from "./components/distributor-page/DistributorPageCmp";
import {SaverLandingListCmp} from "./components/saver-landing-list/SaverLandingListCmp";
import {TasksListCmp} from "./components/tasks-list/TasksListCmp";
import {StaplesReportCmp} from "./components/staples-report/StaplesReportCmp";
import {EditRequestsListCmp} from "./components/edit-requests-list/EditRequestsListCmp";
import {PaQListCmp} from "./components/pa_q_list/PaQListCmp";
import {WriteImplementationContractsCmp} from "./components/write-implemenation-contracts/WriteImplementationContractsCmp";
import {MarkStaplesSentCmp} from "./components/mark-staples-sent/MarkStaplesSentCmp";
import {DisableUserCmp} from "./components/disable-user/DisableUserCmp";
import {LegacySearchCmp} from "./components/legacy-search/legacy-search.component";
import {AdminCommandsCmp} from "./components/admin-commands/AdminCommandsCmp";
import {ReportSnapshotsListCmp} from "./components/report-snapshots-list/ReportSnapshotsListCmp";
import {SalesMashupCmp} from "./components/sales-mashup-report/SalesMashupCmp";
import {NewsletterCompileListCmp} from "./components/newsletters-compile-list/NewsletterCompileListCmp";
import {VendorPageCmp} from "./components/vendor-page/VendorPageCmp";
import {VendorQuarterPageCmp} from "./components/vendor-quarter-page/VendorQuarterPageCmp";
import {ContractChartReportCmp} from "./components/contract-chart-report/ContractChartReportCmp";
import {SearchDistrReportingCmp} from "./components/search-distributors-reporting/SearchDistrReportingCmp";
import {ReviewResultsCmp} from "./components/review-results/ReviewResultsCmp";
import {BenefitReviewsCmp} from "./components/benefit-reviews/BenefitReviewsCmp";
import {PharmaCheckReportCmp} from "./components/pharma-check-report/PharmaCheckReportCmp";
import {SaverLandingPageMgmaCmp} from "./components/saver-landing-page-mgma/SaverLandingPageMgmaCmp";
import {HPGReportCmp} from "./components/hpg-report/HPGReportCmp";
import {CDiffReportCmp} from "./components/cdiff-report/CDiffReportCmp";
import {CSContractsUpdateCmp} from "./components/cs-contracts-update/CSContractsUpdateCmp";
import {CSFIllUinsCmp} from "./components/cs-fill-uins/CSFIllUinsCmp";
import {MembersReportCmp} from "./components/members-report/MembersReportCmp";
import {LocationsMapZoomCmp} from "./components/locations-map-zoom/LocationsMapZoomCmp";
import {SummaryTablesCmp} from "./components/summary-tables/SummaryTablesCmp";
import {CsToolCmp} from "./components/cs-tool/CsToolCmp";
import {CsResultsCmp} from "./components/cs-results/CsResultsCmp";
import {CsPharmaCmp} from "./components/cs-pharma/CsPharmaCmp";
import {CsManufacturersCmp} from "./components/cs-manufacturers/CsManufacturersCmp";
import {CsVendorGroupsCmp} from "./components/cs-vendor-groups/CsVendorGroupsCmp";
import {CsUploadsListCmp} from "./components/cs-uploads-list/CsUploadsListCmp";
import {CsUomConversionsCmp} from "./components/cs-uom-conversions/CsUomConversionsCmp";
import {ContractDifferenceCmp} from "./components/contract-difference/ContractDifferenceCmp";
import {CsAddXrefCmp} from "./components/cs-add-xref/CsAddXrefCmp";
import {CsMovedMatchesCmp} from "./components/cs-moved-matches/CsMovedMatchesCmp";
import {SaverLandingPageAoaCmp} from "./components/saver-landing-page-aoa/SaverLandingPageAoaCmp";
import {SanofiPageCmp} from "./components/sanofi-page/SanofiPageCmp";
import {GpoFileUploadCmp} from "./components/gpo-file-upload/GpoFileUploadCmp";
import {CConnsFileUploadCmp} from "./components/contract-conns-file-upload/CConnsFileUploadCmp";
import {CcResultsCmp} from "./components/cc-results/CcResultsCmp";
import {CcUploadsListCmp} from "./components/cc-uploads-list/CcUploadsListCmp";
import {SalesTracingReportCmp} from "./components/sales-tracing-report/SalesTracingReportCmp";
import {SalesReportingReportCmp} from "./components/sales-reporting-report/SalesReportingReportCmp";
import {LocationsMergeCmp} from "./components/locations-merge/LocationsMergeCmp";
import {RxScreenshotsCmp} from "./components/rx-screenshots/RxScreenshotsCmp";
import {BulkUploadCmp} from "./components/bulk-upload/BulkUploadCmp";
import {RxNewMembersUploadCmp} from "./components/rx-new-members-upload/RxNewMembersUploadCmp";
import {PossibleRepsCmp} from "./components/possible-reps/PossibleRepsCmp";
import {ReportRxConnectionsCmp} from "./components/report-rx-connections/ReportRxConnectionsCmp";
import {ReportPocSuppressedCmp} from "./components/report-poc-suppressed/ReportPocSuppressedCmp";
import {HisciToCrmHinDeaCmp} from "./components/hisci-to-crm/hin-dea/HisciToCrmHinDeaCmp";
import {HisciToCrmRxEffDateCmp} from "./components/hisci-to-crm/rx-eff-date/HisciToCrmRxEffDateCmp";
import {ReportCsTrackingCmp} from "./components/report-cs-tracking/ReportCsTrackingCmp";
import {ReportAmpContactsCmp} from "./components/report-amp-contacts/ReportAmpContactsCmp";
import {EmailCampaignsListCmp} from "./components/email-campaigns-list/EmailCampaignsListCmp";
import {NewsletterDistrSurveyCompileCmp} from "./components/newsletter-distr-survey-compile/NewsletterCompileListCmp";
import {LocsMedsurgDateChartCmp} from "./components/locs-medsurg-date-chart/LocsMedsurgDateChartCmp";
import {PaByDateChartCmp} from "./components/pa-by-date-chart/PaByDateChartCmp";
import {HsFileUploadCmp} from "./components/hs-file-upload/HsFileUploadCmp";
import {ContractRequirementsCmp} from "./components/contract-requirements/ContractRequirementsCmp";
import {ImportedFeedsReportCmp} from "./components/imported-feeds-report/ImportedFeedsReportCmp";
import {ReportAadCmp} from "./components/report-aad/ReportAadCmp";
import {ReportDistrUploadsCmp} from "./components/report-distr-uploads/ReportDistrUploadsCmp";
import {ReportDistrProcessFlowCmp} from "./components/report-distr-process-flow/ReportDistrProcessFlowCmp";
import {SearchRosterCmp} from "./components/search-roster/SearchRosterCmp";

export const routes:Routes = [
  {path: '', component: HomeCmp},
  {path: 'login', component: LoginCmp},
  {path: 'register', component: RegisterCmp},
  {path: 'locations/:kind', component: LocationsCmp},
  {path: 'locations/:kind/:search', component: LocationsCmp},
  {path: 'location/:id', component: LocationCmp},
  {path: 'locations_group/:id', component: LocationsGroupCmp},
  {path: 'rep_manager_loc_groups/:rep_id/:manager_id', component: RepManagerLocGroupsCmp},
  {path: 'user/:id', component: UserCmp},
  {path: 'settings', component: SettingsCmp},
  {path: 'create_contact', component: CreateContactCmp},
  {path: 'logout', component: LogoutCmp},
  {path: 'tickets/:source/:id', component: TicketsCmp},
  {path: 'ticket/:id', component: TicketCmp},
  {path: 'manufacturers', component: ManufacturersCmp},
  {path: 'reps', component: RepsListCmp},
  {path: 'client_managers', component: ClientManagersListCmp},
  {path: 'users', component: AllUsersCmp},
  {path: 'reports', component: ReportsCmp},
  {path: 'report', component: ReportsGlobalCmp},
  {path: 'report_staples', component: StaplesReportCmp},
  {path: 'create_gpo_lifecycles', component: CreateZimmerLifecyclesCmp},
  {path: 'mark_staples_sent', component: MarkStaplesSentCmp},
  {path: 'write_impl_steps', component: WriteImplementationStepsCmp},
  {path: 'write_impl_contracts', component: WriteImplementationContractsCmp},
  {path: 'notifications', component: NotificationsListCmp},
  {path: 'distributor/:id', component: DistributorPageCmp},
  {path: 'saver/mgma', component: SaverLandingPageMgmaCmp},
  {path: 'saver/aoa', component: SaverLandingPageAoaCmp},
  {path: 'saver/mgma/:id', component: SaverLandingPageMgmaCmp},
  {path: 'saver/aoa/:id', component: SaverLandingPageAoaCmp},
  {path: 'saver_landing/:tag', component: SaverLandingListCmp},
  {path: 'tasks', component: TasksListCmp},
  {path: 'edit_requests', component: EditRequestsListCmp},
  {path: 'pa_q', component: PaQListCmp},
  {path: 'disable_user', component: DisableUserCmp},
  {path: 'search_other', component: LegacySearchCmp},
  {path: 'adm_commands', component: AdminCommandsCmp},
  {path: 'report_snapshots_list', component: ReportSnapshotsListCmp},
  {path: 'report_sales_mashup', component: SalesMashupCmp},
  {path: 'newsletter_compile', component: NewsletterCompileListCmp},
  {path: 'newsletter_distr_survey_compile', component: NewsletterDistrSurveyCompileCmp},
  {path: 'vendor/:id', component: VendorPageCmp},
  {path: 'vendor/:id/quarter/:quarter', component: VendorQuarterPageCmp},
  {path: 'report/contract/:id', component: ContractChartReportCmp},
  {path: 'search_distr_reporting', component: SearchDistrReportingCmp},
  {path: 'review_results/:userID', component: ReviewResultsCmp},
  {path: 'benefit_reviews', component: BenefitReviewsCmp},
  {path: 'pharma_check', component: PharmaCheckReportCmp},
  {path: 'hpg_report', component: HPGReportCmp},
  {path: 'cdiff_report', component: CDiffReportCmp},
  {path: 'cs_contracts_update', component: CSContractsUpdateCmp},
  {path: 'cs_fill_uins', component: CSFIllUinsCmp},
  {path: 'members_report', component: MembersReportCmp},
  {path: 'locs_map_zoom', component: LocationsMapZoomCmp},
  {path: 'summary_tables', component: SummaryTablesCmp},
  {path: 'cs', component: CsToolCmp},
  {path: 'cs_results/:id', component: CsResultsCmp},
  {path: 'cs_pharma_file', component: CsPharmaCmp},
  {path: 'cs_manufacturers', component: CsManufacturersCmp},
  {path: 'cs_vendor_groups', component: CsVendorGroupsCmp},
  {path: 'cs_uploads', component: CsUploadsListCmp},
  {path: 'cs_missing_uom_conversions', component: CsUomConversionsCmp},
  {path: 'contract_difference', component: ContractDifferenceCmp},
  {path: 'cs_xref', component: CsAddXrefCmp},
  {path: 'cs_moved_matches', component: CsMovedMatchesCmp},
  {path: 'sanofi', component: SanofiPageCmp},
  {path: 'gpo_file', component: GpoFileUploadCmp},
  {path: 'cc', component: CConnsFileUploadCmp},
  {path: 'cc_results/:id', component: CcResultsCmp},
  {path: 'cc_uploads', component: CcUploadsListCmp},
  {path: 'report/sales_tracing', component: SalesTracingReportCmp},
  {path: 'report/sales_reporting', component: SalesReportingReportCmp},
  {path: 'locations_merge', component: LocationsMergeCmp},
  {path: 'rx_screenshots', component: RxScreenshotsCmp},
  {path: 'locs_bulk_upload', component: BulkUploadCmp},
  {path: 'rx_new_members_upload', component: RxNewMembersUploadCmp},
  {path: 'possible_reps', component: PossibleRepsCmp},
  {path: 'rx_connections_report', component: ReportRxConnectionsCmp},
  {path: 'poc_suppressed_report', component: ReportPocSuppressedCmp},
  {path: 'hisci_to_crm_hin_dea', component: HisciToCrmHinDeaCmp},
  {path: 'hisci_to_crm_rx_eff_date', component: HisciToCrmRxEffDateCmp},
  {path: 'report_cs_tracking', component: ReportCsTrackingCmp},
  {path: 'report_amp_contacts', component: ReportAmpContactsCmp},
  {path: 'report_aad', component: ReportAadCmp},
  {path: 'report_distr_processflow', component: ReportDistrProcessFlowCmp},
  {path: 'report_distr_uploads', component: ReportDistrUploadsCmp},
  {path: 'email_campaigns', component: EmailCampaignsListCmp},
  {path: 'locs_medsurg_date_chart', component: LocsMedsurgDateChartCmp},
  {path: 'pa_date_chart', component: PaByDateChartCmp},
  {path: 'hs_import', component: HsFileUploadCmp},
  {path: 'contract_requirements', component: ContractRequirementsCmp},
  {path: 'imported_feeds', component: ImportedFeedsReportCmp},
  {path: 'search_roster', component: SearchRosterCmp},
];
