
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {IDistributor} from '../interfaces/IDistributor';
import {Observable} from "rxjs";
import {ISearchDistrReportingPrefetch} from "../interfaces/IDistributor";
import {ISearchDistrReportinghQuery} from "../interfaces/IDistributor";
import {ISearchDistrReporting} from "../interfaces/IDistributor";

@Injectable()
export class Distributors {
  private config:Config;
  private session:Session;
  private http:HttpClient;
  private list:Array<IDistributor> = [];
  private loadingPromise;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  load() {
    if (this.list && this.list.length > 0) {
      return new Promise((resolve)=> {
        resolve(this.list);
      });
    }
    if (this.loadingPromise) {
      return this.loadingPromise;
    }
    this.loadingPromise = new Promise((resolve, reject)=> {
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get(this.config.apiUrl + '/distributor', {headers: headers}).subscribe((objects)=> {
        this.loadingPromise = null;
        if (objects instanceof Array) {
          this.list = objects;
          resolve(this.list);
        } else {
          reject();
        }
      }, ()=> {
        this.loadingPromise = null;
        reject();
      });
    });
    return this.loadingPromise;
  }

  loadToMap(map:{[id:number]:IDistributor;}, callback?) {
    this.load().then((distributors)=> {
      if (distributors && distributors.length > 0) {
        distributors.forEach((d)=> {
          map[d.id] = d;
        });
        if (callback) {callback();}
      }
    }, ()=> {});
  }

  loadToList(list:IDistributor[]) {
    this.load().then((distributors)=> {
      if (distributors && distributors.length > 0) {
        distributors.forEach((d)=> {
          list.push(d);
        });
      }
    }, ()=> {});
  }

  getSearchReportingPrefetch():Observable<ISearchDistrReportingPrefetch> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ISearchDistrReportingPrefetch>(this.config.apiUrl + '/distributor/search_reporting/prefetch', {headers: headers});
  }

  getSearchReporting(query:ISearchDistrReportinghQuery):Observable<ISearchDistrReporting> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let q = JSON.parse(JSON.stringify(query));
    q.contract_number = parseInt((query.contract_number || '').toString());
    return this.http.put<ISearchDistrReporting>(this.config.apiUrl + '/distributor/search_reporting', JSON.stringify(q), {headers: headers});
  }

  getSearchReportingCount(query:ISearchDistrReportinghQuery):Observable<number> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let q = JSON.parse(JSON.stringify(query));
    q.contract_number = parseInt((query.contract_number || '').toString());
    return this.http.put(this.config.apiUrl + '/distributor/search_reporting/count', JSON.stringify(q), {headers: headers, responseType: 'text'}).pipe(map(t => parseInt(t)));
  }

  getSearchReportingCSV(query:ISearchDistrReportinghQuery):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let q = JSON.parse(JSON.stringify(query));
    q.contract_number = parseInt((query.contract_number || '').toString());
    return this.http.post(this.config.apiUrl + '/distributor/search_reporting/csv', JSON.stringify(q), {headers: headers});
  }
}
