import {Component} from "@angular/core";
import {Session} from "../../services/Session";
import {ContractRequirements} from "../../services/ContractRequirements";
import {IContractRequirement} from "../../interfaces/IContractRequirement";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'contract-requirements',
  templateUrl: 'contract_requirements.html',
})
export class ContractRequirementsCmp {
  public loading = false;
  public crs: IContractRequirement[] = [];
  private n = new Notify();

  constructor(private session: Session, private cRsrv: ContractRequirements) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.load();
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.cRsrv.getContractRequirements().subscribe((crs) => {
      this.loading = false;
      this.crs = crs;
    }, (err) => {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  save(cr) {
    if (!cr) {
      return;
    }
    cr.___updating = true;
    this.cRsrv.saveContractRequirement(cr).subscribe((newID) => {
      cr.___updating = false;
      if (newID && !cr.id) {
        let id = parseInt(newID);
        if (id) {
          cr.id = id;
        }
      }
      this.n.success("Successfully!")
    }, (err) => {
      cr.___updating = false;
      this.n.httpErr(err);
    });
  }

  del(cr) {
    if (!cr) {
      return;
    }
    if (!cr.id) {
      if (this.crs && this.crs.length > 0) {
        let i = this.crs.indexOf(cr);
        if (i > -1) {
          this.crs.splice(i, 1);
        }
      }
      return;
    }
    cr.___updating = true;
    this.cRsrv.deleteContractRequirement(cr.id).subscribe(() => {
      cr.___updating = false;
      if (this.crs && this.crs.length > 0) {
        let i = this.crs.indexOf(cr);
        if (i > -1) {
          this.crs.splice(i, 1);
        }
      }
      this.n.success("Successfully!")
    }, (err) => {
      cr.___updating = false;
      this.n.httpErr(err);
    });
  }

  add() {
    if (!this.crs) {
      this.crs = [{contract_number: null, t1: null, t2: null, t3: null, t4: null, t5: null, ___expanded: true}];
    } else {
      this.crs.unshift({contract_number: null, t1: null, t2: null, t3: null, t4: null, t5: null, ___expanded: true});
    }
  }
}
