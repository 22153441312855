import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'pages',
  templateUrl: './pagination.html',
  styleUrls: ['./pagination.css'],
})
export class Pagination {
  @Input() pagesCount:number;
  @Input() page:number;
  @Output() pageChange = new EventEmitter();
  public buttonsCount = 5; //const
  public buttonsAround = 2; //const, how many buttons around central button
  public hideRewindBtn = true;
  public hideForwardBtn = true;
  public hideLastBtn = true;
  public rewindPage = 0;
  public forwardPage = 0;
  public page1:number = 2;
  public page2:number = 3;
  public page3:number = 4;
  public page4:number = 5;
  public page5:number = 6;

  constructor() {
    this.updatePages();
  }

  updatePages() {
    this.pagesCount = Math.ceil(this.pagesCount);
    let scrollCount = Math.max(Math.ceil(this.pagesCount/5), this.buttonsCount);
    if (this.pagesCount > this.buttonsCount) {
      this.hideRewindBtn = (this.page < (scrollCount+this.buttonsAround));
      this.hideForwardBtn = ((this.pagesCount-this.page) < (scrollCount+this.buttonsAround));
      this.forwardPage = Math.min(this.pagesCount, this.page+scrollCount);
      this.rewindPage = Math.max(1, this.page-scrollCount);
      this.hideLastBtn = ((this.pagesCount < (this.buttonsCount+this.buttonsAround))
                          || ((this.pagesCount-this.page) < (this.buttonsCount-this.buttonsAround)));
      if (this.page < (this.buttonsAround*2)) {
        this.page1 = 2;
        this.page2 = 3;
        this.page3 = 4;
        this.page4 = 5;
        this.page5 = 6;
      } else {
        if ((this.pagesCount-this.page) < this.buttonsAround) {
          this.page1 = this.pagesCount-4;
          this.page2 = this.pagesCount-3;
          this.page3 = this.pagesCount-2;
          this.page4 = this.pagesCount-1;
          this.page5 = this.pagesCount;
        } else {
          this.page1 = this.page-2;
          this.page2 = this.page-1;
          this.page3 = this.page;
          this.page4 = this.page+1;
          this.page5 = this.page+2;
        }
      }
    }
    this.pageChange.emit(this.page);
  }

  setPage(page) {
    this.page = parseInt(page) || 1;
    if (this.page < 1) {
      this.page = 1;
    }
    if (this.page > this.pagesCount) {
      this.page = this.pagesCount;
    }
    this.updatePages();
  }

  forward() {
    this.setPage(this.forwardPage);
  }

  rewind() {
    this.setPage(this.rewindPage);
  }

  ngOnChanges() {
    this.updatePages();
  }
}
