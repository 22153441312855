import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {IImplementationContract} from "../../interfaces/IImplementationContract";
import {ILocationImplementationContract, ILocGroupCOID} from "../../interfaces/ILocationImplementationContract";
import {Helpers} from "../../services/Helpers";
import {Implementations} from "../../services/Implementations";
import {Notify} from "../../services/Notify";
import {TypeaheadHelper} from "../../services/TypeaheadHelper";

declare var $:any;

@Component({
  templateUrl: 'loc-impl-contracts-list.html',
  selector: 'loc-impl-contracts-list'
})
export class LocationImplContractsListCmp {
  @Input() allContracts:IImplementationContract[];
  @Input() contracts:ILocationImplementationContract[];
  @Input() coids:ILocGroupCOID[];
  @Input() tab:string;
  @Input() locationID:number;
  @Input() indexByPCID:boolean;

  @Output() contractsChange = new EventEmitter();
  @ViewChild('removeContractModal') removeContractModal:ElementRef;
  @ViewChild('icth') icth:ElementRef;
  public implContracts:IImplementationContract[] = [];
  public locContracts:ILocationImplementationContract[] = [];
  public contractsMap = {};
  public newContract:ILocationImplementationContract;
  public removeContractReason = '';
  public contractToRemove:ILocationImplementationContract;
  private filled;

  constructor(private helpers:Helpers, private implSrv:Implementations, private th:TypeaheadHelper) {}

  ngOnInit() {
    this.fill();
  }

  ngOnChanges() {
    this.fill();
  }

  fill() {
    if (!this.allContracts) {
      return;
    }
    if (!this.filled) {
      this.filled = true;
      this.implContracts = [];
      this.allContracts.forEach((v:IImplementationContract)=> {
        this.contractsMap[v.id] = v;
        if ((!this.tab && !v.tab) || (this.tab && v.tab && v.tab.toLowerCase() == this.tab.toLowerCase())) {
          this.implContracts.push(v);
        }
      });
      this.fillTypeahead();
    }
    this.locContracts = [];
    if (this.contracts && this.contracts.length) {
      this.contracts.forEach((lc)=> {
        if (lc.impl_contract_id) {
          if (this.contractsMap[lc.impl_contract_id]) {
            let v = this.contractsMap[lc.impl_contract_id];
            lc['__order_on_page'] = v.order_on_page;
            if ((!this.tab && !v.tab) || (this.tab && v.tab && v.tab.toLowerCase() == this.tab.toLowerCase())) {
              this.locContracts.push(lc);
            }
          }
        }
      });
    }
  }

  fillTypeahead() {
    if (this.icth && this.icth.nativeElement && this.implContracts && this.implContracts.length > 0) {
      let icstrs = [];
      this.implContracts.forEach((v)=> {
        let icstr = v.name;
        if (v.contract_number) {
          icstr = icstr + ' (' + v.contract_number + ')';
        }
        v['___str'] = icstr;
        icstrs.push(icstr);
      });
      if (icstrs.length > 0) {
        this.th.setup(icstrs, this.icth);
        this.th.whenSelected(this.icth, ()=> {this.pickNewIC();});
      }
    }
  }

  addContract() {
    this.newContract = <ILocationImplementationContract>{
      is_interested: true
    };
    if (this.coids && this.coids.length) {
      this.coids.forEach((c:ILocGroupCOID)=> {
        c.__selected = (c.location_id == this.locationID);
      });
    }
    setTimeout(()=> {this.fillTypeahead();}, 10);
  }

  pushNewContract() {
    if (!this.newContract) {
      return;
    }
    let one_added = false;
    if (!this.contracts) {
      this.contracts = [];
    }
    if (this.coids && this.coids.length) {
      this.coids.forEach((f)=> {
        if (f.__selected) {
          let c = this.helpers.copyObject(this.newContract);
          c.location_id = f.location_id;
          this.contracts.push(c);
          one_added = true;
        }
      });
    }
    if (!one_added) {
      this.newContract.location_id = this.locationID;
      this.contracts.push(this.newContract);
    }
    this.contractsChange.emit(this.contracts);
    this.newContract = null;
    this.filled = false;
    this.fill();
  }

  changeDateConnected(date:Date, contract:ILocationImplementationContract) {
    contract.date_connected = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  changeDateSent(date:Date, contract:ILocationImplementationContract) {
    contract.sent_date = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  contractRemoveCancel() {
    this.removeContractReason = '';
    this.hideModalContractRemove();
  }

  hideModalContractRemove() {
    if (this.removeContractModal && this.removeContractModal.nativeElement) {
      $(this.removeContractModal.nativeElement).modal('hide');
    }
  }

  showModalContractRemove(contract:ILocationImplementationContract) {
    this.contractToRemove = contract;
    if (this.removeContractModal && this.removeContractModal.nativeElement) {
      $(this.removeContractModal.nativeElement).modal('show');
    }
  }

  contractRemoveConfirm() {
    this.hideModalContractRemove();
    this.removeContract(this.contractToRemove);
  }

  removeContract(contract:ILocationImplementationContract) {
    this.contractToRemove.__removing = true;
    this.implSrv.removeLocationContract(contract.location_id, contract.impl_contract_id, this.removeContractReason).subscribe(()=> {
      let notify = new Notify();
      notify.success('Removed');
      let i = this.locContracts.indexOf(this.contractToRemove);
      if (i > -1) {
        this.locContracts.splice(i, 1);
      }
      this.contractsChange.emit(this.contracts);
      this.contractToRemove = null;
      this.removeContractReason = '';
    }, ()=> {
      this.contractToRemove.__removing = false;
      let notify = new Notify();
      notify.error('Error');
      this.contractToRemove = null;
    });
  };

  pickNewIC() {
    let val = this.th.getVal(this.icth);
    if (val) {
      for (let i = 0; i < this.implContracts.length; i++) {
        let v = this.implContracts[i];
        if (v.hasOwnProperty('___str') && v['___str'] == val) {
          this.newContract.impl_contract_id = v.id;
        }
      }
    }
  }
}
