import {Component, Input} from '@angular/core';
import {Session} from "../../../services/Session";
import {Reports} from "../../../services/Reports";
import {IHisciSyncReport} from "../../../interfaces/IHisciSyncReport";
import {Locations} from "../../../services/Locations";
import {ILocation} from "../../../interfaces/ILocation";
import {Notify} from "../../../services/Notify";

@Component({
  templateUrl: 'hisci_to_crm.html',
  selector: 'hisci-to-crm-hin-dea',
})
export class HisciToCrmHinDeaCmp {
  public report: IHisciSyncReport;
  public loading = false;
  public updating = 0;
  private n = new Notify();

  constructor(private session: Session, private reports: Reports, private locSrv: Locations) {
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  reload() {
    setTimeout(() => {
      this.load();
    }, 1);
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.reports.getHisciSync_HinDea_Report().subscribe((report) => {
      this.report = report;
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  toggleSelectAll() {
    if (this.report && this.report.records && this.report.records.length) {
      this.report.records.forEach((r) => {
        r.__is_selected = !r.__is_selected;
      });
    }
  }

  applyChanges() {
    if (this.updating > 0) {
      return;
    }
    if (this.report && this.report.records && this.report.records.length) {
      let reloadAfter = () => {
        if (!this.loading && this.updating == 0) {
          setTimeout(() => {
            this.reload();
          }, 500);
        }
      };
      this.report.records.forEach((r) => {
        if (r.__is_selected && (r.new_hin || r.new_dea)) {
          r.__is_updated = false;
          r.__is_update_failed = false;
          this.updating++;
          let loc = <ILocation>{id: r.loc_id};
          if (r.new_hin) {
            loc.hin = r.new_hin;
          }
          if (r.new_dea) {
            loc.dea = r.new_dea;
          }
          if (r.new_rx_eff_date) {
            loc.pharma_eff_date = r.new_rx_eff_date;
            if (r.prim_distr_id) {
              if (!r.current_rx_eff_date || r.current_rx_eff_date === '' || r.current_rx_eff_date.indexOf('0000') > -1) {
                this.locSrv.addLifecycleRecord(r.loc_id, r.prim_distr_id, 11, 7);
              }
            }
          }
          this.locSrv.updateLocation(loc).then(() => {
            this.updating--;
            r.__is_updated = true;
            reloadAfter();
          }, () => {
            this.updating--;
            r.__is_update_failed = false;
            reloadAfter();
          });
        }
      });
    }
  }
}
