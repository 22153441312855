import {Component, Input} from '@angular/core';

@Component({
  selector: 'el-spinner',
  templateUrl: './spinner.html',
  styleUrls: ['./dots.css', './circles.css', './flower.css', './inner-circles.css', './plus.css', './timer.css', './throbber.css',],
})
export class ElSpinnerCmp {
  @Input() spinnerType:string;

  constructor() {
    /**
     * @see http://www.css-spinners.com/
     */
    if (!this.spinnerType) {
      if (!window || !window.localStorage) {
        this.spinnerType = 'throbber-loader';
      } else {
        let spinners = ['throbber-loader', 'circles-loader', 'flower-loader',
          'inner-circles-loader', 'plus-loader', 'timer-loader',
          'dots-loader'];
        let i = parseInt(window.localStorage.getItem('elSpinnerIndex'));
        if (i > 0) {
          this.spinnerType = spinners[i-1];
          if (i >= spinners.length) {
            i = 0;
          }
          window.localStorage.setItem('elSpinnerIndex', (i+1).toString());
        } else {
          window.localStorage.setItem('elSpinnerIndex', '2');
          this.spinnerType = spinners[0];
        }
      }
    }
  }
}
