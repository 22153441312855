import {Session} from "../../services/Session";
import {User, IRepLocInfo, IUserAlias} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Component, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {IDistributor} from "../../interfaces/IDistributor";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {Distributors} from "../../services/Distributors";
import {Specialties} from "../../services/Specialties";
import {Locations} from "../../services/Locations";
import {Config} from "../../services/Config";
import {ILocation} from "../../interfaces/ILocation";
import {UrlParser} from "../../services/UrlParser";
import {Notify} from "../../services/Notify";
import {IUserTotalSales} from "../../interfaces/IUserTotalSales";
import {JobTypes} from "../../services/JobTypes";
import {IJobType} from "../../interfaces/IJobType";

@Component({
  selector: 'user-cmp',
  templateUrl: './user.html',
})
export class UserCmp {
  @Output() update = new EventEmitter();
  @Output() refreshAliases = new EventEmitter();
  public user: IUser;
  public loading: boolean;
  public id;
  public distributorsList: IDistributor[] = [];
  public distributorsMap: { [id: number]: IDistributor; } = {};
  public specialtiesList: ISpecialty[] = [];
  public specialtiesMap: { [id: number]: ISpecialty; } = {};
  public tab = 1;
  public locsTab = 1;
  public locsCount:any = {};
  public locs: ILocation[] = [];
  public repLocsInfo: IRepLocInfo[] = [];
  public repConnectedCount = 0;
  public repReportingCount = 0;
  public managers: IUser[] = [];
  public newRep: IUser;
  public newLocation: ILocation;
  public saving = false;
  public changeLocationMode = false;
  public resetPasswordMode = false;
  public changingRepMgr = false;
  public userToken = '';
  public tokenLoading = false;
  public totalSales: IUserTotalSales;
  public enablingLogin = false;
  public requestAssignToLocation: ILocation;
  public assigningToLocation = false;
  public typeRep = 3;
  public tabOpened = {};
  public changeRepMgrMode = false;
  public newRepMgr: IUser;
  public aliasesCount;
  public displayLeftColumn = true;

  constructor(public session: Session, public users: User, public params: ActivatedRoute, public router: Router,
    public distributors: Distributors, public specialties: Specialties, public urlParser: UrlParser,
    public locations: Locations, public config: Config) {
    this.typeRep = config.userTypeRep;
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    this._setTabValue(1);
    this.locsTab = 1;
    this.id = parseInt(params['id']);
    if (!this.id) {
      this.router.navigate(['']);
      return;
    }
    this.parseUrlParams();
    this.session.redirectIfNotAuth();
    this.loadUser();
    this.distributors.loadToList(this.distributorsList);
    this.distributors.loadToMap(this.distributorsMap);
    this.specialties.loadToList(this.specialtiesList);
    this.specialties.loadToMap(this.specialtiesMap);
    this.locations.getRepLocationsCount(this.id).then(c => this.locsCount = c, () => {});
    this.users.getTotalSalesReport(this.id).subscribe(r => this.totalSales = r, () => {});
  }

  loadUser() {
    this.loading = true;
    this.users.getUserInfo(this.id).then((user) => {
      this.user = user;
      this.loading = false;
      this.update.emit(this.user);
      this.onUserLoad();
    }, () => {
      this.loading = false;
    });
  }

  refreshUserInfo() {
    if (!this.id) {
      return;
    }
    this.users.getFreshUserInfo(this.id).subscribe((info) => {
      this.user = info;
      this.update.emit(this.user);
    }, () => {});
  }

  onUserLoad() {
    if (this.user && this.user.type_id == this.typeRep) {
      this.users.getRelatedLocations(this.id).subscribe((response) => {
        this.locs = response.locations;
        this.repLocsInfo = response.locations_info;
        this.repConnectedCount = response.connected_cnt;
        this.repReportingCount = response.reporting_cnt;
      }, () => {});
      this.users.getRepClientManagers(this.id).subscribe((managers) => {this.managers = managers;});
    }
    this.loadReqAssignLocation();
  }

  addRep() {
    this.newRep = <IUser>{};
    this.newRep.type_id = this.config.userTypeRep;
  }

  cancelNewRepSave() {
    this.newRep = null;
  }

  repCreated() {
    this.newRep = null;
  }

  setNewLocation(newLocation: ILocation) {
    if (!newLocation || !newLocation.id || !this.user || !this.user.id) {
      return;
    }
    this.saving = true;
    let updatingLocation = true;
    let updatingUser = true;
    let finishSaving = () => {
      if (!updatingLocation && !updatingUser) {
        this.saving = false;
        this.changeLocationMode = false;
      }
    };
    let user_id_before = newLocation.primary_contact_user_id;
    let loc_id_before = this.user.location_id;
    newLocation.primary_contact_user_id = this.user.id;
    updatingLocation = true;
    this.locations.updateLocation(newLocation).then(() => {
      updatingLocation = false;
      finishSaving();
      let oldLoc = <ILocation>{};
      oldLoc.id = loc_id_before;
      oldLoc.primary_contact_user_id = 0;
      this.locations.updateLocation(oldLoc);
    }, () => {
      updatingLocation = false;
      finishSaving();
      newLocation.primary_contact_user_id = user_id_before;
    });
    this.user.location_id = newLocation.id;
    updatingUser = true;
    this.users.updateUser(this.user).then(() => {
      updatingUser = false;
      finishSaving();
      this.update.emit(this.user);
    }, () => {
      updatingUser = false;
      this.user.location_id = loc_id_before;
      finishSaving();
    });
  }

  setTab(i) {
    this._setTabValue(parseInt(i));
    this.urlParser.setParameter('tab', this.tab);
  }

  _setTabValue(i: number) {
    if (i < 1 || i > 99) {
      i = 1;
    }
    this.tab = i;
    this.tabOpened[i.toString()] = true;
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.tab) {
      this._setTabValue(parseInt(urlParams.tab));
    }
  }

  loadReqAssignLocation() {
    if (!this.user || !this.user.id) {
      return;
    }
    try {
      let urlParams = this.urlParser.getParameters();
      if (urlParams['req_assign_loc']) {
        let rlocID = parseInt(urlParams['req_assign_loc']);
        if (rlocID > 0 && this.user.location_id != rlocID) {
          this.locations.getLocation(rlocID).then(l => this.requestAssignToLocation = l, () => {});
        }
      }
    } catch (e) {

    }
  }

  setNewRepMgr() {
    if (!this.user || !this.user.id || !this.session || !this.session.user || !this.session.user.id || !this.newRepMgr || !this.newRepMgr.id || this.newRepMgr.type_id != 2) {
      return;
    }
    this.changingRepMgr = true;
    let prev = this.user.cm_user_id;
    this.user.cm_user_id = this.newRepMgr.id;
    this.users.updateUser(this.user).then(() => {
      this.changingRepMgr = false;
      this.changeRepMgrMode = false;
      this.update.emit(this.user);
      this.loadUser();
      this.users.getRepClientManagers(this.id).subscribe((managers) => {this.managers = managers;});
      try {
        let n = new Notify();
        n.success('Successfully');
      } catch (e) {
      }
    }, (err) => {
      this.changingRepMgr = false;
      this.user.cm_user_id = prev;
      try {
        let n = new Notify();
        n.error('Error');
        console.error(err);
      } catch (e) {
      }
    });
  }

  resetPassword() {
    if (!this.resetPasswordMode || !this.user || !this.user.id) {
      return;
    }
    this.saving = true;
    this.users.resetPassword(this.user.id).subscribe(() => {
      this.resetPasswordMode = false;
      this.saving = false;
      let n = new Notify();
      n.success('Password reset and sent to ' + this.user.email);
    }, (err) => {
      this.saving = false;
      console.error(err);
      let n = new Notify();
      n.error('Error');
    });
  }

  loadToken() {
    if (!this.userToken) {
      this.tokenLoading = true;
      this.users.getTokenForUserID(this.id).subscribe((token) => {
        this.userToken = token;
        this.tokenLoading = false;
      }, (err) => {
        this.tokenLoading = false;
        console.error(err);
      });
    }
  }

  enableLogin() {
    this.enablingLogin = true;
    this.users.enableLogin(this.user.id).subscribe(() => {
      this.user.is_disabled = false;
      this.enablingLogin = false;
    }, () => {
      this.enablingLogin = false;
    });
  }

  approveAssignReq() {
    if (!this.requestAssignToLocation || !this.requestAssignToLocation.id || !this.user || !this.user.id) {
      return;
    }
    this.assigningToLocation = true;
    let ex = this.user.location_id;
    this.user.location_id = this.requestAssignToLocation.id;
    let n = new Notify();
    this.users.updateUser(this.user).then(() => {
      this.assigningToLocation = false;
      n.success('Success');
      this.requestAssignToLocation = null;
    }, (err) => {
      this.assigningToLocation = false;
      this.user.location_id = ex;
      try {
        n.error('Error', err);
      } catch (e) {
        n.error('Error');
      }
    });
  }

  declineAssignReq() {
    this.requestAssignToLocation = null;
  }
}
