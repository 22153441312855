import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Config} from './Config';
import {Session} from "./Session";
import {
  ICSUploadReq,
  ICsUploadsList,
  ICSUploadMeta,
  ICsResults,
  ICsResult,
  ICSPharmaUploadReq,
  ICSPharmaUploadStatus,
  IManufacturerGroupInfo,
  IManufacturerSearch,
  ICsUploadInfo,
  ICsNewlyFoundVendorsResponse,
  IMissingUomConversions,
  INewUomConversion,
  IPharmaUomAdjust,
  IXrefItemReq,
  IXrefVendors,
  ICsManuallyMovedMatches,
  IReqCsSkipMatch,
  ICsUploadType,
  ICsLocTracked,
  ICsColor
} from "../interfaces/ICostStudy";

@Injectable()
export class CostStudy {
  constructor(private http: HttpClient, private config: Config, private session: Session) {}

  search(term: string, count: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/cost_study/search/' + count + '/' + term, {headers: headers});
  }

  getInfo(contract: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/cost_study/contract_info/' + contract, {headers: headers});
  }

  add(contract: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/cost_study/contracts_update/' + contract, '', {headers: headers, responseType: 'text'});
  }

  update(contract: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/cost_study/contracts_update/' + contract, '', {headers: headers, responseType: 'text'});
  }

  remove(contract: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/cost_study/contracts_update/' + contract, {headers: headers, responseType: 'text'});
  }

  deleteCsUpload(id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/cost_study/upload/' + id, {headers: headers, responseType: 'text'});
  }

  fillUins(contract: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/cost_study/fill_uins/' + contract, '', {headers: headers, responseType: 'text'});
  }

  fillUinsList(contracts: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/cost_study/fill_uins', contracts, {headers: headers, responseType: 'text'});
  }

  parseFile(req: ICSUploadReq): Observable<number> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/cost_study/parse_file', JSON.stringify(req), {headers: headers, responseType: 'text'}).pipe(map(r => parseInt(r)));
  }

  getCsResults(csUploadID: number, offset: number, limit: number, filter: string): Observable<ICsResults> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICsResults>(this.config.apiUrl + `/cost_study/results/${csUploadID}/${offset}/${limit}/${filter}`, {headers: headers});
  }

  getCsUploadInfo(csUploadID: number): Observable<ICsUploadInfo> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICsUploadInfo>(this.config.apiUrl + `/cost_study/upload_info/${csUploadID}`, {headers: headers});
  }

  rerunCsSearch(csUploadID: number, keep_manual_matches?: boolean): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + `/cost_study/rerun/${csUploadID}`;
    if (keep_manual_matches) {
      url = url + '?keep_manual_matches=1';
    }
    return this.http.post(url, '', {headers: headers, responseType: 'text'});
  }

  getPharmaFileUploadingStats(fileID: number): Observable<ICSPharmaUploadStatus> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICSPharmaUploadStatus>(this.config.apiUrl + `/cost_study/pharma_file/${fileID}/stats`, {headers: headers});
  }

  updateCsResult(result: ICsResult): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/cost_study/result', JSON.stringify(result), {headers: headers, responseType: 'text'});
  }

  uploadPharmaFile(req: ICSPharmaUploadReq): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/cost_study/pharma_file', JSON.stringify(req), {headers: headers, responseType: 'text'});
  }

  getManufacturerGroups(): Observable<string[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<string[]>(this.config.apiUrl + `/cost_study/manufacturer_groups`, {headers: headers});
  }

  getCsUploads(): Observable<ICsUploadsList> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICsUploadsList>(this.config.apiUrl + `/cost_study/uploads`, {headers: headers});
  }

  getCsColors(): Observable<ICsColor[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICsColor[]>(this.config.apiUrl + `/cost_study/colors`, {headers: headers});
  }

  getXrefVendors(): Observable<IXrefVendors> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IXrefVendors>(this.config.apiUrl + `/cost_study/xref_vendors`, {headers: headers});
  }

  getManuallyMovedMatches(): Observable<ICsManuallyMovedMatches> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICsManuallyMovedMatches>(this.config.apiUrl + `/cost_study/moved_match`, {headers: headers});
  }

  getManuallySkippedMatchesCsvUrl(): string {
    return this.config.apiUrl + '/cost_study/manually_skipped_csv/' + this.session.getToken();
  }

  getManuallyAddedMatchesCsvUrl(): string {
    return this.config.apiUrl + '/cost_study/manually_added_csv/' + this.session.getToken();
  }

  getMultiVendorGroups(): Observable<IManufacturerGroupInfo[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IManufacturerGroupInfo[]>(this.config.apiUrl + `/cost_study/multi_vendor_groups`, {headers: headers});
  }

  getManufacturerGroupInfo(label: string, similarLength?: number): Observable<IManufacturerGroupInfo> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IManufacturerGroupInfo>(this.config.apiUrl + `/cost_study/manufacturer_group_info/${label}/${similarLength}`, {headers: headers});
  }

  searchManufacturers(term: string): Observable<IManufacturerSearch> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IManufacturerSearch>(this.config.apiUrl + `/cost_study/manufacturer_search/${term}`, {headers: headers});
  }

  changeManufacturerGroup(id: number, group: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/cost_study/manufacturer_change_group/${id}/${group}`, '', {headers: headers, responseType: 'text'});
  }

  updateManufacturerGroup(group): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/cost_study/manufacturer_group`, JSON.stringify(group), {headers: headers, responseType: 'text'});
  }

  moveManufacturerToGroup(manufacturer_source: string, manufacturer_matched: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/cost_study/manufacturer_to_group`, JSON.stringify({vendor_source: manufacturer_source, vendor_matched: manufacturer_matched}), {headers: headers, responseType: 'text'});
  }

  moveToVendorGroup(manufacturers: number[], groupID: number): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/cost_study/manufacturer/to_vendor_group`, JSON.stringify({manufacturers: manufacturers, vendor_group_id: groupID}), {headers: headers, responseType: 'text'});
  }

  skipMatch(req: IReqCsSkipMatch): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/cost_study/skip_match`, JSON.stringify(req), {headers: headers, responseType: 'text'});
  }

  getCsResultsCsvUrl(id: number): string {
    return this.config.apiUrl + `/cost_study/results_csv/${id}/` + this.session.getToken();
  }

  getFoundVendors(csUploadID: number): Observable<ICsNewlyFoundVendorsResponse> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICsNewlyFoundVendorsResponse>(this.config.apiUrl + `/cost_study/vendors_new/${csUploadID}`, {headers: headers});
  }

  addNewVendor(vendor: string, groupID: number): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/cost_study/vendor`, JSON.stringify({vendor_name: vendor, vendor_group_id: groupID}), {headers: headers, responseType: 'text'});
  }

  getMissingUomConversions(offset: number, limit: number): Observable<IMissingUomConversions> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IMissingUomConversions>(this.config.apiUrl + `/cost_study/missing_uom_conversions/${offset}/${limit}`, {headers: headers});
  }

  addUomConversion(req: INewUomConversion): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/cost_study/uom_conversion`, JSON.stringify(req), {headers: headers, responseType: 'text'});
  }

  addPharmaUomAdjust(req: IPharmaUomAdjust): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/cost_study/pharma_uom_adjust`, JSON.stringify(req), {headers: headers, responseType: 'text'});
  }

  addXrefItem(req: IXrefItemReq): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/cost_study/xref_item`, JSON.stringify(req), {headers: headers, responseType: 'text'});
  }

  copyCsUpload(id: number): Observable<number> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/cost_study/copy_upload/` + id, "", {headers: headers, responseType: 'text'}).pipe(map(r => parseInt(r)));
  }

  approveSkippedMatch(id: number): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/cost_study/approve_skipped_match/` + id, "", {headers: headers, responseType: 'text'});
  }

  approveAddedMatch(id: number): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/cost_study/approve_added_match/` + id, "", {headers: headers, responseType: 'text'});
  }

  removeSkippedMatch(id: number): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/cost_study/remove_moved_match`, JSON.stringify({skipped_match_id: id}), {headers: headers, responseType: 'text'});
  }

  removeAddedMatch(id: number, vendor?: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/cost_study/remove_moved_match`, JSON.stringify({added_result_id: id, vendor: vendor}), {headers: headers, responseType: 'text'});
  }

  saveUploadMeta(meta: ICSUploadMeta): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    if (meta.id > 0) {
      return this.http.put(this.config.apiUrl + `/cost_study/meta`, JSON.stringify(meta), {headers: headers, responseType: 'text'});
    } else {
      return this.http.post(this.config.apiUrl + `/cost_study/meta`, JSON.stringify(meta), {headers: headers, responseType: 'text'});
    }
  }

  getCsUploadTypes(): Observable<ICsUploadType[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICsUploadType[]>(this.config.apiUrl + '/cost_study/type', {headers: headers});
  }

  getCsLocTracked(id: number): Observable<ICsLocTracked[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICsLocTracked[]>(this.config.apiUrl + `/cost_study/tracked/location/${id}`, {headers: headers});
  }
}
