import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ILocation} from "../../interfaces/ILocation";

@Component({
  selector: 'location-implementation',
  templateUrl: './implementation.html',
})
export class LocationImplementationCmp {
  @Input() location:ILocation;
  @Output() update = new EventEmitter();

  emitUpdate() {
    this.update.emit(true);
  }
}
