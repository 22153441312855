import {Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener} from '@angular/core';
import {Observable} from "rxjs";

@Component({
  selector: '[file-upload]',
  template: '<ng-content></ng-content><input type="file" style="display: none" #inp (change)="handleFiles($event)"/>',
})
export class FileUploadButtonDialog {
  @Input() selectFile: Observable<any>;
  @Input() multiple: boolean;
  @Output() filesChange = new EventEmitter();
  @ViewChild('inp') inp: ElementRef;

  ngAfterViewInit() {
    if (this.multiple && this.inp && this.inp.nativeElement) {
      this.inp.nativeElement.setAttribute('multiple', 'multiple');
    }
  }

  ngOnChanges() {
    if (this.inp && this.inp.nativeElement) {
      if (this.multiple) {
        this.inp.nativeElement.setAttribute('multiple', 'multiple');
      } else {
        this.inp.nativeElement.removeAttribute('multiple');
      }
    }
    if (this.selectFile && this.selectFile.subscribe) {
      this.selectFile.subscribe(()=> {
        this.displayDialog();
      });
    }
  }

  @HostListener('click')
  displayDialog() {
    if (this.inp && this.inp.nativeElement) {
      this.inp.nativeElement.click();
    }
  }

  handleFiles($event) {
    if ($event && $event.target) {
      this.filesChange.emit($event.target.files);
      setTimeout(()=> {
        if (this.inp && this.inp.nativeElement) {
          this.inp.nativeElement.value = '';
        }
      }, 1000);
    }
  }
}
