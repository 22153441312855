import {Component, Input} from '@angular/core';

@Component({
  selector: '[users-list]',
  templateUrl: './users-list.html',
})
export class UsersListCmp {
  @Input() users = [];
  @Input() title = '';
  @Input() subTitle = '';
  @Input() repID:number;
}
