import {Component, Input, Output, EventEmitter} from '@angular/core';
import {User} from "../../services/User";
import {Locations} from "../../services/Locations";
import {Notify} from "../../services/Notify";
import {ITaskFileUploaded} from "../../interfaces/ITaskFileUploaded";

@Component({
  selector: 'task-file-upload',
  templateUrl: './task-file-upload.html',
})
export class TaskFileUploadCmp {
  @Input() locationID = 0;
  @Output() upload = new EventEmitter<ITaskFileUploaded>();

  public fileTypesMap = {};
  public fileTypesList = [];
  public loading = false;
  public newFileData:File;
  public newFileName:string;
  public newFileType:number;
  public uploadingFile:boolean = false;

  constructor(public users:User, public locations:Locations) {
    this.users.loadFileTypes(this.fileTypesMap, this.fileTypesList);
  }

  addFile(files) {
    if (!files || !files[0]) {
      return;
    }
    this.newFileName = files[0].name;
    this.newFileData = files[0];
    if (this.fileTypesList && this.fileTypesList.length) {
      if (this.fileTypesList.length > 5) {
        this.newFileType = this.fileTypesList[5].id;
      } else {
        this.newFileType = this.fileTypesList[0].id;
      }
    }
  }

  uploadFile() {
    if (!this.locationID || !this.newFileName || !this.newFileData) {
      return false;
    }
    this.uploadingFile = true;
    this.locations.uploadFile(this.locationID, this.newFileName, this.newFileData, this.newFileType).subscribe((i)=> {
      this.uploadingFile = false;
      let f = {event: i, filename: this.newFileName, filetype: this.newFileType};
      this.upload.emit(f);
      this.resetFileUpload();
    }, (err)=> {
      this.uploadingFile = false;
      let n = new Notify();
      n.error(JSON.stringify(err), 'Error');
      console.error(err);
    });
  }

  resetFileUpload() {
    this.newFileData = null;
    this.newFileName = null;
  }
}
