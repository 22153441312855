import {Component, Input, Output, ViewChild, ElementRef, EventEmitter} from '@angular/core';
declare var $;

@Component({
  template: `
  <input type="text" (click)="openModal()" class="form-control" [ngModel]="text"/>

  <div class="modal fade" tabindex="-1" role="dialog" #modal>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <textarea [(ngModel)]="text" class="form-control" rows="10"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary" (click)="apply()">Apply</button>
        </div>
      </div>
    </div>
  </div>
  `,
  selector: 'popup-textarea',
})
export class PopupTextareaCmp {
  @Input() text:string = '';
  @Input() containerIsBody;
  @ViewChild("modal") modal:ElementRef;
  @Output() textChange = new EventEmitter();
  private movedToBody = false;

  apply() {
    this.textChange.emit(this.text);
    this.hideModal();
  }

  openModal() {
    if (this.modal && this.modal.nativeElement) {
      if (this.containerIsBody && !this.movedToBody) {
        document.body.appendChild(this.modal.nativeElement);
        this.movedToBody = true;
      }
      $(this.modal.nativeElement).modal('show');
    }
  }

  hideModal() {
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('hide');
    }
    return false;
  }

}
