import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {IFeedsImportStats} from "../../interfaces/IFeedsImportStats";

interface IFeedStatsGroup {
  group: string;
  records: IFeedsImportStats[];
}

@Component({
  templateUrl: 'report.html',
  selector: 'imported-feeds-report'
})
export class ImportedFeedsReportCmp {
  public groups: IFeedStatsGroup[];
  public loading = false;
  private n = new Notify();
  private skipFilenames = ["Expansion_FacilityElections.txt.20170109", "TMP_sales_fee_new.txt"];

  constructor(private session: Session, private reports: Reports) {
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  reload() {
    setTimeout(() => {
      this.load();
    }, 1);
  }

  load() {
    this.loading = true;
    this.groups = [{group: "Daily Feeds", records: []}, {group: "HISCI Feed", records: []}, {group: "Sales Tracing Feed", records: []}, {group: "Other", records: []}];
    this.reports.getImportedFeedsStats().subscribe((stats) => {
      if (stats?.length) {
        stats.forEach((s) => {
          if (this.skipFilenames) {
            if (this.skipFilenames.indexOf(s.filename) > -1) {
              return;
            }
          }
          let group;
          if (s.feed_table === "feed_hisci") {
            group = "HISCI Feed";
          } else {
            if (s.feed_table === "sales_tracing") {
              group = "Sales Tracing Feed";
            } else {
              if (s.filename.indexOf(".txt") > 1 && s.feed_table.indexOf("feed_") > -1) {
                group = "Daily Feeds";
              } else {
                group = "Other";
              }
            }
          }
          for (let g of this.groups) {
            if (g.group == group) {
              g.records.push(s);
              break;
            }
          }
        });
      }
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.n.error('Error', JSON.stringify(err));
    });
  }
}
