
import {forkJoin as observableForkJoin, combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {share, startWith, map, debounceTime} from 'rxjs/operators';
import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Manufacturers} from "../../services/Manufacturers";
import {IManufacturer} from "../../interfaces/IManufacturer";
import {FilterPipe} from "../FilterPipe";
import {Session} from "../../services/Session";
declare var _: any;

@Component({
  selector: 'manufacturers',
  templateUrl: './manufacturers.html',
  viewProviders: [FilterPipe]
})
export class ManufacturersCmp {
  public manufacturers: Array<IManufacturer> = [];
  public searchInput : UntypedFormControl;
  public loading = false;
  public saving = false;
  public searchGroup = new UntypedFormGroup({
    searchInput: new UntypedFormControl()
  });

  constructor(public manService: Manufacturers, public filter: FilterPipe, session: Session) {
    session.addOnLoadHandler(()=> {
      this.load();
    });
    session.redirectIfNotAuth();
  }

  load() {
    this.loading = true;
    let fetch = this.manService.load();

    this.searchInput = <UntypedFormControl>this.searchGroup.controls['searchInput'];
    let search = this.searchInput.valueChanges.pipe(debounceTime(150),map((val) => {
      return val.toLowerCase();
    }),startWith(''));

    observableCombineLatest(fetch, search, this.filter.filter).subscribe((results) => {
      this.manufacturers = results;
      this.loading = false;
    }, ()=> {this.loading = false;});
  }

  convert(m: IManufacturer, ms: IManufacturer) {
    if (m.__error) {
      m.__error = false;
    }
    if (ms) {
      m.__changed = true;
      m.original_id_bt = m.id;
      m.id = ms.id;
      m.__group = ms.label;
    }
  }

  save() {
    let objects = _.filter(this.manufacturers, {__changed: true});
    if (objects && objects.length > 0) {
      this.saving = true;
      let qs = [];
      objects.forEach((m: IManufacturer)=> {
        let e = this.manService.update(m).pipe(share());
        qs.push(e);
        e.subscribe(()=> {
          if (m.__error) {
            m.__error = false;
          }
          m.__changed = false;
        }, ()=> {
          m.__error = true;
          m.__changed = false;
        });
      });
      observableForkJoin(qs).subscribe(null, ()=> {
        this.saving = false;
      }, ()=> {
        this.saving = false;
      });
    }
  }
}
