import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CostStudy} from "../../services/CostStudy";
import {ICsResult} from "../../interfaces/ICostStudy";
import {Notify} from "../../services/Notify";
declare var $:any;

@Component({
  selector: "cs-pharma-uom-adjust",
  templateUrl: "conversion.html"
})
export class CsPharmaUomAdjustCmp {
  @Input('item') r:ICsResult;
  @Output('update') update = new EventEmitter();
  public adding = false;
  public total = 0;
  public uom;
  public quantity;
  private n = new Notify();

  constructor(private csSrv:CostStudy) {}

  ngOnInit() {
    if (this.r) {
      this.uom = this.r.fi_uom;
      this.quantity =this.r.fi_quantity;
    }
  }

  add(r:ICsResult) {
    if (this.adding || !r.ndc_mfr_catalog_number || !this.uom || !this.quantity) {
      return;
    }
    this.adding = true;
    this.csSrv.addPharmaUomAdjust({ndc_mfr_catalog_number: r.ndc_mfr_catalog_number, uom: this.uom, quantity: this.quantity, pharma_item_id: r.pharma_item_id}).subscribe(()=> {
      this.adding = false;
      this.n.success('Added');
      this.update.emit();
    }, (err)=> {
      this.adding = false;
      this.n.error('Error', err._body);
    });
  }
}
