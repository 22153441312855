
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {ISurveySession} from "../interfaces/ISurveySession";
import {ISurveyAnswer} from "../interfaces/ISurveyAnswer";
import {Session} from "./Session";
import {ISurveyResult} from "../interfaces/ISurveyResult";
import {ISurveyResultQuestion} from "../interfaces/ISurveyResultQuestion";
import {ISurveyResultSubQuestion} from "../interfaces/ISurveyResultSubQuestion";
declare var _:any;

@Injectable()
export class Survey {
  public sessions:{[id:number]:ISurveySession;} = {};

  constructor(private http: HttpClient, private config:Config, private session:Session) {}

  loadPages():Observable<any> {
    return this.http.get(this.config.apiUrl+'/survey/questions_page');
  }

  saveAnswers(answers:Array<ISurveyAnswer>):Observable<any> {
    return this.http.post(this.config.apiUrl+'/survey/answers',
      JSON.stringify({answers: answers}));
  }

  getNewSurveyAnswersSession(session:ISurveySession):Observable<number> {
    return this.http.post(this.config.apiUrl+'/survey/answers_session',
      JSON.stringify(session),{responseType: 'text'}).pipe(map(r => parseInt(r)));
  }

  saveSurveySession(session:ISurveySession):Observable<any> {
    this.sessions[session.id] = session;
    return this.http.put(this.config.apiUrl+'/survey/answers_session',
      JSON.stringify(session));
  }

  getSurveyResults(user_id:number):Observable<Array<ISurveyResult>> {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'text/json');
      headers = this.session.setAuthHeader(headers);
      return this.http.get<any>(this.config.apiUrl+'/survey/answers/'+user_id, {headers: headers}).pipe(
        map((r) => {
          let map = r;
          let answers = {};
          if (map && map.length) {
            map.forEach((a:ISurveyResult) => {
              if (!answers[a.q_id]) {
                let q = new ISurveyResultQuestion();
                q.description = a.description;
                let sq = new ISurveyResultSubQuestion();
                sq.title = a.sq_title;
                sq.id = a.sq_id;
                sq.question_type_id = a.question_type_id;
                sq.__options = [a];
                q.__questions = [sq];
                answers[a.q_id] = q;
              } else {
                let q = answers[a.q_id];
                let existing_sq:ISurveyResultSubQuestion = _.find(q.__questions, {id: a.sq_id});
                if (existing_sq) {
                  existing_sq.__options.push(a);
                } else {
                  let sq = new ISurveyResultSubQuestion();
                  sq.title = a.sq_title;
                  sq.question_type_id = a.question_type_id;
                  sq.__options = [a];
                  q.__questions.push(sq);
                }
              }
            });
          }
          return _.values(answers);
        }));
    }
}
