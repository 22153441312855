import {Component, Input} from '@angular/core';
import {Tags} from "../../services/Tags";
import {ITag} from "../../interfaces/ITag";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'tags-line',
  template: `
  <span *ngFor="let tag of tags; last as _last"
  style="color: #fff;
  border-radius: 0.5rem;
  padding: 0 0.4rem;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: bold;
  display: inline-block;" [ngStyle]="{'background-color': '#'+tag.hex_color}">
  {{tag.name}}<span *ngIf="!_last" style="color: transparent">,&nbsp;</span></span>`
})
export class TagsLineCmp {
  @Input() tags: Array<ITag> = [];
  @Input() cline: string;
  public tagsMap = {};
  public previous_cline;

  constructor(public tagsService: Tags, public sanitizer: DomSanitizer) {
    this.tagsService.loadToMap(this.tagsMap, ()=> {
      this.ngOnChanges();
    });
  }

  ngOnChanges() {
    if (this.cline && this.cline !== this.previous_cline) {
      this.tags = [];
      let ids = this.cline.split(',');
      if (ids) {
        ids.forEach((idSrc)=> {
          let id = parseInt(idSrc);
          if (this.tagsMap[id]) {
            this.tags.push(this.tagsMap[id]);
          }
        });
      }
    }
  }
}
