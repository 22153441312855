import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Locations} from "../../services/Locations";
import {Distributors} from "../../services/Distributors";
import {Specialties} from "../../services/Specialties";
import {Cots} from "../../services/Cots";
import {ILocationDistributor} from "../../interfaces/ILocationDistributor";
import {IDistributor} from "../../interfaces/IDistributor";
import {TaxStatuses} from "../../services/TaxStatuses";
import {ICotInternal} from "../../interfaces/ICotInternal";
import {YearlySpendTypes} from "../../services/YearlySpendTypes";
import {IUser} from "../../interfaces/IUser";
import {Config} from "../../services/Config";
import {ILocation} from "../../interfaces/ILocation";
import {Notify} from "../../services/Notify";
import {ILocationGroup} from "../../interfaces/ILocationGroup";
import {Themes} from "../../services/Themes";
import {ITheme} from "../../interfaces/ITheme";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {ICot} from "../../interfaces/ICot";
import {ITaxStatus} from "../../interfaces/ITaxStatus";
import {IYearlySpendType} from "../../interfaces/IYearlySpendType";
import {IAccountSize} from "../../interfaces/IAccountSize";
import {AccountSizes} from "../../services/AccountSizes";
import {User} from "../../services/User";
import {ArrayJoinNotEmptyPipe} from "../../services/ArrayJoinNotEmptyPipe";
import {ISubTheme} from "../../interfaces/ISubTheme";
import {Helpers} from "../../services/Helpers";
declare var _;
declare var $:any;
declare var ClipboardJS:any;

@Component({
  selector: 'location-card',
  templateUrl: './location.html',
})
export class LocationCardCmp {
  @Input() location:ILocation;
  @Input() editModeAllowed = true;
  @Input() fields:string[] = [];
  @Input() infoFields:string[] = [];
  @Output() update = new EventEmitter();
  @Output() beforeUpdate = new EventEmitter();
  @Output() cancelSave = new EventEmitter();
  @Output() editModeChange = new EventEmitter();
  @ViewChild('copy') copyBtn:ElementRef;

  public selectCopy = false;
  public editMode = false;
  public copy;
  public saving;
  public distributorsMap:{[id: number]: IDistributor;} = {};
  public distributorsList:IDistributor[] = [];
  public specialtiesMap = {};
  public specialtiesList:ISpecialty[] = [];
  public cotsMap:{[id: number]: ICot;} = {};
  public cotsList:ICot[] = [];
  public cotsInternalMap:{[id: number]: ICotInternal;} = {};
  public cotsInternalList:ICotInternal[] = [];
  public cotsInternalPrimaryList:ICotInternal[] = [];
  public cotsInternalSubList = [];
  public taxStatusesMap = {};
  public taxStatusesList:ITaxStatus[] = [];
  public yearlySpendMap = {};
  public yearlySpendList:IYearlySpendType[] = [];
  public accountSizeMap = {};
  public accountSizeList:IAccountSize[] = [];
  public location_distributors:Array<ILocationDistributor> = [];
  public originalGroupName:string;
  public originalAccountSizeID:number;
  public originalAddressSet:string = '';
  public themes:Array<ITheme> = [];
  public subThemes:Array<ISubTheme> = [];
  public filteredSubThemes:Array<ISubTheme> = [];
  public listsLoadStarted = false;
  public enumDivisionName:string[] = [];
  public enumGroupName:string[] = [];
  public enumMarketName:string[] = [];
  public enumCompanyName:string[] = [];
  public distrRepUsersListHandler;
  public nullValue = null;
  public newRep;
  private subThemeExists = false;
  private allClipboardsRes;
  private copyBtnClipboardRes;

  constructor(public locations: Locations, public distributors: Distributors, public specialties: Specialties,
    public cots: Cots, public taxStatus: TaxStatuses, public yearlySpendSrv: YearlySpendTypes,
    public config: Config, public themesSrv: Themes, private accSizeSrv: AccountSizes, private userSrv: User,
    private arrJoin: ArrayJoinNotEmptyPipe, private h: Helpers) {
    this.loadLists();
    this.distrRepUsersListHandler = (users, context) => {
      return this.distrRepUsersListHandlerF(users, context);
    };
  }

  ngOnDestroy() {
    if (this.allClipboardsRes && this.allClipboardsRes.destroy) {
      try {
        this.allClipboardsRes.destroy();
      } catch (e) {}
    }
  }

  loadLists() {
    if (this.listsLoadStarted) {
      return;
    }
    this.listsLoadStarted = true;
    this.distributors.loadToMap(this.distributorsMap);
    this.distributors.loadToList(this.distributorsList);
    this.specialties.loadToMap(this.specialtiesMap);
    this.specialties.loadToList(this.specialtiesList);
    this.cots.loadToMap(this.cotsMap);
    this.cots.loadToList(this.cotsList);
    this.cots.loadInternalToMap(this.cotsInternalMap);
    this.cots.loadInternalToList(this.cotsInternalList, null, ()=> {this.preloadCotsSub();});
    this.cots.loadInternalToList(this.cotsInternalPrimaryList, c => !c.parent_id);
    this.taxStatus.loadToMap(this.taxStatusesMap);
    this.taxStatus.loadToList(this.taxStatusesList);
    this.yearlySpendSrv.loadToMap(this.yearlySpendMap);
    this.yearlySpendSrv.loadToList(this.yearlySpendList);
    this.accSizeSrv.loadToMap(this.accountSizeMap);
    this.accSizeSrv.loadToList(this.accountSizeList);
    this.themesSrv.getThemes().subscribe((t)=> {this.themes = t;}, ()=> {});
    this.themesSrv.getSubThemes().subscribe((t)=> {
      this.subThemes = t;
      this.filterSubThemes();
    }, ()=> {});
    this.locations.getEnumFields().subscribe((enums)=> {
      if (enums) {
        if (enums.division_name) {
          this.enumDivisionName = enums.division_name;
        }
        if (enums.market_name) {
          this.enumMarketName = enums.market_name;
        }
        if (enums.group_name) {
          this.enumGroupName = enums.group_name;
        }
        if (enums.company_name) {
          this.enumCompanyName = enums.company_name;
        }
      }
    }, ()=> {});
  }

  ngOnChanges() {
    if (this.location && !this.location.id) {
      this.editMode = true;
      this.editModeChange.emit(this.editMode);
      if (this.location['___parent_location_id']) {
        this.locations.getDistributors(this.location['___parent_location_id'], true).subscribe((location_distributors)=> {
          if (location_distributors && location_distributors.length > 0) {
            this.location_distributors = [];
            location_distributors.forEach((ld)=> {
              let newld = this.h.clone(ld);
              newld.id = 0;
              newld.bill_to = '';
              newld.ship_to = '';
              newld.location_id = this.location.id;
              if (newld.level == null) {
                newld.level = 0;
              }
              this.location_distributors.push(newld);
            });
          }
        }, ()=> {});
      }
    }
    if (this.location && this.location.id) {
      this.loadMyDistributors(true);
      this.preloadCotsSub();
      this.initCopyBtn();
      this.filterSubThemes();
    }
  }

  loadMyDistributors(refresh?:boolean) {
    this.locations.getDistributors(this.location.id, refresh).subscribe((location_distributors)=> {
      if (location_distributors && location_distributors.length > 0) {
        this.location_distributors = location_distributors;
        this.location_distributors.forEach((ld)=> {
          if (ld.level == null) {
            ld.level = 0;
          }
        });
      }
    }, ()=> {});
  }

  setThemeID(id) {
    let new_id = parseInt(id)
    if (this.location.theme_id == new_id) {
      return;
    }
    this.location.theme_id = new_id;
    this.location.sub_theme_id = 0;
    this.filterSubThemes();
  }

  filterSubThemes() {
    this.filteredSubThemes = [];
    this.subThemeExists = false;
    if (this.location && this.location.theme_id && this.subThemes && this.subThemes.length > 0) {
      this.subThemes.forEach((t) => {
        if (t.parent_theme_id == this.location.theme_id) {
          this.subThemeExists = true;
          this.filteredSubThemes.push(t);
        }
      });
      if (this.subThemeExists && this.filteredSubThemes.length == 1) {
        this.location.sub_theme_id = this.filteredSubThemes[0].id;
      }
    }
  }

  preloadCotsSub() {
    if (this.location.class_of_trade_internal_primary_id) {
      this.fillCotInternalSubList(this.location.class_of_trade_internal_primary_id);
    }
  }

  getAddressSet(location:ILocation):string {
    if (!location) {
      return '';
    }
    return [(location.address || '').trim(), (location.address2 || '').trim(), (location.city || '').trim(), (location.zip || '').trim(), (location.state || '').trim()].join('::');
  }

  switchEditMode() {
    if (!this.editMode) {
      this.copy = JSON.stringify(this.location);
      this.originalGroupName = this.location.__group_company;
      this.originalAccountSizeID = this.location.__account_size_id;
      this.originalAddressSet = this.getAddressSet(this.location);
    } else {
      if (this.copy) {
        let restore = JSON.parse(this.copy);
        for (let k of Object.keys(restore)) {
          this.location[k] = restore[k];
        }
        this.originalGroupName = this.location.__group_company;
        this.originalAccountSizeID = this.location.__account_size_id;
      }
      this.cancelSave.emit(true);
    }
    this.editMode = !this.editMode;
    this.editModeChange.emit(this.editMode);
  }

  save() {
    this.beforeUpdate.emit(this.location);
    if (this.location && !this.location.__cancelUpdate) {
      if (this.subThemeExists && !this.location.sub_theme_id) {
        let notify = new Notify();
        notify.error("Please select sub-theme - required for selected theme");
        return;
      }
      this.saving = true;
      let is_insert = !(this.location.id);
      if (is_insert && this.location_distributors && this.location_distributors.length > 0) {
        this.location.__distributor_id = null;
        this.location.__distributor_secondary_id = null;
        this.location.__distributor_pharma_id = null;
        this.location.__distributor_pharma_secondary_id = null;
      }
      let addressSet = this.getAddressSet(this.location);
      let toUspsify = (addressSet != '' && addressSet != this.originalAddressSet);
      this.locations.updateLocation(this.location).then(()=> {
        this.saving = false;
        this.copy = null;
        this.editMode = false;
        this.editModeChange.emit(this.editMode);
        if (is_insert && this.location.id) {
          this.saveDistributors();
        }
        if (this.location.__group_company !== this.originalGroupName || this.location.__account_size_id != this.originalAccountSizeID) {
          const updGrp = (id)=> {
            const lg = new ILocationGroup();
            lg.id = id;
            lg.company = this.location.__group_company;
            lg.account_size_id = this.location.__account_size_id;
            this.locations.saveGroup(lg).subscribe(()=> {
              this.originalGroupName = this.location.__group_company;
              this.originalAccountSizeID = this.location.__account_size_id;
              this.update.emit(this.location);
            }, (err) => {
              this.location.__group_company = this.originalGroupName;
              this.location.__account_size_id = this.originalAccountSizeID;
              const notify = new Notify();
              notify.error('Unable to rename group, server error');
              console.error(err);
              this.update.emit(this.location);
            });
          };
          const groupID = this.location.location_group_id;
          if (!groupID) {
            this.locations.getLocation(this.location.id).then((loc:ILocation)=> {
              if (loc.location_group_id) {
                this.location.location_group_id = loc.location_group_id;
                updGrp(loc.location_group_id);
              } else {
                this.update.emit(this.location);
              }
            }, () => {});
          } else {
            updGrp(groupID);
          }
        } else {
          this.update.emit(this.location);
        }
        // Save group IDs
        if (this.location.id === this.location.__group_primary_location_id) {
          const updGrp = (id) => {
            const lg = new ILocationGroup();
            lg.id = id;
            lg.pat_id = this.location.__group_pat_id;
            lg.grade_id = this.location.__group_grade_id;
            lg.medicare_id = this.location.__group_medicare_id;
            lg.implementation_id = this.location.__group_implementation_id;
            lg.authority_to_sign = this.location.__group_authority_to_sign;
            lg.public_entity_designation = this.location.__group_public_entity_designation;
            this.locations.saveGroup(lg).subscribe(() => {
              this.update.emit(this.location);
            }, (err) => {
              const notify = new Notify();
              notify.error('Unable to set group IDs');
              console.error(err);
              this.update.emit(this.location);
            });
          };
          const groupID = this.location.location_group_id;
          if (!groupID) {
            this.locations.getLocation(this.location.id).then((loc:ILocation)=> {
              if (loc.location_group_id) {
                this.location.location_group_id = loc.location_group_id;
                updGrp(loc.location_group_id);
              } else {
                this.update.emit(this.location);
              }
            }, () => {});
          } else {
            updGrp(groupID);
          }
        } else {
          this.update.emit(this.location);
        }
        if (toUspsify) {
          const notify = new Notify();
          this.locations.uspsifyLocation(this.location.id).subscribe(()=> {
            this.update.emit(this.location);
            notify.success('USPS address has been refreshed');
          }, (err)=> {
            notify.httpErr(err);
          });
        }
      }, (response)=> {
        console.error(response);
        this.saving = false;
        let notify = new Notify();
        switch (response.status) {
          case 409:
            notify.error(response._body, 'Duplicate value');
            break;
          case 400:
            notify.error('Non-numeric value used for only-numeric field, please fix it.', 'Wrong type');
            break;
          default:
            notify.error(response.status + ' ' + response._body, 'Error');
        }
      });
      if (!is_insert) {
        this.saveDistributors();
      }
    }
  }

  saveDistributors() {
    if (this.location_distributors && this.location_distributors.length > 0) {
      let i = 0;
      this.location_distributors.forEach((ld:ILocationDistributor)=> {
        if (!ld.location_id) {
          if (!this.location || !this.location.id) {
            return;
          }
          ld.location_id = this.location.id;
        }
        if (ld.level == 0) {
          ld.level = null;
        }
        if (ld['__deleted']) {
          return;
        }
        i = i + 1;
        this.locations.saveDistributor(ld).subscribe(()=> {
          i = i - 1;
          if (i < 1) {
            this.loadMyDistributors(true);
          }
        }, (response)=> {
          i = i - 1;
          if (response.status === 409) {
            let notify = new Notify();
            notify.error(response._body, 'Duplicate value');
          } else {
            let notify = new Notify();
            notify.error(response.status + ' ' + response._body, 'Error');
          }
          console.error(response);
        });
      });
    }
  }

  deleteDistributor(d) {
    let i = this.location_distributors.indexOf(d);
    if (i < 0) {
      return;
    }
    d['__deleted'] = true;
    this.location_distributors.splice(i, 1);
    if (d.id > 0) {
      this.locations.deleteDistributor(d.id).subscribe(()=> {}, ()=> {});
    }
  }

  addDistributor() {
    if (this.newRep) {
      this.nullifyRep();
    }
    let ld = new ILocationDistributor();
    ld.location_id = this.location.id;
    ld.level = 1;
    ld.type_id = 1;
    if (this.location_distributors.length > 0) {
      let has_primary = false;
      let has_secondary = false;
      this.location_distributors.forEach((ldistr)=> {
        if (ldistr.level == 1 && ldistr.type_id == 1) {
          has_primary = true;
        }
        if (ldistr.level == 2 && ldistr.type_id == 1) {
          has_secondary = true;
        }
      });
      if (has_primary) {
        if (has_secondary) {
          ld.level = null;
        } else {
          ld.level = 2;
        }
      } else {
        ld.level = 1;
      }
    }
    this.location_distributors.push(ld);
  }

  changeCotInternal($event) {
    let id = parseInt($event);
    this.location.class_of_trade_internal_primary_id = id;
    this.location.class_of_trade_internal_sub_id = 0;
    if (id > 0) {
      this.fillCotInternalSubList(id);
    }
  }

  fillCotInternalSubList(id) {
    if (!this.cotsInternalList || !this.cotsInternalList.length) {
      return;
    }
    this.cotsInternalSubList = [];
    this.cotsInternalList.forEach((c:ICotInternal)=> {
      if (c.parent_id === id) {
        this.cotsInternalSubList.push(c);
      }
    });
  }

  changePaDate(date:Date) {
    this.location.pa_date = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  changeDateField(date:Date, field) {
    this.location[field] = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  setZero(field) {
    if (!this.location) {
      return;
    }
    this.location[field] = 0;
  }

  initCopyBtn() {
    setTimeout(() => {
      try {
        if (Clipboard) {
          if (this.allClipboardsRes && this.allClipboardsRes.destroy) {
            try {
              this.allClipboardsRes.destroy();
            } catch (e) {}
          }
          let allClipboards = new ClipboardJS('.clipboard-btn');
          allClipboards.on('success', (e) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          this.allClipboardsRes = allClipboards;
        }
      } catch (e) {}
      try {
        if (this.copyBtn && this.copyBtn.nativeElement && ClipboardJS) {
          if (this.copyBtnClipboardRes && this.copyBtnClipboardRes.destroy) {
            try {
              this.copyBtnClipboardRes.destroy();
            } catch (e) {}
          }
          let clipboard = new ClipboardJS(this.copyBtn.nativeElement);
          this.copyBtn.nativeElement.setAttribute('data-clipboard-text', this.getInfoForCopy());
          this.userSrv.getUserInfo(this.location.primary_contact_user_id).then((c)=> {
            if (this.copyBtn && this.copyBtn.nativeElement) {
              this.copyBtn.nativeElement.setAttribute('data-clipboard-text', this.getInfoForCopy(c));
            }
          }, () => {});
          clipboard.on('success', (e) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clipboard.on('error', () => {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
          this.copyBtnClipboardRes = clipboard;
        }
      } catch (e) {}
    }, 60);
  }

  copyInfo($event) {
    $event.stopPropagation();
    setTimeout(() => {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e) => {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(() => {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }

  getInfoForCopy(contact?:IUser):string {
    let f:ILocation = this.location;
    let info = `Legal Name: ${f.facility_name}`;
    if (f.dba) {
      info += `\nDBA name: ${f.dba}`;
    }
    if (f.address || f.address2 || f.city || f.state || f.zip) {
      info += "\nAddress: " + this.arrJoin.transform([f.address, f.address2, f.city, f.state, f.zip]);
    }
    if (f.zimmer_id) {
      info += `\nGPOID: ${f.zimmer_id}`;
    }
    if (f.zimmer_co_id) {
      info += `\nCOID: ${f.zimmer_co_id}`;
    }
    if (contact) {
      let c = contact;
      info += `\nContact name: ${c.fname} ${c.lname}\nContact Email: ${c.email}`;
      if (c.phone) {
        info += `\nContact Phone #: ${c.phone}`;
      }
    }
    return info;
  }

  showField(field:string):boolean {
    if (!this.fields || this.fields.length < 1) {
      return true;
    }
    return (this.fields.indexOf(field) > -1);
  }

  infoField(field:string):boolean {
    if (!this.infoFields || this.infoFields.length < 1) {
      return true;
    }
    return (this.infoFields.indexOf(field) > -1);
  }

  distrRepUsersListHandlerF(users:IUser[], context) {
    if (!context) {
      return;
    }
    this.h.removeFromArr(users, (u:IUser) => {
      return (u.distributor_id != parseInt(context));
    });
  }

  addRep() {
    this.newRep = <IUser>{};
    this.newRep.type_id = this.config.userTypeRep;
  }

  setNewRep(rep, locationDistributor:ILocationDistributor) {
    if (rep && rep.id) {
      locationDistributor.rep_user_id = rep.id;
    }
    this.newRep = null;
  }

  nullifyRep() {
    this.newRep = null;
  }
}
