import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from './Config';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {IUser} from '../interfaces/IUser';

@Injectable()
export class Session {
  public user:IUser = <IUser>{};
  private token = '';
  private storage_key_token = 'crmSession';
  private onLoadHandlers:Array<Function>;
  private storage;

  constructor(private config:Config, private router:Router, private http:HttpClient) {
    this.storage = window.localStorage;
    try {
      let t = this.storage.getItem(this.storage_key_token);
      if (typeof t === 'string') {
        this.token = t;
      }
    } catch (e) {}
  }

  getToken() {
    return this.token;
  }

  setToken(value) {
    if (typeof value !== 'string') {
      console.error('expected string, got', typeof value, value);
      return;
    }
    this.token = value;
    try {
      this.storage.setItem(this.storage_key_token, value);
    } catch (e) {}
  }

  deleteToken() {
    this.token = '';
    try {
      this.storage.removeItem(this.storage_key_token);
    } catch (e) {}
  }

  logout() {
    this.deleteToken();
    this.user = <IUser>{};
  }

  loadUser(success?, error?) {
    if (!this.token) {
      if (error) {
        error();
      }
      return;
    }
    this.http.get<IUser>(this.config.apiUrl + '/session/' + this.token).subscribe((user) => {
        if (user) {
          this.user = user;
          this.callHandlers(this.onLoadHandlers, this.user);
          this.onLoadHandlers = [];
          if (success) {
            success();
          }
        } else {
          if (error) {
            error();
          }
        }
      },
      err => {
        if (error) {
          error();
        }
        this.user = null
      });
  }

  redirectIfNotAuth(routerRedirectPath?:String) {
    routerRedirectPath = routerRedirectPath || 'login';
    if (this.user && this.user.id) {
      this.callHandlers(this.onLoadHandlers, this.user);
      this.onLoadHandlers = [];
      return true;
    }
    this.loadUser(()=> {}, ()=> {
      this.router.navigate([routerRedirectPath]);
    });
  }

  setAuthHeader(headers:HttpHeaders):HttpHeaders {
    if (this.token) {
      return headers.set('Authorization', 'Bearer ' + this.token);
    } else {
      return headers;
    }
  }

  addOnLoadHandler(handler:Function) {
    if (!this.onLoadHandlers) {
      this.onLoadHandlers = [];
    }
    this.onLoadHandlers.push(handler);
  }

  callHandlers(handlersArray:Array<Function>, result?) {
    if (!handlersArray || !handlersArray.length) {
      return;
    }
    handlersArray.forEach((handler)=> {handler(result);});
  }
}
