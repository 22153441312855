import {Pipe} from '@angular/core';

function decimalNumberWithCommas(x) {
  if (!x) {
    return 0;
  }
  let s = (Math.round(x * 100) / 100).toFixed(2).toString();
  return s.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
@Pipe({
  name: 'pricefmt'
})
export class PriceFormatPipe {
  transform(list, args:string[]):any {
    return decimalNumberWithCommas(list);
  }
}
