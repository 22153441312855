import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ICsLocTracked} from "../../interfaces/ICostStudy";
import {Observable} from "rxjs";
import {CostStudy} from "../../services/CostStudy";

@Component({
  selector: 'location-tracked-cs',
  templateUrl: './tracked_cs.html',
})
export class LocationTrackedCsCmp {
  @Input() locationID;
  @Output() count = new EventEmitter();
  @Input() refresh:Observable<any>;
  public uploads:ICsLocTracked[];
  public refreshSubscribed = false;
  public loadingData = false;

  constructor(public cstSrv:CostStudy) {
  }

  ngAfterViewInit() {
    if (this.refresh && !this.refreshSubscribed) {
      this.refresh.subscribe(() => {
        this.loadData();
      }, () => {});
      this.refreshSubscribed = true;
    }
  }

  ngOnChanges() {
    if (this.locationID) {
      this.loadData();
    }
  }

  loadData() {
    if (this.loadingData) {
      return;
    }
    this.loadingData = true;
    this.cstSrv.getCsLocTracked(this.locationID).subscribe((response) => {
      this.loadingData = false;
      this.uploads = response;
      if (this.uploads) {
        this.count.emit(this.uploads.length);
      }
    }, () => {
      this.loadingData = false;
    });
  }
}
