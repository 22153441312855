import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from "rxjs";
import {IContractRequirement} from "../interfaces/IContractRequirement";

@Injectable()
export class ContractRequirements {
  private config: Config;
  private session: Session;
  private http: HttpClient;

  constructor(config: Config, http: HttpClient, session: Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return headers;
  }

  getContractRequirements(): Observable<IContractRequirement[]> {
    return this.http.get<IContractRequirement[]>(this.config.apiUrl + '/contract_requirements', {headers: this.getHeaders()});
  }

  saveContractRequirement(cr: IContractRequirement): Observable<string> {
    return this.http.post(this.config.apiUrl + `/contract_requirements`, JSON.stringify(cr), {headers: this.getHeaders(), responseType: 'text'});
  }

  deleteContractRequirement(id: number): Observable<any> {
    return this.http.delete(this.config.apiUrl + `/contract_requirements/${id}`, {headers: this.getHeaders()});
  }
}
