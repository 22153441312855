
import {of as observableOf, Observable} from 'rxjs';
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {IEditRequest} from "../../interfaces/IEditRequest";
import {Notify} from "../../services/Notify";
import {EditRequests} from "../../services/EditRequests";
import {ILocation} from "../../interfaces/ILocation";
import {IUser} from "../../interfaces/IUser";
import {User} from "../../services/User";
import {Locations} from "../../services/Locations";

@Component({
  templateUrl: './edit-request.html',
  selector: 'edit-request',
})
export class EditRequestCmp {
  @Input() request:IEditRequest;
  @Input() valuesConverter;
  @Input() approveCall:Observable<any>;
  @Output() update = new EventEmitter();
  public saving = false;
  private notify:Notify;
  private old_value_hr:string;
  private new_value_hr:string;

  constructor(private reqSrv:EditRequests, private usrSrv:User, private locSrv:Locations) {
    this.notify = new Notify();
  }

  ngOnChanges() {
    if (this.request && this.request.edit_field && this.valuesConverter && (typeof this.valuesConverter === 'function')) {
      this.valuesConverter(this.request.edit_field, this.request.old_value).subscribe((s)=> {
        this.old_value_hr = s;
      }, ()=> {});
      this.valuesConverter(this.request.edit_field, this.request.new_value).subscribe((s)=> {
        this.new_value_hr = s;
      }, ()=> {});
    }
    if (this.approveCall) {
      this.approveCall.subscribe(()=> {
        this.approve(true);
      }, ()=> {});
    }
  }

  approve(emitUpdateDelayed?:boolean) {
    if (this.saving) {
      return;
    }
    if (!this.request || !this.request.id) {
      this.notify.error('No request ID');
    }
    this.saving = true;
    this.reqSrv.approveRequest(this.request.id, this.old_value_hr, this.new_value_hr).subscribe(()=> {
      this.saving = false;
      this.notify.success('Approved');
      if (emitUpdateDelayed) {
        setTimeout(()=> {
          this.update.emit();
        }, 50);
      } else {
        this.update.emit();
      }
    }, (err)=> {
      this.saving = false;
      this.notify.error('Error');
      console.error(err);
      setTimeout(()=> {
        this.update.emit();
      }, 1000);
    });
  }

  decline() {
    if (!this.request || !this.request.id) {
      this.notify.error('No request ID');
    }
    this.saving = true;
    this.reqSrv.declineRequest(this.request.id).subscribe(()=> {
      this.saving = false;
      this.notify.success('Declined');
      this.update.emit();
    }, (err)=> {
      this.saving = false;
      this.notify.error('Error');
      console.error(err);
      this.update.emit();
    });
  }

  getFieldValueHumanReadable(value):Observable<any> {
    if (this.request && this.request.edit_field && this.valuesConverter && (typeof this.valuesConverter === 'function')) {
      let s = this.valuesConverter(this.request.edit_field, value);
      s.subscribe(()=> {}, ()=> {});
      return s;
    } else {
      return observableOf(value);
    }
  }
}
