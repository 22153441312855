import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Distributors} from "../../services/Distributors";
import {User} from "../../services/User";
import {Specialties} from "../../services/Specialties";
import {IUser} from "../../interfaces/IUser";
import {JobTypes} from "../../services/JobTypes";
import {Notify} from "../../services/Notify";
import {Themes} from "../../services/Themes";
import {ITheme} from "../../interfaces/ITheme";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {IDistributor} from "../../interfaces/IDistributor";
import {IJobType} from "../../interfaces/IJobType";

@Component({
  selector: 'user-card',
  templateUrl: './user-card.html',
})
export class UserCardCmp {
  @Input() user:IUser = <IUser>{};
  @Input() id:number = 0;
  @Input() editModeAllowed = true;
  @Input() infoModeAllowed = true;
  @Input() fields:string[] = [];
  @Input() infoFields:string[] = [];
  @Input() hideInfoFields:string[] = [];
  @Output() update = new EventEmitter();
  @Output() beforeUpdate = new EventEmitter();
  @Output() cancelSave = new EventEmitter();

  public editMode = false;
  public copy;
  public saving;
  public distributorsMap = {};
  public distributorsList:IDistributor[] = [];
  public specialtiesMap = {};
  public specialtiesList:ISpecialty[] = [];
  public jobTypesMap = {};
  public jobTypesList:IJobType[] = [];
  public themes:Array<ITheme> = [];
  public loading = false;

  constructor(public users:User, public distributors:Distributors, public specialties:Specialties,
    public jobTypes:JobTypes, public themesSrv:Themes) {
    this.distributors.loadToMap(this.distributorsMap);
    this.distributors.loadToList(this.distributorsList);
    this.specialties.loadToMap(this.specialtiesMap);
    this.specialties.loadToList(this.specialtiesList);
    this.jobTypes.loadToMap(this.jobTypesMap);
    this.jobTypes.loadToList(this.jobTypesList);
    this.themesSrv.getThemes().subscribe((t) => {this.themes = t;}, () => {});
  }

  ngOnChanges() {
    if (!this.infoModeAllowed || (this.user && !this.user.id && this.editModeAllowed)) {
      this.editMode = true;
    }
    if ((!this.user || !this.user.id || this.id !== this.user.id) && this.id) {
      this.loading = true;
      this.users.getUserInfo(this.id).then((user) => {
        this.loading = false;
        this.user = <IUser>user;
        if (this.infoModeAllowed) {
          this.editMode = false;
        }
      }, (error) => {
        this.loading = false;
        console.error(error);
      });
    }
  }

  switchEditMode() {
    if (!this.editMode) {
      this.copy = JSON.stringify(this.user);
    } else {
      if (this.copy) {
        this.user = JSON.parse(this.copy);
      }
      this.cancelSave.emit(true);
    }
    this.editMode = !this.editMode;
  }

  save() {
    this.beforeUpdate.emit(this.user);
    if (this.user && !this.user.__cancelUpdate) {
      this.saving = true;
      this.users.updateUser(this.user).then(() => {
          this.saving = false;
          this.copy = null;
          if (this.infoModeAllowed) {
            this.editMode = false;
          }
          this.update.emit(this.user);
        },
        (response) => {
          console.error(response);
          this.saving = false;
          let notify = new Notify();
          switch (response.status) {
            case 409:
              notify.error(response._body, 'Duplicate value');
              break;
            case 400:
              notify.error('Non-numeric value used for only-numeric field, please fix it.', 'Wrong type');
              break;
            default:
              notify.error(response.status + ' ' + response._body, 'Error');
          }
        });
    }
  }

  showField(field:string):boolean {
    if (!this.fields || this.fields.length < 1) {
      return true;
    }
    return (this.fields.indexOf(field) > -1);
  }

  infoField(field:string):boolean {
    if (this.hideInfoFields && this.hideInfoFields.indexOf(field) > -1) {
      return false;
    }
    if (!this.infoFields || this.infoFields.length < 1) {
      return true;
    }
    return (this.infoFields.indexOf(field) > -1);
  }

  toggleEmailSuppress($event?) {
    if (this.user && this.user.id) {
      this.user.is_email_suppressed = !this.user.is_email_suppressed;
      this.save();
    }
    if ($event && $event.stopPropagation) {
      $event.stopPropagation();
    }
    return false;
  }
}
