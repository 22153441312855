import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from "rxjs";
import {IVendorData} from "../interfaces/IVendor";
import {IQuarterReport} from "../interfaces/IVendor";

@Injectable()
export class Vendors {
  private config:Config;
  private session:Session;
  private http: HttpClient;

  constructor(config:Config, http: HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  getVendorData(id:number, company_at_r?:string):Observable<IVendorData> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + '/vendor/' + id;
    if (company_at_r) {
      if (company_at_r == 'Independent') {
        company_at_r = 'N/A';
      }
      url = url + '?company_at_r=' + company_at_r;
    }
    return this.http.get<IVendorData>(url, {headers: headers});
  }

  getVendorQuarterReport(id:number, quarter:string):Observable<IQuarterReport> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IQuarterReport>(this.config.apiUrl + '/vendor/' + id + '/quarter/' + quarter, {headers: headers});
  }
}
