
import {of as observableOf, Observable} from 'rxjs';

import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from './Config';
import {IJobType} from "../interfaces/IJobType";

@Injectable()
export class JobTypes {
  private loadingObservable: Observable<any>;
  private list: Array<IJobType> = <Array<IJobType>>[];

  constructor(private http: HttpClient, private config: Config) {}

  load(): Observable<[IJobType]> {
    if (this.list && this.list.length > 0) {
      return <Observable<[IJobType]>>observableOf(this.list);
    }
    if (this.loadingObservable) {
      return this.loadingObservable;
    }
    this.loadingObservable = this.http.get(this.config.apiUrl + '/job_type').pipe(share());
    this.loadingObservable.subscribe((items)=> {
      this.list = items;
    }, ()=> {});
    return this.loadingObservable;
  }

  loadToMap(map: {[id: number]: IJobType;}) {
    this.load().subscribe((JobTypes)=> {
      if (JobTypes && JobTypes.length > 0) {
        JobTypes.forEach((t)=> {
          map[t.id] = t;
        });
      }
    }, ()=> {});
  }

  loadToList(list: IJobType[]) {
    this.load().subscribe((JobTypes)=> {
      if (JobTypes && JobTypes.length > 0) {
        JobTypes.forEach((t)=> {
          list.push(t);
        });
      }
    }, ()=> {});
  }
}
