import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from "rxjs";
import {ISnapshotsListItem} from "../interfaces/ISnapshotsListItem";

@Injectable()
export class ReportSnapshots {
  private config:Config;
  private session:Session;
  private http: HttpClient;

  constructor(config:Config, http: HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  getSnapshotsList():Observable<[ISnapshotsListItem]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<[ISnapshotsListItem]>(this.config.apiUrl + '/report_snapshot', {headers: headers});
  }

  getSnapshot(id:number):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/report_snapshot/' + id, {headers: headers});
  }
}
