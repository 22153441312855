import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {ICompleteRxTaskReq, Tasks} from "../../services/Tasks";
import {Notify} from '../../services/Notify';
import {Reports} from "../../services/Reports";
import {Helpers} from "../../services/Helpers";

export interface IRxSignupsReportLine {
  facility_name?: string;
  merck?: string;
  pfizer?: string;
  sanofi?: string;
  sequiris?: string;
  does_not_use?: string;
  location_id?: number;
  parent_pc_id?: string;
  pc_id?: string;
  gpo_id?: string;
  dea?: string;
  hin?: string;
  pharma_eff_date?: string;
  signature?: string;
  signature_time?: string;
  merck_task_created: boolean;
  merck_task_completed?: boolean;
  task_merck_id?: number;
  ccf_task_created: boolean;
  ccf_task_completed?: boolean;
  task_ccf_id?: number;
}

export interface IRxSignupsReport {
  query: string;
  records: IRxSignupsReportLine[];
}

@Component({
  templateUrl: 'rx_signups.html',
  selector: 'rx-signups-report',
})
export class ReportRxSignupsCmp {
  @Input('report') report:IRxSignupsReport;
  @Output() update = new EventEmitter();
  @ViewChild('completeTaskModal') completeTaskModal:ElementRef;
  @ViewChild('completeTaskCCFModal') completeTaskCCFModal:ElementRef;

  public sortDesc = false;
  public orderField:string = '';
  public loading = false;
  public current:IRxSignupsReportLine;
  public currentTaskName:string = '';
  public csvUrl = '';

  constructor(private taskSrv:Tasks, private reports:Reports, private h:Helpers) {
    this.csvUrl = this.reports.getRxSignupsCsvUrl();
  }

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  createCompleteTask(line:IRxSignupsReportLine, task_name:string) {
    if (!line || line['__creating_task']) {
      return;
    }
    this.current = line;
    this.currentTaskName = task_name;
    setTimeout(()=> {
      if (task_name == 'ccf' && !line.task_ccf_id) {
        this.h.showModal(this.completeTaskCCFModal);
      } else {
        this.h.showModal(this.completeTaskModal);
      }
    }, 1);
  }

  confirmCompleteTask() {
    this.h.hideModal(this.completeTaskCCFModal);
    this.h.hideModal(this.completeTaskModal);
    if (!this.current) {
      return;
    }
    let line = this.current;
    let task_name = this.currentTaskName;
    if (line['__creating_task']) {
      return;
    }
    let r:ICompleteRxTaskReq = {task_name: task_name, location_id: line.location_id};
    if (line.task_ccf_id && task_name == 'ccf') {
      r.task_q_id = line.task_ccf_id;
    } else {
      if (line.task_merck_id && task_name == 'merck') {
        r.task_q_id = line.task_merck_id;
      }
    }
    line['__creating_task'] = true;
    this.taskSrv.completeRxTask(r).subscribe(()=> {
      this.loadReport(()=> {
        line['__creating_task'] = false;
      });
    }, (err)=> {
      line['__creating_task'] = false;
      let n = new Notify();
      n.httpErr(err);
    });
  }

  selectTaskCCF(taskName) {
    this.currentTaskName = taskName;
    setTimeout(()=> {this.confirmCompleteTask();}, 1);
  }

  loadReport(cb?) {
    this.loading = true;
    this.reports.getRxSignups().subscribe((report) => {
      this.loading = false;
      this.report = report;
      if (cb) {cb();}
    }, (err) => {
      this.loading = false;
      let n = new Notify();
      n.error(`Can't load report - server error`);
      console.error(err);
      this.update.emit();
    });
  }

  cancelCompleteTaskDialog() {
    this.h.hideModal(this.completeTaskModal);
    this.h.hideModal(this.completeTaskCCFModal);
    setTimeout(()=> {
      this.current = null;
      this.currentTaskName = '';
    }, 1);
  }
}
