import {Component} from "@angular/core";
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {Locations} from "../../services/Locations";
import {IFileHeaders} from "../../interfaces/IFile";

@Component({
  selector: 'gpo-file-upload',
  templateUrl: 'gpo_file_upload.html',
})
export class GpoFileUploadCmp {
  public newFileData:File;
  public newFileName:string;
  public uploadingFile:boolean = false;
  public parsingHeaders:boolean = false;
  public afterParsingHeaders:boolean = false;
  public fileHeadersList:{index: number, columns_str: string}[];
  public headersIndex:number = 0;
  public columnZimmer = 0;
  public columnZimmerCo = 1;
  public columnHin = 3;
  public columnMedsurg = 5;
  public columnDea = 24;
  public columnPc = 30;
  public columnsList;
  public columnsIndexesList;
  public columnsMap;
  private fileHeaders:{[row:number]:{[column:number]:string}};

  constructor(private session:Session, private locations:Locations) {
    this.session.redirectIfNotAuth();
  }

  addFile(files) {
    if (!files || !files[0]) {
      return;
    }
    this.newFileName = files[0].name;
    this.newFileData = files[0];
    this.afterParsingHeaders = false;
    this.preloadHeaders();
  }

  preloadHeaders() {
    if (!this.newFileData) {
      return false;
    }
    this.parsingHeaders = true;
    this.afterParsingHeaders = false;
    this.columnsList = null;
    this.columnsIndexesList = null;
    this.columnsMap = null;
    this.locations.parseFileHeadersXLS(this.newFileData).subscribe((r:any)=> {
      this.parsingHeaders = false;
      this.afterParsingHeaders = true;
      if (r && r.target && r.target.response) {
        try {
          let resp:IFileHeaders = JSON.parse(r.target.response);
          if (resp && resp.first_lines) {
            this.fileHeaders = resp.first_lines;
            this.fileHeadersList = [];
            for (let hi in this.fileHeaders) {
              this.fileHeadersList.push({index: parseInt(hi), columns_str: Object.values(this.fileHeaders[hi]).join("; ")});
            }
            this.selectHeadersIndex(resp.headers_index);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, (err)=> {
      this.parsingHeaders = false;
      console.error(err);
    });
  }

  selectHeadersIndex(i:number) {
    this.headersIndex = i;
    this.columnsMap = this.fileHeaders[this.headersIndex];
    this.columnsList = Object.values(this.fileHeaders[this.headersIndex]);
    this.columnsIndexesList = Object.keys(this.fileHeaders[this.headersIndex]);
  }

  resetFileUpload() {
    this.newFileData = null;
    this.newFileName = null;
    this.columnsList = null;
    this.columnsIndexesList = null;
    this.columnsMap = null;
    this.headersIndex = null;
    this.fileHeaders = null;
    this.fileHeadersList = null;
    this.afterParsingHeaders = false;
  }

  uploadFile() {
    if (!this.newFileData || !this.newFileName) {
      return;
    }
    this.uploadingFile = true;
    this.locations.uploadGpoFile(this.newFileName, this.newFileData, this.headersIndex, this.columnZimmer, this.columnZimmerCo, this.columnHin, this.columnMedsurg, this.columnDea, this.columnPc).subscribe(()=> {
      this.uploadingFile = false;
      let n = new Notify();
      n.success('Successfully!');
      setTimeout(()=> {
        this.resetFileUpload();
      }, 1500);
    }, (err)=> {
      this.uploadingFile = false;
      let n = new Notify();
      n.error('Error');
      console.error(err);
    });
  }
}
