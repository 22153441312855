import {Component} from '@angular/core';
import {Notify} from "../../services/Notify";
import {Implementations} from "../../services/Implementations";
import {Session} from "../../services/Session";

@Component({
  selector: 'mark-staples-sent',
  templateUrl: './mark-staples-sent.html',
})
export class MarkStaplesSentCmp {
  public zimmer_ids:string;
  public sending = false;
  public notFoundList:string;
  public sent = false;

  constructor(public implSrv:Implementations, private session: Session) {
    this.session.redirectIfNotAuth();
  }

  send() {
    let n = new Notify();
    if (!this.zimmer_ids) {
      n.warning('Please input IDs');
      return false;
    }
    this.sending = true;
    this.implSrv.setStaplesSentByZimmerIDs(this.zimmer_ids.split("\n")).subscribe((not_found)=> {
      if (not_found && not_found.length > 0) {
        this.notFoundList = not_found.join(', ');
      }
      this.sending = false;
      this.sent = true;
      n.success('Successfully!');
    }, (err)=> {
      this.sending = false;
      this.sent = false;
      console.error(err);
      n.error('Error');
    });
  }
}
