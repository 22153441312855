import {Component, ViewChild, ElementRef, Output, EventEmitter} from "@angular/core";
import {Session} from "../../services/Session";
import {ILocation} from "../../interfaces/ILocation";
import {IUser} from "../../interfaces/IUser";
import {Locations} from "../../services/Locations";
import {Config} from "../../services/Config";
import {Notify} from "../../services/Notify";
import {CostStudy} from "../../services/CostStudy";
import {ICSUploadReq} from "../../interfaces/ICostStudy";
import {IFileHeaders} from "../../interfaces/IFile";
import {Distributors} from "../../services/Distributors";
import {IDistributor} from "../../interfaces/IDistributor";
import {ICSUploadMeta} from "../../interfaces/ICostStudy";
import {UrlParser} from "../../services/UrlParser";
import {Helpers} from "../../services/Helpers";
import {User} from "../../services/User";

@Component({
  templateUrl: "cs.html",
  selector: "cs-tool"
})
export class CsToolCmp {
  @ViewChild('metaInfoModal') metaInfoModal: ElementRef;
  @Output() changeMetaRep = new EventEmitter();
  @Output() changeMetaLoc = new EventEmitter();
  public loc: ILocation;
  public rep: IUser;
  public newFileData: File;
  public newFileName: string;
  public uploadingFile: boolean = false;
  public parsingHeaders: boolean = false;
  public afterParsingHeaders: boolean = false;
  public fileHeadersList: { index: number, columns_str: string }[];
  public headersIndex: number = 0;
  public columnItem = 0;
  public columnManufacturer = 1;
  public columnUom = null;
  public columnQuantity = null;
  public columnUsageQuantity = null;
  public columnPrice = null;
  public columnDescription = null;
  public columnDistributorItem = null;
  public csUploadID;
  public parsing = false;
  public parsed = false;
  public uploadedFileID;
  public columnsList;
  public columnsIndexesList;
  public columnsMap;
  public ticketNumber;
  public dateReceived;
  public distributorId = 0;
  public cotId = 0;
  public typeId = 1;
  public notes;
  public isTracking = false;
  private fileHeaders: { [row: number]: { [column: number]: string } };
  private fileType: number;
  public distributorsMap = {};
  public savingMeta = false;
  public repID = 0;
  public loading = false;
  public isRepHasManager = true;
  public checkingRep = false;
  private metaInfoModalTriggered = false;

  constructor(private csSrv: CostStudy, private session: Session, private locations: Locations, private config: Config,
    private distributors: Distributors, private urlParser: UrlParser, private h: Helpers, private userSrv: User) {
    this.fileType = this.config.fileTypeUsage;
    this.session.addOnLoadHandler(() => {this.init();});
    this.session.redirectIfNotAuth();
    this.distributors.loadToMap(this.distributorsMap);
  }

  init() {
    this.parseUrlParams();
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.id) {
      let id = parseInt(urlParams.id);
      if (id > 0) {
        this.loadCsuInfo(id);
      }
    }
  }

  loadCsuInfo(id) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.csSrv.getCsUploadInfo(id).subscribe((i) => {
      this.loading = false;
      if (i) {
        this.csUploadID = id;
        try {
          this.ticketNumber = i.ticket_number;
          this.repID = i.rep_id;
          this.columnItem = i.column_item;
          this.columnManufacturer = i.column_manufacturer;
          this.columnUom = i.column_uom;
          this.columnQuantity = i.column_quantity;
          this.columnUsageQuantity = i.column_usage_quantity;
          this.columnPrice = i.column_price;
          this.columnDescription = i.column_description;
          this.columnDistributorItem = i.column_distributor_item;
          this.headersIndex = i.headers_index;
          this.distributorId = i.distributor_id;
          this.cotId = i.class_of_trade_id;
          this.typeId = i.type_id;
          this.notes = i.notes;
          this.isTracking = i.is_tracking;
          this.dateReceived = i.received_date;
          if (i.facility_id) {
            this.locations.getLocation(i.facility_id).then((loc) => {
              this.loc = loc;
            }, () => {});
          }
        } catch (e) {}
      }
    }, (err) => {
      this.loading = false;
      let n = new Notify();
      n.httpErr(err);
    });
  }

  setLocation(loc) {
    this.loc = loc;
    this.changeMetaLoc.emit(this.loc);
  }

  addLoc() {
    this.loc = <ILocation>{};
  }

  removeLoc() {
    if (this.loc && !this.loc.id) {
      this.loc = null;
      this.changeMetaLoc.emit(this.loc);
    }
  }

  setRep(user) {
    if (user) {
      this.rep = user;
      this.changeMetaRep.emit(this.rep);
      if (!user.cm_user_id) {
        this.isRepHasManager = false;
      } else {
        this.isRepHasManager = true;
      }
    }
  }

  addRep() {
    this.rep = <IUser>{type_id: this.config.userTypeRep};
  }

  removeRep() {
    if (this.rep && !this.rep.id) {
      this.rep = null;
      this.changeMetaRep.emit(this.rep);
    }
  }

  addFile(files) {
    if (!files || !files[0]) {
      return;
    }
    this.newFileName = files[0].name;
    this.newFileData = files[0];
    this.uploadedFileID = null;
    this.afterParsingHeaders = false;
    this.preloadHeaders();
  }

  preloadHeaders() {
    if (!this.newFileData) {
      return false;
    }
    this.parsingHeaders = true;
    this.afterParsingHeaders = false;
    this.columnsList = null;
    this.columnsIndexesList = null;
    this.columnsMap = null;
    this.locations.parseFileHeadersXLS(this.newFileData).subscribe((r: any) => {
      this.parsingHeaders = false;
      this.afterParsingHeaders = true;
      if (r && r.target && r.target.response) {
        try {
          let resp: IFileHeaders = JSON.parse(r.target.response);
          if (resp && resp.first_lines) {
            this.fileHeaders = resp.first_lines;
            this.fileHeadersList = [];
            for (let hi in this.fileHeaders) {
              this.fileHeadersList.push({index: parseInt(hi), columns_str: Object.values(this.fileHeaders[hi]).join("; ")});
            }
            this.selectHeadersIndex(resp.headers_index);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, (err) => {
      this.parsingHeaders = false;
      console.error(err);
    });
  }

  selectHeadersIndex(i: number) {
    this.headersIndex = i;
    this.columnsMap = this.fileHeaders[this.headersIndex];
    this.columnsList = Object.values(this.fileHeaders[this.headersIndex]);
    this.columnsIndexesList = Object.keys(this.fileHeaders[this.headersIndex]);
  }

  uploadFile() {
    if (!this.loc || !this.loc.id || !this.newFileName || !this.newFileData) {
      return false;
    }
    this.uploadingFile = true;
    this.locations.uploadFile(this.loc.id, this.newFileName, this.newFileData, this.fileType).subscribe((r: any) => {
      this.uploadingFile = false;
      if (r && r.target && r.target.response) {
        try {
          let resp = JSON.parse(r.target.response);
          if (resp && resp.id) {
            this.uploadedFileID = resp.id;
          }
        } catch (e) {
          console.error(e);
        }
      }
      let n = new Notify();
      n.success('File uploaded');
    }, (err) => {
      this.uploadingFile = false;
      let n = new Notify();
      n.error('File Uploading Error', JSON.stringify(err));
      console.error(err);
    });
  }

  resetFileUpload() {
    this.newFileData = null;
    this.newFileName = null;
    this.columnsList = null;
    this.columnsIndexesList = null;
    this.columnsMap = null;
    this.uploadedFileID = null;
    this.headersIndex = null;
    this.fileHeaders = null;
    this.fileHeadersList = null;
    this.afterParsingHeaders = false;
  }

  parseUploadedFile() {
    if (!this.uploadedFileID || this.parsing) {
      return;
    }
    this.parsing = true;
    let req: ICSUploadReq = {
      file_id: this.uploadedFileID,
      rep_user_id: this.rep.id,
      item_ci: this.getColumnIndexFilteredVal(this.columnItem),
      manufacturer_ci: this.getColumnIndexFilteredVal(this.columnManufacturer),
      uom_ci: this.getColumnIndexFilteredVal(this.columnUom),
      qnt_ci: this.getColumnIndexFilteredVal(this.columnQuantity),
      usage_qnt_ci: this.getColumnIndexFilteredVal(this.columnUsageQuantity),
      price_ci: this.getColumnIndexFilteredVal(this.columnPrice),
      description_ci: this.getColumnIndexFilteredVal(this.columnDescription),
      distributor_item_ci: this.getColumnIndexFilteredVal(this.columnDistributorItem),
      headers_index: this.headersIndex,
      location_id: this.loc.id,
    };
    if (this.csUploadID) {
      req.id = this.csUploadID;
    }
    this.parsed = false;
    this.csSrv.parseFile(req).subscribe((id) => {
      if (id) {
        this.csUploadID = id;
        this.urlParser.setParameter('id', this.csUploadID);
      }
      this.parsing = false;
      this.parsed = true;
    }, () => {
      this.parsing = false;
    });
  }

  repsListTextHandler(user: IUser, context) {
    if (!user.fname && !user.lname) {
      return;
    }
    if (!context.distributorsMap) {
      return;
    }
    let d = context.distributorsMap[user.distributor_id];
    if (d) {
      return user.fname + ' ' + user.lname + ' (' + d.name + ')';
    } else {
      return;
    }
  }

  openMetaInfoModal() {
    if (this.metaInfoModalTriggered) {
      this.h.hideModal(this.metaInfoModal);
    }
    this.metaInfoModalTriggered = true;
    this.h.showModal(this.metaInfoModal);
  }

  closeMetaInfoModal() {
    this.h.hideModal(this.metaInfoModal);
  }

  updateMetaInfo(meta: ICSUploadMeta) {
    if (meta && !this.csUploadID && meta.id) {
      this.csUploadID = meta.id;
      this.urlParser.setParameter('id', this.csUploadID);
    }
    if (meta && meta.rep_user_id) {
      this.rep = null;
      this.repID = meta.rep_user_id;
    }
    if (meta && meta.location_id) {
      this.loc = null;
      this.locations.getLocation(meta.location_id).then((loc) => {
        this.loc = loc;
      }, () => {});
    }
    this.closeMetaInfoModal();
  }

  getColumnIndexFilteredVal($val): number {
    if ($val === null || $val == 'None') {
      return null;
    }
    return Math.max(0, parseInt($val));
  }

  checkRep() {
    if (this.rep && this.rep.id) {
      this.checkingRep = true;
      this.userSrv.getUserInfo(this.rep.id, true).then((info) => {
        this.checkingRep = false;
        if (info) {
          this.rep = info;
          if (!info.cm_user_id) {
            this.isRepHasManager = false;
          } else {
            this.isRepHasManager = true;
          }
        }
      }, () => {
        this.checkingRep = false;
      });
    }
  }
}
