import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {CostStudy} from "../../services/CostStudy";
import {IXrefItemReq,IXrefItemCsv, IXrefVendors, IFeedVendor} from "../../interfaces/ICostStudy";
import {Helpers} from "../../services/Helpers";
declare var $:any;

@Component({
  templateUrl: 'add_xref.html'
})
export class CsAddXrefCmp {
  @ViewChild('fromVendor') fromVendor:ElementRef;
  @ViewChild('toVendor') toVendor:ElementRef;
  @ViewChild('toItem') toItem:ElementRef;
  public csvLines:IXrefItemCsv[];
  public importCsvCounter = 0;
  public loading_vendors = false;
  public is_pharma = false;
  public showSolo = false;
  public solo:IXrefItemReq = <IXrefItemReq>{is_pharma: false, from_item: null};
  public highlightResetSolo = false;
  public vendors:IXrefVendors;
  private vendors_to:IFeedVendor[];

  constructor(private session:Session, private cstSrv:CostStudy, private helpers:Helpers) {
    this.session.addOnLoadHandler(()=> {
      this.loading_vendors = true;
      this.cstSrv.getXrefVendors().subscribe((v)=> {
        this.loading_vendors = false;
        this.vendors = v;
        this.loadTypeaheadFrom();
        this.loadTypeaheadTo();
      }, ()=> {
        this.loading_vendors = false;
      });
    });
    this.session.redirectIfNotAuth();
  }

  addFile(files:File[]) {
    if (!files || !files[0]) {
      return;
    }
    this.parseCsv(files[0], (csvData)=> {
      let lines = this.helpers.csvToArray(csvData);
      if (lines) {
        this.csvLines = [];
        lines.forEach((line)=> {
          this.csvLines.push({
            from_vendor: line[0],
            from_item: line[1],
            to_vendor: line[2],
            to_item: line[3],
          });
        });
      }
    });
  }

  parseCsv(csv:File, cb) {
    const reader = new FileReader();
    reader.onload = ($event)=> {
      if ($event.target && $event.target['result']) {
        cb($event.target['result']);
      }
    };
    reader.readAsText(csv);
  }

  importCsv() {
    if (!this.csvLines || !this.csvLines.length || this.importCsvCounter > 0) {
      return;
    }
    this.importCsvCounter = this.csvLines.length;
    this.csvLines.forEach((item:IXrefItemCsv)=> {
      if (item.__import_success) {
        this.importCsvCounter--;
        return;
      }
      item.__import_success = null;
      item.__import_failure = null;
      item.__import_failure_text = null;
      item.__importing = true;
      this.cstSrv.addXrefItem({from_vendor: item.from_vendor, from_item: item.from_item, to_vendor: item.to_vendor, to_item: item.to_item, is_pharma: this.is_pharma}).subscribe(()=> {
        this.importCsvCounter--;
        item.__importing = false;
        item.__import_success = true;
      }, (err)=> {
        this.importCsvCounter--;
        item.__importing = false;
        item.__import_failure = true;
        if (err && err._body) {
          item.__import_failure_text = err._body;
        }
      });
    });
  }

  resetSolo() {
    this.solo = <IXrefItemReq>{is_pharma: false, from_item: null};
    $(this.fromVendor.nativeElement).typeahead('val', null);
    $(this.toVendor.nativeElement).typeahead('val', null);
    $(this.toItem.nativeElement).typeahead('val', null);
    this.loadTypeaheadTo();
  }

  toggleSoloTargetTable() {
    this.solo.is_pharma = !this.solo.is_pharma;
    this.solo.to_vendor = null;
    this.solo.to_item = null;
    $(this.toVendor.nativeElement).typeahead('val', null);
    $(this.toItem.nativeElement).typeahead('val', null);
    this.loadTypeaheadTo();
  }

  loadTypeaheadFrom() {
    setTimeout(()=> {
      if (this.vendors && this.vendors.vendors_from && this.vendors.vendors_from.length) {
        try {
          let substringMatcher = function (strs) {
            return function findMatches(q, cb) {
              let substringRegex;
              let matches = [];
              let substrRegex = new RegExp(q, 'i');
              $.each(strs, function (i, str) {
                if (substrRegex.test(str)) {
                  matches.push(str);
                }
              });
              cb(matches);
            };
          };
          $(this.fromVendor.nativeElement).typeahead('destroy');
          $(this.fromVendor.nativeElement).typeahead({
            highlight: true,
            classNames: {
              menu: 'dropdown-menu',
            }
          }, {name: 'vendorsFrom', source: substringMatcher(this.vendors.vendors_from)});
        } catch (e) {
          console.error(e);
        }
      }
    }, 1);
  }

  loadTypeaheadTo() {
    setTimeout(()=> {
      if (!this.vendors) {
        return;
      }
      let vendors_to:string[] = [];
      if (this.solo.is_pharma) {
        this.vendors_to = this.vendors.vendors_to_pharma;
      } else {
        this.vendors_to = this.vendors.vendors_to;
      }
      if (this.vendors_to && this.vendors_to.length > 0) {
        this.vendors_to.forEach((v:IFeedVendor)=> {vendors_to.push(v.vendor_name);});
        try {
          let substringMatcher = function (strs) {
            return function findMatches(q, cb) {
              let substringRegex;
              let matches = [];
              let substrRegex = new RegExp(q, 'i');
              $.each(strs, function (i, str) {
                if (substrRegex.test(str)) {
                  matches.push(str);
                }
              });
              cb(matches);
            };
          };
          $(this.toVendor.nativeElement).typeahead('destroy');
          $(this.toVendor.nativeElement).typeahead({
            highlight: true,
            classNames: {
              menu: 'dropdown-menu',
            }
          }, {name: 'vendorsTo', source: substringMatcher(vendors_to)});
          $(this.toVendor.nativeElement).bind('typeahead:close', ()=> {
            this.soloToVendorChanged();
          });
        } catch (e) {
          console.error(e);
        }
      }
    }, 1);
  }

  loadTypeaheadItemTo() {
    setTimeout(()=> {
      if (!this.vendors_to || !this.vendors_to.length || !this.solo.to_vendor) {
        return;
      }
      let items_to:string[] = [];
      for (let v of this.vendors_to) {
        if (v.vendor_name == this.solo.to_vendor) {
          items_to = v.items.split(',');
          break;
        }
      }
      if (items_to.length > 0) {
        try {
          let substringMatcher = function (strs) {
            return function findMatches(q, cb) {
              let substringRegex;
              let matches = [];
              let substrRegex = new RegExp(q, 'i');
              $.each(strs, function (i, str) {
                if (substrRegex.test(str)) {
                  matches.push(str);
                }
              });
              cb(matches);
            };
          };
          $(this.toItem.nativeElement).typeahead('destroy');
          $(this.toItem.nativeElement).typeahead({
            highlight: true,
            classNames: {
              menu: 'dropdown-menu',
            }
          }, {name: 'itemsTo', source: substringMatcher(items_to)});
        } catch (e) {
          console.error(e);
        }
      }
    }, 1);
  }

  importSolo() {
    if (this.solo.__importing) {
      return;
    }
    if (this.solo.__import_success) {
      this.highlightResetSolo = true;
      setTimeout(()=> {
        this.highlightResetSolo = false;
      }, 340);
      return;
    }
    this.solo.from_vendor = $(this.fromVendor.nativeElement).typeahead('val');
    this.solo.to_vendor = $(this.toVendor.nativeElement).typeahead('val');
    this.solo.to_item = $(this.toItem.nativeElement).typeahead('val');

    if (!this.solo.from_vendor || !this.solo.to_vendor || !this.solo.to_item) {
      return;
    }

    this.solo.__importing = true;
    this.solo.__import_failure = null;
    this.solo.__import_failure_text = null;
    this.cstSrv.addXrefItem(this.solo).subscribe(()=> {
      this.solo.__importing = false;
      this.solo.__import_success = true;
    }, (err)=> {
      this.solo.__importing = false;
      this.solo.__import_failure = true;
      if (err && err._body) {
        this.solo.__import_failure_text = err._body;
      }
    });
  }

  soloToVendorChanged() {
    let new_val = $(this.toVendor.nativeElement).typeahead('val');
    if (this.solo.to_vendor != new_val) {
      this.solo.to_item = null;
      $(this.toItem.nativeElement).typeahead('val', null);
      this.solo.to_vendor = new_val;
      this.loadTypeaheadItemTo();
    }
  }
}
