import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {ITicket} from "../../interfaces/ITicket";
import {Tickets} from "../../services/Tickets";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
declare var $:any;

@Component({
  selector: 'location-impl-notes',
  templateUrl: './notes.html',
})
export class LocationImplNotesCmp {
  @Input() locationID;
  @ViewChild('addNoteModal') addNoteModal:ElementRef;
  @ViewChild('showNoteModal') showNoteModal:ElementRef;
  public managerID;
  public newNote:ITicket = <ITicket>{};
  public creatingNote = false;
  public loading = false;
  public tickets:ITicket[] = [];
  public selectedNote:ITicket;

  constructor(public ticketService:Tickets, public session:Session) {
    if (this.session.user && this.session.user.id){
      this.managerID = this.session.user.id;
    } else {
      this.session.addOnLoadHandler(()=>{
        if (this.session.user && this.session.user.id){
          this.managerID = this.session.user.id;
        }
      });
      this.session.redirectIfNotAuth();
    }
  }

  addNote() {
    let note = new ITicket();
    note.type_id = 4;
    note.location_id = this.locationID;
    note.cm_user_id = this.managerID;
    note.requestor_user_id = this.managerID;
    this.newNote = note;
    $(this.addNoteModal.nativeElement).modal('show');
  }

  createNote() {
    this.creatingNote = true;
    this.ticketService.save(this.newNote)
      .subscribe((id)=> {
        this.load();
        $(this.addNoteModal.nativeElement).modal('hide');
        this.creatingNote = false;
        let notify = new Notify();
        notify.success('Added');
      }, (err)=> {
        console.error(err);
        this.creatingNote = false;
        let notify = new Notify();
        notify.error('Error');
      });
  }

  load() {
    if (!this.locationID) {
      return;
    }
    this.loading = true;
    this.ticketService.loadImplNotes(this.locationID).subscribe((tickets) => {
      this.tickets = tickets;
      this.loading = false;
    }, ()=> {
      this.loading = false;
    });
  }

  ngOnChanges() {
    this.load();
  }

  showNote(note) {
    this.selectedNote = note;
    $(this.showNoteModal.nativeElement).modal('show');
  }

  deleteNote(note, $event) {
    this.ticketService.remove(note.id).subscribe(()=> {
      let notify = new Notify();
      notify.success('Removed');
      this.load();
    }, ()=> {
      let notify = new Notify();
      notify.error('Error');
    });
    $event.stopPropagation();
  }
}
