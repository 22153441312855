import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {CostStudy} from "../../services/CostStudy";
declare var $:any;

@Component({
  selector: 'cs-contracts-update',
  templateUrl: 'csupdate.html'
})
export class CSContractsUpdateCmp {
  public n = new Notify();
  public info;
  public contract;
  public loadingInfo = false;
  public adding = false;
  public updating = false;
  public removing = false;

  constructor(private session:Session, private csSrv:CostStudy) {
    this.session.redirectIfNotAuth();
  }

  loadContractInfo(contract) {
    this.contract = contract;
    this.info = null;
    this.loadingInfo = true;
    this.csSrv.getInfo(contract.contract_number).subscribe((i)=> {
      this.loadingInfo = false;
      this.info = i;
    }, (err)=> {
      this.loadingInfo = false;
      this.n.error(JSON.stringify(err));
    });
  }

  errNotification(err) {
    if (err && err.status && err.status == 504) {
      this.n.info('Will be completed', 'Request is too long for browser to wait, but it\'s being executed right now, please don\'t send same request again');
    } else {
      this.n.error(JSON.stringify(err));
    }
  }

  add() {
    if (this.contract && this.contract.contract_number) {
      this.adding = true;
      this.csSrv.add(this.contract.contract_number).subscribe((r)=> {
        this.n.success('Added ' + r);
        this.adding = false;
      }, (err)=> {
        this.adding = false;
        this.errNotification(err);
      });
    }
  }

  update() {
    if (this.contract && this.contract.contract_number) {
      this.updating = true;
      this.csSrv.update(this.contract.contract_number).subscribe((r)=> {
        this.n.success('Updated ' + r);
        this.updating = false;
      }, (err)=> {
        this.updating = false;
        this.errNotification(err);
      });
    }
  }

  remove() {
    if (this.contract && this.contract.contract_number) {
      this.removing = true;
      this.csSrv.remove(this.contract.contract_number).subscribe((r)=> {
        this.n.success('Removed ' + r);
        this.removing = false;
      }, (err)=> {
        this.removing = false;
        this.errNotification(err);
      });
    }
  }
}
