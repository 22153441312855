import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from "./Config";
import {Observable} from "rxjs";

@Injectable()
export class Formatters {
  private monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  private daysNames = [
    "Sunday", "Monday", "Tuesday", "Wednesday",
    "Thursday", "Friday", "Saturday"
  ];

  constructor(private config: Config, private http: HttpClient) {}

  getFormattedState(state): Observable<string> {
    return this.http.get(this.config.apiUrl + '/format/state/' + state, {responseType: 'text'});
  }

  getFormattedZip(zip): Observable<string> {
    return this.http.get(this.config.apiUrl + '/format/zip/' + zip, {responseType: 'text'});
  }

  getFormattedDate(dt: Date, format) {
    if (dt.toString() === 'Invalid Date') {
      console.error('Invalid Date');
      return format;
    }
    try {
      format = format.replace('[yyyy]', dt.getFullYear());
      format = format.replace('[YYYY]', dt.getFullYear());
      format = format.replace('[yy]', (dt.getFullYear() + "").substring(2));
      format = format.replace('[YY]', (dt.getFullYear() + "").substring(2));
      format = format.replace('[MMMM]', this.monthNames[dt.getMonth()]);
      format = format.replace('[MMM]', this.monthNames[dt.getMonth()].substring(0, 3));
      format = format.replace('[MM]', this.pad(dt.getMonth() + 1, 2));
      format = format.replace('[M]', dt.getMonth() + 1);
      format = format.replace('[dd]', this.pad(dt.getDate(), 2));
      format = format.replace('[d]', dt.getDate());
      format = format.replace('[DD]', this.daysNames[dt.getDay()]);
      format = format.replace('[D]', this.daysNames[dt.getDay()].substring(0, 3));
      format = format.replace('[HH]', this.pad(dt.getHours(), 2));
      format = format.replace('[H]', dt.getHours());
      format = format.replace('[mm]', this.pad(dt.getMinutes(), 2));
      format = format.replace('[m]', dt.getMinutes());
      format = format.replace('[ss]', this.pad(dt.getSeconds(), 2));
      format = format.replace('[s]', dt.getSeconds());
      return format;
    } catch (e) {
      console.error('dt:', dt, 'format:', format, 'error:', e);
      return format;
    }
  }

  pad(n, width: number, z?: string): string {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
}
