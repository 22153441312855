import {Component} from "@angular/core";
import {Session} from "../../services/Session";
import {ILocation} from "../../interfaces/ILocation";
import {IUser} from "../../interfaces/IUser";
import {Locations} from "../../services/Locations";
import {Config} from "../../services/Config";
import {Notify} from "../../services/Notify";
import {CostStudy} from "../../services/CostStudy";
import {ICSUploadReq, ICSPharmaUploadReq, ICSPharmaUploadStatus} from "../../interfaces/ICostStudy";
import {IFileHeaders} from "../../interfaces/IFile";

@Component({
  templateUrl: "cs_pharma.html",
  selector: "cs-pharma"
})
export class CsPharmaCmp {
  public newFileData:File;
  public newFileName:string;
  public uploadingFile:boolean = false;
  public parsingHeaders:boolean = false;
  public afterParsingHeaders:boolean = false;
  public afterParsingFile:boolean = false;
  public fileHeadersList:{index: number, columns_str: string}[];
  public headersIndex:number = 0;
  public req:ICSPharmaUploadReq = {
    file_id: 0, headers_index: 0,
    ndc_mfr_catalog_number: 0,
    ahfs_classification: 1,
    fdb_smart_key: 2,
    gcn: 3,
    description: 4,
    manufacturer: 5,
    contract_number: 6,
    generic_name: 7,
    trade_name: 8,
    strength: 9,
    route: 10,
    dose_form: 11,
    package_size: 12,
    unit_dose: 13,
    price_tier: 14,
    item_contract_status: 15,
    ambulatory_care: 16,
    amb_optimal: 17,
    uom: 18,
    uom_quantity: 19,
    rebate: 20,
    loc: 21,
    fet: 22,
    price_effective_date: 23,
    price_expire_date: 24,
    price_status: 25,
    contract_effective_date: 26,
    contract_expire_date: 27,
  };
  public parsing = false;
  public uploadedFileID;
  public columnsList;
  public columnsMap;
  public info:ICSPharmaUploadStatus;
  private fileHeaders:{[row:number]:{[column:number]:string}};
  private fileType:number;

  constructor(private csSrv:CostStudy, private session:Session, private locations:Locations, private config:Config) {
    this.fileType = this.config.fileTypeCSPharmaID;
    this.session.redirectIfNotAuth();
  }

  addFile(files) {
    if (!files || !files[0]) {
      return;
    }
    this.newFileName = files[0].name;
    this.newFileData = files[0];
    this.uploadedFileID = null;
    this.afterParsingHeaders = false;
    this.info = null;
    this.req = {
      file_id: 0, headers_index: 0,
      ndc_mfr_catalog_number: 0,
      ahfs_classification: 1,
      fdb_smart_key: 2,
      gcn: 3,
      description: 4,
      manufacturer: 5,
      contract_number: 6,
      generic_name: 7,
      trade_name: 8,
      strength: 9,
      route: 10,
      dose_form: 11,
      package_size: 12,
      unit_dose: 13,
      price_tier: 14,
      item_contract_status: 15,
      ambulatory_care: 16,
      amb_optimal: 17,
      uom: 18,
      uom_quantity: 19,
      rebate: 20,
      loc: 21,
      fet: 22,
      price_effective_date: 23,
      price_expire_date: 24,
      price_status: 25,
      contract_effective_date: 26,
      contract_expire_date: 27,
    };
    this.preloadHeaders();
  }

  preloadHeaders() {
    if (!this.newFileData) {
      return false;
    }
    this.parsingHeaders = true;
    this.afterParsingHeaders = false;
    this.columnsList = null;
    this.columnsMap = null;
    this.locations.parseFileHeadersCSV(this.newFileData).subscribe((r:any)=> {
      this.parsingHeaders = false;
      this.afterParsingHeaders = true;
      if (r && r.target && r.target.response) {
        try {
          let resp:IFileHeaders = JSON.parse(r.target.response);
          if (resp && resp.first_lines) {
            this.fileHeaders = resp.first_lines;
            this.fileHeadersList = [];
            for (let hi in this.fileHeaders) {
              this.fileHeadersList.push({index: parseInt(hi), columns_str: Object.values(this.fileHeaders[hi]).join("; ")});
            }
            this.selectHeadersIndex(resp.headers_index);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, (err)=> {
      this.parsingHeaders = false;
      console.error(err);
    });
  }

  selectHeadersIndex(i) {
    this.headersIndex = parseInt(i);
    this.columnsMap = this.fileHeaders[this.headersIndex];
    this.columnsList = Object.values(this.fileHeaders[this.headersIndex]);
  }

  uploadFile() {
    if (!this.newFileName || !this.newFileData) {
      return false;
    }
    this.info = null;
    this.uploadingFile = true;
    this.locations.uploadFile(0, this.newFileName, this.newFileData, this.fileType).subscribe((r:any)=> {
      this.uploadingFile = false;
      if (r && r.target && r.target.response) {
        try {
          let resp = JSON.parse(r.target.response);
          if (resp && resp.id) {
            this.uploadedFileID = resp.id;
          }
        } catch (e) {
          console.error(e);
        }
      }
      let n = new Notify();
      n.success('File uploaded');
    }, (err)=> {
      this.uploadingFile = false;
      let n = new Notify();
      n.error('File Uploading Error', JSON.stringify(err));
      console.error(err);
    });
  }

  resetFileUpload() {
    this.req = {
      file_id: 0, headers_index: 0,
      ndc_mfr_catalog_number: 0,
      ahfs_classification: 1,
      fdb_smart_key: 2,
      gcn: 3,
      description: 4,
      manufacturer: 5,
      contract_number: 6,
      generic_name: 7,
      trade_name: 8,
      strength: 9,
      route: 10,
      dose_form: 11,
      package_size: 12,
      unit_dose: 13,
      price_tier: 14,
      item_contract_status: 15,
      ambulatory_care: 16,
      amb_optimal: 17,
      uom: 18,
      uom_quantity: 19,
      rebate: 20,
      loc: 21,
      fet: 22,
      price_effective_date: 23,
      price_expire_date: 24,
      price_status: 25,
      contract_effective_date: 26,
      contract_expire_date: 27,
    };
    this.info = null;
    this.newFileData = null;
    this.newFileName = null;
    this.columnsList = null;
    this.columnsMap = null;
    this.uploadedFileID = null;
    this.headersIndex = null;
    this.fileHeaders = null;
    this.fileHeadersList = null;
    this.afterParsingHeaders = false;
    this.afterParsingFile = false;
  }

  parseUploadedFile() {
    if (!this.uploadedFileID) {
      return;
    }
    this.parsing = true;
    this.afterParsingFile = false;
    this.info = null;
    this.req.file_id = this.uploadedFileID;
    this.req.headers_index = this.headersIndex;
    setTimeout(()=> {this.watchInfo();}, 1000);
    this.csSrv.uploadPharmaFile(this.req).subscribe(()=> {
      this.parsing = false;
      this.afterParsingFile = true;
    }, ()=> {
      this.parsing = false;
    });
  }

  watchInfo() {
    this.csSrv.getPharmaFileUploadingStats(this.req.file_id).subscribe((info)=> {
      if (info && info.status) {
        this.info = info;
        if (info.status != 'Completed') {
          setTimeout(()=> { this.watchInfo();}, 500);
        } else {
          this.parsing = false;
          this.afterParsingFile = true;
        }
      } else {
        this.info = null;
        setTimeout(()=> { this.watchInfo();}, 1000);
      }
    }, ()=> {
      this.info = null;
    });
  }
}
