import {Component, ViewChild, ElementRef} from '@angular/core';
import {IPopupQuery} from "../../interfaces/IPopupQuery";
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";
import {ICDiffRecord} from "../../interfaces/ICDiff";

@Component({
  templateUrl: 'report.html',
  selector: 'cdiff-report'
})
export class CDiffReportCmp {
  public results:ICDiffRecord[] = [];
  public loading = false;
  public queries:IPopupQuery[] = [];
  public contract = '';
  public loadedContract = '';
  public orderTypeFiltered = [];
  public filter1 = false;
  @ViewChild('tbl') tbl:ElementRef;
  private n = new Notify();

  constructor(private session:Session, private reports:Reports) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  reload() {
    setTimeout(()=> {
      this.load();
    }, 1);
  }

  load() {
    this.results = [];
    this.loading = true;
    this.queries = [];
    this.reports.getCDiffReport(this.filter1).subscribe((report)=> {
      this.results = report.report;
      this.queries = [{query: report.query, name: 'Query'}];
      this.loading = false;
    }, (err)=> {
      this.loading = false;
      this.n.error('Error', JSON.stringify(err));
    });
  }

  exportCSV() {
    if (!this.results || this.results.length < 1) {
      return;
    }
    let h = new Helpers();
    h.exportCsv(this.tbl, 'contracts_difference_report.csv');
  }
}
