
import {startWith, map, debounceTime} from 'rxjs/operators';
import {Component, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {User} from "../../services/User";
import {Session} from "../../services/Session";
import {IUser} from "../../interfaces/IUser";
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {UrlParser} from "../../services/UrlParser";
import {Notify} from "../../services/Notify";
declare var $:any;

@Component({
  selector: 'all-users',
  templateUrl: './all-users.html',
})
export class AllUsersCmp {
  @ViewChild('disableUser') disableUserModal:ElementRef;
  public users:IUser[] = [];
  public searchInput:UntypedFormControl;
  public loading = false;
  public itemsLimit = 15;
  public page = 1;
  public searchTerm:string;
  public usersCount = 100000;
  public searchGroup = new UntypedFormGroup({
    searchInput: new UntypedFormControl()
  });
  public userToDisable:IUser;
  public disabling = false;

  constructor(public usersService:User, public session:Session, public router:Router, public urlParser:UrlParser) {
    this.session.redirectIfNotAuth();
    this.usersService.getUsersCount().subscribe((c) => {
      if (c > 0) {
        this.usersCount = c;
      }
    });
    this.searchInput = <UntypedFormControl>this.searchGroup.controls['searchInput'];
    this.searchInput.valueChanges.pipe(debounceTime(200),map((val) => {
      return val.toLowerCase();
    }),startWith('')).subscribe((val)=> {
      if (!this.searchTerm && !val) {
        return false;
      }
      this.searchTerm = val;
      this.search(this.searchTerm);
    }, ()=> {});
    let page = parseInt(urlParser.getParameter('page'));
    if (page > 1) {
      this.setPage(page, true);
    } else {
      this.reload();
    }
  }

  reload() {
    this.loading = true;
    this.users = [];
    this.usersService.getUsersList(this.searchInput.value, (this.page - 1) * this.itemsLimit, this.itemsLimit).subscribe((users)=> {
      this.users = users;
      this.loading = false;
    }, ()=> {
      this.loading = false;
    });
  }

  search(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.loading = true;
      this.users = [];
      this.usersService.getUsersList(searchTerm, (this.page - 1) * this.itemsLimit, this.itemsLimit).subscribe((users)=> {
        this.users = users;
        this.loading = false;
      }, ()=> {
        this.loading = false;
      });
    }
  }

  setItemsLimit(n) {
    let x = parseInt(n);
    if (x < 1) {
      x = 15;
    }
    this.itemsLimit = x;
    this.reload();
  }

  openLocation(id, $event) {
    if ($event && $event.stopPropagation) {
      $event.stopPropagation();
    }
    this.router.navigate(['location', id]);
    return false;
  }

  setPage($event, searchReset = false) {
    let newPage = Math.max(0, parseInt($event) || 1);
    this.urlParser.setParameter('page', newPage);
    if (this.page !== newPage || searchReset) {
      this.page = newPage;
      this.reload();
    }
  }

  disableUserLogin(user:IUser) {
    if (!user || !this.disableUserModal || !this.disableUserModal.nativeElement) {
      return;
    }
    this.userToDisable = user;
    $(this.disableUserModal.nativeElement).modal('show');
  }

  disableUserConfirm() {
    if (!this.userToDisable || !this.disableUserModal || !this.disableUserModal.nativeElement) {
      return;
    }
    this.disabling = true;
    let n = new Notify();
    this.usersService.disableLogin(this.userToDisable.id).subscribe(()=> {
      $(this.disableUserModal.nativeElement).modal('hide');
      this.disabling = false;
      this.userToDisable.is_disabled = true;
      this.userToDisable = null;
      n.success('Action successful');
    }, (err)=> {
      this.disabling = false;
      console.error(err);
      n.error(JSON.stringify(err), 'Error');
    });
  }
}
