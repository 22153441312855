import {Component, Input, Output, ViewChild, ElementRef, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {IVendorReport} from "../../../interfaces/IVendor";

@Component({
  templateUrl: './report.html',
  selector: 'vendor-report'
})
export class VendorReportCmp {
  @Input() report:IVendorReport[];
  @Input() id:number;
  @Input() parentTab:number;
  @Input() companyAtr = '';
  @ViewChild('tbl') tbl:ElementRef;
  @Output('companyAtrChanged') companyAtrChanged = new EventEmitter();
  public orderField;
  public sortDesc;
  public popupListLength = 15;
  public contractStatusFiltered = [];
  public quartersFiltered = [];
  public contractsStFiltered = [];
  public contractsSfFiltered = [];
  public sellersFiltered = [];
  public atrCompanies = [
    'Catholic Health Initiatives',
    'CHSPSC, LLC',
    'Trinity Health Corporation',
    'Lifepoint Health',
    'HCA',
    'Tenet Healthcare Corporation',
    'Independent',
  ];

  setCompanyAtr(company) {
    this.companyAtr = company;
    this.companyAtrChanged.emit(company);
  }

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  exportCsv() {
    if (!this.tbl || !this.tbl.nativeElement) {
      return;
    }
    let oldL = this.popupListLength;
    this.popupListLength = 1500;
    setTimeout(()=> {
      let csv = '';
      let delimitter = ',';
      for (let i = 0, row; row = this.tbl.nativeElement.rows[i]; i++) {
        let line = '';
        for (let j = 0, col; col = row.cells[j]; j++) {
          line = line + '"' + col.innerText.replace('drag_handle', '').replace('filter_list', '') + '"' + delimitter;
          if (col.colSpan > 0) {
            for (let c = 1; c < col.colSpan; c++) {
              line = line + delimitter;
            }
          }
        }
        csv = csv + line.replace(/,(\s+)?$/, '').replace(/;(\s+)?$/, '') + "\n";
      }
      let a = this.getCsvExportLink(csv, 'vendor_' + this.id + '_report.csv');
      a.click();
      setTimeout(()=> {
        this.popupListLength = oldL;
      }, 2);
    }, 1);
  }

  getCsvExportLink(csv, fileName):HTMLElement {
    // https://stackoverflow.com/a/23922475/680786
    let pom = document.createElement('a');
    var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', fileName);
    return pom;
  }
}
