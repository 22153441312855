import {Component, Input} from "@angular/core";
import {EditRequests} from "../../services/EditRequests";
import {IEditRequest} from "../../interfaces/IEditRequest";

@Component({
  selector: 'group-edit-requests',
  templateUrl: './log.html',
})
export class GroupEditRequestsCmp {
  @Input() groupID:number;
  public log:IEditRequest[] = [];
  public loading = false;

  constructor(private editReqSrv:EditRequests) {}

  ngOnChanges() {
    if (this.groupID) {
      this.log = [];
      this.loading = true;
      this.editReqSrv.getGroupLogRequests(this.groupID).subscribe((log)=> {
        this.loading = false;
        if (log) {
          this.log = log;
        }
      }, (err)=> {
        this.loading = false;
        console.error(err);
      });
    }
  }
}
