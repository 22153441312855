import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {CostStudy} from "../../services/CostStudy";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'cs-fill-uins',
  templateUrl: 'fill_uins.html',
})
export class CSFIllUinsCmp {
  public contract;
  public contracts;
  public sending = false;
  public listMode = false;
  private n = new Notify();

  constructor(private session:Session, private csSrv:CostStudy) {
    this.session.redirectIfNotAuth();
  }

  fillUins() {
    if ((this.listMode && this.contracts) || !this.contract) {
      return this.fillUinsList();
    }
    if (!this.contract || this.sending) {
      return;
    }
    this.sending = true;
    this.csSrv.fillUins(this.contract).subscribe((r)=> {
      this.sending = false;
      this.n.success('Updated: ' + r);
    }, (err)=> {
      this.sending = false;
      if (err && err.status && err.status == 504) {
        this.n.info('Will be updated', 'Request is too long for browser to wait, but it\'s being executed right now, please don\'t send same request again');
      } else {
        this.n.error(JSON.stringify(err));
      }
    });
  }

  fillUinsList() {
    if (!this.contracts || !this.listMode) {
      return this.fillUins();
    }
    if (this.sending) {
      return;
    }
    this.sending = true;
    this.csSrv.fillUinsList(this.contracts.split(/\n/).join(',').trim()).subscribe((r)=> {
      this.sending = false;
      this.n.success('Will be updated in background');
    }, (err)=> {
      this.sending = false;
      if (err && err.status && err.status == 504) {
        this.n.info('Will be updated', 'Request is too long for browser to wait, but it\'s being executed right now, please don\'t send same request again');
      } else {
        this.n.error(JSON.stringify(err));
      }
    });
  }
}
