import {Component} from '@angular/core';
import {Locations} from "../../services/Locations";
import {Notify} from "../../services/Notify";
import {Distributors} from "../../services/Distributors";
import {Lifecycles} from "../../services/Lifecycles";
import {IDistributor} from "../../interfaces/IDistributor";
import {Session} from "../../services/Session";

@Component({
  selector: 'create-zimmer-lifecycles',
  templateUrl: './zimmer_lifecycles.html',
})
export class CreateZimmerLifecyclesCmp {
  public zimmer_ids:string;
  public distributor_id:number = 0;
  public distributorsList:IDistributor[] = [];
  public distributorsMap = {};
  public reasonsList = [];
  public reasonsMap = {};
  public reason_id:number = 0;
  public typesList = [];
  public typesMap = {};
  public type_id:number = 0;
  public sending = false;
  public notFoundList:string;
  public loading = false;
  public sent = false;
  public datetime:string;

  constructor(public locations:Locations, public distributors:Distributors, public lifecycles:Lifecycles, private session:Session) {
    this.session.redirectIfNotAuth();
    this.loadReasons();
    this.distributors.loadToList(this.distributorsList);
    this.distributors.loadToMap(this.distributorsMap);
    this.lifecycles.loadTypesToList(this.typesList);
    this.lifecycles.loadTypesToMap(this.typesMap);
  }

  send() {
    let n = new Notify();
    if (!this.zimmer_ids) {
      n.warning('Please input IDs');
      return false;
    }
    if (!this.distributor_id) {
      n.warning('Please select distributor');
      return false;
    }
    if (!this.type_id) {
      n.warning('Please select type');
      return false;
    }
    this.notFoundList = '';
    this.sending = true;
    this.locations.addLifecycleRecordsByZimmerIds(this.zimmer_ids.split("\n"),
      this.distributor_id, this.type_id, this.reason_id, this.datetime).subscribe((not_found)=> {
      if (not_found && not_found.length > 0) {
        this.notFoundList = not_found.join(', ');
      }
      this.sending = false;
      this.sent = true;
      n.success('Successfully!');
    }, (err)=> {
      this.sending = false;
      this.sent = false;
      console.error(err);
      n.error('Error');
    });
  }

  loadReasons() {
    this.loading = true;
    this.locations.getLifecycleReasons().subscribe((reasons)=> {
      this.loading = false;
      this.reasonsList = reasons;
      this.reasonsMap = {};
      if (reasons && reasons.length) {
        reasons.forEach((r)=> {
          this.reasonsMap[r.id] = r;
        });
      }
    }, ()=> {
      this.loading = false;
    });
  }

  setDate(date:Date) {
    this.datetime = date.toISOString().substring(0, 19).replace('T', ' ');
  }
}
