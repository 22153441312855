import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {ICcResult} from "../../services/ContractConnections";
import {ICcUploadInfo} from "../../services/ContractConnections";
import {ContractConnections} from "../../services/ContractConnections";
declare var $:any;

@Component({
  selector: "cc-results",
  templateUrl: "results.html"
})
export class CcResultsCmp {
  @Input() ccUploadID;
  @ViewChild('rerunModal') rerunModal:ElementRef;
  public results:ICcResult[];
  public n = new Notify();
  public loaded = false;
  public searchStatus = '';
  public page = 0;
  public perPage = 300;
  public searchCount = 0;
  public info:ICcUploadInfo;
  public rerunning = false;
  public pageLoading = false;
  private loadingThreads = 0;

  constructor(private ccSrv:ContractConnections, private session:Session, private params:ActivatedRoute) {
    this.params.params.subscribe(params => {
      if (params && params['id']) {
        this.ccUploadID = parseInt(params['id']);
        this.initLoad();
      }
    });
    this.session.addOnLoadHandler(()=> {
      this.initLoad();
    });
    this.session.redirectIfNotAuth();
  }

  initLoad() {
    if (!this.ccUploadID) {
      return;
    }
    if (this.searchStatus == '') {
      this.searchStatus = 'In Progress';
      this.loadInfo();
      this.load();
    }
  }

  loadInfo() {
    this.ccSrv.getCcUploadInfo(this.ccUploadID).subscribe((response) => {
      this.info = response;
    }, ()=> {});
  }

  reset() {
    this.results = null;
    this.loaded = false;
    this.searchStatus = '';
    this.page = 0;
    this.searchCount = 0;
    this.rerunning = false;
    this.pageLoading = false;
  }

  load() {
    if (!this.ccUploadID) {
      return;
    }
    if (this.loadingThreads > 0) {
      return;
    }
    this.loadingThreads++;
    this.ccSrv.getCcResults(this.ccUploadID, this.page * this.perPage, this.perPage).subscribe((response) => {
        this.loadingThreads--;
        this.pageLoading = false;
        if (!this.results || (response.results.length != this.results.length) || (JSON.stringify(this.results) != JSON.stringify(response.results))) {
          this.results = response.results;
        }
        if (response.count) {
          this.searchCount = response.count;
        }
        this.searchStatus = '';
        if (response.status) {
          this.searchStatus = response.status;
        } else {
          this.searchStatus = "Completed";
        }
        if (this.searchStatus != "Completed") {
          setTimeout(()=> {
            this.load();
            this.loadInfo();
          }, 3000);
        } else {
          this.loaded = true;
        }
      }, ()=> {
        this.loadingThreads--;
        this.pageLoading = false;
      }
    );
  }

  rerun() {
    this.openRerunModal();
  }

  rerunAnsw() {
    this.closeRerunModal();
    this.rerunning = true;
    this.ccSrv.rerunCcSearch(this.ccUploadID).subscribe(() => {
      this.rerunning = false;
      this.reset();
      this.initLoad();
    }, () => {
      this.rerunning = false;
    });
  }

  nextPage() {
    if (this.searchCount > 0 && ((this.page + 1) * this.perPage) >= this.searchCount) {
      return;
    }
    this.page++;
    this.pageLoading = true;
    if (this.loaded) {
      this.load();
    }
  }

  prevPage() {
    if (this.page < 1) {
      return;
    }
    this.page--;
    this.pageLoading = true;
    if (this.loaded) {
      this.load();
    }
  }

  openRerunModal() {
    if (this.rerunModal && this.rerunModal.nativeElement) {
      $(this.rerunModal.nativeElement).modal('show');
    }
  }

  closeRerunModal() {
    if (this.rerunModal && this.rerunModal.nativeElement) {
      $(this.rerunModal.nativeElement).modal('hide');
    }
  }

  cancelRerun() {
    this.closeRerunModal();
  }
}
