import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from "./Config";
import {Observable} from "rxjs";
import {ITheme} from "../interfaces/ITheme";
import {ISubTheme} from "../interfaces/ISubTheme";

@Injectable()
export class Themes {
  constructor(private config: Config, private http: HttpClient) {

  }

  getThemes(): Observable<Array<ITheme>> {
    return this.http.get<Array<ITheme>>(this.config.apiUrl + '/theme');
  }

  getSubThemes():Observable<ISubTheme[]> {
    return this.http.get<ISubTheme[]>(this.config.apiUrl + '/sub_theme');
  }
}
