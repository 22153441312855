import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {ILocationDistributor} from "../../interfaces/ILocationDistributor";
import {Locations} from "../../services/Locations";
declare var $:any;

@Component({
  selector: '[loc-distributors-modal]',
  templateUrl: 'loc_distrs.html',
  host: {"(click)": "click($event)"},
})
export class LocationDistributorsModalCmp {
  @Input() id:number;
  @Input() distributors:ILocationDistributor[];
  @ViewChild('loc_distrs_modal') modal:ElementRef;
  public isActive = false;
  public loading = false;
  private movedToBody = false;
  private initialLoad = false;

  constructor(private locations:Locations) {

  }

  click() {
    if (!this.isActive) {
      this.isActive = true;
      setTimeout(()=> {
        if (!this.movedToBody && this.modal && this.modal.nativeElement) {
          document.body.appendChild(this.modal.nativeElement);
          this.movedToBody = true;
        }
        setTimeout(()=> {
          this.renderModal();
        }, 1);
      }, 1);
    } else {
      this.renderModal();
    }
  }

  renderModal() {
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('show');
      this.load();
    }
  }

  ngOnChanges() {
    if (this.isActive) {
      this.load();
    }
  }

  load() {
    if (this.loading || !this.id) {
      return;
    }
    if (this.distributors && this.distributors.length > 0 && !this.initialLoad) {
      this.initialLoad = true;
      return;
    }
    this.loading = true;
    this.distributors = [];
    this.locations.getDistributors(this.id, true).subscribe((location_distributors)=> {
      this.loading = false;
      if (location_distributors && location_distributors.length > 0) {
        this.distributors = location_distributors;
        this.distributors.forEach((ld)=> {
          if (ld.level == null) {
            ld.level = 0;
          }
        });
      }
    }, ()=> {
      this.loading = false;
    });
  }
}
