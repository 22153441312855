import {Component} from '@angular/core';
import {Session} from "../../services/Session";

@Component({
  templateUrl: './notifications.html',
})
export class NotificationsListCmp {
  public messages = [];
  public loading = true;

  constructor(public session: Session) {
    this.session.addOnLoadHandler(()=> {
      this.watch();
    })
    this.session.redirectIfNotAuth();
  }

  watch() {
  }
}
