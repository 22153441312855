import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CostStudy} from "../../services/CostStudy";
import {Session} from "../../services/Session";
import {ICsResult, ICsQuery, ICsResultsStats, ICsUploadInfo, ICsVendorMatchRecord} from "../../interfaces/ICostStudy";
import {Notify} from "../../services/Notify";
declare var $:any;

@Component({
  selector: "cs-found-vendors",
  templateUrl: "found_vendors.html"
})
export class CsFoundVendorsCmp {
  @Input('uploadID') uploadID:number;
  @Output('loaded') loaded = new EventEmitter();
  public existingVendorsNames:string[];
  public existingVendors:ICsVendorMatchRecord[];
  public newVendors:string[];
  public sending = false;

  constructor(private csSrv:CostStudy) {
  }

  ngOnInit() {
    if (this.uploadID) {
      this.load();
    }
  }

  load() {
    if (!this.uploadID) {
      return;
    }
    this.csSrv.getFoundVendors(this.uploadID).subscribe((response)=> {
      if (response && response.existing_vendors) {
        this.existingVendors = response.existing_vendors;
        this.existingVendorsNames = [];
        response.existing_vendors.forEach((vendor)=> {
          this.existingVendorsNames.push(vendor.vendor_name + ' (' + vendor.group_size.toString() + ')');
        });
        this.setupVendorsAutoSuggest();
      }
      if (response && response.new_vendors) {
        this.newVendors = response.new_vendors;
        this.loaded.emit(response.new_vendors.length);
      }
    }, ()=> {});
  }

  setupVendorsAutoSuggest() {
    setTimeout(()=> {
      if (this.existingVendorsNames && this.existingVendorsNames.length) {
        try {
          let substringMatcher = function (strs) {
            return function findMatches(q, cb) {
              let substringRegex;
              let matches = [];
              let substrRegex = new RegExp(q, 'i');
              $.each(strs, function (i, str) {
                if (substrRegex.test(str)) {
                  matches.push(str);
                }
              });
              cb(matches);
            };
          };
          $(".crm-fv-typeahead").typeahead({
            highlight: true,
            classNames: {
              menu: 'dropdown-menu',
            }
          }, {name: 'vendors', source: substringMatcher(this.existingVendorsNames)});
        } catch (e) {
          console.error(e);
        }
      }
    }, 1);
  }

  createGroup(v) {
    if (!v) {
      return;
    }
    this.sending = true;
    this.csSrv.addNewVendor(v, 0).subscribe(()=> {
      this.sending = false;
      let i = this.newVendors.indexOf(v);
      if (i > -1) {
        this.newVendors.splice(i, 1);
      }
    }, ()=> {
      this.sending = false;
    });
  }

  moveIntoGroup(v, vendorEl) {
    let vendorTarget = $(vendorEl).typeahead('val');
    if (!v || !vendorTarget || !this.existingVendors) {
      return;
    }
    let group_id = 0;
    this.existingVendors.forEach((vendor:ICsVendorMatchRecord)=> {
      if ((vendor.vendor_name + ' (' + vendor.group_size.toString() + ')') == vendorTarget) {
        group_id = vendor.vendor_group_id;
      }
    });
    if (!group_id) {
      return;
    }
    this.sending = true;
    this.csSrv.addNewVendor(v, group_id).subscribe(()=> {
      this.sending = false;
      let i = this.newVendors.indexOf(v);
      if (i > -1) {
        this.newVendors.splice(i, 1);
      }
    }, ()=> {
      this.sending = false;
    });
  }
}
