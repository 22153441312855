import {Component} from '@angular/core';
import {Notify} from "../../services/Notify";
import {Implementations} from "../../services/Implementations";
import {IImplementationContract} from "../../interfaces/IImplementationContract";
import {Session} from "../../services/Session";

@Component({
  selector: 'write-implementation-contracts',
  templateUrl: './write_impl_contracts.html',
})
export class WriteImplementationContractsCmp {
  public zimmer_ids:string;
  public reason_id:number = 0;
  public contract_id:number = 0;
  public step_id:number = 0;
  public sending = false;
  public notFoundList:string;
  public loading = false;
  public sent = false;
  public datetime:string = '';
  public implContracts:IImplementationContract[] = [];

  constructor(public implSrv:Implementations, private session:Session) {
    this.implContracts = [];
    this.session.addOnLoadHandler(()=> {
      this.implSrv.getContracts().subscribe((contracts) => {
        if (contracts) {
          contracts.forEach((c) => {
            if (c.contract_number && c.is_exportable && c.is_featured) {
              this.implContracts.push(c);
            }
          });
        }
      }, (response) => {
        console.error(response);
      });
    });
    this.session.redirectIfNotAuth();
  }

  send() {
    let n = new Notify();
    if (!this.zimmer_ids) {
      n.warning('Please input IDs');
      return false;
    }
    if (!this.contract_id) {
      n.warning('Please select contract');
      return false;
    }
    this.sending = true;
    this.implSrv.setContractsByZimmerIDs(this.zimmer_ids.split("\n"), this.contract_id, this.step_id, this.datetime).subscribe((not_found)=> {
      if (not_found && not_found.length > 0) {
        this.notFoundList = not_found.join(', ');
      }
      this.sending = false;
      this.sent = true;
      n.success('Successfully!');
    }, (err)=> {
      this.sending = false;
      this.sent = false;
      console.error(err);
      n.error('Error');
    });
  }

  setDate(date:Date) {
    this.datetime = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  resetForm() {
    this.sent = false;
    this.notFoundList = null;
  }
}
