import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Observable} from "rxjs";
import {IChartDataset} from "../../interfaces/IChartDataset";
declare var Chart:any;

@Component({
  templateUrl: 'chart.html',
  selector: 'crm-chart'
})
export class ChartCmp {
  @Input('sets') sets:IChartDataset[]; //required
  @Input('x') x:string[]; // required

  @ViewChild('chart') chart:ElementRef;
  @Input('type') type:string = 'line';
  @Input('stacked') stacked:boolean = false;
  @Input('draw') callDraw:Observable<any>;
  @Input('customLegend') customLegend:boolean = false;
  @Input('legendPosition') legendPosition:string = 'top';
  public columns = [];
  public datasets = [];
  private ch;
  private firstDraw = false;

  ngOnChanges() {
    if (this.callDraw) {
      try {
        this.callDraw.subscribe(() => {
          this.initDraw();
        });
      } catch (e) {}
    }
    if (!this.firstDraw && this.sets && this.sets.length > 0 && Chart && this.chart && this.chart.nativeElement) {
      this.firstDraw = true;
      this.initDraw();
    }
  }

  initDraw() {
    if (this.sets && Chart && this.chart && this.chart.nativeElement) {
      this.datasets = [];
      this.columns = [];
      this.sets.forEach((s)=> {
        let ds = {label: s.label, data: s.data, backgroundColor: s.color, borderColor: s.color, fill: false, hidden: s.hidden};
        this.datasets.push(ds);
        let ic = s.column || 0;
        if (!this.columns[ic]) {
          this.columns[ic] = [ds];
        } else {
          this.columns[ic].push(ds);
        }
      });
      if (this.customLegend && this.columns.length < 1) {
        this.customLegend = false;
      }
      this.draw();
    }
  }

  draw() {
    if (this.sets && Chart && this.chart && this.chart.nativeElement && this.datasets && this.datasets.length > 0) {
      let canvas = this.chart.nativeElement;
      let data = {datasets: this.datasets, labels: this.x};
      let numberWithCommas = function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };
      this.ch = new Chart(canvas.getContext('2d'), {
        type: this.type,
        data: data,
        options: {
          legend: {display: !this.customLegend, position: this.legendPosition},
          tooltips: {
            mode: 'label',
            callbacks: {
              label: function (tooltipItem, data) {
                let s = data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel);
                return s;
              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: this.stacked
            }],
            yAxes: [{
              stacked: this.stacked
            }]
          }
        }
      });
    }
  }

  update() {
    if (this.ch) {
      this.draw();
    } else {
      if (this.datasets && this.datasets.length > 0) {
        this.draw();
      } else {
        this.initDraw();
      }
    }
  }
}
