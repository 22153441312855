import {Component} from "@angular/core";
import {IBenefitReview} from "../../interfaces/IBenefitReview";
import {Session} from "../../services/Session";
import {Questions} from "../../services/Questions";

@Component({
  selector: 'benefit-reviews',
  templateUrl: 'reviews.html',
})
export class BenefitReviewsCmp {
  public loading = true;
  public reviews:IBenefitReview[] = [];
  public searchTerm:string;

  constructor(private session:Session, private qSrv:Questions) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.loading = true;
    this.reviews = [];
    this.qSrv.getLastReviews().subscribe((data)=> {
      this.loading = false;
      if (data && data.reviews) {
        this.reviews = data.reviews;
      }
    }, ()=> {
      this.loading = false;
    });
  }

  isVisible(r:IBenefitReview) {
    if (!this.searchTerm) {
      return true;
    } else {
      let s = this.searchTerm.toLowerCase();
      if (r.faciliy_name && r.faciliy_name.toLowerCase().indexOf(s) > -1) {
        return true;
      }
      if (r.fname && r.fname.toLowerCase().indexOf(s) > -1) {
        return true;
      }
      if (r.lname && r.lname.toLowerCase().indexOf(s) > -1) {
        return true;
      }
      let name = r.fname + ' ' + r.lname;
      if (name && name.toLowerCase().indexOf(s) > -1) {
        return true;
      }
      return false;
    }
  }
}
