import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {of as observableOf, Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {Config} from './Config';
import {Session} from "./Session";
import {ITask} from "../interfaces/ITask";
import {ITaskEmail} from "../interfaces/ITaskEmail";

export interface ICompleteRxTaskReq {
  task_q_id?: number;
  location_id?: number;
  task_name?: string;
}

@Injectable()
export class Tasks {
  private loadingObservable: Observable<any>;
  private list: ITask[] = [];

  constructor(private http:HttpClient, private config:Config, private session:Session) {}

  getLocationTasks(id:number):Observable<ITask[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITask[]>(this.config.apiUrl + `/task/location/${id}`, {headers: headers});
  }

  getLocationHiddenTasks(id:number):Observable<ITask[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITask[]>(this.config.apiUrl + `/task/location/${id}/hidden`, {headers: headers});
  }

  getTasks(refresh?):Observable<ITask[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);

    if (!refresh && this.list && this.list.length > 0) {
      return <Observable<ITask[]>>observableOf(this.list);
    }
    if (this.loadingObservable) {
      return this.loadingObservable;
    }
    this.loadingObservable = this.http.get<ITask[]>(this.config.apiUrl + '/task', {headers: headers}).pipe(share());
    this.loadingObservable.subscribe((items)=> {
      this.list = items;
    }, ()=> {});
    return this.loadingObservable;
  }

  getTaskEmails(id:number):Observable<ITaskEmail[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITaskEmail[]>(this.config.apiUrl + `/task/emails/${id}`, {headers: headers});
  }

  getTaskEmailsToUser(id:number):Observable<ITaskEmail[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITaskEmail[]>(this.config.apiUrl + `/task/emails_to_user/${id}`, {headers: headers});
  }

  deleteTask(id:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + `/task/${id}`, {headers: headers, responseType: 'text'});
  }

  updateTask(task:ITask):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/task`, JSON.stringify(task), {headers: headers, responseType: 'text'});
  }

  deleteTaskEmail(id:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + `/task/email/${id}`, {headers: headers, responseType: 'text'});
  }

  updateTaskEmail(email:ITaskEmail):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/task/email`, JSON.stringify(email), {headers: headers, responseType: 'text'});
  }

  resendTaskEmail(email:ITaskEmail):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/task/resend_email`, JSON.stringify(email), {headers: headers, responseType: 'text'});
  }

  createTask(task:ITask):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/task`, JSON.stringify(task), {headers: headers, responseType: 'text'});
  }

  createTaskEmail(taskEmail:ITaskEmail):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/task/email`, JSON.stringify(taskEmail), {headers: headers, responseType: 'text'});
  }

  completeRxTask(req:ICompleteRxTaskReq):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/rx/complete_task`, JSON.stringify(req), {headers: headers, responseType: 'text'});
  }

  getBoxOfTask(t:ITask) {
    if (!t) {
      return 'INBOX';
    }
    if (t.is_cancelled) {
      return 'CANCELED';
    }
    if (t.is_completed) {
      return 'SENT';
    }
    if (t.emails && t.emails[0] && t.emails[0].sent_date) {
      if (t.emails[0].sending_error) {
        return 'SENDING ERROR';
      } else {
        return 'SENT';
      }
    }
    if (t.emails && t.emails[0] && t.emails[0].send_on_date) {
      return 'OUTBOX';
    }
    if (t.delayed_until_date) {
      return 'POSTPONED';
    }
    return 'INBOX';
  }
}
