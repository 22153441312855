import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {CostStudy} from "../../services/CostStudy";
import {Session} from "../../services/Session";
import {OrderByPipe} from "../../services/OrderByPipe";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";
import {ICsColor, ICSUploadMeta} from "../../interfaces/ICostStudy";
import {ICsUpload} from "../../interfaces/ICostStudy";
import {UrlParser} from "../../services/UrlParser";

declare var $: any;

@Component({
  selector: "cs-uploads-list",
  templateUrl: "cs-uploads.html"
})
export class CsUploadsListCmp {
  @ViewChild('metaInfoModal') metaInfoModal: ElementRef;
  @ViewChild('tbl') tbl: ElementRef;
  public loading = false;
  public rendering = true;
  public exporting = false;
  public uploads: ICsUpload[];
  public items: ICsUpload[] = [];
  public onlyMetas = false;
  public onlyTracked = false;
  public onlyLocSuggested = false;
  public onlyMembers = false;
  public onlyLocSubmitted = false;
  public onlyLocNotSubmitted = false;
  public showAll = false;
  public metaInfoID = 0;
  public displayCtrlButtons = false;
  public page = 1;
  public itemsLimit = 40;
  private itemsLimitPre = 40;
  private n = new Notify();
  public orderField = '';
  public sortDesc;
  public loadingThreads = 0;
  public colors: ICsColor[] = [];
  public colorsMap: {} = {};
  public filterByColorCode = '';
  public findLocation = '';
  public minLocSearchTermLength = 2;
  private metaInfoModalTriggered = false;
  private orderSrv: OrderByPipe;

  constructor(private csSrv: CostStudy, private session: Session, private h: Helpers, private urlParser: UrlParser) {
    this.session.addOnLoadHandler(() => {
      this.rendered();
      this.initLoad();
    });
    this.session.redirectIfNotAuth();
    this.orderSrv = new OrderByPipe();
  }

  rendered() {
    setTimeout(() => {
      this.rendering = false;
    }, 60);
  }

  initLoad() {
    this.loading = true;
    this.parseUrlParams();
    this.load();
    this.csSrv.getCsColors().subscribe((c) => {
      if (c && c.length > 0) {
        this.colors = c;
        this.colorsMap = {};
        c.forEach((color) => {
          this.colorsMap[color.code] = color;
        });
      }
    }, () => {});
  }

  toggleShowAll() {
    if (this.showAll) {
      this.showAll = false;
      this.itemsLimit = this.itemsLimitPre;
    } else {
      this.rendering = true;
      this.showAll = true;
      this.itemsLimitPre = this.itemsLimit;
      setTimeout(() => {
        this.setPage(1);
        this.itemsLimit = 100000;
        this.rendered();
      }, 1);
    }
  }

  fillItems() {
    this.setPage(1);
    this.setUrlParams();
    if (!this.uploads || !this.uploads.length) {
      return;
    }
    this.rendering = true;
    setTimeout(() => {
      this.items = [];
      this.uploads.forEach((u) => {
        // filters
        if (this.onlyMetas) {
          if (u.file_id) {
            return;
          }
        }
        if (this.onlyTracked) {
          if (!u.is_tracking) {
            return;
          }
        }
        if (this.onlyLocSuggested) {
          if (!u.is_loc_suggested) {
            return;
          }
        }
        if (this.onlyMembers) {
          if (!u.__is_member) {
            return;
          }
        }
        if (this.onlyLocSubmitted) {
          if (!u.is_loc_submitted) {
            return;
          }
        }
        if (this.onlyLocNotSubmitted) {
          if (u.is_loc_submitted) {
            return;
          }
        }
        if (this.filterByColorCode) {
          if (u.cst_color_code !== this.filterByColorCode) {
            return;
          }
        }
        if (this.findLocation && this.findLocation.length >= this.minLocSearchTermLength) {
          let t = this.findLocation.toLowerCase();
          if (!((u.facility_name && u.facility_name.toLowerCase().indexOf(t) > -1) || (u.__dba && u.__dba.toLowerCase().indexOf(t) > -1))) {
            return;
          }
        }
        //
        this.items.push(u);
      });
      this.orderSrv.transform(this.items, this.orderField, this.sortDesc);
      this.rendered();
    }, 1);
  }

  load() {
    if (this.loadingThreads > 0) {
      return;
    }
    let myurl = window.location.href;
    this.loadingThreads++;
    this.csSrv.getCsUploads().subscribe((r) => {
      this.loading = false;
      this.loadingThreads--;
      if (r.uploads) {
        this.uploads = r.uploads;
        this.fillItems();
      }
    }, (err) => {
      this.loading = false;
      this.loadingThreads--;
      this.n.error('Error - Can\'t load list', err._body);
    });
  }

  deleteUpload(csu, $event) {
    if ($event) {
      try {
        if ($event.stopPropagation) {
          $event.stopPropagation();
        }
      } catch (e) {}
    }
    if (!csu || !csu.id || csu.__deleting) {
      return false;
    }
    csu.__deleting = true;
    this.csSrv.deleteCsUpload(csu.id).subscribe(() => {
      this.n.success('Removed');
      this.load();
    }, (err) => {
      csu.__deleting = false;
      this.n.error('Error', err._body);
    });
    return false;
  }

  copyUpload(csu, $event) {
    if ($event) {
      try {
        if ($event.stopPropagation) {
          $event.stopPropagation();
        }
      } catch (e) {}
    }
    if (!csu || !csu.id || csu.__deleting) {
      return false;
    }
    csu.__deleting = true;
    this.csSrv.copyCsUpload(csu.id).subscribe(() => {
      this.n.success('Copied');
      this.load();
    }, (err) => {
      csu.__deleting = false;
      this.n.error('Error', err._body);
    });
    return false;
  }

  openInfoModal(id) {
    if (this.metaInfoModalTriggered) {
      this.h.hideModal(this.metaInfoModal);
    }
    if (id) {
      this.metaInfoID = id;
      this.metaInfoModalTriggered = true;
      this.h.showModal(this.metaInfoModal);
    }
  }

  closeMetaInfoModal() {
    this.metaInfoID = 0;
    this.h.hideModal(this.metaInfoModal);
  }

  metaInfoUpdated(meta: ICSUploadMeta) {
    if (meta && meta.id && this.uploads && this.uploads.length) {
      for (let i = 0; i < this.uploads.length; i++) {
        if (this.uploads[i].id == meta.id) {
          let u = this.uploads[i];
          u.type_id = meta.type_id;
          u.is_tracking = meta.is_tracking;
          u.is_loc_suggested = meta.is_loc_suggested;
          break;
        }
      }
    }
    this.load();
    this.closeMetaInfoModal();
  }

  setPage($event) {
    let newPage = Math.max(0, parseInt($event) || 1);
    this.urlParser.setParameter('page', newPage);
    if (this.page !== newPage) {
      this.page = newPage;
    }
  }

  exportCSV() {
    if (!this.items || !this.items.length || this.exporting) {
      return;
    }
    this.rendering = true;
    this.exporting = true;
    let itemsLimitPre = this.itemsLimit;
    let prePage = this.page;
    let preOnlyTracked = this.onlyTracked;
    this.onlyTracked = false;
    let refill = (this.onlyTracked != preOnlyTracked);
    if (refill) {
      this.fillItems();
    }
    this.page = 1;
    this.itemsLimit = 100000;
    setTimeout(() => {
      this.h.exportCsv(this.tbl, 'cs_uploads.csv', () => {
        this.exporting = false;
        this.itemsLimit = itemsLimitPre;
        this.page = prePage;
        this.onlyTracked = preOnlyTracked;
        if (refill) {
          this.fillItems();
        }
        this.rendered();
      }, (celltext) => {
        return celltext.replace('check_circle', 'X').replace('visibility', 'X');
      });
    }, 120);
  }

  setUrlParams() {
    this.urlParser.setParameter('onlyMetas', this.onlyMetas);
    this.urlParser.setParameter('onlyTracked', this.onlyTracked);
    this.urlParser.setParameter('onlyLocSuggested', this.onlyLocSuggested);
    this.urlParser.setParameter('onlyMembers', this.onlyMembers);
    this.urlParser.setParameter('onlyLocSubmitted', this.onlyLocSubmitted);
    this.urlParser.setParameter('onlyLocNotSubmitted', this.onlyLocNotSubmitted);
  }

  parseUrlParams() {
    this.setPage(parseInt(this.urlParser.getParameter('page')));
    let urlParams = this.urlParser.getParameters();
    if (urlParams.onlyMetas) {
      this.onlyMetas = true;
    }
    if (urlParams.onlyTracked) {
      this.onlyTracked = true;
    }
    if (urlParams.onlyLocSuggested) {
      this.onlyLocSuggested = true;
    }
    if (urlParams.onlyMembers) {
      this.onlyMembers = true;
    }
    if (urlParams.onlyLocSubmitted) {
      this.onlyLocSubmitted = true;
      this.onlyLocNotSubmitted = false;
    } else {
      if (urlParams.onlyLocNotSubmitted) {
        this.onlyLocNotSubmitted = true;
        this.onlyLocSubmitted = false;
      }
    }
  }

  setOrderField(f: string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
    this.fillItems();
  }

  toggleOnlyTracked() {
    this.onlyTracked = !this.onlyTracked;
    this.orderField = '';
    this.fillItems();
  }

  setColorFilter(colorCode) {
    this.filterByColorCode = colorCode;
    this.fillItems();
  }

  lookingForLocation($text) {
    this.findLocation = $text;
    this.fillItems();
  }
}
