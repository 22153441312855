import {Component, Input} from '@angular/core';
import {Reports} from "../../services/Reports";
import {IGroupTopVendorsReport} from "../../interfaces/IGroupTopVendorsReport";
import {IGroupSalesReport} from "../../interfaces/IGroupSalesReport";

@Component({
  templateUrl: './report.html',
  selector: 'group-fin-report',
})
export class FinancialReportCmp {
  @Input() group_id: number;
  @Input() location_gpo_id: string = '';
  public csv_url: string;
  public csv_rebates_url: string;
  public sales: IGroupSalesReport;
  public rebates: IGroupSalesReport;
  public loading_sales = false;
  public loading_rebates = false;
  public vendors: IGroupTopVendorsReport[];
  public loading_vendors = false;
  public tab = 1;

  constructor(public reports: Reports) {}

  ngOnChanges() {
    if (this.group_id) {
      this.csv_url = this.reports.getGroupFinDetailsUrl(this.group_id);
      this.csv_rebates_url = this.reports.getGroupFinDetailsRebatesUrl(this.group_id);

      this.loading_sales = true;
      this.sales = null;
      this.reports.getGroupSalesReport(this.group_id).subscribe((r) => {
        this.sales = r;
        this.loading_sales = false;
      }, () => {
        this.loading_sales = false;
      });

      this.loading_rebates = true;
      this.rebates = null;
      this.reports.getGroupRebatesReport(this.group_id).subscribe((r) => {
        this.rebates = r;
        this.loading_rebates = false;
      }, () => {
        this.loading_rebates = false;
      });

      this.loading_vendors = true;
      this.vendors = null;
      this.reports.getGroupTopVendorsReport(this.group_id).subscribe((r) => {
        this.loading_vendors = false;
        this.vendors = r;
      }, () => {
        this.loading_vendors = false;
      })
    }
  }
}
