import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {routes} from './app.routes';

import {HomeCmp} from './components/home/home';
import {LoginCmp} from './components/login/login';
import {RegisterCmp} from './components/register/register';
import {SettingsCmp} from "./components/settings/settings";
import {UserCmp} from "./components/user/user";
import {LocationsCmp} from "./components/locations/locations";
import {LocationCmp} from "./components/location/location";
import {RepManagerLocGroupsCmp} from "./components/rep_manager_loc_groups/groups";
import {LocationsGroupCmp} from "./components/locations_group/locations_group";
import {CreateContactCmp} from "./components/create-contact/CreateContactCmp";
import {LogoutCmp} from "./components/logout/LogoutCmp";
import {TicketsCmp} from "./components/tickets/TicketsCmp";
import {TicketCmp} from "./components/ticket/TicketCmp";
import {ManufacturersCmp} from "./components/manufacturers/ManufacturersCmp";
import {RepsListCmp} from "./components/reps-list/reps-list";
import {ClientManagersListCmp} from "./components/client-managers-list/ClientManagersListCmp";
import {AllUsersCmp} from "./components/all-users/AllUsersCmp";
import {AnswerRadioCmp} from "./components/answer-radio/AnswerRadioCmp";
import {ReportsCmp} from "./components/reports/ReportsCmp";
import {CreateZimmerLifecyclesCmp} from "./components/create-zimmer-lifecycles/CreateZimmerLifecyclesCmp";
import {WriteImplementationStepsCmp} from "./components/write-implemenation-steps/WriteImplementationStepsCmp";
import {NotificationsListCmp} from "./components/notifications-list/NotificationsListCmp";
import {ReportsGlobalCmp} from "./components/reports-global/ReportsGlobalCmp";
import {DistributorPageCmp} from "./components/distributor-page/DistributorPageCmp";
import {AnswerSelectCmp} from "./components/answer-select/AnswerSelectCmp";
import {AnswerSelectMultipleCmp} from "./components/answer-select-multiple/AnswerSelectMultipleCmp";
import {AnswerTextCmp} from "./components/answer-text/AnswerTextCmp";
import {AnswersListCmp} from "./components/answers-list/AnswersListCmp";
import {CreateSignupLinkCmp} from "./components/create-signup-link/CreateSignupLinkCmp";
import {DatePickerCmp} from "./components/date-picker/DatePickerCmp";
import {FieldCmp} from "./components/field/FieldCmp";
import {FileUploadButtonDialog} from "./components/file-upload-button/FileUploadDialogCmp";
import {FilesCmp} from "./components/files/FilesCmp";
import {FormHeaderCmp} from "./components/form-header/FormHeaderCmp";
import {HeaderSearchBoxCmp} from "./components/header-search-box/HeaderSearchBoxCmp";
import {LocationCardCmp} from "./components/location-card/LocationCardCmp";
import {LocationContactsCmp} from "./components/location-contacts/LocationContactsCmp";
import {LocationImplNotesCmp} from "./components/location-impl-notes/LocationImplNotesCmp";
import {LocationImplStepsCmp} from "./components/location-impl-steps/LocationImplStepsCmp";
import {LocationImplVendorsCmp} from "./components/location-impl-vendors/LocationImplVendorsCmp";
import {LocationImplementationCmp} from "./components/location-implementation/LocationImplementationCmp";
import {LocationFileCycleCmp} from "./components/location-life-cycle/LocationFileCycleCmp";
import {LocationPrimaryContactCmp} from "./components/location-primary-contact/LocationPrimaryContactCmp";
import {LocationPurchasingContactCmp} from "./components/location-purchasing-contact/LocationPurchasingContactCmp";
import {LocationSelectCmp} from "./components/location-select/location-select";
import {Pagination} from "./components/pagination/pagination";
import {RepCardCmp} from "./components/rep-card/rep-card";
import {RepLocationsCmp} from "./components/rep-locations/RepLocations";
import {ReviewResultsCmp} from "./components/review-results/ReviewResultsCmp";
import {SortArrowCmp} from "./components/sort-arrow/SortArrowCmp";
import {Spinner} from "./components/spinner/spinner";
import {SurveyResultsCmp} from "./components/survey-results/SurveyResultsCmp";
import {TagsLineCmp} from "./components/tags-line/TagsLineCmp";
import {UnsafeTextCmp} from "./components/unsafe-text/UnsafeTextCmp";
import {GpoFileUploadCmp} from "./components/gpo-file-upload/GpoFileUploadCmp";
import {UserCardCmp} from "./components/user-card/user-card";
import {UserSelectCmp} from "./components/user-select/user-select";
import {UserTypeCmp} from "./components/user-type/UserTypeCmp";
import {UsersListCmp} from "./components/users-list/UsersList";
import {UsersMergeWindowCmp} from "./components/users-merge-window/UsersMergeWindowCmp";
import {TimeAgo} from "./services/TimeAgo";
import {FilterPipe} from "./components/FilterPipe";
import {SlDistributorCbCmp} from "./components/sl-distributor-cb/SlDistributorCbCmp";
import {SaverLandingListCmp} from "./components/saver-landing-list/SaverLandingListCmp";
import {AspsUserSelectCmp} from "./components/asps-user-select/asps-user-select";
import {LocationImplContractsCmp} from "./components/location-impl-contracts/LocationImplContractsCmp";
import {DeaSearchCmp} from "./components/dea-search/DeaSearchCmp";
import {AddLifeCycleRecordCmp} from "./components/add-lifecycle-record/AddLifeCycleRecordCmp";
import {TasksListCmp} from "./components/tasks-list/TasksListCmp";
import {LocationTaskCmp} from "./components/location-task/LocationTaskCmp";
import {FinancialReportCmp} from "./components/financial-report/FinancialReportCmp";
import {NumbersCommaSeparatePipe} from "./services/NumbersCommaSeparatePipe";
import {PieChartCmp} from "./components/pie-chart/PieChartCmp";
import {FragmentLinkCmp} from "./components/fragment-link/FragmentLinkCmp";
import {OrderByPipe} from "./services/OrderByPipe";
import {FilterByWordPipe} from "./services/FilterByWordPipe";
import {FilterTagsLineCmp} from "./components/filter-tags-line/FilterTagsLineCmp";
import {StaplesReportCmp} from "./components/staples-report/StaplesReportCmp";
import {EditRequestCmp} from "./components/edit-request/EditRequestCmp";
import {EditRequestsListCmp} from "./components/edit-requests-list/EditRequestsListCmp";
import {PaQListCmp} from "./components/pa_q_list/PaQListCmp";
import {AutoFocusCmp} from "./components/auto-focus/AutoFocusCmp";
import {UserEditRequestsCmp} from "./components/user-edit-requests/UserEditRequestsCmp";
import {WriteImplementationContractsCmp} from "./components/write-implemenation-contracts/WriteImplementationContractsCmp";
import {ArrayJoinNotEmptyPipe} from "./services/ArrayJoinNotEmptyPipe";
import {GroupEditRequestsCmp} from "./components/group-edit-requests/GroupEditRequestsCmp";
import {LocationImplOtherCmp} from "./components/location-impl-other/LocationImplOtherCmp";
import {TaskFileUploadCmp} from "./components/task-file-upload/TaskFileUploadCmp";
import {MarkStaplesSentCmp} from "./components/mark-staples-sent/MarkStaplesSentCmp";
import {DisableUserCmp} from "./components/disable-user/DisableUserCmp";
import {LegacySearchCmp} from './components/legacy-search/legacy-search.component';
import {LocationHistoryCmp} from './components/location-history/LocationHistoryCmp';
import {AdminCommandsCmp} from "./components/admin-commands/AdminCommandsCmp";
import {LocationGradesCmp} from "./components/location-grades/LocationGradesCmp";
import {ReportSnapshotsListCmp} from "./components/report-snapshots-list/ReportSnapshotsListCmp";
import {LocationSalesTracingCmp} from "./components/location-sales-tracing/LocationSalesTracingCmp";
import {SalesMashupCmp} from "./components/sales-mashup-report/SalesMashupCmp";
import {NewsletterCompileListCmp} from "./components/newsletters-compile-list/NewsletterCompileListCmp";
import {VendorPageCmp} from "./components/vendor-page/VendorPageCmp";
import {PopupListCmp} from "./components/popup-list/PopupListCmp";
import {VendorReportCmp} from "./components/vendor-page/vendor-report/VendorReportCmp";
import {VendorQuarterPageCmp} from "./components/vendor-quarter-page/VendorQuarterPageCmp";
import {VendorQuarterReportCmp} from "./components/vendor-quarter-page/vendor-quarter-report/VendorQuarterReportCmp";
import {FiltersSelectorCmp} from "./components/filters-selector/FiltersSelectorCmp";
import {PopupQueriesCmp} from "./components/popup-queries/PopupQueriesCmp";
import {SummPipe} from "./services/SummPipe";
import {ContractChartCmp} from "./components/contract-chart/ContractChartCmp";
import {ContractChartReportCmp} from "./components/contract-chart-report/ContractChartReportCmp";
import {SearchDistrReportingCmp} from "./components/search-distributors-reporting/SearchDistrReportingCmp";
import {TextSpinnerCmp} from "./components/text-spinner/TextSpinnerCmp";
import {BenefitReviewsCmp} from "./components/benefit-reviews/BenefitReviewsCmp";
import {PharmaCheckReportCmp} from "./components/pharma-check-report/PharmaCheckReportCmp";
import {Utc2EtPipe} from "./services/Utc2EtPipe";
import {SaverLandingPageMgmaCmp} from "./components/saver-landing-page-mgma/SaverLandingPageMgmaCmp";
import {SaverLandingPageAoaCmp} from "./components/saver-landing-page-aoa/SaverLandingPageAoaCmp";
import {HPGReportCmp} from "./components/hpg-report/HPGReportCmp";
import {CDiffReportCmp} from "./components/cdiff-report/CDiffReportCmp";
import {CSContractsUpdateCmp} from "./components/cs-contracts-update/CSContractsUpdateCmp";
import {CSContactsSearchBoxCmp} from "./components/cs-contracts-update/contracts-search-box/ContractsSearchBoxCmp";
import {CSFIllUinsCmp} from "./components/cs-fill-uins/CSFIllUinsCmp";
import {MembersReportCmp} from "./components/members-report/MembersReportCmp";
import {ChartCmp} from "./components/crm-chart/ChartCmp";
import {LocationsMapZoomCmp} from "./components/locations-map-zoom/LocationsMapZoomCmp";
import {SummaryTablesCmp} from "./components/summary-tables/SummaryTablesCmp";
import {CsToolCmp} from "./components/cs-tool/CsToolCmp";
import {CsResultsCmp} from "./components/cs-results/CsResultsCmp";
import {VaccinesCellCmp} from "./components/saver-vaccines-cell/VaccinesCellCmp";
import {FileColumnSelectorCmp} from "./components/file-column-selector/FileColumnSelectorCmp";
import {CsPharmaCmp} from "./components/cs-pharma/CsPharmaCmp";
import {CsManufacturersCmp} from "./components/cs-manufacturers/CsManufacturersCmp";
import {CsVendorGroupsCmp} from "./components/cs-vendor-groups/CsVendorGroupsCmp";
import {CsUploadsListCmp} from "./components/cs-uploads-list/CsUploadsListCmp";
import {PriceFormatPipe} from "./services/PriceFormatPipe";
import {CsFoundVendorsCmp} from "./components/cs-found-vendors/CsFoundVendorsCmp";
import {CsUomConversionsCmp} from "./components/cs-uom-conversions/CsUomConversionsCmp";
import {ContractDifferenceCmp} from "./components/contract-difference/ContractDifferenceCmp";
import {CsUomConversionCmp} from "./components/cs-uom-conversion/CsUomConversionCmp";
import {CsPharmaUomAdjustCmp} from "./components/cs-pharma-uom-adjust/CsPharmaUomAdjustCmp";
import {CsAddXrefCmp} from "./components/cs-add-xref/CsAddXrefCmp";
import {CsMovedMatchesCmp} from "./components/cs-moved-matches/CsMovedMatchesCmp";
import {LocationImplContractsListCmp} from "./components/location-impl-contracts-list/LocationImplContractsListCmp";
import {SanofiPageCmp} from "./components/sanofi-page/SanofiPageCmp";
import {SanofiUploadResultsCmp} from "./components/sanofi-upload-results/SanofiUploadResultsCmp";
import {CConnsFileUploadCmp} from "./components/contract-conns-file-upload/CConnsFileUploadCmp";
import {CcResultsCmp} from "./components/cc-results/CcResultsCmp";
import {CcUploadsListCmp} from "./components/cc-uploads-list/CcUploadsListCmp";
import {ReportRxTasksCmp} from "./components/report-rx-tasks/ReportRxTasksCmp";
import {OrderBySlowPipe} from "./services/OrderBySlowPipe";
import {ReportRxOrphansCmp} from "./components/report-rx-orphans/ReportRxOrphansCmp";
import {ReportUnsentReportsCmp} from "./components/report-unsent-reports/ReportUnsentReportsCmp";
import {LocationMarketingCmp} from "./components/location-marketing/LocationMarketingCmp";
import {PopupTextareaCmp} from "./components/popup-textarea/PopupTextareaCmp";
import {AlsoKnownAsCmp} from "./components/also-known-as/AlsoKnownAsCmp";
import {UserAliasesListCmp} from "./components/user-aliases-list/UserAliasesListCmp";
import {SalesTracingReportCmp} from "./components/sales-tracing-report/SalesTracingReportCmp";
import {SalesReportingReportCmp} from "./components/sales-reporting-report/SalesReportingReportCmp";
import {ReportRxSignupsCmp} from "./components/report-rx-signups/ReportRxSignupsCmp";
import {LocationsMergeCmp} from "./components/locations-merge/LocationsMergeCmp";
import {RxScreenshotsCmp} from "./components/rx-screenshots/RxScreenshotsCmp";
import {BulkUploadCmp} from "./components/bulk-upload/BulkUploadCmp";
import {ReportRebateChartCmp} from "./components/report-rebate-chart/ReportRebateChartCmp";
import {RxNewMembersUploadCmp} from "./components/rx-new-members-upload/RxNewMembersUploadCmp";
import {LocsMergeFieldValueCmp} from "./components/locations-merge/LocsMergeFieldValueCmp";
import {PossibleRepsCmp} from "./components/possible-reps/PossibleRepsCmp";
import {ReportMissingLocationsCmp} from "./components/report-missing-locations/ReportMissingLocationsCmp";
import {ReportRxConnectionsCmp} from "./components/report-rx-connections/ReportRxConnectionsCmp";
import {ReportPocSuppressedCmp} from "./components/report-poc-suppressed/ReportPocSuppressedCmp";
import {HisciToCrmHinDeaCmp} from "./components/hisci-to-crm/hin-dea/HisciToCrmHinDeaCmp";
import {HisciToCrmRxEffDateCmp} from "./components/hisci-to-crm/rx-eff-date/HisciToCrmRxEffDateCmp";
import {ElSpinnerCmp} from "./components/el-spinner/ElSpinnerCmp";
import {CsMetaCardCmp} from "./components/cs-meta-card/CsMetaCardCmp";
import {LocationDistributorsModalCmp} from "./components/location-distributors-modal/LocationDistributorsModalCmp";
import {ReportCsTrackingCmp} from "./components/report-cs-tracking/ReportCsTrackingCmp";
import {PopupInfoCmp} from "./components/popup-info/PopupInfoCmp";
import {LocationTrackedCsCmp} from "./components/location-tracked-cs/LocationTrackedCsCmp";
import {ReportAmpContactsCmp} from "./components/report-amp-contacts/ReportAmpContactsCmp";
import {EmailCampaignCmp} from "./components/email-campaign/EmailCampaignCmp";
import {EmailCampaignsListCmp} from "./components/email-campaigns-list/EmailCampaignsListCmp";
import { NewsletterDistrSurveyCompileCmp } from './components/newsletter-distr-survey-compile/NewsletterCompileListCmp';
import {LocsMedsurgDateChartCmp} from "./components/locs-medsurg-date-chart/LocsMedsurgDateChartCmp";
import {PaByDateChartCmp} from "./components/pa-by-date-chart/PaByDateChartCmp";
import {HsFileUploadCmp} from "./components/hs-file-upload/HsFileUploadCmp";
import {MouseDownLongCmp} from "./components/mouse-down-long/MouseDownLongCmp";
import {ContractRequirementsCmp} from "./components/contract-requirements/ContractRequirementsCmp";
import {ImportedFeedsReportCmp} from "./components/imported-feeds-report/ImportedFeedsReportCmp";
import {LocationSignupLogCmp} from "./components/location-signup-log/LocationSignupLogCmp";
import {ReportAadCmp} from "./components/report-aad/ReportAadCmp";
import {ReportDistrUploadsCmp} from "./components/report-distr-uploads/ReportDistrUploadsCmp";
import {ReportDistrProcessFlowCmp} from "./components/report-distr-process-flow/ReportDistrProcessFlowCmp";
import {SearchRosterCmp} from "./components/search-roster/SearchRosterCmp";
import {DcrListCmp} from "./components/data-change-requests-list/DcrListCmp";

@NgModule({
  imports: [BrowserModule, HttpClientModule, RouterModule.forRoot(routes), FormsModule, ReactiveFormsModule],
  declarations: [AddLifeCycleRecordCmp, AdminCommandsCmp, ArrayJoinNotEmptyPipe, AllUsersCmp, AnswerRadioCmp, AnswerSelectCmp,
    AnswerSelectMultipleCmp, AnswerTextCmp, AnswersListCmp, AppComponent, AspsUserSelectCmp, AutoFocusCmp,
    ClientManagersListCmp, CreateContactCmp, CreateSignupLinkCmp, CreateZimmerLifecyclesCmp, DatePickerCmp,
    DeaSearchCmp, DisableUserCmp, DistributorPageCmp, EditRequestCmp, EditRequestsListCmp, FieldCmp,
    FileUploadButtonDialog, FilesCmp, FilterPipe, FilterByWordPipe, FilterTagsLineCmp, OrderByPipe,
    FinancialReportCmp, FormHeaderCmp, FragmentLinkCmp, GroupEditRequestsCmp, HeaderSearchBoxCmp, HomeCmp,
    LocationCmp, LocationCardCmp, LocationContactsCmp, LocationImplNotesCmp, LocationImplStepsCmp,
    LocationImplContractsCmp, LocationImplVendorsCmp, LocationImplOtherCmp, LocationImplementationCmp,
    LocationFileCycleCmp, LocationPrimaryContactCmp, LocationPurchasingContactCmp, LocationSelectCmp, LocationsCmp,
    LocationsGroupCmp, LoginCmp, LogoutCmp, ManufacturersCmp, MarkStaplesSentCmp, NotificationsListCmp,
    NumbersCommaSeparatePipe, Pagination, PaQListCmp, PieChartCmp, RegisterCmp, RepCardCmp, RepLocationsCmp,
    RepManagerLocGroupsCmp, ReportsCmp, ReportsGlobalCmp, RepsListCmp, ReviewResultsCmp, SettingsCmp,
    StaplesReportCmp, SortArrowCmp, Spinner, SurveyResultsCmp, TagsLineCmp, TasksListCmp, LocationTaskCmp,
    TicketCmp, TicketsCmp, TimeAgo, UnsafeTextCmp, GpoFileUploadCmp, UserCmp, UserCardCmp, UserSelectCmp,
    UserTypeCmp, UsersListCmp, UsersMergeWindowCmp, UserEditRequestsCmp, WriteImplementationStepsCmp,
    WriteImplementationContractsCmp, SlDistributorCbCmp, SaverLandingListCmp, TaskFileUploadCmp, LegacySearchCmp,
    LocationHistoryCmp, LocationGradesCmp, ReportSnapshotsListCmp, LocationSalesTracingCmp, SalesMashupCmp,
    NewsletterCompileListCmp, VendorPageCmp, PopupListCmp, VendorReportCmp, VendorQuarterPageCmp, VendorQuarterReportCmp,
    FiltersSelectorCmp, PopupQueriesCmp, SummPipe, ContractChartCmp, ContractChartReportCmp, SearchDistrReportingCmp, TextSpinnerCmp,
    BenefitReviewsCmp, PharmaCheckReportCmp, Utc2EtPipe, SaverLandingPageMgmaCmp, SaverLandingPageAoaCmp, HPGReportCmp,
    CDiffReportCmp, CSContractsUpdateCmp, CSContactsSearchBoxCmp, CSFIllUinsCmp, MembersReportCmp, ChartCmp,
    LocationsMapZoomCmp, SummaryTablesCmp, CsToolCmp, CsResultsCmp, VaccinesCellCmp, FileColumnSelectorCmp, CsPharmaCmp,
    CsManufacturersCmp, CsVendorGroupsCmp, CsUploadsListCmp, PriceFormatPipe, CsFoundVendorsCmp, CsUomConversionsCmp,
    ContractDifferenceCmp, CsUomConversionCmp, CsPharmaUomAdjustCmp, CsAddXrefCmp, CsMovedMatchesCmp, LocationImplContractsListCmp,
    SanofiPageCmp, SanofiUploadResultsCmp, CConnsFileUploadCmp, CcResultsCmp, CcUploadsListCmp, ReportRxTasksCmp, OrderBySlowPipe,
    ReportRxOrphansCmp, ReportUnsentReportsCmp, LocationMarketingCmp, PopupTextareaCmp, AlsoKnownAsCmp, UserAliasesListCmp,
    SalesTracingReportCmp, SalesReportingReportCmp, ReportRxSignupsCmp, LocationsMergeCmp, RxScreenshotsCmp, BulkUploadCmp,
    ReportRebateChartCmp, RxNewMembersUploadCmp, LocsMergeFieldValueCmp, PossibleRepsCmp, ReportMissingLocationsCmp,
    ReportRxConnectionsCmp, ReportPocSuppressedCmp, HisciToCrmHinDeaCmp, HisciToCrmRxEffDateCmp, ElSpinnerCmp,
    CsMetaCardCmp, LocationDistributorsModalCmp, ReportCsTrackingCmp, PopupInfoCmp, LocationTrackedCsCmp, ReportAmpContactsCmp,
    EmailCampaignCmp, EmailCampaignsListCmp, NewsletterDistrSurveyCompileCmp, LocsMedsurgDateChartCmp, PaByDateChartCmp,
    HsFileUploadCmp, MouseDownLongCmp, ContractRequirementsCmp, ImportedFeedsReportCmp, LocationSignupLogCmp, ReportAadCmp,
    ReportDistrUploadsCmp, ReportDistrProcessFlowCmp, SearchRosterCmp, DcrListCmp
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
