import {Component, Input, EventEmitter, Output} from '@angular/core';
import {User, IUserAlias} from "../../services/User";
import {Observable} from "rxjs";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'also-known-as',
  template: `<text-spinner *ngIf="updating || (displayedOnce && loading)"></text-spinner>
  <span *ngIf="!updating && !(displayedOnce && loading) && aliases && aliases.length>0">Also known as:&nbsp;
     <span *ngFor="let a of aliases; let isLast = last">
     <span class="pointer" (click)="setAsMain(a.id)">{{a.fname}} {{a.lname}}<span *ngIf="a.title">&nbsp;({{a.title}})</span></span>
     <i *ngIf="aliases.length>1" class="material-icons-outlined pointer md in-text color-primary-dark left-space" (click)="setAsMain(a.id)">swap_vertical_circle</i>
     <button *ngIf="aliases.length==1" type="button" class="btn btn-sm btn-outline-primary" (click)="setAsMain(a.id)">Use as main info for this contact</button>
     <span *ngIf="!isLast">,&nbsp;</span>
     </span>
     <span *ngIf="aliases.length>1"><hr/>
     <i class="material-icons-outlined md in-text color-primary-dark">swap_vertical_circle</i> - <span class="btn btn-sm btn-outline-primary">Use as main info for this contact</span></span>
    </span>`,
})
export class AlsoKnownAsCmp {
  @Input() userID:number;
  @Input() refresh:Observable<any>;
  @Output() count = new EventEmitter();
  @Output() userUpdate = new EventEmitter();

  public aliases:IUserAlias[] = [];
  public updating = false;
  public displayedOnce = false;
  public loading = false;
  private loadedFor:number;
  private refreshSubscribed = false;

  constructor(private usrSrv:User) {}

  ngAfterViewInit() {
    if (this.userID && (!this.aliases || this.aliases.length < 1)) {
      this.load();
    }
    if (!this.refreshSubscribed && this.refresh) {
      this.refreshSubscribed = true;
      this.refresh.subscribe(()=> {
        this.forceReload();
      }, ()=> {});
    }
  }

  forceReload() {
    this.loadedFor = 0;
    this.load();
  }

  ngOnChanges() {
    if (this.userID) {
      this.load();
    }
  }

  load() {
    if (this.loading) {
      return;
    }
    if (this.loadedFor == this.userID && this.aliases.length > 0) {
      return;
    }
    this.loading = true;
    this.usrSrv.getAliases(this.userID).subscribe((aliases)=> {
      this.loading = false;
      if (aliases && aliases.length > 0) {
        this.aliases = aliases;
        this.loadedFor = this.userID;
        this.displayedOnce = true;
      } else {
        this.aliases = [];
      }
      this.count.emit(this.aliases.length);
    }, ()=> {
      this.loading = false;
    });
  }

  setAsMain(aliasID:number) {
    if (this.updating) {
      return;
    }
    this.updating = true;
    this.usrSrv.setAliasAsMain(aliasID).subscribe(()=> {
      this.updating = false;
      this.userUpdate.emit();
      this.forceReload();
    }, (err)=> {
      this.updating = false;
      let n = new Notify();
      n.httpErr(err);
    });
  }
}
