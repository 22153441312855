import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Helpers} from "../../services/Helpers";

@Component({
  template: `
  <ng-content></ng-content>

  <div class="modal fade" tabindex="-1" role="dialog" #modalInfoPopup>
    <div class="modal-dialog" role="document">
      <div class="modal-content" *ngIf="isActive">
        <div class="modal-header inverse-primary tiny-header">
          <h5 class="modal-title text-xs-left"><span *ngIf="header">{{header}}</span><i *ngIf="!header" class="material-icons color-white cursor-none">info</i>
            <button type="button" class="close bright" (click)="hideModal($event)" aria-label="Close"><span aria-hidden="true" class="material-icons">close</span></button>
          </h5>
        </div>
        <div class="modal-body">
          <div>{{info}}</div>
        </div>
      </div>
    </div>
  </div>
  `,
  selector: '[popup-info]',
  host: {"(click)": "click($event)"},
})
export class PopupInfoCmp {
  @Input() info:string = '';
  @Input() containerIsBody;
  @Input() header:string = '';
  @ViewChild("modalInfoPopup") modal:ElementRef;
  public isActive = false;
  private movedToBody = false;

  constructor(private h:Helpers) {}

  click($event) {
    if ($event) {}
    if (!this.isActive) {
      this.isActive = true;
      setTimeout(()=> {
        if (this.containerIsBody && !this.movedToBody && this.modal && this.modal.nativeElement) {
          document.body.appendChild(this.modal.nativeElement);
          this.movedToBody = true;
        }
        setTimeout(()=> {
          this.openModal($event);
        }, 1);
      }, 1);
    } else {
      this.openModal($event);
    }
  }

  openModal($event?) {
    this.h.showModal(this.modal, $event);
  }

  hideModal($event?) {
    this.h.hideModal(this.modal, $event);
  }
}
