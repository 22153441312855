import {Pipe} from '@angular/core';

@Pipe({
  name: 'summ',
  pure: false,
})
export class SummPipe {
  transform(value, globalField:string, getSumm:boolean, reset:boolean):any {
    if (reset) {
      try {localStorage.removeItem(globalField);} catch (e) {}
    }
    let prev = localStorage.getItem(globalField) || '0';
    if (!getSumm) {
      if (value == null) {
        return value;
      }
      localStorage.setItem(globalField, (parseFloat(value) + parseFloat(prev)).toString());
      return value;
    } else {
      try {localStorage.removeItem(globalField);} catch (e) {}
      return parseFloat(prev) + parseFloat(value);
    }
  }
}
