import {Component, Input, Output, EventEmitter} from '@angular/core';
import {IEmailCampaignData} from "../../interfaces/IEmailCampaignData";
import {Notify} from "../../services/Notify";
import {Newsletter} from "../../services/Newsletter";

@Component({
  templateUrl: 'email_campaign.html',
  selector: 'email-campaign',
})
export class EmailCampaignCmp {
  @Input() campaign:IEmailCampaignData;
  @Output() update = new EventEmitter();
  public saving = false;
  private n = new Notify();

  constructor(private newsSrv:Newsletter) {}

  save() {
    if (this.saving || !this.campaign) {
      return;
    }
    this.saving = true;
    this.newsSrv.updateEmailCampaign(this.campaign).subscribe(()=> {
      this.saving = false;
      this.n.success('Updated');
      this.update.emit();
    }, (err)=> {
      this.saving = false;
      this.n.httpErr(err);
    });
  }
}
