import {Component} from '@angular/core';
import {User} from "../../services/User";
import {Session} from "../../services/Session";

@Component({
  selector: 'possible-reps',
  templateUrl: 'possible_reps.html'
})
export class PossibleRepsCmp {
  public reps:string[];

  constructor(private usr:User, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.usr.getPossibleRepsList().subscribe((reps)=> {
      this.reps = reps;
    }, ()=> {});
  }
}
