import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Implementations} from "../../services/Implementations";
import {IImplementationContract} from "../../interfaces/IImplementationContract";
import {ILocationImplementationContract, ILocGroupCOID} from "../../interfaces/ILocationImplementationContract";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {ILocation} from "../../interfaces/ILocation";
import {Helpers} from "../../services/Helpers";
declare var _:any;

@Component({
  selector: 'location-impl-contracts',
  templateUrl: './contracts.html',
})
export class LocationImplContractsCmp {
  @Input() location:ILocation;
  @Output() update = new EventEmitter();
  public contracts:Array<ILocationImplementationContract>;
  public group_contracts:Array<ILocationImplementationContract>;
  public coids:ILocGroupCOID[];
  public allContracts:Array<IImplementationContract>;
  public contractsMap = {};
  public saving = false;
  public loading = false;
  public tab = 1;
  public rx_tab = 3;
  public tabNames = {1: 'Distributed', 2: 'Direct', 3: 'RX'};
  public token:string;

  constructor(public implSrv:Implementations, public session:Session, private h:Helpers) {
    this.token = this.session.getToken();
    if (!this.token) {
      this.session.addOnLoadHandler(()=> {
        this.token = this.session.getToken();
      });
      this.session.redirectIfNotAuth();
    }
  }

  ngOnInit() {
    if (this.location && this.location.id && !this.contracts) {
      this.loadContracts();
    }
  }

  ngOnChanges() {
    this.loadContracts();
  }

  setTab(t) {
    this.tab = parseInt(t);
  }

  loadContracts() {
    this.loading = true;
    if (!this.allContracts) {
      this.implSrv.getContracts().subscribe((contracts)=> {
        this.allContracts = contracts;
        this.allContracts.forEach((v:IImplementationContract)=> {
          this.contractsMap[v.id] = v;
        });
      }, (response) => {
        this.loading = false;
        console.error(response);
      });
    }
    this.implSrv.getLocationContracts(this.location.id).subscribe((contracts)=> {
      this.contracts = contracts.contracts;
      this.coids = contracts.group_coids;
      this.group_contracts = contracts.group_contracts;
    }, (response) => {
      console.error(response);
    });
  }

  saveContracts() {
    if (this.tab != this.rx_tab && (!this.contracts || !this.contracts.length)) {
      return;
    }
    if (this.tab == this.rx_tab && (!this.group_contracts || !this.group_contracts.length)) {
      return;
    }
    let contracts;
    if (this.tab == this.rx_tab) {
      contracts = this.group_contracts;
    } else {
      contracts = this.contracts;
    }
    this.saving = true;
    this.implSrv.setLocationContracts(contracts).subscribe(()=> {
      this.saving = false;
      this.update.emit(true);
      let notify = new Notify();
      notify.success('Saved');
    }, ()=> {
      this.saving = false;
      let notify = new Notify();
      notify.error('Error');
    });
  }

  exportTab() {
    let tab_name = this.tabNames[this.tab];
    let filename = 'Impl Contracts, ' + tab_name + ', ' + this.location.pc_id + '.csv';
    this.h.exportCsvBySelector('location-impl-contracts .tab-pane.active table', filename);
  }
}
