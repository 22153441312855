import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {IImplementationVendor} from "../interfaces/IImplementationVendor";
import {IImplementationStep} from "../interfaces/IImplementationStep";
import {ILocationImplementationVendor} from "../interfaces/ILocationImplementationVendor";
import {ILocationImplementationStep} from "../interfaces/ILocationImplementationStep";
import {IImplementationContract} from "../interfaces/IImplementationContract";
import {ILocationImplementationContracts} from "../interfaces/ILocationImplementationContract";
import {IStaplesSentMessages} from "../interfaces/IStaplesSentMessages";

@Injectable()
export class Implementations {
  private config:Config;
  private session:Session;
  private http:HttpClient;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  getVendors():Observable<Array<IImplementationVendor>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<Array<IImplementationVendor>>(this.config.apiUrl + '/implementation/vendor', {headers: headers});
  }

  getSteps():Observable<Array<IImplementationStep>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<Array<IImplementationStep>>(this.config.apiUrl + '/implementation/step', {headers: headers});
  }

  getLocationVendors(location_id):Observable<Array<ILocationImplementationVendor>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<Array<ILocationImplementationVendor>>(this.config.apiUrl + '/implementation/location/' + location_id + '/vendor', {headers: headers});
  }

  getLocationSteps(location_id):Observable<Array<ILocationImplementationStep>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<Array<ILocationImplementationStep>>(this.config.apiUrl + '/implementation/location/' + location_id + '/step', {headers: headers});
  }

  setLocationVendors(vendors):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/implementation/location/vendors', JSON.stringify(vendors), {headers: headers});
  }

  setLocationSteps(steps):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/implementation/location/steps', JSON.stringify(steps), {headers: headers});
  }

  removeLocationStep(locID:number, stepID:number, reason?:string):Observable<any> {
    if (reason && reason.length > 0) {
      return this.removeStepWithReason(locID, reason, stepID);
    }
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + `/implementation/location/${locID}/step/${stepID}`, {headers: headers});
  }

  removeStepWithReason(location_id:number, reason:string, step_id:number):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/implementation/location/${location_id}/step_remove/${step_id}`, reason, {headers: headers, responseType: 'text'});
  }

  setStepsByZimmerIDs(zimmer_ids:Array<string>, step_id:number, datetime?:string):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/implementation/zimmer/steps', JSON.stringify({
      zimmer_ids: zimmer_ids,
      step_id: step_id,
      datetime: datetime
    }), {headers: headers});
  }

  setStaplesSentByZimmerIDs(zimmer_ids:Array<string>):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/implementation/location/staples_sent_zimmer_ids', JSON.stringify({zimmer_ids: zimmer_ids}), {headers: headers});
  }

  setContractsByZimmerIDs(zimmer_ids:Array<string>, contract_id:number, step_id:number, datetime?:string):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/implementation/zimmer/contracts', JSON.stringify({
      zimmer_ids: zimmer_ids,
      contract_id: contract_id,
      step_id: step_id,
      datetime: datetime
    }), {headers: headers});
  }

  getContracts():Observable<Array<IImplementationContract>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<Array<IImplementationContract>>(this.config.apiUrl + '/implementation/contract', {headers: headers});
  }

  getStaplesSent(cm_id:number):Observable<IStaplesSentMessages> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IStaplesSentMessages>(this.config.apiUrl + '/implementation/staples/' + cm_id.toString(), {headers: headers});
  }

  getLocationContracts(location_id):Observable<ILocationImplementationContracts> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocationImplementationContracts>(this.config.apiUrl + '/implementation/location/' + location_id + '/contract', {headers: headers});
  }

  setLocationContracts(contracts):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/implementation/location/contracts', JSON.stringify(contracts), {headers: headers});
  }

  removeLocationContract(location_id:number, impl_contract_id:number, reason:string):Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/implementation/location/${location_id}/contract_remove/${impl_contract_id}`, reason, {headers: headers});
  }
}
