import {Component} from '@angular/core';
import {User} from "../../services/User";
import {Session} from "../../services/Session";

@Component({
  selector: 'client-managers-list',
  templateUrl: './client-managers-list.html',
})
export class ClientManagersListCmp {
  public managers = [];
  public sort_order = '';
  public sort_field = '';
  public itemsLimit = 15;
  public page = 1;
  public loading = false;

  constructor(public userService:User, private session: Session) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.loading = true;
    this.userService.getClientManagers(this.sort_field, this.sort_order!=='desc').subscribe((managers) => {
      this.managers = managers;
      this.loading = false;
    }, ()=> {});
  }

  sort(field) {
    if (this.sort_field===field) {
      if (this.sort_order==='asc') {
        this.sort_order = 'desc';
      } else {
        this.sort_order = '';
        this.sort_field = '';
      }
    } else {
      this.sort_order = 'asc';
      this.sort_field = field;
    }
    this.reload();
  }

  setPage(page) {
    this.page = page;
  }
}
