import {Component} from '@angular/core';
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {ISanofiResult} from "../../services/Locations";

@Component({
  selector: 'sanofi-page',
  templateUrl: './sanofi.html',
})
export class SanofiPageCmp {
  public results:ISanofiResult[];

  constructor(private session:Session) {
    this.session.redirectIfNotAuth();
  }

  setResults($event) {
    try {
      this.results = JSON.parse($event);
    } catch (e) {
      this.results = [];
    }
  }
}
