import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CostStudy} from "../../services/CostStudy";
import {Session} from "../../services/Session";
import {IMissingUomConversion} from "../../interfaces/ICostStudy";
import {Notify} from "../../services/Notify";
import {UrlParser} from "../../services/UrlParser";
declare var $:any;

@Component({
  selector: "cs-uom-conversions",
  templateUrl: "conversions.html"
})
export class CsUomConversionsCmp {
  public perPage = 20;
  public page = 0;
  public loading = false;
  public pageLoading = false;
  public results:IMissingUomConversion[];
  public total = 0;
  public queried = false;
  private n = new Notify();

  constructor(private csSrv:CostStudy, private session:Session, private urlParser:UrlParser, private params:ActivatedRoute) {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.page) {
      let page = parseInt(urlParams.page);
      if (page > 0) {
        this.page = page - 1;
      }
    }
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.queried = true;
    this.results = null;
    this.csSrv.getMissingUomConversions(this.page * this.perPage, this.perPage).subscribe((response) => {
      this.loading = false;
      this.pageLoading = false;
      if (!response) {
        this.n.error('Error', 'Empty response');
        return;
      }
      this.results = response.conversions;
      this.total = response.count;
    }, (err) => {
      this.loading = false;
      this.pageLoading = false;
      this.n.error('Error', err._body);
    });
  }

  nextPage() {
    if (this.loading || this.pageLoading) {
      return;
    }
    if (this.total > 0 && ((this.page + 1) * this.perPage) >= this.total) {
      return;
    }
    this.page++;
    this.urlParser.setParameter('page', this.page + 1);
    this.pageLoading = true;
    if (this.queried) {
      this.load();
    }
  }

  prevPage() {
    if (this.loading || this.pageLoading) {
      return;
    }
    if (this.page < 1) {
      return;
    }
    this.page--;
    this.urlParser.setParameter('page', this.page + 1);
    this.pageLoading = true;
    if (this.queried) {
      this.load();
    }
  }
}
