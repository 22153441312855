import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Newsletter} from "../../services/Newsletter";
import {Notify} from "../../services/Notify";
import {IEmailCampaignData} from "../../interfaces/IEmailCampaignData";

@Component({
  selector: 'newsletter-compile-list',
  templateUrl: 'form.html',
})
export class NewsletterCompileListCmp {
  public ids:string = '';
  public campaigns:IEmailCampaignData[] = [];
  public campaignID:number = 0;
  public sending = false;
  public n = new Notify();

  constructor(private session:Session, private srv:Newsletter) {
    this.session.redirectIfNotAuth();
    this.srv.getEmailCampaigns().subscribe((r)=> {
      this.campaigns = r;
    }, ()=> {});
  }

  send() {
    if (!this.ids || this.ids.length < 1) {
      this.n.warning("Please input comma-separated list");
      return;
    }
    if (!this.campaignID) {
      this.n.warning("Please select campaign");
      return;
    }
    this.sending = true;
    this.srv.compileLettersForList(this.ids, this.campaignID).subscribe((c)=> {
      this.ids = '';
      this.sending = false;
      this.n.success("Compiled: " + c);
    }, (err)=> {
      this.sending = false;
      this.n.error(JSON.stringify(err), "Error");
    });
  }
}
