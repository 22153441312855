// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.narrow-state-column
{
  width: 6.5%
}

.wide-cm-name
{
  width: 10%
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvY2F0aW9ucy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7O0VBRUU7QUFDRjs7QUFFQTs7RUFFRTtBQUNGIiwiZmlsZSI6ImxvY2F0aW9ucy5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubmFycm93LXN0YXRlLWNvbHVtblxue1xuICB3aWR0aDogNi41JVxufVxuXG4ud2lkZS1jbS1uYW1lXG57XG4gIHdpZHRoOiAxMCVcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/locations/locations.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;;AAEA;;EAEE;AACF;;AAEA,gWAAgW","sourcesContent":[".narrow-state-column\n{\n  width: 6.5%\n}\n\n.wide-cm-name\n{\n  width: 10%\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
