import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';

@Injectable()
export class Lifecycles {
  private config:Config;
  private session:Session;
  private http: HttpClient;
  private list = [];
  private typesLoadingPromise;

  constructor(config:Config, http: HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  loadTypes() {
    if (this.list && this.list.length > 0) {
      return new Promise((resolve)=> {
        resolve(this.list);
      });
    }
    if (this.typesLoadingPromise) {
      return this.typesLoadingPromise;
    }
    this.typesLoadingPromise = new Promise((resolve, reject)=> {
      this.http.get(this.config.apiUrl+'/location_lifecycle_type')

        .subscribe((objects)=> {
          this.typesLoadingPromise = null;
          if (objects instanceof Array) {
            this.list = objects;
            resolve(this.list);
          } else {
            reject();
          }
        }, ()=> {
          this.typesLoadingPromise = null;
          reject();
        });
    });
    return this.typesLoadingPromise;
  }

  loadTypesToMap(map) {
    this.loadTypes().then((types)=> {
      if (types && types.length > 0) {
        types.forEach((d)=> {
          map[d.id] = d;
        });
      }
    }, ()=> {});
  }

  loadTypesToList(list) {
    this.loadTypes().then((types)=> {
      if (types && types.length > 0) {
        types.forEach((d)=> {
          list.push(d);
        });
      }
    }, ()=> {});
  }
}
