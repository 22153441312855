export class ILocationGroup {
  id:number = 0;
  primary_location_id:number;
  rx_location_id:number;
  company:string;
  manager_id:number;
  all_locations_managed:number;
  purchasing_contact_user_id:number;
  account_size_id:number;
  pat_id?: string;
  grade_id?: string;
  medicare_id?: string;
  implementation_id?: string;
  authority_to_sign?: boolean;
  public_entity_designation?: boolean;
}
