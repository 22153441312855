import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {Config} from "../../services/Config";
import {IUser} from "../../interfaces/IUser";
import {User} from "../../services/User";

@Component({
  selector: 'location-primary-contact',
  templateUrl: './location-primary-contact.html',
})
export class LocationPrimaryContactCmp {
  @Input() location: ILocation;
  @Input() userID: number;
  @Output() locationChange = new EventEmitter();
  public displayPrimaryContactUser = false;
  public saving = false;
  public displayChangeTheme = false;
  public user: IUser;

  constructor(public locations: Locations, public config: Config, public usersSrv: User) {}

  ngOnChanges() {
    this.user = null;
    if (this.userID) {
      this.usersSrv.getUserInfo(this.userID).then((user)=> {
        if (user) {
          this.user = <IUser>user;
        }
      }, ()=> {});
    }
  }

  setPrimaryContact(force) {
    let themeWillBeChanged = false;
    if (!force && (!this.location.theme_id || this.location.theme_id === this.config.defaultThemeID)) {
      if (this.user && this.user.theme_id && this.user.theme_id != this.config.defaultThemeID) {
        themeWillBeChanged = true;
      }
    }
    if (this.displayPrimaryContactUser || (!this.location.primary_contact_user_id && !themeWillBeChanged)) {
      if (!force && this.location.primary_contact_user_id) {
        return;
      } else {
        this.saving = true;
        let prev = this.location.primary_contact_user_id;
        this.location.primary_contact_user_id = this.userID;
        this.locations.updateLocation(this.location).then(()=> {
          this.saving = false;
          this.displayPrimaryContactUser = false;
          this.displayChangeTheme = false;
          this.locationChange.emit(this.location);
        }, (response)=> {
          this.location.primary_contact_user_id = prev;
          console.error(response);
          this.saving = false;
        });
      }
    } else {
      this.displayPrimaryContactUser = true;
      this.displayChangeTheme = themeWillBeChanged;
    }
  }
}
