import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {IGlobalReport} from "../../interfaces/IGlobalReport";
declare var d3:any;
declare var venn:any;

@Component({
  templateUrl: './reports-global.html',
})
export class ReportsGlobalCmp {
  public report:IGlobalReport = <IGlobalReport>{};
  public loading = true;

  constructor(public reports:Reports, public session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.init();
    });
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    if (!window['d3']) {
      let el = document.createElement('script');
      el.setAttribute('src', 'https://d3js.org/d3.v5.min.js');
      document.body.appendChild(el);
    }
  }

  init() {
    this.reports.getGlobalReport().subscribe((r)=> {
      this.report = r;
      this.loading = false;
      setTimeout(()=> {
        this.setGraphs();
      }, 100);
    }, (err)=> {
      this.loading = false;
      console.error(err);
    });
  }

  ngOnChanges() {
    this.init();
  }

  setGraphs() {
    this.setPendingReviewDistributors();
  }

  setPendingReviewDistributors() {
    if (venn && d3) {
      try {
        var sets = [{sets: ['Benefit Review'], size: this.report.pending_waiting_review},
          {sets: ['Distributor Info'], size: this.report.pending_waiting_distributor},
          {sets: ['Benefit Review', 'Distributor Info'], size: this.report.pending_waiting_review_and_distributor}];

        var chart = venn.VennDiagram();
        d3.select("#pending-review-distributor").datum(sets).call(chart);
      } catch (e) {
        console.error(e);
      }
    }
  }
}
