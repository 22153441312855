import {Component} from '@angular/core';
import {Locations} from "../../services/Locations";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {ILocation} from "../../interfaces/ILocation";
import {UrlParser} from "../../services/UrlParser";
import {ILocDbLinks, ILocLinkedTableRecordField,ILocLinkedTableRecord} from "../../interfaces/LocationsMerge";

@Component({
  selector: 'locations-merge',
  templateUrl: 'locations_merge.html',
})
export class LocationsMergeCmp {
  public mainLocID:number;
  public remLocID:number;
  public loadingMain = false;
  public loadingRem = false;
  public mainLoc:ILocation;
  public remLoc:ILocation;
  public links:ILocDbLinks;
  public mainLocLinks:ILocDbLinks;
  public loadingLinks = false;
  public loadingMainLocLinks = false;
  public merging = false;
  private n = new Notify();

  constructor(private locsSrv:Locations, private session:Session, private urlParser:UrlParser) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.parseUrlParams();
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    this.init(urlParams);
  }

  init(params) {
    if (!this.mainLocID) {
      let m = parseInt(params['main']);
      if (m > 0) {
        this.mainLocID = m;
      }
    }
    if (!this.remLocID) {
      let r = parseInt(params['rem']);
      if (r > 0) {
        this.remLocID = r;
      }
    }
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.loadMainLoc();
    this.loadRemLoc();
    this.loadLinks();
  }

  loadMainLoc() {
    if (this.mainLocID) {
      if (this.mainLoc && this.mainLoc.id == this.mainLocID) {
        return;
      }
      if (this.loadingMain) {
        return;
      }
      this.loadingMain = true;
      this.locsSrv.getLocation(this.mainLocID).then((loc)=> {
        this.loadingMain = false;
        this.mainLoc = loc;
      }, (err)=> {
        this.loadingMain = false;
        this.n.httpErr(err);
      });
    }
  }

  loadRemLoc() {
    if (this.remLocID) {
      if (this.remLoc && this.remLoc.id == this.remLocID) {
        return;
      }
      if (this.loadingRem) {
        return;
      }
      this.loadingRem = true;
      this.locsSrv.getLocation(this.remLocID).then((loc)=> {
        this.loadingRem = false;
        this.remLoc = loc;
      }, (err)=> {
        this.loadingRem = false;
        this.n.httpErr(err);
      });
    }
  }

  loadLinks() {
    if (this.loadingLinks || !this.remLocID) {
      return;
    }
    this.loadingLinks = true;
    this.locsSrv.getLocationDbLinks(this.remLocID).subscribe((links)=> {
      this.loadingLinks = false;
      if (links && links.table_links) {
        this.links = links;
        this.links.table_links.forEach((tl)=> {
          let mt = this.getTableByName(tl.name);
          if (mt) {
            tl['__mt'] = mt;
            if (tl.name == 'location_distributor') {
              let mld = '';
              mt.records.forEach((mtld)=> {
                mtld.fields.forEach((mtld_field)=> {
                  if (mtld_field.name == 'distributor_id') {
                    mld = mld + ',' + mtld_field.value;
                  }
                });
              });
              let rld = '';
              tl.records.forEach((tlld)=> {
                tlld.fields.forEach((tlld_field)=> {
                  if (tlld_field.name == 'distributor_id') {
                    rld = rld + ',' + tlld_field.value;
                  }
                });
              });
              if (mld != '' && mld == rld) {
                tl.records = [];
              }
            }
          }
        });
      } else {
        this.links = null;
      }
    }, (err)=> {
      this.loadingLinks = false;
      this.n.httpErr(err);
    });
  }

  loadMainLocLinks() {
    if (this.loadingMainLocLinks || !this.mainLocID) {
      return;
    }
    this.loadingMainLocLinks = true;
    this.locsSrv.getLocationDbLinks(this.mainLocID).subscribe((links)=> {
      this.loadingMainLocLinks = false;
      if (links && links.table_links) {
        this.mainLocLinks = links;
      } else {
        this.mainLocLinks = null;
      }
    }, (err)=> {
      this.loadingMainLocLinks = false;
      this.n.httpErr(err);
    });
  }

  setMainLocation(loc) {
    if (!loc || !loc.id) {
      return;
    }
    this.mainLoc = loc;
    this.mainLocID = loc.id;
    if (this.remLocID == this.mainLocID) {
      this.remLocID = null;
      this.remLoc = null;
    }
    this.loadMainLocLinks();
  }

  getTableByName(tbl:string) {
    if (!this.mainLocLinks || !this.mainLocLinks.table_links) {
      return false;
    }
    let tables = this.mainLocLinks.table_links;
    for (let i = 0; i < tables.length; i++) {
      if (tables[i].name == tbl) {
        return tables[i];
      }
    }
    return false;
  }

  setRemLocation(loc) {
    if (!loc || !loc.id) {
      return;
    }
    this.remLoc = loc;
    this.remLocID = loc.id;
    if (this.remLocID == this.mainLocID) {
      this.mainLocID = null;
      this.mainLoc = null;
    }
    this.loadRemLoc();
    this.loadLinks();
  }

  mergeLocations() {
    if (!this.links || !this.mainLocID || !this.remLocID) {
      return;
    }
    this.merging = true;
    this.locsSrv.mergeLocations({
      main_location_id: this.mainLocID,
      remove_location_id: this.remLocID,
      links: this.links,
    }).subscribe(()=> {
        this.merging = false;
        this.n.success('Locations merged!');
        this.remLocID = null;
        this.remLoc = null;
      }, (err)=> {
        this.merging = false;
        this.n.httpErr(err);
      }
    );
  }
}
