
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {ISaverLandingDistributors} from "../interfaces/ISaverLandingDistributors";
import {ISaverLandingUser} from "../interfaces/ISaverLandingUser";
import {IVaccinesTable} from "../interfaces/ISaverLandingVaccines";

@Injectable()
export class SaverLanding {
  constructor(private http: HttpClient, private session: Session, private config: Config) {}

  getDistributors(): Observable<ISaverLandingDistributors> {
    return this.http.get<ISaverLandingDistributors>(this.config.apiUrl + '/saver_landing/distributor');
  }

  getVaccines(): Observable<IVaccinesTable> {
    return this.http.get<IVaccinesTable>(this.config.apiUrl + '/saver_landing/vaccines');
  }

  getUsers(tag): Observable<Array<ISaverLandingUser>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<Array<ISaverLandingUser>>(this.config.apiUrl + `/saver_landing/users/${tag}`, {headers: headers});
  }

  getUser(id: number): Observable<ISaverLandingUser> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ISaverLandingUser>(this.config.apiUrl + '/saver_landing/user/' + id, {headers: headers});
  }

  saveUser(user: ISaverLandingUser): Observable<number> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/saver_landing/user', JSON.stringify(user), {headers: headers, responseType: 'text'}).pipe(map(r => parseInt(r)));
  }
}
