// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-pagination {
  clear: both;
  position: relative;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
}

.btn-pagination > *, .btn-pagination .btn-group {
  display: inline-block;
  float: none;
}

.btn-pagination .btn {
  outline-style: none;
}

.page-arrow {
  margin-left: 0.34rem;
  margin-right: 0.34rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhZ2luYXRpb24uY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsV0FBVztFQUNYLGtCQUFrQjtFQUNsQixrQkFBa0I7RUFDbEIscUJBQXFCO0VBQ3JCLGtCQUFrQjtFQUNsQixjQUFjO0FBQ2hCOztBQUVBO0VBQ0UscUJBQXFCO0VBQ3JCLFdBQVc7QUFDYjs7QUFFQTtFQUNFLG1CQUFtQjtBQUNyQjs7QUFFQTtFQUNFLG9CQUFvQjtFQUNwQixxQkFBcUI7QUFDdkIiLCJmaWxlIjoicGFnaW5hdGlvbi5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYnRuLXBhZ2luYXRpb24ge1xuICBjbGVhcjogYm90aDtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIG1hcmdpbi1ib3R0b206IDAuNXJlbTtcbiAgbWFyZ2luLXRvcDogMC41cmVtO1xuICBkaXNwbGF5OiBibG9jaztcbn1cblxuLmJ0bi1wYWdpbmF0aW9uID4gKiwgLmJ0bi1wYWdpbmF0aW9uIC5idG4tZ3JvdXAge1xuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gIGZsb2F0OiBub25lO1xufVxuXG4uYnRuLXBhZ2luYXRpb24gLmJ0biB7XG4gIG91dGxpbmUtc3R5bGU6IG5vbmU7XG59XG5cbi5wYWdlLWFycm93IHtcbiAgbWFyZ2luLWxlZnQ6IDAuMzRyZW07XG4gIG1hcmdpbi1yaWdodDogMC4zNHJlbTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/pagination/pagination.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA,46BAA46B","sourcesContent":[".btn-pagination {\n  clear: both;\n  position: relative;\n  text-align: center;\n  margin-bottom: 0.5rem;\n  margin-top: 0.5rem;\n  display: block;\n}\n\n.btn-pagination > *, .btn-pagination .btn-group {\n  display: inline-block;\n  float: none;\n}\n\n.btn-pagination .btn {\n  outline-style: none;\n}\n\n.page-arrow {\n  margin-left: 0.34rem;\n  margin-right: 0.34rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
