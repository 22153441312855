import {Component, Input} from "@angular/core";
import {IDataChangeRequest, IDcrFilter, IDcrFilterGroup, IDcrRequest, IDcrType} from "../../interfaces/IDataChangeRequest";
import {Session} from "../../services/Session";
import {DataChangeRequests} from "../../services/DataChangeRequests";
import {UrlParser} from "../../services/UrlParser";
import {Helpers} from "../../services/Helpers";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'data-change-requests-list',
  templateUrl: 'dcr_list.html'
})
export class DcrListCmp {
  @Input() visibleFilters: string[];
  @Input() preFilters: IDcrFilterGroup;
  public filterGroups: IDcrFilterGroup[];
  public loading: boolean = false;
  public page = 0;
  public perPage = 20;
  public separatorOr: boolean = false;
  public log: IDataChangeRequest[];
  public query: string;
  public typesList: IDcrType[];
  public typesMap;

  constructor(private session: Session, private dcrSrv: DataChangeRequests, private urlParser: UrlParser, private h: Helpers) {
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
    this.setPage(parseInt(this.urlParser.getParameter('page')));
    this.dcrSrv.getTypes().subscribe((types) => {
      this.typesList = types;
      this.typesMap = this.h.getMapFromList(types);
    });
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    let filterGroups: IDcrFilterGroup[] = this.h.clone(this.filterGroups);
    let separatorOr = this.separatorOr;
    if (this.preFilters && this.preFilters.filters) {
      filterGroups.push(this.preFilters);
      separatorOr = false;
    }
    let req: IDcrRequest = {
      filter_groups: this.filterGroups,
      separator_or: separatorOr,
      offset: this.page * this.perPage,
      limit: this.perPage,
    };
    this.dcrSrv.getLog(req).subscribe((res) => {
      this.loading = false;
      this.log = res.log;
      this.query = res.query;
    }, (err) => {
      this.loading = false;
      let n = new Notify();
      n.httpErr(err);
    });
  }

  setPage($event) {
    let newPage = Math.max(0, parseInt($event));
    this.urlParser.setParameter('page', newPage);
    if (this.page !== newPage) {
      this.page = newPage;
    }
  }
}
