import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Questions} from "../../services/Questions";
import {Session} from "../../services/Session";

@Component({
  selector: 'review-results',
  templateUrl: './review_results.html',
})
export class ReviewResultsCmp {
  @Input() userID;
  public results;
  public loading;

  constructor(public qService:Questions, public session:Session, public params:ActivatedRoute) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      if (params && params['userID']) {
        this.userID = parseInt(params['userID']);
        this.load();
      }
    });
  }

  ngOnChanges() {
    this.load();
  }

  load() {
    if (this.loading) {
      return;
    }
    if (!this.session.user || !this.session.user.id || !this.userID) {
      return;
    }
    this.loading = true;
    this.qService.getReviewResults(this.userID).subscribe((results) => {
        this.results = results;
        this.loading = false;
      }, ()=> {
        this.loading = false;
      }
    );
  }
}
