import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'field',
  template: `<fieldset class="form-group">
        <label *ngIf="label">{{label}}</label>
        <input type="text" [ngModel]="model" (ngModelChange)="updateModel($event)" class="form-control" placeholder="{{placeholder}}" [attr.autofocus]="focus">
      </fieldset>`
})
export class FieldCmp {
  @Input() label = '';
  @Input() placeholder:any = false;
  @Input() model;
  @Input() focus = false;
  @Output('modelChange') update = new EventEmitter();

  ngOnChanges() {
    if (this.placeholder===false) {
      this.placeholder = this.label;
    }
  }

  updateModel(v) {
    this.update.emit(v);
  }
}
