import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Router} from '@angular/router';

@Component({
  selector: 'login',
  templateUrl: './register.html',
})
export class RegisterCmp {
  public Session:Session;
  public http:HttpClient;
  public config:Config;
  public loading;
  public router;
  public error = 0;

  constructor(Session:Session, http:HttpClient, config:Config, router:Router) {
    this.Session = Session;
    this.http = http;
    this.config = config;
    this.router = router;
  }

  clearErrors() {
    this.error = 0;
  }

  register(username:string, password:string) {
    this.loading = true;
    this.error = 0;
    this.http.post(this.config.apiUrl + '/register', JSON.stringify({username: username, password: password}),
      {responseType: 'text', observe: 'response'}).subscribe((response) => {
      this.Session.setToken(response.body);
      this.Session.loadUser();
      this.router.navigate(['']);
      this.loading = false;
    }, (response)=> {
      this.error = response.status;
      this.loading = false;
    });
  }
}
