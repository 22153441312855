import {Component, Input} from '@angular/core';
import {IReviewResultSubQuestion} from "../../interfaces/IReviewResultSubQuestion";

@Component({
  selector: 'answer-select-multiple',
  templateUrl: './answer_select_multiple.html'
})
export class AnswerSelectMultipleCmp {
  @Input() answer:IReviewResultSubQuestion;
}
