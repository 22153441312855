import {Component, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: 'file-column-selector',
  templateUrl: 'selector.html',
})
export class FileColumnSelectorCmp {
  public columnsList;
  public columnsIndexes;
  @Input() columnsMap;
  @Input() label:string;
  @Input() column;
  @Output() columnChange = new EventEmitter();

  ngOnInit() {
    if (this.columnsMap) {
      this.columnsList = Object.values(this.columnsMap);
      this.columnsIndexes = Object.keys(this.columnsMap);
    }
  }

  ngOnChanges() {
    if (this.columnsMap) {
      this.columnsList = Object.values(this.columnsMap);
      this.columnsIndexes = Object.keys(this.columnsMap);
    }
  }

  update($event) {
    if (!$event && $event !== '0' && $event !== 0) {
      this.column = null;
      this.columnChange.emit(null);
    } else {
      let index = parseInt($event);
      if (!index) {
        index = 0;
      }
      this.column = index;
      this.columnChange.emit(index);
    }
  }
}
