import {Component, ViewChild, ElementRef} from '@angular/core';
import {Locations} from "../../services/Locations";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {IRxScreenshot, IRxScreenshotFile} from "../../interfaces/IRx";
import {Helpers} from "../../services/Helpers";

@Component({
  selector: 'rx-screenshots',
  templateUrl: 'rx_screenshots.html',
})
export class RxScreenshotsCmp {
  public loading = false;
  public records:IRxScreenshot[];
  public imgScale = 120;
  public modalImgUrl = '';
  public page = 1;
  public perPage = 20;
  public modalRecord:IRxScreenshot;
  public modalFile:IRxScreenshotFile;
  @ViewChild('modalImg') modalImg:ElementRef;
  private n = new Notify();
  private waitingForReload = false;

  constructor(private locsSrv:Locations, private session:Session, private h:Helpers) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  ngAfterViewInit() {
    window.addEventListener("focus", (event)=> {
      if (this.waitingForReload) {
        this.load();
      }
    }, false);
    window.addEventListener("blur", (event)=> {
      this.waitingForReload = true;
    }, false);
  }

  load() {
    if (this.loading) {
      return false;
    }
    this.waitingForReload = false;
    this.loading = true;
    this.locsSrv.getRxScreenshotsForReview().subscribe((records)=> {
      this.loading = false;
      this.records = records;
    }, (err)=> {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  scale(ch) {
    let c = parseInt(ch);
    this.imgScale += c;
    if (this.imgScale < 1) {
      this.imgScale = 1;
    }
  }

  showImg(r:IRxScreenshot, f:IRxScreenshotFile) {
    if (!r || !f || !f.url || !this.modalImg) {
      return;
    }
    this.h.hideModal(this.modalImg);
    this.modalRecord = r;
    this.modalFile = f;
    this.modalImgUrl = f.url;
    this.h.showModal(this.modalImg);
  }

  hideImg() {
    if (!this.modalImg) {
      return;
    }
    this.h.hideModal(this.modalImg);
  }

  deleteImg(r:IRxScreenshot, f:IRxScreenshotFile) {
    if (f['__deleting']) {
      return;
    }
    f['__deleting'] = true;
    this.locsSrv.deleteRxScreenshot(f.id).subscribe(()=> {
      f['__deleting'] = false;
      this.n.success('Deleted');
      let i = r.files.indexOf(f);
      if (i > -1) {
        r.files.splice(i, 1);
      }
      this.hideImg();
    }, (err)=> {
      f['__deleting'] = false;
      this.n.httpErr(err);
    });
  }

  setReviewed(r:IRxScreenshot) {
    if (r['__reviewing']) {
      return;
    }
    r['__reviewing'] = true;
    this.locsSrv.setRxScreenshotReviewed(r.rx_id).subscribe(()=> {
      r['__reviewing'] = false;
      this.load();
      this.hideImg();
    }, (err)=> {
      r['__reviewing'] = false;
      this.n.httpErr(err);
    });
  }

  setPage(p) {
    this.page = parseInt(p);
    if (this.page < 1) {
      this.page = 1;
    }
  }
}
