import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {ISalesMashupReport} from "../../interfaces/ISalesMashupReport";
import {ISalesMashup} from "../../interfaces/ISalesMashupReport";
declare var Chart:any;

@Component({
  templateUrl: 'report.html',
  selector: 'sales-mashup-report'
})
export class SalesMashupCmp {
  public loading = false;
  public rendering = false;
  public report:ISalesMashupReport;
  public table:any;
  public quarters:string[] = [];
  public onlyMck = false;
  public onlyHs = false;
  @ViewChild('tbl') tbl:ElementRef;
  private n = new Notify();

  constructor(private session:Session, private reports:Reports) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    if (!this.loading && this.session.user && this.session.user.id) {
      this.load();
    }
  }

  load() {
    this.loading = true;
    this.report = null;
    this.reports.getSalesMashupReport().subscribe((r)=> {
      this.loading = false;
      this.report = r;
      this.convertReport();
    }, (err)=> {
      this.loading = false;
      this.n.error(JSON.stringify(err), 'Error');
    });
  }

  convertReport() {
    if (!this.report || !this.report.report) {
      return false;
    }
    this.quarters = [];
    let items:any = {};
    this.table = [];
    this.report.report.forEach((r:ISalesMashup)=> {
      if (this.quarters.indexOf(r.quarter) < 0) {
        this.quarters.push(r.quarter);
      }
      let t;
      if (!items[r.gpo_id]) {
        t = {'gpo_id': r.gpo_id};
        items[r.gpo_id] = t;
      } else {
        t = items[r.gpo_id];
      }
      t[r.quarter] = r;
    });
    this.quarters.sort();
    this.rendering = true;
    setTimeout(()=> {
      for (let i in items) {
        let t = items[i];
        t._empty_mck = true;
        t._empty_hs = true;
        for (let q of this.quarters) {
          if (!t[q]) {
            continue;
          }
          if (t[q].st_mck > 0 || t[q].sf_mck > 0) {
            t._empty_mck = false;
          }
          if (t[q].st_hs > 0 || t[q].sf_hs > 0) {
            t._empty_hs = false;
          }
        }
        if (!(t._empty_mck && t._empty_hs)) {
          this.table.push(t);
        }
      }
      setTimeout(()=> {
        this.rendering = false;
      }, 1);
    }, 60);
  }

  toggle(t) {
    this.rendering = true;
    setTimeout(()=> {
      if (t == 'mck') {
        this.onlyMck = !this.onlyMck;
        this.onlyHs = false;
      } else {
        this.onlyHs = !this.onlyHs;
        this.onlyMck = false;
      }
      setTimeout(()=> {
        this.rendering = false;
      }, 60);
    }, 1);
  }

  exportCsv() {
    if (!this.tbl || !this.tbl.nativeElement) {
      return;
    }
    let csv = '';
    let delimitter = ',';
    for (let i = 0, row; row = this.tbl.nativeElement.rows[i]; i++) {
      let line = '';
      for (let j = 0, col; col = row.cells[j]; j++) {
        line = line + '"' + col.innerText + '"' + delimitter;
        if (col.colSpan > 0) {
          for (let c = 1; c < col.colSpan; c++) {
            line = line + delimitter;
          }
        }
      }
      csv = csv + line.replace(/,(\s+)?$/, '').replace(/;(\s+)?$/, '') + "\n";
    }
    let a = this.getCsvExportLink(csv, 'sales_mashup.csv');
    a.click();
  }

  getCsvExportLink(csv, fileName):HTMLElement {
    // https://stackoverflow.com/a/23922475/680786
    let pom = document.createElement('a');
    var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', fileName);
    return pom;
  }

}
