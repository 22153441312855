import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  templateUrl: './filter-tags-line.html',
  selector: 'filter-tags-line',
  styles: [`
      .filter-badge {
        margin-right: .3rem;
        cursor: pointer;
        font-size: 0.85rem;
      }
    `],
})
export class FilterTagsLineCmp {
  @Input() tags: string[] = [];
  @Input() filterTags: string[] = [];
  @Input() label: string = '';
  @Output('filterTagsChange') filterTagsChange = new EventEmitter();

  toggle(tag) {
    this.handleTagClick(tag);
    this.filterTagsChange.emit(this.filterTags);
  }

  ngOnChanges() {
    if (this.tags && this.tags.length > 1) {
      this.tags.sort();
    }
  }

  handleTagClick(tag) {
    if (!this.filterTags) {
      this.filterTags = [tag];
      return;
    }
    let i = this.filterTags.indexOf(tag);
    if (i < 0) {
      this.filterTags.push(tag);
    } else {
      this.filterTags.splice(i, 1);
    }
  }
}
