import { Component, Input } from '@angular/core';
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {IGroupLifecycleRecord} from "../../interfaces/IGroupLifecycleRecord";
import {ITicket} from "../../interfaces/ITicket";
import {ITask} from "../../interfaces/ITask";
import {IEditRequest} from "../../interfaces/IEditRequest";
import {EditRequests} from "../../services/EditRequests";
import {IHistoryItem} from "../../interfaces/IHistoryItem";
import {ILifecycleRecord} from "../../interfaces/ILifecycleRecord";
import {Utc2EtPipe} from "../../services/Utc2EtPipe";
declare var _;

@Component({
  selector: 'location-history',
  templateUrl: './location-history.html',
  styleUrls: ['./location-history.css']
})
export class LocationHistoryCmp {
  // one of them should be known
  @Input() locGroupID:number;
  @Input() locationID:number;
  @Input() location:ILocation;
  //
  public loading = 0;
  public lcRecords:IGroupLifecycleRecord[];
  public tickets:ITicket[];
  public tasks:ITask[];
  public editRequests:IEditRequest[];
  public items:IHistoryItem[] = [];
  public sorted = false;

  constructor(private locSrv:Locations, private editReqSrv:EditRequests) {
  }

  ngOnChanges() {
    if (this.locGroupID) {
      this.load();
    } else {
      if (this.location && this.location.location_group_id) {
        this.locGroupID = this.location.location_group_id;
        this.load();
      } else {
        if (this.locationID || (this.location && this.location.id)) {
          this.loading = 1;
          this.locSrv.getLocation(this.locationID || this.location.id).then((loc)=> {
            this.location = loc;
            this.locGroupID = loc.location_group_id;
            this.loading = 0;
            this.load();
          }, ()=> {
            this.loading = 0;
          });
        }
      }
    }
  }

  load() {
    this.items = [];
    this.sorted = false;
    this.loadLifecycle();
    this.loadNotes();
    this.loadTasks();
    this.loadEditRequests();
  }

  sortAfterLoad() {
    if (this.loading > 0 || this.sorted) {
      return;
    }
    let utc2et = new Utc2EtPipe();
    this.items = [];
    this.sorted = false;
    if (this.lcRecords && this.lcRecords.length > 0) {
      for (let lcr of this.lcRecords) {
        this.items.push({timestamp: utc2et.transform(lcr.datetime), lcRecord: lcr});
      }
    }
    if (this.tickets && this.tickets.length > 0) {
      for (let t of this.tickets) {
        this.items.push({timestamp: (utc2et.transform(t.last_updated) || t.date_created), ticket: t});
      }
    }
    if (this.tasks && this.tasks.length > 0) {
      for (let task of this.tasks) {
        this.items.push({timestamp: utc2et.transform(task.created_date), task: task});
      }
    }
    if (this.editRequests && this.editRequests.length > 0) {
      for (let req of this.editRequests) {
        this.items.push({timestamp: req.edit_time, editRequest: req});
      }
    }
    if (this.location && this.location.pa_date) {
      this.items.push({timestamp: utc2et.transform(this.location.pa_date), is_pa_date: true});
    }
    this.items = _.orderBy(this.items, ['timestamp'], ['desc']);
    this.sorted = true;
  }

  loadLifecycle() {
    if (!this.locGroupID) {
      return;
    }
    this.loading++;
    this.lcRecords = null;
    this.locSrv.getLocGroupLifecycle(this.locGroupID).subscribe((lgr)=> {
      if (lgr && lgr.records && lgr.records.length > 0) {
        this.lcRecords = lgr.records;
      }
      this.loading--;
      this.sortAfterLoad();
    }, ()=> {
      this.loading--;
      this.sortAfterLoad();
    });
  }

  loadNotes() {
    if (!this.locGroupID) {
      return;
    }
    this.loading++;
    this.tickets = null;
    this.locSrv.getLocGroupTickets(this.locGroupID).subscribe((tickets)=> {
      if (tickets && tickets.length > 0) {
        this.tickets = tickets;
      }
      this.loading--;
      this.sortAfterLoad();
    }, ()=> {
      this.loading--;
      this.sortAfterLoad();
    });
  }

  loadTasks() {
    if (!this.locGroupID) {
      return;
    }
    this.loading++;
    this.tasks = null;
    this.locSrv.getLocationGroupTasks(this.locGroupID).subscribe((tasks)=> {
      if (tasks && tasks.length > 0) {
        this.tasks = tasks;
      }
      this.loading--;
      this.sortAfterLoad();
    }, ()=> {
      this.loading--;
      this.sortAfterLoad();
    });
  }

  loadEditRequests() {
    if (!this.locGroupID) {
      return;
    }
    this.loading++;
    this.editRequests = null;
    this.editReqSrv.getGroupLogRequests(this.locGroupID).subscribe((reqs)=> {
      if (reqs && reqs.length > 0) {
        this.editRequests = reqs;
      }
      this.loading--;
      this.sortAfterLoad();
    }, ()=> {
      this.loading--;
      this.sortAfterLoad();
    });
  }

}
