import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from "rxjs";
import {INewsletter} from "../interfaces/INewsletter";
import {IEmailCampaignData} from "../interfaces/IEmailCampaignData";

export interface IRecommendedContract {
  contract_number?: number;
  vendor_name: string;
  contract_description?: string;
}

export interface IRecommendedContracts {
  contracts: IRecommendedContract[];
}

@Injectable()
export class Newsletter {
  private config:Config;
  private session:Session;
  private http:HttpClient;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  getNewsletterCodeForLocGroup(id:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/newsletter/code/' + id, {headers: headers, responseType: 'text'});
  }

  compileLetterForLocationGroup(id:number):Observable<INewsletter> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post<INewsletter>(this.config.apiUrl + '/newsletter/' + id + '/compile', '', {headers: headers});
  }

  compileLettersForList(ids:string, campaignID):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/newsletter/compile/' + campaignID, ids, {headers: headers, responseType: 'text'});
  }

  compileDistrSurveyLettersForList(ids:string, campaignID):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/newsletter/compile_distr_survey/' + campaignID, ids, {headers: headers, responseType: 'text'});
  }

  getRecommendedContracts(locID:number):Observable<IRecommendedContracts> {
    return this.http.get<IRecommendedContracts>(this.config.apiUrl + '/newsletter/recommendations/' + locID);
  }

  getEmailCampaigns():Observable<IEmailCampaignData[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IEmailCampaignData[]>(this.config.apiUrl + '/email_campaign', {headers: headers});
  }

  updateEmailCampaign(campaign:IEmailCampaignData):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put<INewsletter>(this.config.apiUrl + '/email_campaign', JSON.stringify(campaign), {headers: headers});
  }
}
