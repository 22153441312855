import {Component, EventEmitter, Output, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {AdminQueries} from "../../services/AdminQueries";
import {Notify} from "../../services/Notify";

@Component({
  templateUrl: './commands.html',
  selector: 'admin-commands'
})
export class AdminCommandsCmp {
  public auth = false;
  private n = new Notify();
  public runningGrades = false;
  public runningGradesBtn = false;
  public runnintReportSnapshot = false;
  public resettingRxSession = false;
  public rxSessionCode: string;
  public rxSessionResetResult = '';
  private rxResultMessageTimeout: any;

  constructor(private session: Session, private adminQ: AdminQueries) {
    this.session.addOnLoadHandler(() => {
      this.auth = true;
    });
    this.session.redirectIfNotAuth();
  }

  runGrades() {
    if (!this.auth) {
      return;
    }
    this.runningGrades = true;
    this.runningGradesBtn = true;
    this.adminQ.runGrades().subscribe(() => {
      this.runningGrades = false;
      setTimeout(() => {
        this.runningGradesBtn = false;
      }, 60000);
      this.n.success('Started');
    }, (err) => {
      this.runningGrades = false;
      this.runningGradesBtn = false;
      console.error(err);
      this.n.error(JSON.stringify(err), 'Error');
    });
  }

  snapshotReport() {
    if (!this.auth) {
      return;
    }
    this.runnintReportSnapshot = true;
    this.adminQ.createReportSnapshot("cm_loc_groups").subscribe(() => {
      this.runnintReportSnapshot = false;
      this.n.success('Done');
    }, (err) => {
      this.runnintReportSnapshot = false;
      this.n.error(JSON.stringify(err), 'Error');
    });
  }

  resetRx() {
    if (this.rxSessionCode) {
      this.resettingRxSession = true;
      clearTimeout(this.rxResultMessageTimeout);
      this.adminQ.resetRxSession(this.rxSessionCode).subscribe(() => {
        this.resettingRxSession = false;
        this.rxSessionCode = '';
        this.rxSessionResetResult = "Done";
        this.n.success("Successful!");
        this.rxResultMessageTimeout = setTimeout(() => {this.rxSessionResetResult = '';}, 5000);
      }, (err) => {
        this.resettingRxSession = false;
        if (err.status == 404) {
          this.rxSessionResetResult = "Not Found";
          this.n.error("Not Found");
          this.rxResultMessageTimeout = setTimeout(() => {this.rxSessionResetResult = '';}, 5000);
        } else {
          if (err.status == 412) {
            this.rxSessionResetResult = "Preconditions Failed " + err._body;
            this.n.error("Preconditions Failed", err._body);
            this.rxResultMessageTimeout = setTimeout(() => {this.rxSessionResetResult = '';}, 5000);
          } else {
            this.rxSessionResetResult = "Server Error";
            this.n.error("Server Error");
            this.rxResultMessageTimeout = setTimeout(() => {this.rxSessionResetResult = '';}, 5000);
          }
        }
      });
    }
  }
}
