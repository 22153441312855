import {Pipe} from '@angular/core';
declare var moment:any;

@Pipe({
  name: 'utc2et',
  pure: true,
})
export class Utc2EtPipe {
  transform(dt, onlyDate:boolean = false):any {
    try {
      if (dt === '0000-00-00 00:00:00' || dt === '0000-00-00') {
        return null;
      }
      if (dt == null || dt === '' || dt === 0) {
        return dt;
      }
      let d = new Date(dt);
      if (isNaN(d.getTime())) {
        return dt;
      }
      if (d.getMinutes() == 0 && d.getHours() == 0 && d.getSeconds() == 0) {
        let date_ = d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
        if (onlyDate) {
          return date_;
        }
        let time_ = d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0');
        return date_ + ' ' + time_;
      }
      if (moment && moment.utc) {
        try {
          moment.tz.add('America/New_York|EST EDT|50 40|0101|1Lz50 1zb0 Op0');
          let f = 'YYYY-MM-DD HH:mm:ss';
          if (onlyDate) {
            f = 'YYYY-MM-DD';
          }
          let x = moment.utc(dt).tz("America/New_York").format(f);
          return x;
        } catch (e) {
          console.error(e);
        }
      }
      let curr = new Date();
      let pos = curr.getMonth() * 30 + curr.getDate();
      if (pos > 70 && pos < 303) { //EDT
        d.setHours(d.getHours() - 4);
      } else { //EST
        d.setHours(d.getHours() - 5);
      }
      let date_ = d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
      if (onlyDate) {
        return date_;
      }
      let time_ = d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0');
      return date_ + ' ' + time_;
    } catch (e) {
      return dt;
    }
  }
}
