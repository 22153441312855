import {Component, Input, Output, EventEmitter} from '@angular/core';
import {User, ISignupDomain} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Config} from "../../services/Config";
import {Themes} from "../../services/Themes";
import {ITheme} from "../../interfaces/ITheme";
declare var _:any;

@Component({
  selector: 'create-signup-link',
  templateUrl: './create-signup.html',
})
export class CreateSignupLinkCmp {
  @Input() managerID:number = 0;
  @Input() repID:number = 0;
  @Input() locationID:number = 0;
  @Input() userID:number = 0;
  @Output() linkCreated = new EventEmitter();
  public domainID:number;
  public selectedDomain:ISignupDomain;
  public isOpen = false;
  public user:IUser;
  public loading = false;
  public domains:ISignupDomain[];

  constructor(public userService:User, public config:Config) {
    this.userService.getSignupDomains().subscribe((domains)=> {
      this.domains = domains;
    }, ()=> {});
  }

  setUser(user) {
    this.user = user;
  }

  setRep(rep) {
    this.repID = rep.id;
  }

  setManager(manager) {
    this.managerID = manager.id;
  }

  setDomain($event) {
    let domain = _.find(this.domains, {id: parseInt($event)})
    if (domain) {
      this.selectedDomain = domain;
      this.domainID = domain.id;
    } else {
      this.selectedDomain = null;
    }
  }

  toggleMe() {
    this.isOpen = !this.isOpen;
  }

  showMe() {
    this.isOpen = true;
  }

  hideMe() {
    this.isOpen = false;
  }

  createLink() {
    if (this.user && this.user.id) {
      this.loading = true;
      this.userService.createSignupLink(this.user.id, this.managerID, this.repID, this.locationID, this.selectedDomain.theme_id, this.selectedDomain.__signup_target_id, this.selectedDomain.id).subscribe((code)=> {
        this.linkCreated.emit(this.config.getSignupUrl(code, this.selectedDomain.theme_id, this.selectedDomain.signup_link_template));
      }, ()=> {
        this.loading = false;
      }, ()=> {
        this.loading = false;
        this.hideMe();
      });
    }
  }
}
