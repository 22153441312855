import {Directive} from '@angular/core';

@Directive({
  selector: '[fragment]',
  inputs: ['fragment'],
  host: {
    "(click)": "linkClicked($event)"
  }
})
export class FragmentLinkCmp {
  fragment: string;

  linkClicked(e) {
    e.preventDefault();
    this.scrollToID(this.fragment);
  }

  scrollToID(id) {
    let target = document.getElementById(this.fragment);
    if (target) {
      document.body.scrollTop = target.offsetTop;
    }
  }
}
