import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {share} from 'rxjs/operators';
import {Component, EventEmitter, Output, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Router, ActivatedRoute} from '@angular/router';
import {Locations} from "../../services/Locations";
import {Distributors} from "../../services/Distributors";
import {Specialties} from "../../services/Specialties";
import {User} from "../../services/User";
import {IInactivityReason, ILocation, ILocationExtraData} from "../../interfaces/ILocation";
import {Config} from "../../services/Config";
import {IUser} from "../../interfaces/IUser";
import {Notify} from "../../services/Notify";
import {UrlParser} from "../../services/UrlParser";
import {ILocationGroup} from "../../interfaces/ILocationGroup";
import {Formatters} from "../../services/Formatters";
import {Themes} from "../../services/Themes";
import {ITheme} from "../../interfaces/ITheme";
import {IDistributor} from "../../interfaces/IDistributor";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {IDeaSearchResult} from "../../interfaces/IDeaSearchResult";
import {IItemsToDelete} from "../../interfaces/IItemsToDelete";
import {ArrayJoinNotEmptyPipe} from "../../services/ArrayJoinNotEmptyPipe";
import {Newsletter} from "../../services/Newsletter";
import {IFile} from "../../interfaces/IFile";
import {ILocationReps} from "../../interfaces/ILocationReps";
import {Helpers} from "../../services/Helpers";

declare var $: any;
declare var ClipboardJS: any;

@Component({
  selector: 'location',
  templateUrl: './location.html',
})
export class LocationCmp {
  @Output() update = new EventEmitter();
  @ViewChild('copyLocsInfoBtn') copyLocsInfoBtn: ElementRef;
  @ViewChild('inactivityModal') inactivityModal: ElementRef;

  public id: number;
  public location: ILocation = <ILocation>{};
  public loading: boolean = false;
  public distributorsMap = {};
  public specialtiesMap = {};
  public distributorsList: IDistributor[] = [];
  public specialtiesList: ISpecialty[] = [];
  public editMode = false;
  public copy: string;
  public saving = false;
  public tab = 1;
  public related = [];
  public reps: ILocationReps = {};
  public claiming = false;
  public contact;
  public campaignsList = [];
  public campaigns = [];
  public signupLinks = [];
  public reviewing = false;
  public loadingLinks = false;
  public newLocation: ILocation;
  public mc_date: string;
  public mc_saving: boolean = false;
  public mc_moving: number = 0;
  public newUser: IUser;
  public expandDeletion = false;
  public preDeletePreloading = false;
  public preDeleteLoaded = false;
  public deletion = false;
  public delItems: IItemsToDelete = <IItemsToDelete>{};
  public delFilesNewLocation: ILocation;
  public purchasing_contact_user: IUser;
  public rep_user: IUser;
  public unclaimDialog = false;
  public unclaiming = false;
  public managers = [];
  public themes: { [id: number]: ITheme; } = {};
  public deaNewData: IDeaSearchResult;
  public displayAddLC = false;
  public selectCopy = false;
  public locsInfoLoading = 1;
  public savingNewsletter = false;
  public newsletterCode = '';
  public tabOpened = {};
  public trackedCsCount = 0;
  public expandCard = false;
  public tasksCount = 0;
  public tab15Expanded = false;
  public exportAllRelatedCsvUrl = '';
  public locationNotFound: boolean = false;
  public inactivityReasons: IInactivityReason[] = [];
  public extraData: ILocationExtraData;

  constructor(public session: Session, public params: ActivatedRoute, public router: Router,
    public locations: Locations, public distributors: Distributors, public urlParser: UrlParser,
    public specialties: Specialties, public users: User, public config: Config,
    public formatters: Formatters, public themesSrv: Themes, private arrJoin: ArrayJoinNotEmptyPipe,
    public newsletter: Newsletter, private h: Helpers) {
    this.distributors.loadToMap(this.distributorsMap);
    this.distributors.loadToList(this.distributorsList);
    this.specialties.loadToMap(this.specialtiesMap);
    this.specialties.loadToList(this.specialtiesList);
    this.themesSrv.getThemes().subscribe((themes) => {
      if (themes && themes.length > 0) {
        themes.forEach((t) => {
          this.themes[t.id] = t;
        });
      }
    }, () => {});
    this.locations.getInactivityReasons().subscribe((l) => {this.inactivityReasons = l;}, () => {})
  }

  init(params) {
    this.reset();
    this._setTabValue(1);
    this.campaignsList = [];
    this.id = parseInt(params['id']);
    if (!this.id) {
      this.router.navigate(['']);
      return;
    }
    let token = this.session.getToken();
    this.exportAllRelatedCsvUrl = this.config.apiUrl + `/locations_related/${this.id}/csv/` + token;
    this.parseUrlParams();
    this.session.redirectIfNotAuth();
    this.loadLocation();
    this.users.getClientManagers().subscribe(managers => this.managers = managers, () => {});
  }

  reset() {
    this.location = <ILocation>{};
    this.editMode = false;
    this.copy = '';
    this.saving = false;
    this.tab = 1;
    this.related = [];
    this.reps = {};
    this.claiming = false;
    this.campaignsList = [];
    this.campaigns = [];
    this.signupLinks = [];
    this.reviewing = false;
    this.loadingLinks = false;
    this.mc_date = null;
    this.mc_saving = false;
    this.mc_moving = 0;
    this.newUser = null;
    this.expandDeletion = false;
    this.preDeletePreloading = false;
    this.preDeleteLoaded = false;
    this.deletion = false;
    this.delItems = <IItemsToDelete>{};
    this.delFilesNewLocation = null;
    this.purchasing_contact_user = null;
    this.rep_user = null;
    this.unclaimDialog = false;
    this.unclaiming = false;
    this.managers = [];
    this.deaNewData = null;
    this.displayAddLC = false;
    this.selectCopy = false;
    this.locsInfoLoading = 1;
    this.savingNewsletter = false;
    this.newsletterCode = '';
    this.tabOpened = {};
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  ngOnDestroy() {
    this.reset();
  }

  loadLocation() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.locations.getLocation(this.id, true).then((location: ILocation) => {
      if (location) {
        this.locationNotFound = false;
        this.location = location;
        this.update.emit(location);
        if (this.location.__type_title !== 'Member') {
          this.loadSignupLinks();
        }
        if (this.location.__purchasing_contact_user_id) {
          this.users.getUserInfo(this.location.__purchasing_contact_user_id).then((info) => {
            this.purchasing_contact_user = <IUser>info;
          }, () => {});
        }
        if (this.location && this.location.__rep_user_id) {
          this.loadRepUser();
        }
        if (location.location_group_id) {
          this.newsletter.getNewsletterCodeForLocGroup(location.location_group_id).subscribe((code) => {
            this.newsletterCode = code;
          }, () => {});
        }
        this.loadExtraData();
      } else {
        this.locationNotFound = true;
      }
      this.loading = false;
    }, () => {
      this.loading = false;
      this.locationNotFound = true;
    });
    this.locations.getRelatedLocations(this.id).then((related) => {
      this.related = <[any]>related;
      this.initCopyLocsInfoBtn();
    }, () => {});
    this.locations.getReps(this.id).then((reps) => {
      this.reps = reps;
    }, () => {});
  }

  loadExtraData() {
    if (this.location && this.location.id) {
      this.locations.getExtraData(this.location.id, 'inactive').subscribe((data) => {
        this.extraData = data;
      }, () => {});
    }
  }

  switchEditMode() {
    if (!this.editMode) {
      this.copy = JSON.stringify(this.location);
    } else {
      if (this.copy) {
        this.location = JSON.parse(this.copy);
      }
    }
    this.editMode = !this.editMode;
  }

  save() {
    if (this.location && this.location.id) {
      this.saving = true;
      this.locations.updateLocation(this.location).then(() => {
        this.saving = false;
        this.copy = null;
        this.editMode = false;
        this.update.emit(this.location);
        if (this.location.__rep_user_id) {
          this.loadRepUser();
        }
      }, (error) => {
        let notify = new Notify();
        notify.error('Error');
        this.saving = false;
        console.error(error);
      });
    }
  }

  claim() {
    if (!this.id) {
      return;
    }
    this.claiming = true;
    this.locations.claim(this.id).then(() => {
      this.claiming = false;
      this.location.__manager_id = this.session.user.id;
      this.location.__manager_fname = this.session.user.fname;
      this.location.__manager_lname = this.session.user.lname;
    }, () => {this.claiming = false;});
  }

  claimBy(id) {
    if (!id || !this.id) {
      return;
    }
    this.claiming = true;
    let lg = <ILocationGroup>{};
    lg.id = this.location.location_group_id;
    lg.manager_id = id;
    this.locations.saveGroup(lg).subscribe(() => {
      this.claiming = false;
      this.loadLocation();
    }, () => {
      this.claiming = false;
    });
  }

  askUnclaim() {
    this.unclaimDialog = !this.unclaimDialog;
  }

  unclaim() {
    if (!this.location || !this.location.location_group_id) {
      return;
    }
    this.unclaiming = true;
    let lg = new ILocationGroup();
    lg.id = this.location.location_group_id;
    lg.manager_id = 0;
    this.unclaimDialog = false;
    this.locations.saveGroup(lg).subscribe(() => {
      this.location.__manager_id = 0;
      this.loadLocation();
      this.unclaiming = false;
    }, (err) => {
      this.unclaiming = false;
      let n = new Notify();
      n.error('Error');
      console.error(err);
    });
  }

  addCampaign(campaign) {
    this.campaigns.push(campaign);
  }

  newSignupLink(link) {
    this.loadSignupLinks();
  }

  checkRequiredFieldForMembership() {
    let checked = true;
    try {
      let n = new Notify();
      let s = 'Field is required:';
      if (!this.location.__manager_id) {
        checked = false;
        n.error('Location should be claimed by a Client Manager', 'Should be claimed');
      }
      if (!this.location.facility_name) {
        checked = false;
        n.error('Facility Name', s);
      }
      if (!this.location.address) {
        checked = false;
        n.error('Address', s);
      }
      if (!this.location.city) {
        checked = false;
        n.error('City', s);
      }
      if (!this.location.state) {
        checked = false;
        n.error('State', s);
      }
      if (!this.location.zip) {
        checked = false;
        n.error('Zip', s);
      }
      if (!this.location.phone) {
        checked = false;
        n.error('Primary Phone Number', s);
      }
      if (!this.location.class_of_trade_internal_primary_id) {
        checked = false;
        n.error('Class of Trade Int, Primary', s);
      }
      if (!this.location.class_of_trade_internal_sub_id) {
        checked = false;
        n.error('Class of Trade Int., Sub', s);
      }
      if (!this.location.tax_status_id) {
        checked = false;
        n.error('Tax Status', s);
      }
      if (typeof this.location.zimmer_specialty_id === "undefined" || this.location.zimmer_specialty_id === null) {
        checked = false;
        n.error('HT Specialty', s);
      }
      if (!this.location.yearly_spend_type_id) {
        checked = false;
        n.error('Yearly Spend', s);
      }
      if (!this.location.__purchasing_contact_user_id) {
        checked = false;
        n.error('Purchasing Contact', s);
      }
      if (!this.location.is_roster_checked) {
        checked = false;
        n.error('Roster Check field should be checked');
      }
      if (!this.purchasing_contact_user) {
        checked = false;
        if (!this.loading) {
          this.loadLocation();
        }
        n.warning('Please wait - loading Purchasing Contact information');
      } else {
        if (!this.purchasing_contact_user.fname) {
          checked = false;
          n.error('Purchasing Contact First Name', s);
        }
        if (!this.purchasing_contact_user.lname) {
          checked = false;
          n.error('Purchasing Contact Last Name', s);
        }
        if (!this.purchasing_contact_user.phone) {
          checked = false;
          n.error('Purchasing Contact Phone', s);
        }
        if (!this.purchasing_contact_user.email) {
          checked = false;
          n.error('Purchasing Contact Email', s);
        }
      }
    } catch (e) {
      console.error(e);
    }
    return checked;
  }

  checkFormatsForMembership(): Observable<any> {
    let n = new Notify();
    let stateCheck = this.formatters.getFormattedState(this.location.state).pipe(share());
    stateCheck.subscribe(state => this.location.state = state, () => {
      n.error('State is in wrong format');
    });
    let zipCheck = this.formatters.getFormattedZip(this.location.zip).pipe(share());
    zipCheck.subscribe(zip => this.location.zip = zip, () => {
      n.error('Zip is in wrong format');
    });
    return observableForkJoin([
      stateCheck, zipCheck
    ]);
  }

  reviewByManager() {
    if (this.session.user && this.session.user.cm_access_level >= 20) {
      if (!this.checkRequiredFieldForMembership()) {
        return;
      }
      this.reviewing = true;
      this.locations.reviewByManager(this.location.location_group_id).subscribe(() => {
        this.location.__reviewed_by_manager_id = this.session.user.id;
        this.reviewing = false;
      }, () => {
        this.reviewing = false;
      });
    }
  }

  reviewByUser() {
    if (this.session.user && this.session.user.cm_access_level >= 15) {
      if (!this.checkRequiredFieldForMembership()) {
        return;
      }
      this.reviewing = true;
      this.checkFormatsForMembership().subscribe(() => {
        this.locations.reviewByUser(this.location.location_group_id).subscribe(() => {
          this.location.__reviewed_by_user_id = this.session.user.id;
          this.reviewing = false;
        }, () => {
          this.reviewing = false;
        });
      }, () => {
        this.reviewing = false;
      });
    }
  }

  loadSignupLinks() {
    this.loadingLinks = true;
    this.locations.getSignupLinks(this.id).subscribe(
      (links) => {
        this.signupLinks = links;
        this.loadingLinks = false;
      },
      () => {this.loadingLinks = false;});
  }

  addLocation() {
    this.newLocation = <ILocation>{location_group_id: this.location.location_group_id};
    try {
      let l: ILocation;
      if (this.location.__group_primary_location_id === this.location.id) {
        l = this.location;
      } else {
        if (this.related && this.related.length > 0) {
          for (let r of this.related) {
            if (r.id === this.location.__group_primary_location_id) {
              l = r;
              break;
            }
          }
        }
      }
      if (l) {
        this.newLocation.facility_name = l.facility_name;
        this.newLocation.specialty_id = l.specialty_id;
        this.newLocation.zimmer_specialty_id = l.zimmer_specialty_id;
        this.newLocation.website = l.website;
        this.newLocation.doctors_count = l.doctors_count;
        this.newLocation.yearly_spend_type_id = l.yearly_spend_type_id;
        this.newLocation.tax_status_id = l.tax_status_id;
        this.newLocation.pa_date = l.pa_date;
        this.newLocation.theme_id = l.theme_id;
        this.newLocation.sub_theme_id = l.sub_theme_id;
        this.newLocation.group_name = l.group_name;
        this.newLocation.division_name = l.division_name;
        this.newLocation.market_name = l.market_name;
        this.newLocation.primary_contact_user_id = l.primary_contact_user_id;
        this.newLocation['___parent_location_id'] = this.location.id;
      }
    } catch (e) {}
  }

  cancelNewLocationSave() {
    this.newLocation = null;
  }

  locationCreated() {
    this.newLocation = null;
    this.loadLocation();
  }

  addContact() {
    this.newUser = <IUser>{};
    this.newUser.location_id = this.location.id;
  }

  cancelNewUserSave() {
    this.newUser = null;
  }

  userCreated() {
    if (this.newUser.id && !this.location.primary_contact_user_id) {
      this.location.primary_contact_user_id = this.newUser.id;
      this.save();
    }
    this.newUser = null;
    this.loadLocation();
  }

  moveToCustomer() {
    if (this.location && this.location.id && this.location.__manager_id && this.mc_date) {
      this.location.pa_date = this.mc_date + ' 00:00:00';
      this.mc_saving = true;
      this.locations.updateLocation(this.location).then(() => {
        this.mc_saving = false;
        this.mc_moving = 0;
      }, () => {
        this.mc_saving = false;
        this.mc_moving = 0;
      });
    }
  }

  toggleOnHold() {
    if (this.location && this.location.id) {
      this.location.is_on_hold = (this.location.is_on_hold > 0) ? 0 : 1;
      this.saving = true;
      this.locations.updateLocation(this.location).then(() => {
        this.saving = false;
      }, () => {
        this.saving = false;
      });
    }
  }

  toggleInactive() {
    if (this.location && this.location.id) {
      let status_inactive = (this.location.is_inactive > 0) ? 0 : 1;
      if (status_inactive === 1) {
        if (!this.extraData) {
          this.extraData = {
            inactive: {
              location_id: this.location.id,
              inactivity_reason_id: 0,
              comment: '',
              date_inactivated: null
            }
          };
        } else {
          this.extraData.inactive = {
            location_id: this.location.id,
            inactivity_reason_id: 0,
            comment: '',
            date_inactivated: null
          };
        }
        this.h.showModal(this.inactivityModal);
      } else {
        this.location.is_inactive = 0;
        this.saving = true;
        this.locations.updateLocation(this.location).then(() => {
          this.saving = false;
          this.loadLocation();
        }, () => {
          this.saving = false;
        });
      }
    }
  }

  toggleIneligible() {
    if (this.location && this.location.id) {
      this.location.is_ineligible = (this.location.is_ineligible > 0) ? 0 : 1;
      this.saving = true;
      this.locations.updateLocation(this.location).then(() => {
        this.saving = false;
        this.loadLocation();
      }, () => {
        this.saving = false;
      });
    }
  }

  editInactiveData() {
    if (!this.extraData) {
      this.extraData = {
        inactive: {
          location_id: this.location.id,
          inactivity_reason_id: 0,
          comment: '',
          date_inactivated: null
        }
      };
    } else {
      if (!this.extraData.inactive) {
        this.extraData.inactive = {
          location_id: this.location.id,
          inactivity_reason_id: 0,
          comment: '',
          date_inactivated: null
        };
      }
    }
    this.h.showModal(this.inactivityModal);
  }

  completeInactiveDialog(markInactive) {
    this.h.hideModal(this.inactivityModal);
    if (markInactive) {
      this.location.is_inactive = 1;
      this.saving = true;
      this.locations.updateLocation(this.location).then(() => {
        this.saving = false;
        this.locations.writeExtraData(this.location.id, this.extraData).subscribe(() => {
          this.loadLocation();
        }, () => {});
      }, () => {
        this.saving = false;
      });
    }
  }

  preloadDeletion() {
    if (this.expandDeletion) {
      return this.cancelDeletion();
    }
    this.expandDeletion = true;
    this.preDeletePreloading = true;
    this.locations.getPreDeleteInfo(this.id).subscribe(
      (response) => {
        this.preDeletePreloading = false;
        this.preDeleteLoaded = true;
        if (response) {
          this.delItems = response;
        }
      },
      (response) => {
        try {
          let notify = new Notify();
          notify.error('Error');
        } catch (e) {}
        console.error(response);
        this.preDeletePreloading = false;
      });
  }

  moveFilesOnDeletion() {
    if (this.delFilesNewLocation && this.delFilesNewLocation.id && this.delFilesNewLocation.id != this.location.id) {
      if (this.delItems && this.delItems.files && this.delItems.files.length) {
        this.delItems.files.forEach((f: IFile) => {
          f.location_id = this.delFilesNewLocation.id;
          this.locations.updateFile(f).subscribe(() => {}, () => {});
        });
      }
    }
  }

  deleteLocation() {
    if (!this.expandDeletion || !this.id) {
      return;
    }
    this.deletion = true;
    this.moveFilesOnDeletion();
    this.locations.deleteLocation(this.id).subscribe(() => {
      this.deletion = false;
      this.delItems = <IItemsToDelete>{};
      this.delFilesNewLocation = null;
      try {
        let notify = new Notify();
        notify.success('Deleted');
      } catch (e) {}
      this.router.navigate(['']);
    }, (response) => {
      this.deletion = false;
      try {
        let notify = new Notify();
        notify.error('Error');
      } catch (e) {}
      console.error(response);
    });
  }

  deleteLocationWithoutRels() {
    if (!this.expandDeletion || !this.id) {
      return;
    }
    this.deletion = true;
    this.moveFilesOnDeletion();
    this.locations.deleteLocationWithoutRels(this.id).subscribe(() => {
      this.deletion = false;
      this.delItems = <IItemsToDelete>{};
      this.delFilesNewLocation = null;
      try {
        let notify = new Notify();
        notify.success('Deleted');
      } catch (e) {}
      this.router.navigate(['']);
    }, (response) => {
      this.deletion = false;
      try {
        let notify = new Notify();
        notify.error('Error');
      } catch (e) {}
      console.error(response);
    });
  }

  cancelDeletion() {
    this.expandDeletion = false;
    this.preDeletePreloading = false;
    this.preDeleteLoaded = false;
    this.deletion = false;
    this.delItems = <IItemsToDelete>{};
    this.delFilesNewLocation = null;
  }

  setTab(i) {
    this._setTabValue(parseInt(i));
    this.urlParser.setParameter('tab', this.tab);
    if (this.tab == 2) {
      this.initCopyLocsInfoBtn();
    }
  }

  _setTabValue(i: number) {
    if (i < 1 || i > 99) {
      i = 1;
    }
    this.tab = i;
    this.tabOpened[i.toString()] = true;
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.tab) {
      this._setTabValue(parseInt(urlParams.tab));
    }
  }

  loadRepUser() {
    if (this.location && this.location.__rep_user_id) {
      this.users.getUserInfo(this.location.__rep_user_id).then(u => this.rep_user = <IUser>u, () => {});
    }
  }

  setDeaData(data?: IDeaSearchResult) {
    if (data) {
      this.deaNewData = data;
      $('#dea_data').modal('show');
      return;
    } else {
      if (!this.deaNewData) {
        $('#dea_data').modal('hide');
        return;
      } else {
        if (this.location && this.location.id) {
          if (this.deaNewData.dea) {
            this.location.dea = this.deaNewData.dea;
          }
          if (this.deaNewData.company) {
            this.location.dea_name = this.deaNewData.company;
          }
          this.location.dea_address = this.getNewDeaAddressLine(this.deaNewData, this.location.dea_address);
          this.saving = true;
          this.locations.updateLocation(this.location).then(() => {
            this.saving = false;
            this.deaNewData = null;
            $('#dea_data').modal('hide');
            this.update.emit(this.location);
          }, (error) => {
            let notify = new Notify();
            notify.error('Error');
            this.saving = false;
            console.error(error);
          });
        }
      }
    }
  }

  getNewDeaAddressLine(data: IDeaSearchResult, oldAddress?: string) {
    let address = oldAddress || '';
    if (data.address1 || data.address2 || data.address3 || (data.address4 || data.state || data.zip)) {
      address = [data.address1, data.address2, data.address3].join(' ');
      if (data.address4 || data.state || data.zip) {
        address = [address.trim(), data.address4, data.state, data.zip].join(', ');
      }
    }
    return address;
  }

  lcAdded() {
    this.displayAddLC = false;
    this.update.emit();
  }

  initCopyLocsInfoBtn() {
    setTimeout(() => {
      if (!this.related || this.related.length < 1) {
        return;
      }
      try {
        if (this.copyLocsInfoBtn && ClipboardJS) {
          let clipboard = new ClipboardJS(this.copyLocsInfoBtn.nativeElement);
          this.locsInfoLoading = this.related.length;
          let info = '';
          this.related.forEach((loc: ILocation) => {
            if (loc.is_inactive || loc.is_on_hold) {
              this.locsInfoLoading--;
              return;
            }
            if (loc.primary_contact_user_id) {
              this.users.getUserInfo(loc.primary_contact_user_id).then((c) => {
                info += "\n\n" + this.getInfoForCopy(loc, c);
                this.locsInfoLoading--;
                this.copyLocsInfoBtn.nativeElement.setAttribute('data-clipboard-text', info.trim());
              }, () => {
                info += "\n\n" + this.getInfoForCopy(loc);
                this.locsInfoLoading--;
                this.copyLocsInfoBtn.nativeElement.setAttribute('data-clipboard-text', info.trim());
              });
            } else {
              info += "\n\n" + this.getInfoForCopy(loc);
              this.locsInfoLoading--;
            }
          });
          this.copyLocsInfoBtn.nativeElement.setAttribute('data-clipboard-text', info.trim());
          clipboard.on('success', (e) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clipboard.on('error', () => {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
        }
      } catch (e) {}
    }, 60);
  }

  copyLocsInfo($event) {
    $event.stopPropagation();
    setTimeout(() => {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e) => {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(() => {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }

  getInfoForCopy(f: ILocation, contact?: IUser): string {
    let info = `Legal Name: ${f.facility_name}`;
    if (f.dba) {
      info += `\nDBA name: ${f.dba}`;
    }
    if (f.address || f.address2 || f.city || f.state || f.zip) {
      info += "\nAddress: " + this.arrJoin.transform([f.address, f.address2, f.city, f.state, f.zip]);
    }
    if (f.zimmer_id) {
      info += `\nGPOID: ${f.zimmer_id}`;
    }
    if (f.zimmer_co_id) {
      info += `\nCOID: ${f.zimmer_co_id}`;
    }
    if (contact) {
      let c = contact;
      info += `\nContact name: ${c.fname} ${c.lname}\nContact Email: ${c.email}`;
      if (c.phone) {
        info += `\nContact Phone #: ${c.phone}`;
      }
    }
    return info;
  }

  saveNewsletter() {
    if (!this.location || !this.location.location_group_id) {
      return;
    }
    this.savingNewsletter = true;
    this.newsletterCode = '';
    let n = new Notify();
    this.newsletter.compileLetterForLocationGroup(this.location.location_group_id).subscribe((letter) => {
      this.savingNewsletter = false;
      if (letter && letter.code) {
        this.newsletterCode = letter.code;
      }
      n.success('Saved');
    }, () => {
      this.savingNewsletter = false;
      n.error('Error', 'Not saved');
    });
  }

  setAsRxLocation() {
    if (this.location && this.location.id && this.location.location_group_id) {
      let lg = new ILocationGroup();
      lg.id = this.location.location_group_id;
      lg.rx_location_id = this.location.id;
      this.saving = true;
      this.locations.saveGroup(lg).subscribe(() => {
        this.saving = false;
        this.location.__group_rx_location_id = this.location.id;
        this.update.emit(this.location);
      }, (response) => {
        this.saving = false;
        let notify = new Notify();
        notify.httpErr(response);
        this.update.emit(this.location);
      });
    }
  }

  setAsPrimaryLocation() {
    if (this.location && this.location.id && this.location.location_group_id && this.location.id != this.location.__group_primary_location_id) {
      let lg = new ILocationGroup();
      lg.id = this.location.location_group_id;
      lg.primary_location_id = this.location.id;
      this.saving = true;
      this.locations.saveGroup(lg).subscribe(() => {
        this.saving = false;
        this.location.__group_primary_location_id = this.location.id;
        this.update.emit(this.location);
      }, (response) => {
        this.saving = false;
        let notify = new Notify();
        notify.httpErr(response);
        this.update.emit(this.location);
      });
    }
  }
}
