import {Component} from "@angular/core";
import {Reports, ISummaryTablesUpdateDates} from "../../services/Reports";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'summary-tables',
  templateUrl: 'summary_tables.html'
})
export class SummaryTablesCmp {
  public dates:ISummaryTablesUpdateDates = {sales_fee: '', sales_tracing: ''};
  public sending = false;
  public lastSalesFeeDate = '';
  public lastSalesTracingDate = '';
  public lastFetchedSalesFeeDate = '';
  public lastFetchedSalesTracingDate = '';
  private n = new Notify();

  constructor(private reports:Reports, private session:Session) {
    this.session.addOnLoadHandler(()=> {this.load();});
    this.session.redirectIfNotAuth();
  }

  load() {
    this.getDates();
  }

  getDates() {
    this.reports.getSummaryTablesUpdateDates().subscribe((dates)=> {
      this.dates = dates;
      if (dates.sales_fee && dates.sales_fee != this.lastFetchedSalesFeeDate) {
        this.lastSalesFeeDate = dates.sales_fee;
        this.lastFetchedSalesFeeDate = dates.sales_fee;
      }
      if (dates.sales_tracing && dates.sales_tracing != this.lastFetchedSalesTracingDate) {
        this.lastSalesTracingDate = dates.sales_tracing;
        this.lastFetchedSalesTracingDate = dates.sales_tracing;
      }
      setTimeout(()=> {this.getDates();}, 2000);
    }, ()=> {});
  }

  updateTables() {
    if (this.sending) {
      return;
    }
    this.sending = true;
    this.lastSalesFeeDate = '';
    this.lastSalesTracingDate = '';
    this.reports.updateSummaryTables().subscribe(()=> {
      this.sending = false;
      this.n.success("Update started");
    }, ()=> {
      this.sending = false;
    });
  }
}
