// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-holder {
  position: relative;
  display: inline-block;
  width: 3.3em;
  margin-left: -.7em;
  margin-right: .2em;
}

.badge-holder .badge {
  width: 100%;
}

.dropdown-menu {
  overflow: hidden;
}

.member-icon-holder {
  min-width: 1.5em;
  min-height: 1rem;
  display: inline-block;
}

.member-icon-holder > .material-icons {
  color: rgb(31, 169, 131);
  font-size: 1em;
  left: -.5em;
  top: .2em;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRhZy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQkFBa0I7RUFDbEIscUJBQXFCO0VBQ3JCLFlBQVk7RUFDWixrQkFBa0I7RUFDbEIsa0JBQWtCO0FBQ3BCOztBQUVBO0VBQ0UsV0FBVztBQUNiOztBQUVBO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUVBO0VBQ0UsZ0JBQWdCO0VBQ2hCLGdCQUFnQjtFQUNoQixxQkFBcUI7QUFDdkI7O0FBRUE7RUFDRSx3QkFBd0I7RUFDeEIsY0FBYztFQUNkLFdBQVc7RUFDWCxTQUFTO0FBQ1giLCJmaWxlIjoidGFnLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5iYWRnZS1ob2xkZXIge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgd2lkdGg6IDMuM2VtO1xuICBtYXJnaW4tbGVmdDogLS43ZW07XG4gIG1hcmdpbi1yaWdodDogLjJlbTtcbn1cblxuLmJhZGdlLWhvbGRlciAuYmFkZ2Uge1xuICB3aWR0aDogMTAwJTtcbn1cblxuLmRyb3Bkb3duLW1lbnUge1xuICBvdmVyZmxvdzogaGlkZGVuO1xufVxuXG4ubWVtYmVyLWljb24taG9sZGVyIHtcbiAgbWluLXdpZHRoOiAxLjVlbTtcbiAgbWluLWhlaWdodDogMXJlbTtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xufVxuXG4ubWVtYmVyLWljb24taG9sZGVyID4gLm1hdGVyaWFsLWljb25zIHtcbiAgY29sb3I6IHJnYigzMSwgMTY5LCAxMzEpO1xuICBmb250LXNpemU6IDFlbTtcbiAgbGVmdDogLS41ZW07XG4gIHRvcDogLjJlbTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/header-search-box/tag.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,cAAc;EACd,WAAW;EACX,SAAS;AACX;;AAEA,4iCAA4iC","sourcesContent":[".badge-holder {\n  position: relative;\n  display: inline-block;\n  width: 3.3em;\n  margin-left: -.7em;\n  margin-right: .2em;\n}\n\n.badge-holder .badge {\n  width: 100%;\n}\n\n.dropdown-menu {\n  overflow: hidden;\n}\n\n.member-icon-holder {\n  min-width: 1.5em;\n  min-height: 1rem;\n  display: inline-block;\n}\n\n.member-icon-holder > .material-icons {\n  color: rgb(31, 169, 131);\n  font-size: 1em;\n  left: -.5em;\n  top: .2em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
