import {Component, Input} from '@angular/core';
import {IParticipationAgreement, ISignupAnswer} from "../../interfaces/ISignup";
import {Locations} from "../../services/Locations";
import {UrlParser} from "../../services/UrlParser";

interface ISignupInfoEditObject {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  job_title: string;
  facility_name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

interface ISignupInfoEditParsed {
  special_value?: string;
  before_edit?: ISignupInfoEditObject[];
  after_edit?: ISignupInfoEditObject[];
  datetime: string;
}

@Component({
  selector: 'location-signup-log',
  templateUrl: 'location_signup_log.html',
})
export class LocationSignupLogCmp {
  @Input() locationID;
  public answers: ISignupAnswer[] = [];
  public edits: ISignupInfoEditParsed[] = [];
  public paRecords: IParticipationAgreement[] = [];
  public loading = false;
  public tab = 1;

  constructor(private locs: Locations, private urlParser: UrlParser) {}

  ngOnInit() {
    this.parseUrlParams();
    this.loadData();
  }

  ngOnChanges() {
    this.loadData();
  }

  loadData() {
    if (this.loading || !this.locationID) {
      return;
    }
    this.loading = true;
    this.answers = [];
    this.edits = [];
    this.locs.getLocationSignupDetails(this.locationID).subscribe((response) => {
      this.loading = false;
      if (response && response.answers) {
        this.answers = response.answers;
      }
      if (response && response.info_edits) {
        for (let e of response.info_edits) {
          if (e.before_edit === 'pre-filled') {
            let x = JSON.parse(e.after_edit);
            if (x && x['facility']) {
              x['facility_name'] = x['facility'];
            }
            if (x && x['jobtitle']) {
              x['job_title'] = x['jobtitle'];
            }
            this.edits.push({special_value: 'Pre-filled Data', datetime: e.datetime, after_edit: x});
          } else {
            this.edits.push({before_edit: JSON.parse(e.before_edit), datetime: e.datetime, after_edit: JSON.parse(e.after_edit)});
          }
        }
      }
      if (response && response.pa_records) {
        this.paRecords = response.pa_records;
      }
    }, () => {
      this.loading = false;
    });
  }

  setTab(i) {
    this._setTabValue(parseInt(i));
    this.urlParser.setParameter('subtab', this.tab);
  }

  _setTabValue(i: number) {
    if (i < 1 || i > 99) {
      i = 1;
    }
    this.tab = i;
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.subtab) {
      this._setTabValue(parseInt(urlParams.subtab));
    }
  }
}
