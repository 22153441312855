import {Pipe} from '@angular/core';

// http://stackoverflow.com/a/2901298/680786
function numberWithCommas(x) {
  if (!x) {
    return 0;
  }
  //let s = (Math.round(x*100)/100).toFixed(2).toString();
  //if (s.indexOf(".00")>-1) {
  //  s = (Math.round(x*100)/100).toString();
  //}
  let s = Math.round(x).toString();
  return s.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
@Pipe({
  name: 'numcomm'
})
export class NumbersCommaSeparatePipe {
  transform(list, args?: string[]): any {
    return numberWithCommas(list);
  }
}
