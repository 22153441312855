import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Implementations} from "../../services/Implementations";
import {IImplementationVendor} from "../../interfaces/IImplementationVendor";
import {ILocationImplementationVendor} from "../../interfaces/ILocationImplementationVendor";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
declare var _:any;

@Component({
  selector: 'location-impl-vendors',
  templateUrl: './vendors.html',
})
export class LocationImplVendorsCmp {
  @Input() locationID;
  @Output() update = new EventEmitter();
  public vendors:Array<ILocationImplementationVendor>;
  public allVendors:Array<IImplementationVendor>;
  public vendorsMap = {};
  public saving = false;
  public loading = false;

  constructor(public implSrv:Implementations, public session:Session) {}

  ngOnInit() {
    if (this.locationID && !this.vendors) {
      this.loadVendors();
    }
  }

  ngOnChanges() {
    this.loadVendors();
  }

  loadVendors() {
    let locVendors;
    this.loading = true;
    let merge = ()=> {
      this.loading = false;
      if (!this.allVendors || !locVendors) {
        return;
      }
      this.vendors = [];
      this.allVendors.forEach((v:IImplementationVendor)=> {
        this.vendorsMap[v.id] = v;
        let vToPush:ILocationImplementationVendor = null;
        locVendors.forEach((lv:ILocationImplementationVendor)=> {
          if (lv.impl_vendor_id == v.id) {
            if (!vToPush) {
              vToPush = lv;
            } else {
              for (let f in lv) {
                if (lv.hasOwnProperty(f) && lv[f]) {
                  if (!vToPush.hasOwnProperty(f) || !vToPush[f]) {
                    vToPush[f] = lv[f];
                  }
                }
              }
            }
          }
        });
        if (vToPush) {
          this.vendors.push(vToPush);
        } else {
          let nv = new ILocationImplementationVendor();
          nv.location_id = this.locationID;
          nv.impl_vendor_id = v.id;
          if (this.session && this.session.user && this.session.user.id) {
            nv.user_id = this.session.user.id;
          }
          this.vendors.push(nv);
        }
      });
    };
    if (!this.allVendors) {
      this.implSrv.getVendors().subscribe((vendors)=> {
        this.allVendors = vendors;
        merge();
      }, (response) => {
        this.loading = false;
        console.error(response);
      });
    }
    this.implSrv.getLocationVendors(this.locationID).subscribe((vendors)=> {
      locVendors = vendors;
      merge();
    }, (response) => {
      console.error(response);
    });
  }

  saveVendors() {
    if (!this.vendors || !this.vendors.length) {
      return;
    }
    this.saving = true;
    this.implSrv.setLocationVendors(this.vendors).subscribe(()=> {
      this.saving = false;
      this.update.emit(true);
      let notify = new Notify();
      notify.success('Saved');
    }, ()=> {
      this.saving = false;
      let notify = new Notify();
      notify.error('Error');
    });
  }

  changeDate(date:Date, vendor:ILocationImplementationVendor, field?:string) {
    let f = field || 'date_connected';
    vendor[f] = date.toISOString().substring(0, 19).replace('T',' ');
    this.saveVendors();
  }
}
