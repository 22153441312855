import {Component} from '@angular/core';
import {Reports, IReqContractDiff, IContractDifference} from "../../services/Reports";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";

@Component({
  selector: "contract-difference",
  templateUrl: "contract-difference.html"
})
export class ContractDifferenceCmp {
  public req:IReqContractDiff;
  public csvURL = '';
  public loading = false;
  public gpoids;
  private n = new Notify();

  constructor(private session:Session, private reports:Reports) {
    this.req = this.getEmptyReq();
    this.session.redirectIfNotAuth();
  }

  getToken() {
    if (this.loading) {
      return;
    }
    if (!this.req.contract_number) {
      this.n.warning('Contract Number is required');
      return;
    }
    this.loading = true;
    this.csvURL = '';
    this.gpoids = null;
    this.reports.getContractDifference(this.req).subscribe((response)=> {
      this.loading = false;
      this.gpoids = response.gpoids;
      this.csvURL = this.reports.getContractDifferenceCsvUrl(response.req_token);
    }, (err)=> {
      this.loading = false;
      this.n.error('Error', err._body);
    });
  }

  getEmptyReq():IReqContractDiff {
    return {
      contract_number: null, currently_active: true, include_non_ate: false,
      sales_date_from: null, sales_date_to: null, hpg_date_from: null, hpg_date_to: null,
      membership_date_after: null, membership_date_before: null
    };
  }

  reset() {
    if (this.loading) {
      return;
    }
    this.req = this.getEmptyReq();
    this.csvURL = '';
    this.gpoids = null;
  }
}
