import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {IVendorReport} from "../../../interfaces/IVendor";

@Component({
  templateUrl: './report.html',
  selector: 'vendor-quarter-report'
})
export class VendorQuarterReportCmp {
  @Input() report:IVendorReport[];
  @ViewChild('tbl') tbl:ElementRef;
  public orderField;
  public sortDesc;
  public csFiltered = [];
  public cFiltered = [];
  public uniq = Math.random() * 99999999;

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  exportCsv() {
    if (!this.tbl || !this.tbl.nativeElement) {
      return;
    }
    let csv = '';
    let delimitter = ',';
    for (let i = 0, row; row = this.tbl.nativeElement.rows[i]; i++) {
      let line = '';
      for (let j = 0, col; col = row.cells[j]; j++) {
        line = line + '"' + col.innerText.replace('drag_handle', '') + '"' + delimitter;
        if (col.colSpan > 0) {
          for (let c = 1; c < col.colSpan; c++) {
            line = line + delimitter;
          }
        }
      }
      csv = csv + line.replace(/,(\s+)?$/, '').replace(/;(\s+)?$/, '') + "\n";
    }
    let a = this.getCsvExportLink(csv, 'vendor_quarter_report.csv');
    a.click();
  }

  getCsvExportLink(csv, fileName):HTMLElement {
    // https://stackoverflow.com/a/23922475/680786
    let pom = document.createElement('a');
    var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', fileName);
    return pom;
  }
}
