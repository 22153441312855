import {Component, ViewChild, ElementRef} from "@angular/core";
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {Locations} from "../../services/Locations";
import {Helpers} from "../../services/Helpers";
import {UrlParser} from "../../services/UrlParser";
import {IDistrImportPreCheckData} from "../../interfaces/IDistributor";
import {IUser} from "../../interfaces/IUser";
import {Config} from "../../services/Config";

@Component({
  selector: 'hs-file-upload',
  templateUrl: 'hs_import.html',
})
export class HsFileUploadCmp {
  @ViewChild('addRepModal') addRepModal: ElementRef;
  @ViewChild('selectRepModal') selectRepModal: ElementRef;
  public newFileData: File;
  public newFileName: string;
  public uploadingFile: boolean = false;
  public fileUploadedSuccessfully = false;
  public fileUploadError = false;
  public totalLines: number;
  public parsedLinesCount: number;
  public gpoIdEmptyCount: number;
  public gpoIdNotFoundCount: number;
  public errorMessage: string;
  public csvLines;
  public emptyGpoLines: number;
  public emptyGpoLinesPercent: number;
  public firstNRows = 16;
  public offset = 0;
  public datetime;
  public preCheckData: IDistrImportPreCheckData;
  public nonAllowedComments: string[];
  public nonUniqueRepNames: string[];
  public nonExistingRepNames: string[];
  public newRep: IUser;
  private allCsvLines;
  private csvHeadersLine: string[];
  private repNameIndex = 2;
  private repEmailIndex = 3;
  private selectRepForName;

  constructor(private session: Session, private locations: Locations, private h: Helpers, private urlParser: UrlParser, public config: Config) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.parseUrlParams();
    this.locations.getDistrImportPreCheckData(2).subscribe((data) => {
      if (data) {
        this.preCheckData = data;
      }
    });
  }

  parseUrlParams() {
    let params = this.urlParser.getParameters();
    if (params['c']) {
      this.checkStatus(params['c']);
    }
  }

  addFile(files) {
    if (!files || !files[0]) {
      return;
    }
    this.resetFileUpload();
    this.newFileName = files[0].name;
    this.newFileData = files[0];
    this.parseCsv(files[0], (s) => {
      let lines = this.h.csvToArray(s, {
        'fSep': ',',
        'rSep': '\n',
        'quot': '"',
        'head': false
      });
      let removedLines = lines.splice(0, 1);
      if (removedLines && removedLines[0]) {
        this.csvHeadersLine = removedLines[0];
      }
      this.emptyGpoLines = 0;
      this.emptyGpoLinesPercent = 0;
      this.totalLines = lines.length;
      if (this.totalLines < 0) {
        this.totalLines = null;
      } else {
        for (let i = 1; i < lines.length; i++) {
          let l = lines[i];
          if (l.length && l.length > 1) {
            if (!l[9]) {
              this.emptyGpoLines++;
            }
            if (this.preCheckData) {
              let c = l[0].trim().toLowerCase();
              if (c && c.length > 0 && this.preCheckData.reasons && this.preCheckData.reasons.indexOf(c) < 0) {
                if (!this.nonAllowedComments) {
                  this.nonAllowedComments = [c];
                } else {
                  if (this.nonAllowedComments.indexOf(c) < 0) {
                    this.nonAllowedComments.push(c);
                  }
                }
              }

              let rep_email = (l[this.repEmailIndex] || '').trim();
              if (!rep_email || rep_email.indexOf('@') < 0) {
                let rep_name_origin = l[this.repNameIndex];
                let rep_name = l[this.repNameIndex].trim().toLowerCase();
                if (rep_name) {
                  if (this.preCheckData.non_unique_rep_names && this.preCheckData.non_unique_rep_names.length) {
                    for (let nun of this.preCheckData.non_unique_rep_names) {
                      if (nun.trim().toLowerCase() == rep_name) {
                        if (!this.nonUniqueRepNames) {
                          this.nonUniqueRepNames = [rep_name_origin];
                        } else {
                          if (this.nonUniqueRepNames.indexOf(rep_name_origin) < 0) {
                            this.nonUniqueRepNames.push(rep_name_origin);
                          }
                        }
                        break;
                      }
                    }
                  }

                  if (this.preCheckData.existing_rep_names && this.preCheckData.existing_rep_names.length) {
                    let found = false;
                    for (let ern of this.preCheckData.existing_rep_names) {
                      let n1 = [ern.fname.trim(), ern.lname.trim()].join(' ').trim().toLowerCase();
                      let n2 = [ern.lname.trim(), ern.fname.trim()].join(' ').trim().toLowerCase();
                      if (n1 == rep_name || n2 == rep_name) {
                        found = true;
                        break;
                      }
                    }
                    if (!found) {
                      if (!this.nonExistingRepNames) {
                        this.nonExistingRepNames = [rep_name_origin];
                      } else {
                        if (this.nonExistingRepNames.indexOf(rep_name_origin) < 0) {
                          this.nonExistingRepNames.push(rep_name_origin);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (this.emptyGpoLines > 0) {
          this.emptyGpoLinesPercent = Math.round((this.emptyGpoLines * 100) / this.totalLines);
        }
      }
      this.allCsvLines = lines;
      this.renderPreview();
    });
  }

  scroll(n, event?) {
    this.offset = this.offset + parseInt(n);
    if (this.offset < 0) {
      this.offset = 0;
    }
    this.renderPreview();
    if (event) {
      this.h.preventBubbling(event);
    }
    return false;
  }

  renderPreview() {
    this.csvLines = this.allCsvLines.slice(this.offset, this.firstNRows + this.offset);
  }

  parseCsv(csv: File, cb) {
    const reader = new FileReader();
    reader.onload = ($event) => {
      if ($event.target && $event.target['result']) {
        cb($event.target['result']);
      }
    };
    reader.readAsText(csv);
  }

  resetFileUpload() {
    this.newFileData = null;
    this.newFileName = null;
    this.fileUploadedSuccessfully = false;
    this.fileUploadError = false;
    this.totalLines = null;
    this.parsedLinesCount = null;
    this.gpoIdEmptyCount = null;
    this.gpoIdNotFoundCount = null;
    this.errorMessage = null;
    this.csvLines = null;
    this.emptyGpoLines = null;
    this.emptyGpoLinesPercent = null;
    this.urlParser.setParameter('c', null);
    this.nonAllowedComments = null;
    this.nonUniqueRepNames = null;
    this.nonExistingRepNames = null;
  }

  uploadFile() {
    if (!this.newFileData || !this.newFileName) {
      return;
    }
    this.uploadingFile = true;
    this.locations.uploadHsFile(this.newFileName, this.newFileData, this.datetime).subscribe((cache_id) => {
      if (cache_id) {
        this.urlParser.setParameter('c', cache_id);
        setTimeout(() => {
          this.checkStatus(cache_id);
        }, 400);
      } else {
        this.uploadingFile = false;
        let n = new Notify();
        n.error('Error');
        this.errorMessage = 'Cache ID is missing';
        this.fileUploadError = true;
        this.fileUploadedSuccessfully = false;
      }
    }, (err) => {
      this.uploadingFile = false;
      let n = new Notify();
      n.error('Error');
      console.error(err);
      this.fileUploadedSuccessfully = false;
      this.fileUploadError = true;
    });
  }

  checkStatus(cache_id) {
    this.locations.getHsFileParsingStatus(cache_id).subscribe((status) => {
      if (status) {
        this.uploadingFile = true;
        if (!status.finished) {
          setTimeout(() => {
            this.checkStatus(cache_id);
          }, 300);
        } else {
          this.uploadingFile = false;
        }
        this.parsedLinesCount = status.lines_parsed;
        this.gpoIdEmptyCount = status.gpo_id_empty;
        this.gpoIdNotFoundCount = status.gpo_id_not_found;
        if (status.error) {
          this.errorMessage = status.error;
        }
        if (status.finished) {
          if (!status.completed) {
            this.fileUploadedSuccessfully = false;
            this.fileUploadError = true;
          } else {
            let n = new Notify();
            n.success('Successfully!');
            this.fileUploadedSuccessfully = true;
            this.fileUploadError = false;
          }
        }
      } else {
        this.uploadingFile = false;
        let n = new Notify();
        n.error('Error');
        this.fileUploadedSuccessfully = false;
        this.fileUploadError = true;
        this.errorMessage = 'Status response is empty';
      }
    }, (err) => {
      this.uploadingFile = false;
      let n = new Notify();
      n.httpErr(err);
      this.fileUploadedSuccessfully = false;
      this.fileUploadError = true;
      this.errorMessage = 'Cache ID is wrong';
    });
  }

  setDate(dt) {
    if (dt && dt.toISOString) {
      this.datetime = dt.toISOString().slice(0, 10);
    }
  }

  setRepForName(rep: IUser, name: string) {
    if (rep && this.allCsvLines && this.allCsvLines.length) {
      let modified = false;
      for (let i in this.allCsvLines) {
        let l = this.allCsvLines[i];
        if (l[this.repNameIndex] == name) {
          if (rep.fname || rep.lname) {
            l[this.repNameIndex] = [rep.fname, rep.lname].join(' ');
            modified = true;
          }
          if (rep.email) {
            l[this.repEmailIndex] = rep.email;
            modified = true;
          }
          this.allCsvLines[i] = l;
        }
      }
      if (modified) {
        this.rebuildCsvFile();
        this.renderPreview();
      }
    }
  }

  addRep(fromName) {
    this.h.hideModal(this.addRepModal);
    this.newRep = <IUser>{};
    if (fromName) {
      this.newRep['___from_name'] = fromName;
      let n = fromName.split(' ');
      if (n && n[0]) {
        this.newRep.fname = n[0];
      }
      if (n && n[1]) {
        this.newRep.lname = n[1];
      }
    }
    this.newRep.type_id = this.config.userTypeRep;
    this.h.showModal(this.addRepModal);
  }

  cancelNewRepSave() {
    this.h.hideModal(this.addRepModal);
    this.newRep = null;
  }

  repCreated(rep: IUser) {
    this.h.hideModal(this.addRepModal);
    if (this.newRep && this.newRep['___from_name']) {
      this.setRepForName(rep, this.newRep['___from_name']);
      let i = this.nonExistingRepNames.indexOf(this.newRep['___from_name']);
      this.nonExistingRepNames.splice(i, 1);
    }
    this.newRep = null;
  }

  openSelectRep(forName) {
    this.selectRepForName = forName;
    this.h.showModal(this.selectRepModal);
  }

  cancelRepSelect() {
    this.h.hideModal(this.selectRepModal);
    this.selectRepForName = null;
  }

  setSelectedRep(rep: IUser) {
    this.h.hideModal(this.selectRepModal);
    if (this.selectRepForName) {
      this.setRepForName(rep, this.selectRepForName);
      let i = this.nonExistingRepNames.indexOf(this.selectRepForName);
      this.nonExistingRepNames.splice(i, 1);
    }
    this.selectRepForName = null;
  }

  skipRepName(name: string) {
    if (!name) {
      return;
    }
    if (this.allCsvLines && this.allCsvLines.length) {
      for (let i in this.allCsvLines) {
        let l = this.allCsvLines[i];
        if (l[this.repNameIndex] == name) {
          l[this.repNameIndex] = '';
          this.allCsvLines[i] = l;
        }
      }
      this.rebuildCsvFile();
      this.renderPreview();
    }
    let i = this.nonExistingRepNames.indexOf(name);
    this.nonExistingRepNames.splice(i, 1);
  }

  rebuildCsvFile() {
    this.newFileData = this.h.arrayToCsvFile(this.allCsvLines, this.newFileName, this.csvHeadersLine);
  }
}
