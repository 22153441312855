import {Component, Input} from '@angular/core';

export interface IRebateChartRecord {
  allocation_cycle_month: string;
  fee_amount: number;
}

export interface IRebateChart {
  query: string;
  records: IRebateChartRecord[];
}

@Component({
  templateUrl: 'rebate_chart.html',
  selector: 'rebate-chart',
})
export class ReportRebateChartCmp {
  @Input('report') report:IRebateChart;
  public sortDesc = false;
  public orderField:string = '';

  ngOnInit() {
    if (window['google'] && window['google'].charts) {
      window['google'].charts.load('current', {packages: ['corechart', 'line']});
      window['google'].charts.setOnLoadCallback(()=> {
        this.drawChart();
      });
    }
  }

  drawChart() {
    let google = window['google'];
    var data = new google.visualization.DataTable();
    data.addColumn('date', 'X');
    data.addColumn('number', 'Fees');
    let rows = [];
    this.report.records.forEach((r)=> {
      rows.push([new Date(r.allocation_cycle_month), r.fee_amount]);
    });
    data.addRows(rows);

    var options = {
      hAxis: {
        title: 'Time'
      },
      vAxis: {
        title: 'Fees',
        format: 'currency'
      },
      backgroundColor: '#fff'
    };

    var chart = new google.visualization.LineChart(document.getElementById('rebate_chart'));
    chart.draw(data, options);
  }
}
