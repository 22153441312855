import {Component, ViewChild, ElementRef} from "@angular/core";
import {Session} from "../../services/Session";
import {CostStudy} from "../../services/CostStudy";
declare var $:any;

@Component({
  selector: 'cs-manufacturers',
  templateUrl: 'manufacturers.html',
})
export class CsManufacturersCmp {
  @ViewChild('changeGroupModal') changeGroupModal:ElementRef;
  @ViewChild('editGroupModal') editGroupModal:ElementRef;
  @ViewChild('targetGroup') targetGroup:ElementRef;

  public manufacturers = [];
  public searchTerm:string;
  private lastSearchTerm:string;
  public searching = false;
  public ch;
  public newGroup:string;
  public moving = false;
  public edGroup;
  public updating = false;
  private groups:string[];

  constructor(private session:Session, private cs:CostStudy) {
    this.session.redirectIfNotAuth();
    this.cs.getManufacturerGroups().subscribe((groups)=> {
      this.groups = groups;
    }, ()=> {});
  }

  search() {
    if (!this.searchTerm) {
      return;
    }
    if (this.searching) {
      return;
    }
    this.lastSearchTerm = this.searchTerm;
    this.reloadSearch(this.searchTerm);
  }

  reloadSearch(term) {
    if (!term) {
      return;
    }
    this.searching = true;
    this.manufacturers = [];
    this.cs.searchManufacturers(term).subscribe((r)=> {
      this.searching = false;
      if (r && r.manufacturers) {
        this.manufacturers = r.manufacturers;
      }
    }, ()=> {
      this.searching = false;
    });
  }

  showChangeGroupModal(m) {
    if (!m) { return;}
    this.ch = m;
    this.setGroupsAutoSuggest();
    if (this.changeGroupModal && this.changeGroupModal.nativeElement) {
      $(this.changeGroupModal.nativeElement).modal('show');
    }
  }

  hideChangeGroupModal() {
    if (this.changeGroupModal && this.changeGroupModal.nativeElement) {
      $(this.changeGroupModal.nativeElement).modal('hide');
    }
  }

  showEditGroupModal(groupLabel) {
    if (!groupLabel) {
      return;
    }
    this.edGroup = {label: groupLabel};
    this.cs.getManufacturerGroupInfo(groupLabel).subscribe((i)=> {
      this.edGroup = i;
    }, ()=> {});
    if (this.editGroupModal && this.editGroupModal.nativeElement) {
      $(this.editGroupModal.nativeElement).modal('show');
    }
  }

  hideEditGroupModal() {
    if (this.editGroupModal && this.editGroupModal.nativeElement) {
      $(this.editGroupModal.nativeElement).modal('hide');
    }
  }

  changeGroup() {
    if (!this.ch || !this.ch.id || !this.newGroup || this.moving) {
      return;
    }
    if (this.targetGroup && this.targetGroup.nativeElement) {
      try {
        this.newGroup = $(this.targetGroup.nativeElement).typeahead('val');
      } catch (e) {}
    }
    if (!this.newGroup) {
      return;
    }
    this.moving = true;
    this.cs.changeManufacturerGroup(this.ch.id, this.newGroup).subscribe(()=> {
      this.moving = false;
      this.hideChangeGroupModal();
      this.reloadSearch(this.lastSearchTerm);
    }, () => {
      this.moving = false;
    });
  }

  updateGroup() {
    if (!this.edGroup || this.updating) {
      return;
    }
    this.updating = true;
    this.cs.updateManufacturerGroup(this.edGroup).subscribe(()=> {
      this.updating = false;
      this.hideEditGroupModal();
      this.reloadSearch(this.lastSearchTerm);
    }, ()=> {
      this.updating = false;
    });
  }

  setGroupsAutoSuggest() {
    setTimeout(()=> {
      if (this.groups && this.groups.length && this.targetGroup && this.targetGroup.nativeElement) {
        try {
          let substringMatcher = function (strs) {
            return function findMatches(q, cb) {
              let substringRegex;
              let matches = [];
              let substrRegex = new RegExp(q, 'i');
              $.each(strs, function (i, str) {
                if (substrRegex.test(str)) {
                  matches.push(str);
                }
              });
              cb(matches);
            };
          };
          $(this.targetGroup.nativeElement).typeahead({
            highlight: true,
            classNames: {
              menu: 'dropdown-menu',
            }
          }, {name: 'groups', source: substringMatcher(this.groups)});
        } catch (e) {
          console.error(e);
        }
      } else {
        console.warn('Can not prepare typeahead')
      }
    }, 1);
  }
}
