import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Newsletter} from "../../services/Newsletter";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'newsletter-distr-survey-compile',
  templateUrl: 'form.html',
})
export class NewsletterDistrSurveyCompileCmp {
  public ids: string = '';
  public campaignID: number = 29;
  public sending = false;
  public n = new Notify();

  constructor(private session: Session, private srv: Newsletter) {
    this.session.redirectIfNotAuth();
  }

  send() {
    if (!this.ids || this.ids.length < 1) {
      this.n.warning("Please input comma-separated list");
      return;
    }
    this.sending = true;
    this.srv.compileDistrSurveyLettersForList(this.ids, this.campaignID).subscribe((c) => {
      this.ids = '';
      this.sending = false;
      this.n.success("Compiled: " + c);
    }, (err) => {
      this.sending = false;
      this.n.error(JSON.stringify(err), "Error");
    });
  }
}
