import {startWith, map, debounceTime} from 'rxjs/operators';
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {ILocationContact} from "../../interfaces/ILocationContact";

@Component({
  selector: 'user-select',
  templateUrl: './user-select.html',
})
export class UserSelectCmp {
  @Input() id;
  @Input() typeID:number;
  @Input() listHandler;
  @Input() inputTextHandler;
  @Input() context;
  @Input() preload:ILocationContact;
  @Output() userSelect = new EventEmitter();
  public users;
  public isOpen = false;
  public searchInput:UntypedFormControl;
  public loading = false;
  public limit = 10;
  public searchGroup = new UntypedFormGroup({
    searchInput: new UntypedFormControl()
  });

  constructor(public userService:User) {
    this.searchInput = <UntypedFormControl>this.searchGroup.controls['searchInput'];
    this.searchInput.valueChanges.pipe(debounceTime(200), map((val) => {
      return val.toLowerCase();
    }), startWith('')).subscribe((val) => {
      this.search(val);
    }, () => {});
  }

  ngOnChanges() {
    this.id = parseInt(this.id);
    if (this.id > 0) {
      if (this.preload && this.preload.id == this.id) {
        let user = this.preload;
        if (this.listHandler && (typeof this.listHandler === 'function')) {
          this.listHandler([user], this.context);
        }
        this.selectUser(<IUser>{id: user.id, fname: user.fname, lname: user.lname, type_id: user.type_id, email: user.email, phone: user.phone, is_email_suppressed: user.is_email_suppressed});
      } else {
        this.userService.getUserInfo(this.id).then((user:IUser) => {
          if (this.listHandler && (typeof this.listHandler === 'function')) {
            this.listHandler([user], this.context);
          }
          this.selectUser(user);
        }, () => {});
      }
    } else {
      this.reset();
    }
  }

  reset() {
    this.id = 0;
    this.searchInput.setValue('', {emitEvent: false});
    this.isOpen = false;
  }

  search(searchTerm) {
    if (searchTerm && searchTerm.length > 0) {
      this.loading = true;
      this.users = [];
      this.showDropdown();
      let searchLimit = this.limit;
      if (this.listHandler && (typeof this.listHandler === 'function')) {
        searchLimit = 4000;
      }
      this.userService.getUsersList(searchTerm, 0, searchLimit, this.typeID).subscribe((users) => {
        if (this.listHandler && (typeof this.listHandler === 'function')) {
          this.listHandler(users, this.context);
          if (users.length > this.limit) {
            users = users.slice(0, this.limit);
          }
        }
        this.users = users;
        this.loading = false;
      }, () => {
        this.loading = false;
        if (this.users.length < 1) {
          this.hideDropdown();
        }
      });
    } else {
      this.hideDropdown();
    }
  }

  selectUser(user:IUser) {
    this.searchInput.setValue(this.getUserDisplayedName(user), {emitEvent: false});
    this.hideDropdown();
    this.id = user.id;
    this.userSelect.emit(user);
  }

  getUserDisplayedName(user:IUser):string {
    let v = user.fname + ' ' + user.lname;
    if (this.inputTextHandler && (typeof this.inputTextHandler === 'function')) {
      let vx = this.inputTextHandler(user, this.context);
      if (vx && vx !== '') {
        v = vx;
      }
    }
    return v;
  }

  showDropdown() {
    if (!this.isOpen) {
      this.isOpen = true;
    }
  }

  hideDropdown() {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }
}
