import {Component, Input, ViewChild, ElementRef, EventEmitter} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {IMembersReportRecord} from "../../interfaces/IMembersReport";
import {IPopupQuery} from "../../interfaces/IPopupQuery";
declare var Chart:any;

@Component({
  templateUrl: 'report.html',
  selector: 'members-report'
})
export class MembersReportCmp {
  @ViewChild('chart') chart:ElementRef;
  @ViewChild('chart1') chart1:ElementRef;

  public loading = false;
  public csv = '';
  public report:IMembersReportRecord[];
  public tab = 1;
  public queries:IPopupQuery[] = [];
  private n = new Notify();

  constructor(private session:Session, private reports:Reports) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    if (!this.loading && this.session.user && this.session.user.id) {
      this.load();
    }
  }

  load() {
    this.loading = true;
    this.report = null;
    this.reports.getMembersReport().subscribe((r)=> {
      this.loading = false;
      this.report = r.report;
      this.queries = [{query: r.query, name: 'Query'}];
      setTimeout(()=> {
        this.drawChartByCompany();
        this.drawChartByGroup();
      }, 200);
    }, (err)=> {
      this.loading = false;
      this.n.error(JSON.stringify(err), 'Error');
    });
  }

  setTab(t) {
    this.tab = t;
  }

  public labelsCompany = [];
  public setsCompany = [];

  drawChartByCompany() {
    if (this.report) {
      let datasetAte = {label: 'ATE members', data: [], color: '#8E24AA', hidden: false, column: 0};
      let datasetAteC = {label: 'ATE Cumulative', data: [], color: '#4A148C', hidden: true, column: 0};
      let datasetAdvt = {label: 'AdvT Members', data: [], color: '#1E88E5', hidden: true, column: 1};
      let datasetAdvtC = {label: 'AdvT Cumulative', data: [], color: '#0D47A1', hidden: true, column: 1};
      let datasetNonAcute = {label: 'All Non-Acute Members', data: [], color: '#00897B', hidden: true, column: 2};
      let datasetNonAcuteC = {label: 'All Non-Acute Cumulative', data: [], color: '#004D40', hidden: true, column: 2};
      this.labelsCompany = [];
      this.report.forEach((r)=> {
        this.labelsCompany.push(r.month);
        datasetAte.data.push(r.ate_members);
        datasetAteC.data.push(r.ate_cumulative);
        datasetAdvt.data.push(r.advt_members);
        datasetAdvtC.data.push(r.advt_cumulative);
        datasetNonAcute.data.push(r.non_acute_members);
        datasetNonAcuteC.data.push(r.non_acute_cumulative);
      });
      this.setsCompany = [datasetAte, datasetAteC, datasetAdvt, datasetAdvtC, datasetNonAcute, datasetNonAcuteC];
    }
  }

  public labelsGroup = [];
  public setsGroup = [];

  drawChartByGroup() {
    if (this.report) {
      this.setsGroup = [];
      let datasetAte = {label: 'ATE members', data: [], color: '#8E24AA', hidden: true, column: 0};
      let datasetAteC = {label: 'ATE Cumulative', data: [], color: '#4A148C', hidden: true, column: 0};
      let datasetAmp = {label: 'AMP members', data: [], color: '#1E88E5', hidden: false, column: 1};
      let datasetAmpC = {label: 'AMP Cumulative', data: [], color: '#0D47A1', hidden: true, column: 1};
      let datasetCap = {label: 'CAP Members', data: [], color: '#00897B', hidden: false, column: 2};
      let datasetCapC = {label: 'CAP Cumulative', data: [], color: '#004D40', hidden: true, column: 2};
      let datasetMgma = {label: 'MGMA Members', data: [], color: '#FB8C00', hidden: false, column: 3};
      let datasetMgmaC = {label: 'MGMA Cumulative', data: [], color: '#E65100', hidden: true, column: 3};
      this.labelsGroup = [];
      this.report.forEach((r)=> {
        this.labelsGroup.push(r.month);
        datasetAte.data.push(r.ate_members);
        datasetAteC.data.push(r.ate_cumulative);
        datasetAmp.data.push(r.amp_members);
        datasetAmpC.data.push(r.amp_cumulative);
        datasetCap.data.push(r.cap_members);
        datasetCapC.data.push(r.cap_cumulative);
        datasetMgma.data.push(r.mgma_members);
        datasetMgmaC.data.push(r.mgma_cumulative);
      });
      this.setsGroup = [datasetAte, datasetAteC, datasetAmp, datasetAmpC, datasetCap, datasetCapC, datasetMgma, datasetMgmaC];
    }
  }
}
