import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {IContractChart} from "../../interfaces/IContractChart";
import {IPopupQuery} from "../../interfaces/IPopupQuery";
import {IContractChartReport} from "../../interfaces/IContractChart";
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  templateUrl: 'report.html',
  selector: 'contract-chart-report'
})
export class ContractChartReportCmp {
  public loading = false;
  public queries:IPopupQuery[];
  public report:IContractChart[];
  public id:number;

  public constructor(private reports:Reports, private params:ActivatedRoute, private router:Router, private session:Session) {}

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    if (!this.id) {
      this.id = parseInt(params['id']);
    }
    if (!this.id) {
      this.router.navigate(['']);
      return;
    }
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    if (this.id) {
      this.loading = true;
      this.reports.getContractChartReport(this.id).subscribe((response:IContractChartReport)=> {
        if (!response) {
          return;
        }
        this.queries = [{name: 'Query', query: response.query}];
        if (response.report && response.report.length) {
          this.report = response.report;
        }
        this.loading = false;
      }, ()=> {
        this.loading = false;
      });
    }
  }
}
