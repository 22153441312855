import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {User} from "../../services/User";
import {IPAQ} from "../../interfaces/IPAQ";
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";
import {IUser} from "../../interfaces/IUser";
import {Notify} from "../../services/Notify";
import {IDupField} from "../../interfaces/IDupField";
declare var $;

@Component({
  templateUrl: './pa-q-list.html',
})
export class PaQListCmp {
  public qs = [];
  public loading = true;
  public pd:IPAQ = <IPAQ>{};
  @ViewChild('dialogModal') dialogModal:ElementRef;

  constructor(private session:Session, private usrSrv:User, private locSrv:Locations) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.loading = true;
    this.usrSrv.getPAQuarantineList().subscribe((r)=> {
      this.loading = false;
      if (r && r.length) {
        this.qs = r;
      }
    }, ()=> {
      this.loading = false;
    });
  }

  hide(paq:IPAQ) {
    if (!paq || !paq.id) {
      return;
    }
    this.qs.splice(this.qs.indexOf(paq), 1);
    this.usrSrv.hidePAQuarantineItem(paq.id).subscribe(()=> {}, (err) => {
      this.load();
      console.error(err);
    });
  }

  create(paq:IPAQ, confirmed?:boolean, recursion?:boolean) {
    if (!paq || !paq.id || paq.__new_user_id || paq.__new_loc_id) {
      return;
    }
    let n = new Notify();
    if (!paq._duplicate_user && !paq._duplicate_facility) {
      paq.__saving = true;
      let user = this.getUserFromPAQ(paq);
      this.usrSrv.updateUser(user).then(() => {
        paq.__new_user_id = user.id;
        let loc = this.getLocFromPAQ(paq, null, user);
        loc.primary_contact_user_id = user.id;
        this.locSrv.updateLocation(loc).then(()=> {
          paq.__new_loc_id = loc.id;
          paq.__saving = false;
          user.location_id = loc.id;
          this.usrSrv.updateUser(user).then(() => {}, () => {});
        }, (err)=> {
          paq.__saving = false;
          console.error(err);
          n.error(JSON.stringify(err), 'Error - can\'t create location');
        });
      }, (err) => {
        paq.__saving = false;
        console.error(err);
        n.error(JSON.stringify(err), 'Error - can\'t create user');
      });
    } else {
      this.pd = paq;
      if (paq._duplicate_user) {
        // we have existing user and maybe existing maybe not location
        if (confirmed) {
          paq.__saving = true;
          paq._duplicate_user = this.getUserFromPAQ(paq, paq._duplicate_user);
          // if we have existing location - just update user
          if (paq._duplicate_facility && paq._duplicate_facility.id) {
            if (!paq._duplicate_user.location_id) {
              paq._duplicate_user.location_id = paq._duplicate_facility.id;
            }
            this.usrSrv.updateUser(paq._duplicate_user).then(() => {
              paq.__new_user_id = paq._duplicate_user.id;
              paq.__saving = false;
              this.dialogHide();
            }, (err)=> {
              paq.__saving = false;
              console.error(err);
              n.error(JSON.stringify(err), 'Error - can\'t update user');
            });
          } else {
            // create location
            let loc = this.getLocFromPAQ(paq, null, paq._duplicate_user);
            loc.primary_contact_user_id = paq._duplicate_user.id;
            this.locSrv.updateLocation(loc).then(()=> {
              paq.__new_loc_id = loc.id;
              if (!paq._duplicate_user.location_id) {
                paq._duplicate_user.location_id = loc.id;
              }
              this.usrSrv.updateUser(paq._duplicate_user).then(() => {
                paq.__new_user_id = paq._duplicate_user.id;
                paq.__saving = false;
                this.dialogHide();
              }, (err)=> {
                paq.__saving = false;
                console.error(err);
                n.error(JSON.stringify(err), 'Error - can\'t update user');
              });
            }, (err)=> {
              paq.__saving = false;
              console.error(err);
              n.error(JSON.stringify(err), 'Error - can\'t create location');
            });
          }
        } else {
          let user = this.getUserFromPAQ(paq, paq._duplicate_user);
          paq.__dup_user_fields = [];
          for (let field in user) {
            if (user.hasOwnProperty(field) && paq._duplicate_user.hasOwnProperty(field)) {
              if (user[field] != paq._duplicate_user[field]) {
                let dup:IDupField = {
                  field: field,
                  old_value: paq._duplicate_user[field],
                  new_value: user[field],
                };
                paq.__dup_user_fields.push(dup);
              }
            }
          }
        }
      }
      if (paq._duplicate_facility) {
        // we have existing facility, and maybe existing maybe not user
        if (confirmed) {
          // we have to update facility and, if does not exist, create user (do not update, only create if does not exist)
          paq.__saving = true;
          paq._duplicate_facility = this.getLocFromPAQ(paq, paq._duplicate_facility);
          // if we have existing user - just update location (updating user is task of another "if" case in this code)
          if (paq._duplicate_user && paq._duplicate_user.id) {
            if (!paq._duplicate_facility.primary_contact_user_id) {
              paq._duplicate_facility.primary_contact_user_id = paq._duplicate_user.id;
            }
            this.locSrv.updateLocation(paq._duplicate_facility).then(()=> {
              paq.__new_loc_id = paq._duplicate_facility.id;
              paq.__saving = false;
              this.dialogHide();
            }, (err)=> {
              paq.__saving = false;
              console.error(err);
              n.error(JSON.stringify(err), 'Error - can\'t update location');
            });
          } else {
            // if we don't have user yet - create it and then update location (to be able to set new user as primary contact)
            let user = this.getUserFromPAQ(paq);
            user.location_id = paq._duplicate_facility.id;
            this.usrSrv.updateUser(user).then(() => {
              paq.__new_user_id = user.id;
              if (!paq._duplicate_facility.primary_contact_user_id) {
                paq._duplicate_facility.primary_contact_user_id = user.id;
              }
              this.locSrv.updateLocation(paq._duplicate_facility).then(()=> {
                paq.__new_loc_id = paq._duplicate_facility.id;
                paq.__saving = false;
                this.dialogHide();
              }, (err)=> {
                paq.__saving = false;
                console.error(err);
                n.error(JSON.stringify(err), 'Error - can\'t update location');
              });
            }, (err) => {
              paq.__saving = false;
              console.error(err);
              n.error(JSON.stringify(err), 'Error - can\'t create user');
            });
          }
        } else {
          let loc = this.getLocFromPAQ(paq, paq._duplicate_facility);
          paq.__dup_loc_fields = [];
          for (let field in loc) {
            if (loc.hasOwnProperty(field) && paq._duplicate_facility.hasOwnProperty(field)) {
              if (loc[field] != paq._duplicate_facility[field]) {
                let dup:IDupField = {
                  field: field,
                  old_value: paq._duplicate_facility[field],
                  new_value: loc[field],
                };
                paq.__dup_loc_fields.push(dup);
              }
            }
          }
        }
      }
      let user_changes = (this.pd.__dup_user_fields && this.pd.__dup_user_fields.length > 0);
      let loc_changes = (this.pd.__dup_loc_fields && this.pd.__dup_loc_fields.length > 0);
      if (loc_changes || user_changes) {
        this.dialogShow();
      } else {
        if (!loc_changes && !user_changes) {
          n.info('Existing and quarantined objects are equal.', 'Nothing to update');
        } else {
          if (!confirmed) {
            if (!recursion) {
              this.create(paq, true, true);
            }
          }
        }
      }
    }
  }

  getLocFromPAQ(paq:IPAQ, base_location?, existingUser?:IUser):ILocation {
    base_location = base_location || <ILocation>{};
    let loc = $.extend({}, base_location);
    loc.facility_name = paq.facility_name || base_location.facility_name;
    loc.address = paq.address || base_location.address;
    loc.address2 = paq.address2 || base_location.address2;
    loc.city = paq.city || base_location.city;
    loc.state = paq.state || base_location.state;
    loc.zip = paq.zip || base_location.zip;
    loc.country = paq.country || base_location.country;
    loc.dba = paq.dba || base_location.dba;
    loc.dea = paq.dea || base_location.dea;
    loc.hin = paq.hin || base_location.hin;
    loc.phone = paq.phone || base_location.phone;
    loc.__distributor_id = paq.distributor_id || base_location.__distributor_id;
    loc.__distributor_pharma_id = paq.distributor_pharma_id || base_location.__distributor_pharma_id;
    loc.__distributor_secondary_id = paq.distributor_secondary_id || base_location.__distributor_secondary_id;
    if (!loc.facility_name && existingUser) {
      if (existingUser.fname || existingUser.lname) {
        loc.facility_name = ((existingUser.fname || '') + ' ' + (existingUser.lname || '')).trim();
      } else {
        loc.facility_name = 'Facility (set name)';
      }
    }
    return loc;
  }

  getUserFromPAQ(paq:IPAQ, base_user?):IUser {
    base_user = base_user || <IUser>{};
    let user = $.extend({}, base_user);
    user.fname = paq.fname || base_user.fname;
    user.lname = paq.lname || base_user.lname;
    user.email = paq.email || base_user.email;
    user.job_title = paq.job_title || base_user.job_title;
    user.phone = paq.phone || base_user.phone;
    user.address = paq.address || base_user.address;
    user.address2 = paq.address2 || base_user.address2;
    user.city = paq.city || base_user.city;
    user.state = paq.state || base_user.state;
    user.zip = paq.zip || base_user.zip;
    user.dea = paq.dea || base_user.dea;
    user.distributor_id = paq.distributor_id || base_user.distributor_id;
    return user;
  }

  dialogShow() {
    if (this.dialogModal && this.dialogModal.nativeElement) {
      $(this.dialogModal.nativeElement).modal('show');
    }
  }

  dialogHide() {
    if (this.dialogModal && this.dialogModal.nativeElement) {
      $(this.dialogModal.nativeElement).modal('hide');
    }
  }
}
