import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";

@Component({
  templateUrl: 'report.html',
  selector: 'cs-tracking-report'
})
export class ReportCsTrackingCmp {
  public report;
  public loading = false;
  @ViewChild('tbl') tbl:ElementRef;
  private n = new Notify();

  constructor(private session:Session, private reports:Reports) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  reload() {
    setTimeout(()=> {
      this.load();
    }, 1);
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.reports.getCsTrackingReport().subscribe((report)=> {
      this.report = report;
      this.loading = false;
    }, (err)=> {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  exportCSV() {
    if (!this.report || !this.report.records) {
      return;
    }
    let h = new Helpers();
    h.exportCsv(this.tbl, 'cs_tracking_report.csv', null, (t)=> {
      return t.replace('check_circle', 'Yes');
    });
  }
}
