import {Component, Input} from '@angular/core';
import {Locations} from "../../services/Locations";
import {ILocationGradeReportRow} from "../../interfaces/ILocationGradeReportRow";

@Component({
  selector: 'location-grades',
  templateUrl: './location_grades.html',
})
export class LocationGradesCmp {
  @Input() locationGroupID:number;
  public loading = false;
  public rows = [];
  public grades = [];

  constructor(public locationsSrv:Locations) {
    this.reload();
  }

  reload() {
    if (!this.locationGroupID) {
      return;
    }
    this.loading = true;
    this.rows = [];
    this.grades = [];
    this.locationsSrv.getGradesOfGroup(this.locationGroupID).subscribe((grades) => {
      if (grades && grades.length > 0) {
        let table:any = {};
        grades.forEach((grade:ILocationGradeReportRow)=> {
          let d = grade.month + '/' + grade.year;
          if (table[d]) {
            table[d].push(grade);
          } else {
            table[d] = [grade];
          }
          if (this.grades.indexOf(grade.grade) < 0) {
            this.grades.push(grade.grade);
          }
        });
        for (let d in table) {
          this.rows.push({date: d, row: table[d]});
        }
      }
      this.loading = false;
    }, (response)=> {
      console.error(response);
      this.loading = false;
    });
  }

  ngOnChanges() {
    this.reload();
  }
}
