export class ILifecycleRecord {
  id:number;
  location_id:number;
  distributor_id:number;
  datetime: string;
  reason_id:number;
  type_id:number;
  __distributor: string;
  __reason: string;
}
