import {Component} from "@angular/core";
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {Locations} from "../../services/Locations";
import {IFileHeaders} from "../../interfaces/IFile";
import {Config} from "../../services/Config";
import {ContractConnections, ICConnsUploadReq} from "../../services/ContractConnections";

@Component({
  selector: 'contract-conns-file-upload',
  templateUrl: 'cconns_file_upload.html',
})
export class CConnsFileUploadCmp {
  public newFileData:File;
  public newFileName:string;
  public uploadingFile:boolean = false;
  public parsingHeaders:boolean = false;
  public afterParsingHeaders:boolean = false;
  public fileHeadersList:{index: number, columns_str: string}[];
  public headersIndex:number = 0;
  public columnCoid = 8;
  public columnContractNumber = 9;
  public columnTier = 11;
  public columnSubmitDate = 16;
  public columnPriceDate = 19;
  public columnLocStatus = 14;
  public columnsList;
  public columnsIndexesList;
  public columnsMap;
  public ccUploadID;
  public uploadedFileID;
  public parsing = false;
  private fileType:number;
  private fileHeaders:{[row:number]:{[column:number]:string}};

  constructor(private ccSrv:ContractConnections, private session:Session, private locations:Locations, private config:Config) {
    this.fileType = this.config.fileTypeCC;
    this.session.redirectIfNotAuth();
  }

  addFile(files) {
    if (!files || !files[0]) {
      return;
    }
    this.newFileName = files[0].name;
    this.newFileData = files[0];
    this.afterParsingHeaders = false;
    this.preloadHeaders();
  }

  preloadHeaders() {
    if (!this.newFileData) {
      return false;
    }
    this.parsingHeaders = true;
    this.afterParsingHeaders = false;
    this.columnsList = null;
    this.columnsIndexesList = null;
    this.columnsMap = null;
    this.locations.parseFileHeadersXLS(this.newFileData).subscribe((r:any)=> {
      this.parsingHeaders = false;
      this.afterParsingHeaders = true;
      if (r && r.target && r.target.response) {
        try {
          let resp:IFileHeaders = JSON.parse(r.target.response);
          if (resp && resp.first_lines) {
            this.fileHeaders = resp.first_lines;
            this.fileHeadersList = [];
            for (let hi in this.fileHeaders) {
              this.fileHeadersList.push({index: parseInt(hi), columns_str: Object.values(this.fileHeaders[hi]).join("; ")});
            }
            this.selectHeadersIndex(resp.headers_index);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, (err)=> {
      this.parsingHeaders = false;
      console.error(err);
    });
  }

  selectHeadersIndex(i:number) {
    this.headersIndex = i;
    this.columnsMap = this.fileHeaders[this.headersIndex];
    this.columnsList = Object.values(this.fileHeaders[this.headersIndex]);
    this.columnsIndexesList = Object.keys(this.fileHeaders[this.headersIndex]);
  }

  uploadFile() {
    if (!this.newFileName || !this.newFileData) {
      return false;
    }
    this.uploadingFile = true;
    this.ccUploadID = null;
    this.locations.uploadFile(0, this.newFileName, this.newFileData, this.fileType).subscribe((r:any)=> {
      this.uploadingFile = false;
      if (r && r.target && r.target.response) {
        try {
          let resp = JSON.parse(r.target.response);
          if (resp && resp.id) {
            this.uploadedFileID = resp.id;
          }
        } catch (e) {
          console.error(e);
        }
      }
      let n = new Notify();
      n.success('File uploaded');
    }, (err)=> {
      this.uploadingFile = false;
      let n = new Notify();
      n.error('File Uploading Error', JSON.stringify(err));
      console.error(err);
    });
  }

  resetFileUpload() {
    this.newFileData = null;
    this.newFileName = null;
    this.columnsList = null;
    this.columnsIndexesList = null;
    this.columnsMap = null;
    this.ccUploadID = null;
    this.uploadedFileID = null;
    this.headersIndex = null;
    this.fileHeaders = null;
    this.fileHeadersList = null;
    this.afterParsingHeaders = false;
  }

  parseUploadedFile() {
    if (!this.uploadedFileID) {
      return;
    }
    this.parsing = true;
    let req:ICConnsUploadReq = {
      file_id: this.uploadedFileID,
      src_filename: this.newFileName,
      coid_ci: this.columnCoid,
      contract_number_ci: this.columnContractNumber,
      tier_ci: this.columnTier,
      submit_date_ci: this.columnSubmitDate,
      price_start_date_ci: this.columnPriceDate,
      loc_status_ci: this.columnLocStatus,
      headers_index: this.headersIndex,
    };
    this.ccSrv.parseFile(req).subscribe((id)=> {
      this.ccUploadID = id;
      this.parsing = false;
    }, ()=> {
      this.parsing = false;
    });
  }
}
