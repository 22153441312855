import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {IUser} from '../interfaces/IUser';
import {Observable} from "rxjs";
import {IAspsInfo} from "../interfaces/IAspsInfo";
import {IUserFromEmail} from "../interfaces/IUserFromEmail";
import {IUserTotalSales} from "../interfaces/IUserTotalSales";
import {IPAQ} from "../interfaces/IPAQ";
import {ILocation} from "../interfaces/ILocation";
import {IFile} from "../interfaces/IFile";
import {HttpCache} from "./HttpCache";

export interface ISignupDomain {
  id: number;
  title: string;
  theme_id: number;
  signup_link_template: string;
  __signup_target_id?: number;
}

export interface IRepLocInfo {
  id: number;
  is_primary_distributor_match: boolean;
  is_secondary_distributor_match: boolean;
  is_connected: boolean;
  is_reporting: boolean;
  is_dejected: boolean;
  dejected_distributor_date: string;
  dejected_customer_date: string;
  is_rx_connected: boolean;
  group_manager_name: string;
  rep_manager_name: string;
  parent_gpo_id: string;
}

export interface IRepLocsReport {
  locations: ILocation[];
  locations_info: IRepLocInfo[];
  connected_cnt: number;
  reporting_cnt: number;
}

export interface IUserAlias {
  id?: number;
  user_id: number;
  fname?: string;
  lname?: string;
  title?: string;
  job_type_id?: number;
  //
  ___updating?: boolean;
  ___removing?: boolean;
}

export interface ISignatureReq {
  cm_id?: number;
  cm_email?: string;
  theme_id?: number;
  sub_theme_id?: number;
  location_id?: number;
  recipient_user_id?: number;
  recipient_user_email?: string;
  signature_target: string;
}

@Injectable()
export class User {
  private mem = {};
  private memLoading = {};

  constructor(private http: HttpCache, private config: Config, private session: Session) {
  }

  getNewTotpSecret(password): Observable<HttpResponse<string>> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/totp', JSON.stringify({password: password}), {headers: headers, responseType: 'text', observe: 'response'});
  }

  removeTotpSecret(password):Observable<HttpResponse<string>> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/totp_remove', JSON.stringify({password: password}), {headers: headers, responseType: 'text', observe: 'response'});
  }

  checkTotpCode(code):Observable<HttpResponse<string>> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/totp/' + code, {headers: headers, responseType: 'text', observe: 'response'});
  }

  getUserInfo(id, refresh?):Promise<IUser> {
    if (id < 1) {
      return new Promise((resolve, reject) => {
        reject('Wrong ID');
      });
    }
    let k = id.toString();
    if (!this.memLoading[k]) {
      this.memLoading[k] = new Promise((resolve, reject) => {
        let t = id.toString() + '_' + (Math.floor(new Date().getTime() / 10000)).toString();
        if (this.mem[t] && !refresh) {
          resolve(this.mem[t]);
          return;
        }
        let headers = new HttpHeaders();
        headers = this.session.setAuthHeader(headers);
        this.http.get(this.config.apiUrl + '/user/' + id, {headers: headers}).subscribe((info)=> {
          this.mem[t] = info;
          resolve(info);
          this.memLoading[k] = null;
        }, reject);
      });
    }
    return this.memLoading[k];
  }

  getFreshUserInfo(id:number):Observable<IUser> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IUser>(this.config.apiUrl + '/user/' + id, {headers: headers});
  }

  getRelatedLocations(id:number):Observable<IRepLocsReport> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IRepLocsReport>(this.config.apiUrl + '/get_rep_locations/' + id, {headers: headers});
  }

  updateUser(user:IUser) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'text/json');
      headers = this.session.setAuthHeader(headers);
      this.http.put(this.config.apiUrl + '/user', JSON.stringify(user), {headers: headers, responseType: 'text'}).subscribe((id) => {
        if (!user.id) {
          user.id = parseInt(id);
        }
        resolve(parseInt(id));
      }, reject);
    });
  }

  getRepClientManagers(repID):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<any>(this.config.apiUrl + '/get_rep_client_managers/' + repID, {headers: headers});
  }

  getUsersList(searchTerm:string, offset = 0, limit = 0, type_id?:number):Observable<IUser[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    if (searchTerm === null || !searchTerm || searchTerm === undefined) {
      searchTerm = '';
    }
    let url = this.config.apiUrl + '/user?search=' + searchTerm + '&offset=' + offset + '&limit=' + limit;
    if (type_id !== null && typeof type_id !== 'undefined') {
      url = url + '&type_id=' + type_id;
    }
    return this.http.getc<IUser[]>(url, {headers: headers});
  }

  getFiles(id):Observable<IFile[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IFile[]>(this.config.apiUrl + '/files_of_user/' + id, {headers: headers});
  }

  loadFileTypes(map, list):void {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    this.http.getc<any>(this.config.apiUrl + '/file_type', {headers: headers}).subscribe((file_types) => {
      if (file_types && file_types.length > 0) {
        file_types.forEach((t) => {
          map[t.id] = t;
        });
        file_types.forEach((t) => {
          list.push(t);
        });
      }
    }, () => {});
  }

  createSignupLink(user_id:number, manager_id:number = 0, rep_id:number = 0, location_id:number = 0, theme_id:number = 0, target_id:number = 0, signup_domain_id?:number) {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/signup_link',
      JSON.stringify({user_id: user_id, rep_id: rep_id, manager_id: manager_id, location_id: location_id, theme_id: theme_id, signup_target_id: target_id, signup_domain_id: signup_domain_id}),
      {headers: headers, responseType: 'text'});
  }

  getSignupDomains():Observable<ISignupDomain[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ISignupDomain[]>(this.config.apiUrl + '/signup_domain', {headers: headers});
  }

  getReps(sort_field?:String, sort_asc?:boolean):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + '/rep';
    if (sort_field) {
      url = url + '?sort=' + sort_field;
      if (!sort_asc) {
        url = url + '&sort_desc=1';
      }
    }
    return this.http.getc(url, {headers: headers});
  }

  getClientManagers(sort_field?:String, sort_asc?:boolean):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + '/client_manager';
    if (sort_field) {
      url = url + '?sort=' + sort_field;
      if (!sort_asc) {
        url = url + '&sort_desc=1';
      }
    }
    return this.http.getc(url, {headers: headers});
  }

  getUsersCount() {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/users_count', {headers: headers, responseType: 'text'}).pipe(map(r => parseInt(r)));
  }

  getUsersForMerge(userDestID:number):Observable<[IUser]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<[IUser]>(this.config.apiUrl + '/users_merge/' + userDestID.toString(), {headers: headers});
  }

  mergeUser(userSrcID:number, userDestID:number):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/users_merge/' + userSrcID.toString() + '/' + userDestID.toString(),
      '', {headers: headers, responseType: 'text'});
  }

  resetPassword(userID:number):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/user/reset_password', userID.toString(), {headers: headers, responseType: 'text'});
  }

  getTokenForUserID(userID:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/user_session/' + userID, {headers: headers, responseType: 'text'});
  }

  searchAspsInfo(searchTerm:string, tags?:string, theme_id?:number):Observable<[IAspsInfo]> {
    let url = this.config.apiUrl + '/user/asps_info_search/' + searchTerm + '?theme_id=' + (theme_id || 0)
    if (tags !== null && typeof tags !== 'undefined') {
      url = url + '&tags=' + tags;
    }
    return this.http.getc<[IAspsInfo]>(url);
  }

  getUsersFromEmails():Observable<IUserFromEmail[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IUserFromEmail[]>(this.config.apiUrl + '/user_from_email', {headers: headers});
  }

  getTotalSalesReport(userID:number):Observable<IUserTotalSales> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IUserTotalSales>(this.config.apiUrl + '/report/user_total_sales/' + userID, {headers: headers}, 30000);
  }

  getPAQuarantineList():Observable<IPAQ[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IPAQ[]>(this.config.apiUrl + '/pa_quarantine', {headers: headers});
  }

  hidePAQuarantineItem(id:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/pa_quarantine/${id}/hide`, '', {headers: headers, responseType: 'text'});
  }

  disableLogin(id:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/user/${id}/disable`, '', {headers: headers, responseType: 'text'});
  }

  enableLogin(id:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/user/${id}/enable`, '', {headers: headers, responseType: 'text'});
  }

  disableEmailLogin(email:string):Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/user/${email}/disable_email`, '', {headers: headers, responseType: 'text'});
  }

  getRxCodeOfUser(userID:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + `/rx/code_of_user/${userID}`, {headers: headers, responseType: 'text'});
  }

  generateRxCodeForUser(userID:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/rx/code_for_user/${userID}`, '', {headers: headers, responseType: 'text'});
  }

  generateSiimCodeForUser(userID:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/siim/code_for_user/${userID}`, '', {headers: headers, responseType: 'text'});
  }

  getAliases(userID:number):Observable<IUserAlias[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IUserAlias[]>(this.config.apiUrl + `/user_aliases/${userID}`, {headers: headers});
  }

  createAlias(alias:IUserAlias):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/user_aliases`, JSON.stringify(alias), {headers: headers, responseType: 'text'});
  }

  setAliasAsMain(aliasID:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/user/use_alias/${aliasID}`, '', {headers: headers, responseType: 'text'});
  }

  updateAlias(alias:IUserAlias):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/user_aliases`, JSON.stringify(alias), {headers: headers, responseType: 'text'});
  }

  removeAlias(aliasID:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + `/user_aliases/${aliasID}`, {headers: headers, responseType: 'text'});
  }

  getPossibleRepsList(): Observable<string[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<string[]>(this.config.apiUrl + '/report/possible_reps', {headers: headers});
  }

  getSignature(req: ISignatureReq): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/plain');
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + '/signature';
    let params = [];
    for (let k in req) {
      let v = req[k];
      if (v) {
        params.push(k + '=' + v);
      }
    }
    if (params.length) {
      url = url + '?' + params.join('&');
    }
    return this.http.getc(url, {headers: headers, responseType: 'text'});
  }
}
