import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {ITicket} from "../interfaces/ITicket";
import {Observable} from "rxjs";

declare var _: any;

@Injectable()
export class Tickets {
  private map = [];

  constructor(private http: HttpClient, private session: Session, private config: Config) {}

  loadByLocationID(id): Observable<ITicket[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let req = this.http.get<ITicket[]>(this.config.apiUrl + '/ticket/location/' + id, {headers: headers});
    req.subscribe((response) => {
      this.map = _.uniqBy(this.map.concat(response), 'id');
    }, () => {});
    return req;
  }

  refreshFDTicket(fdTicketID): Observable<ITicket> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITicket>(this.config.apiUrl + '/fd_ticket_refreshed/' + fdTicketID, {headers: headers});
  }

  loadImplNotes(locationID): Observable<ITicket[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let req = this.http.get<ITicket[]>(this.config.apiUrl + '/implementation/tickets/location/' + locationID, {headers: headers});
    req.subscribe((response) => {
      this.map = _.uniqBy(this.map.concat(response), 'id');
    }, () => {});
    return req;
  }

  loadByUserID(id): Observable<ITicket[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let req = this.http.get<ITicket[]>(this.config.apiUrl + '/ticket/user/' + id, {headers: headers});
    req.subscribe((response) => {
      this.map = _.uniqBy(this.map.concat(response), 'id');
    }, () => {});
    return req;
  }

  loadByUserRelatedID(id): Observable<ITicket[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let req = this.http.get<ITicket[]>(this.config.apiUrl + '/ticket/user_related/' + id, {headers: headers});
    req.subscribe((response) => {
      this.map = _.uniqBy(this.map.concat(response), 'id');
    }, () => {});
    return req;
  }

  loadChildren(id): Observable<ITicket[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITicket[]>(this.config.apiUrl + '/ticket/' + id + '/children', {headers: headers});
  }

  getFromLoaded(id) {
    return _.find(this.map, {id: id});
  }

  getByID(id): Observable<ITicket> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITicket>(this.config.apiUrl + '/ticket/' + id, {headers: headers});
  }

  save(ticket: ITicket): Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/ticket', JSON.stringify(ticket), {headers: headers, responseType: 'text'});
  }

  remove(id): Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/ticket/' + id, {headers: headers, responseType: 'text'});
  }
}
