// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circled {
  background-color: rgb(127, 195, 68);
  color: #fff;
  font-weight: bold;
}

.cb {
  cursor: pointer;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNsLWRpc3RyaWJ1dG9yLWNiLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG1DQUFtQztFQUNuQyxXQUFXO0VBQ1gsaUJBQWlCO0FBQ25COztBQUVBO0VBQ0UsZUFBZTtBQUNqQiIsImZpbGUiOiJzbC1kaXN0cmlidXRvci1jYi5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2lyY2xlZCB7XG4gIGJhY2tncm91bmQtY29sb3I6IHJnYigxMjcsIDE5NSwgNjgpO1xuICBjb2xvcjogI2ZmZjtcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG59XG5cbi5jYiB7XG4gIGN1cnNvcjogcG9pbnRlcjtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/sl-distributor-cb/sl-distributor-cb.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA,geAAge","sourcesContent":[".circled {\n  background-color: rgb(127, 195, 68);\n  color: #fff;\n  font-weight: bold;\n}\n\n.cb {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
