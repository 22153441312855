import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";
import {UrlParser} from "../../services/UrlParser";
import {IRosterSearchReq, IRosterSearchResp} from "../../interfaces/IRosterSearch";

@Component({
  templateUrl: 'search_roster.html',
  selector: 'search-roster'
})
export class SearchRosterCmp {
  public report: IRosterSearchResp;
  public req: IRosterSearchReq;
  public loading = false;
  @ViewChild('tbl') tbl: ElementRef;
  public page = 1;
  public itemsLimit = 70;
  private itemsLimitPre = 70;
  public exporting = false;
  public rendering = false;
  private n = new Notify();

  constructor(private session: Session, private reports: Reports, private urlParser: UrlParser, private h: Helpers) {
    this.reset();
    this.setPage(parseInt(this.urlParser.getParameter('page')));
    this.session.redirectIfNotAuth();
  }

  reset() {
    this.req = {coretrust: false, term: null, address: null, city: null, state: null, zip: null, dea: null};
  }

  reload() {
    setTimeout(() => {
      this.search();
    }, 1);
  }

  search() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.reports.searchRoster(this.req).subscribe((report) => {
      this.report = report;
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  exportCSV() {
    if (!this.report || !this.report.results || !this.report.results.length || this.exporting) {
      return;
    }
    this.rendering = true;
    this.exporting = true;
    let itemsLimitPre = this.itemsLimit;
    let prePage = this.page;
    this.page = 1;
    this.itemsLimit = 100000;
    setTimeout(() => {
      this.h.exportCsv(this.tbl, 'roster_search.csv', () => {
        this.exporting = false;
        this.itemsLimit = itemsLimitPre;
        this.page = prePage;
        this.rendered();
      }, (celltext) => {
        return celltext.replace('check_circle', 'X');
      });
    }, 120);
  }

  rendered() {
    setTimeout(() => {
      this.rendering = false;
    }, 60);
  }

  setPage($event) {
    let newPage = Math.max(0, parseInt($event) || 1);
    this.urlParser.setParameter('page', newPage);
    if (this.page !== newPage) {
      this.page = newPage;
    }
  }
}
