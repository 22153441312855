import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {ISalesTracing} from "../../interfaces/ISalesTracing";
import {Notify} from "../../services/Notify";
import {ISTSalesByMonth} from "../../interfaces/ISalesTracing";
declare var Chart:any;

@Component({
  templateUrl: 'sales.html',
  selector: 'location-sales-tracing'
})
export class LocationSalesTracingCmp {
  @Input() locationGroupID;
  @ViewChild('distrSummary') distrSummary:ElementRef;
  @ViewChild('salesByMonth') salesByMonth:ElementRef;

  public loading = false;
  public csv = '';
  public report:ISalesTracing;
  public orderField = '';
  public sortDesc;
  public contractsFiltered = [];
  public categoryFiltered = [];
  public manufacturerFiltered = [];
  public invoiceFiltered = [];
  private n = new Notify();

  constructor(private session:Session, private reports:Reports) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    if (!this.loading && this.session.user && this.session.user.id) {
      this.load();
    }
  }

  load() {
    if (!this.locationGroupID) {
      return;
    }
    this.loading = true;
    this.report = null;
    this.csv = this.reports.getLocGroupSalesDataUrl(this.locationGroupID);
    this.reports.getLocGroupSalesTracing(this.locationGroupID).subscribe((r)=> {
      this.loading = false;
      this.report = r;
      setTimeout(()=> {
        this.drawDistrSummaryChart();
        this.drawSalesByMonthChart();
      }, 200);
    }, (err)=> {
      this.loading = false;
      this.n.error(JSON.stringify(err), 'Error');
    });
  }

  drawDistrSummaryChart() {
    if (this.report && Chart && this.distrSummary && this.report.distributor_summary && this.report.distributor_summary.length > 0 &&
      this.report.distributor_summary[0] && this.report.distributor_summary[0].total &&
      this.report.distributor_summary[1] && this.report.distributor_summary[1].total
      && this.distrSummary.nativeElement) {
      let canvas = this.distrSummary.nativeElement;
      let data = {
        datasets: [{
          data: [this.report.distributor_summary[0].total, this.report.distributor_summary[1].total],
          backgroundColor: ['#0275d8', '#1a8468']
        }],

        labels: [
          this.report.distributor_summary[0].distributor,
          this.report.distributor_summary[1].distributor
        ]
      };
      let numberWithCommas = function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };
      let c = new Chart(canvas.getContext('2d'), {
        type: 'doughnut',
        data: data,
        options: {
          responsive: false,
          tooltips: {
            mode: 'label',
            callbacks: {
              label: function (tooltipItem, data) {
                return data.labels[tooltipItem.index] + ": " + numberWithCommas(data.datasets[0].data[tooltipItem.index]);
              }
            }
          }
        }
      });
    }
  }

  drawSalesByMonthChart() {
    if (this.report && Chart && this.salesByMonth && this.report.sales_by_month && this.report.sales_by_month.length > 0 && this.salesByMonth.nativeElement) {
      let canvas = this.salesByMonth.nativeElement;
      let datasetOnContract = {label: 'On Contract', data: [], backgroundColor: '#1a8468'};
      let datasetOffContract = {label: 'Off Contract', data: [], backgroundColor: '#0275d8'};
      let dataTotal = [];
      let labels = [];
      this.report.sales_by_month.forEach((r:ISTSalesByMonth)=> {
        labels.push(r.month);
        datasetOnContract.data.push(r.on_contract);
        datasetOffContract.data.push(r.off_contract);
        dataTotal.push(r.total);
      });
      let data = {datasets: [datasetOffContract, datasetOnContract], labels: labels};
      let numberWithCommas = function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };
      let c = new Chart(canvas.getContext('2d'), {
        type: 'bar',
        data: data,
        options: {
          tooltips: {
            mode: 'label',
            callbacks: {
              label: function (tooltipItem, data) {
                let s = data.datasets[tooltipItem.datasetIndex].label + ": " + numberWithCommas(tooltipItem.yLabel);
                if (tooltipItem.datasetIndex == 1) {
                  return [s, "Total: " + numberWithCommas(dataTotal[tooltipItem.index])];
                } else {
                  return s;
                }
              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          }
        }
      });
    }
  }

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }
}
