import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Locations} from "../../services/Locations";
import {ILocation} from "../../interfaces/ILocation";

@Component({
  selector: 'location-purchasing-contact',
  templateUrl: './purchasing-contact.html',
})
export class LocationPurchasingContactCmp {
  @Input() location:ILocation = <ILocation>{};
  @Input() userID:number;
  @Output() locationChange = new EventEmitter();
  public displayPurchasingContactUser = false;
  public savingPurchasingContact = false;

  constructor(public locations:Locations) {}

  setPurchasingContact(force) {
    if (this.displayPurchasingContactUser || !this.location.__purchasing_contact_user_id) {
      if (!force && this.location.__purchasing_contact_user_id) {
        return;
      } else {
        this.savingPurchasingContact = true;
        this.locations.setLocationGroupPurchasingContact(this.location.location_group_id, this.userID)
          .subscribe(()=> {
            this.savingPurchasingContact = false;
            this.displayPurchasingContactUser = false;
            this.location.__purchasing_contact_user_id = this.userID;
            this.locationChange.emit(this.location);
          }, (response)=> {
            console.error(response);
            this.savingPurchasingContact = false;
          });
      }
    } else {
      this.displayPurchasingContactUser = true;
    }
  }
}
