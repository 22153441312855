import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {IPaByDateReport} from "../../interfaces/IPaByDateReport";

declare var Chart: any;

@Component({
  templateUrl: 'report.html',
  selector: 'pa-by-date-chart'
})
export class PaByDateChartCmp {
  @ViewChild('chart') chart: ElementRef;
  public loading = false;
  public csv = '';
  public report: IPaByDateReport;
  public labels = [];
  public sets = [];
  public n_days = 30;
  public n_reporting = 30;
  private n = new Notify();

  constructor(private session: Session, private reports: Reports) {
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  ngOnChanges() {
    if (!this.loading && this.session.user && this.session.user.id) {
      this.load();
    }
  }

  load() {
    this.loading = true;
    this.n_reporting = this.n_days;
    this.report = null;
    this.reports.getPaByDateReport(this.n_days.toString()).subscribe((r) => {
      this.loading = false;
      this.report = r;
      setTimeout(() => {
        this.drawChart();
      }, 200);
    }, (err) => {
      this.loading = false;
      this.n.error(JSON.stringify(err), 'Error');
    });
  }

  drawChart() {
    if (this.report) {
      let dataset = {label: 'PAs count', data: [], color: '#1E88E5', hidden: false, column: 0};
      this.labels = [];
      this.report.report.forEach((r) => {
        this.labels.push(r.pa_date);
        dataset.data.push(r.pa_count);
      });
      this.sets = [dataset];
    }
  }
}
