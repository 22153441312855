import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {IPopupQuery} from "../../interfaces/IPopupQuery";
import {Helpers} from "../../services/Helpers";
declare var $;

@Component({
  templateUrl: 'popup.html',
  selector: 'popup-queries',
})
export class PopupQueriesCmp {
  @Input() queries: IPopupQuery[];
  @Input() queriesSimple: string[];
  @Input() query: string;
  @ViewChild("modal") modal:ElementRef;
  @Input() small:boolean;
  private clipboards;

  constructor(private h:Helpers) {}

  ngOnChanges() {
    this.init();
  }

  init() {
    if (!this.queries) {
      if (this.query) {
        this.queries = [{name: "Query", query: this.query}];
      } else {
        if (this.queriesSimple) {
          this.queries = [];
          this.queriesSimple.forEach((q) => {
            this.queries.push({name: "Query", query: q});
          });
        }
      }
    }
  }

  initCopyBtns() {
    setTimeout(()=> {
      let c = this.h.initClipboardAttrButtons(this.clipboards, this.modal);
      if (c) {
        this.clipboards = c;
      } else {
        this.clipboards = null;
      }
    }, 60);
  }

  openModal() {
    this.init();
    if (this.modal && this.modal.nativeElement) {
      $(this.modal.nativeElement).modal('show');
      this.initCopyBtns();
    }
  }
}
