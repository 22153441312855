import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ISaverLandingToggle} from "../../interfaces/ISaverLandingToggle";
import {ISaverLandingDistributor} from "../../interfaces/ISaverLandingDistributor";

@Component({
  selector: 'sl-distributor-cb',
  templateUrl: './sl-distributor-cb.html',
  styleUrls: ['./sl-distributor-cb.css'],
})
export class SlDistributorCbCmp {
  @Input() label: string;
  @Input() id: number;
  @Input() checked: boolean;
  @Input() answers: string;
  @Input() distributors: [ISaverLandingDistributor];
  @Output() toggle: EventEmitter<ISaverLandingToggle> = new EventEmitter<ISaverLandingToggle>();

  ngOnChanges() {
    if (this.answers && this.id) {
      this.checked = (this.answers.split(',').indexOf(this.id.toString()) > -1);
    }
    if (!this.label && this.distributors && this.id) {
      this.distributors.forEach((d: ISaverLandingDistributor)=> {
        if (d.id == this.id) {
          this.label = d.name;
        }
      })
    }
  }

  toggleEmit() {
    this.checked = !this.checked;
    this.toggle.emit({
      checked: this.checked,
      id: this.id
    });
  }
}
