import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {ICot} from '../interfaces/ICot';
import {ICotInternal} from "../interfaces/ICotInternal";

@Injectable()
export class Cots {
  private config:Config;
  private session:Session;
  private http: HttpClient;
  private list:Array<ICot> = [];
  private loadingPromise;
  private listInternal:Array<ICotInternal> = [];
  private loadingPromiseInternal;

  constructor(config:Config, http: HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  load() {
    if (this.list && this.list.length > 0) {
      return new Promise((resolve)=> {
        resolve(this.list);
      });
    }
    if (this.loadingPromise) {
      return this.loadingPromise;
    }
    this.loadingPromise = new Promise((resolve, reject)=> {
      this.http.get(this.config.apiUrl+'/cot')

        .subscribe((objects)=> {
          this.loadingPromise = null;
          if (objects instanceof Array) {
            this.list = objects;
            resolve(this.list);
          } else {
            reject();
          }
        }, ()=> {
          this.loadingPromise = null;
          reject();
        });
    });
    return this.loadingPromise;
  }

  loadInternal() {
    if (this.listInternal && this.listInternal.length > 0) {
      return new Promise((resolve)=> {
        resolve(this.listInternal);
      });
    }
    if (this.loadingPromiseInternal) {
      return this.loadingPromiseInternal;
    }
    this.loadingPromiseInternal = new Promise((resolve, reject)=> {
      this.http.get(this.config.apiUrl+'/cot_internal')

        .subscribe((objects)=> {
          this.loadingPromiseInternal = null;
          if (objects instanceof Array) {
            this.listInternal = objects;
            resolve(this.listInternal);
          } else {
            reject();
          }
        }, ()=> {
          this.loadingPromiseInternal = null;
          reject();
        });
    });
    return this.loadingPromiseInternal;
  }

  loadToMap(map:{[id:number]:ICot;}, callback?) {
    this.load().then((cots)=> {
      if (cots && cots.length > 0) {
        cots.forEach((s)=> {
          map[s.id] = s;
        });
        if (callback) {callback();}
      }
    }, ()=> {});
  }

  loadToList(list:ICot[]) {
    this.load().then((cots)=> {
      if (cots && cots.length > 0) {
        cots.forEach((s)=> {
          list.push(s);
        });
      }
    }, ()=> {});
  }

  loadInternalToMap(map:{[id:number]:ICotInternal;}, filter?) {
    this.loadInternal().then((cots)=> {
      if (cots && cots.length > 0) {
        cots.forEach((s)=> {
          if (filter && !filter(s)) {
            return;
          }
          map[s.id] = s;
        });
      }
    }, ()=> {});
  }

  loadInternalToList(list:ICotInternal[], filter?, cb?) {
    this.loadInternal().then((cots)=> {
      if (cots && cots.length > 0) {
        cots.forEach((s)=> {
          if (filter && !filter(s)) {
            return;
          }
          list.push(s);
        });
      }
      if (cb) {
        cb();
      }
    }, ()=> {});
  }
}
