import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {ICompleteRxTaskReq, Tasks} from "../../services/Tasks";
import {Notify} from '../../services/Notify';
import {Reports} from "../../services/Reports";
import {Helpers} from "../../services/Helpers";

export interface IRxTasksReportLine {
  parent_pc_id: string;
  pc_id: string;
  dba: string;
  rx_pa_date: string;
  pharma_eff_date: string;
  merck_use: boolean;
  merck_task_created: boolean;
  merck_task_completed: boolean;
  gsk_use: boolean;
  gsk_task_created: boolean;
  gsk_task_completed: boolean;
  task_merck_id?: number;
  task_gsk_id?: number;
  loc_id?: number;
}

export interface IRxTasksReport {
  query: string;
  records: IRxTasksReportLine[];
}

@Component({
  templateUrl: 'rx_tasks.html',
  selector: 'rx-tasks-report',
})
export class ReportRxTasksCmp {
  @Input('report') report:IRxTasksReport;
  @Output() update = new EventEmitter();
  @ViewChild('completeTaskModal') completeTaskModal:ElementRef;

  public sortDesc = false;
  public orderField:string = '';
  public loading = false;
  public current:IRxTasksReportLine;
  public currentTaskName:string = '';

  constructor(private taskSrv:Tasks, private reports:Reports, private h:Helpers) {}

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  createCompleteTask(line:IRxTasksReportLine, task_name:string) {
    if (!line || line['__creating_task']) {
      return;
    }
    this.current = line;
    this.currentTaskName = task_name;
    setTimeout(()=> {
      this.h.showModal(this.completeTaskModal);
    }, 1);
  }

  confirmCompleteTask() {
    this.h.hideModal(this.completeTaskModal);
    if (!this.current) {
      return;
    }
    let line = this.current;
    let task_name = this.currentTaskName;
    if (line['__creating_task']) {
      return;
    }
    let r:ICompleteRxTaskReq = {task_name: task_name, location_id: line.loc_id};
    if (line.task_gsk_id && task_name == 'gsk') {
      r.task_q_id = line.task_gsk_id;
    } else {
      if (line.task_merck_id && task_name == 'merck') {
        r.task_q_id = line.task_merck_id;
      }
    }
    line['__creating_task'] = true;
    this.taskSrv.completeRxTask(r).subscribe(()=> {
      this.loadReport(()=> {
        line['__creating_task'] = false;
      });
    }, (err)=> {
      line['__creating_task'] = false;
      let n = new Notify();
      n.httpErr(err);
    });
  }

  loadReport(cb?) {
    this.loading = true;
    this.reports.getRxTasks().subscribe((report) => {
      this.loading = false;
      this.report = report;
      if (cb) {cb();}
    }, (err) => {
      this.loading = false;
      let n = new Notify();
      n.error(`Can't load report - server error`);
      console.error(err);
      this.update.emit();
    });
  }

  cancelCompleteTaskDialog() {
    this.h.hideModal(this.completeTaskModal);
    setTimeout(()=> {
      this.current = null;
      this.currentTaskName = '';
    }, 1);
  }
}
