import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {Tasks} from "../../services/Tasks";
import {ITask} from "../../interfaces/ITask";
import {User} from "../../services/User";
import {IUserFromEmail} from "../../interfaces/IUserFromEmail";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {ITaskMeta} from "../../interfaces/ITaskMeta";
import {Notify} from "../../services/Notify";
import {IUser} from "../../interfaces/IUser";
import {Helpers} from "../../services/Helpers";

declare var $: any;

@Component({
  selector: 'tasks-list',
  templateUrl: './tasks.html',
})
export class TasksListCmp {
  @Input() location_id: number;
  @Output() setCount = new EventEmitter();
  @ViewChild('newCustomTaskModal') newCustomTaskModal: ElementRef;
  @ViewChild('reassignModal') reassignModal: ElementRef;
  public loading = false;
  public tasks: ITask[] = [];
  public fromEmails: IUserFromEmail[];
  public displayAll = false;
  public tab = 1;
  public searchTerm: string;
  public metasList: ITaskMeta[];
  public metasFilter: number[] = [];
  public managersList;
  public managersFilter: number[] = [];
  public newCustomTask: ITask;
  public creatingCustomTask = false;
  public selectedItems: ITask[] = [];
  public reassignment = false;
  public newAssignedUser: IUser;
  public assignUsersHandler;
  private n = new Notify();

  constructor(public taskService: Tasks, public users: User, public session: Session, public config: Config, private h: Helpers) {
    this.session.addOnLoadHandler(() => {
      this.users.getUsersFromEmails().subscribe((emails) => {
        this.fromEmails = emails;
      }, () => {});
    });
    this.session.redirectIfNotAuth();
    this.assignUsersHandler = (users) => {
      this.assignUsersHandlerF(users);
    };
  }

  setTab(t) {
    this.tab = t;
  }

  getVisibleTasks() {
    if (!this.tasks || this.tasks.length < 1) {
      return [];
    }
    let tasks = [];
    this.tasks.forEach((t) => {
      if (!this.isHidden(t)) {
        tasks.push(t);
      }
    });
    return tasks;
  }

  isHidden(t: ITask): boolean {
    if (this.metasFilter && this.metasFilter.length > 0) {
      if (this.metasFilter.indexOf(t.meta.id) < 0) {
        return true;
      }
    }
    if (this.managersFilter && this.managersFilter.length > 0) {
      if (this.managersFilter.indexOf(t.user_id) < 0) {
        return true;
      }
    }
    if (this.getTabForTask(t) != this.tab) {
      return true;
    }
    if (this.isTimeInPast(t.due_date)) {
      t['__past_due_date'] = 1;
    }
    if (!this.location_id && !this.displayAll && t.user_id != this.session.user.id) {
      return true;
    }
    if (this.tab == 4 && this.searchTerm && this.searchTerm.length > 0) {
      if (!t.__facility_name) {
        return true;
      }
      if (t.__facility_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) < 0) {
        return true;
      }
    }
    return false;
  }

  isTimeInPast(d: string): boolean {
    try {
      if (!d) {
        return true;
      }
      let today = new Date();
      let that_day = new Date(d);
      if (that_day.getTime() < today.getTime()) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  getTabForTask(t: ITask) {
    switch (this.taskService.getBoxOfTask(t)) {
      case 'SENDING ERROR':
        return 6;
      case 'CANCELED':
        return 5;
      case 'POSTPONED':
        return 3;
      case 'SENT':
        return 4;
      case 'OUTBOX':
        return 2;
      default:
        return 1;
    }
  }

  ngOnInit() {
    this.loadTasks();
  }

  ngOnChanges() {
    this.loadTasks();
  }

  loadTasks() {
    if (this.loading) {
      return;
    }
    this.setCount.emit(0);
    let refresh = true;
    if (!this.tasks || this.tasks.length < 1) {
      refresh = false;
    }
    this.loading = true;
    let x;
    if (!this.location_id) {
      x = this.taskService.getTasks(refresh);
    } else {
      x = this.taskService.getLocationTasks(this.location_id);
    }
    x.subscribe((results) => {
      this.tasks = results;
      this.selectedItems = [];
      let cnt = 0;
      if (results.length > 0) {
        this.tasks.forEach((t) => {
          if (this.getTabForTask(t) == 1 && t.user_id == this.session.user.id) {
            cnt++;
          }
        });
      }
      this.setCount.emit(cnt);
      this.loading = false;
      this.createMetasList();
      this.createManagersList();
    }, () => {
      this.loading = false;
    });
  }

  createMetasList() {
    if (!this.tasks || !this.tasks.length) {
      return;
    }
    this.metasList = [];
    let ids = {};
    this.tasks.forEach((t: ITask) => {
      if (!t.meta) {
        return;
      }
      if (!ids[t.meta.id]) {
        ids[t.meta.id] = true;
        this.metasList.push(t.meta);
      }
    });
  }

  createManagersList() {
    if (!this.tasks || !this.tasks.length) {
      return;
    }
    this.managersList = [];
    let ids = {};
    this.tasks.forEach((t: ITask) => {
      if (!t.user_id) {
        return;
      }
      if (!ids[t.user_id]) {
        ids[t.user_id] = true;
        this.managersList.push({id: t.user_id, name: t.__user_name});
      }
    });
  }

  toggleFilterMeta(m: ITaskMeta) {
    let id = m.id;
    let i = this.metasFilter.indexOf(id);
    if (i < 0) {
      this.metasFilter.push(id);
    } else {
      this.metasFilter.splice(i, 1);
    }
  }

  resetFilterMeta() {
    this.metasFilter = [];
  }

  toggleFilterManager(m) {
    let id = m.id;
    let i = this.managersFilter.indexOf(id);
    if (i < 0) {
      this.managersFilter.push(id);
    } else {
      this.managersFilter.splice(i, 1);
    }
  }

  resetFilterManager() {
    this.managersFilter = [];
  }

  openNewCustomTaskModal() {
    this.newCustomTask = <ITask>{task_id: this.config.TASK_CUSTOM_TYPE_DONE, user_id: this.session.user.id};
    if (this.location_id > 0) {
      this.newCustomTask.location_id = this.location_id;
    }
    if (this.newCustomTaskModal && this.newCustomTaskModal.nativeElement) {
      $(this.newCustomTaskModal.nativeElement).modal('show');
    }
  }

  closeNewCustomTaskModal() {
    if (this.newCustomTaskModal && this.newCustomTaskModal.nativeElement) {
      $(this.newCustomTaskModal.nativeElement).modal('hide');
    }
  }

  setCustomTaskLocation(loc) {
    if (!loc || !loc.id || !this.newCustomTask) {
      return;
    }
    this.newCustomTask.location_id = loc.id;
  }

  createCustomTask(task: ITask) {
    if (!task || this.creatingCustomTask) {
      return;
    }
    if (!task.due_date) {
      this.n.warning('"Due date" is required');
      return;
    }
    if (!task.override_name) {
      this.n.warning('"Task name" is required');
      return;
    }
    if (!task.location_id) {
      this.n.warning('"Location" is required');
      return;
    }
    if (this.isTimeInPast(task.due_date)) {
      this.n.error('Please check that Due Date is not in the past');
      return;
    }
    if (!task.task_id) {
      task.task_id = this.config.TASK_CUSTOM_TYPE_DONE;
    }
    this.creatingCustomTask = true;
    this.taskService.createTask(task).subscribe(() => {
      this.creatingCustomTask = false;
      this.n.success('Created');
      this.loadTasks();
      this.closeNewCustomTaskModal();
    }, (err) => {
      this.creatingCustomTask = false;
      this.n.error(err._body);
    });
  }

  reassignSelected(confirmed?) {
    if (this.selectedItems.length < 1) {
      return;
    }
    if (!confirmed) {
      this.openReassignModal();
      return;
    }
    if (!this.newAssignedUser || !this.newAssignedUser.id) {
      return;
    }
    this.reassignment = true;
    let q: number = 0;
    let done = () => {
      if (q < 1) {
        this.reassignment = false;
        this.closeReassignModal();
        this.loadTasks();
      }
    };
    this.selectedItems.forEach((t) => {
      q++;
      t.user_id = this.newAssignedUser.id;
      this.taskService.updateTask(t).subscribe(() => {
        q--;
        done();
      }, () => {
        q--;
        done();
      });
    });
  }

  addToSelected(t: ITask) {
    let i = this.selectedItems.indexOf(t);
    if (i < 0) {
      this.selectedItems.push(t);
    }
  }

  removeFromSelected(t: ITask) {
    let i = this.selectedItems.indexOf(t);
    if (i > -1) {
      this.selectedItems.splice(i, 1);
    }
  }

  openReassignModal() {
    this.closeReassignModal();
    if (this.reassignModal && this.reassignModal.nativeElement) {
      $(this.reassignModal.nativeElement).modal('show');
    }
  }

  closeReassignModal() {
    if (this.reassignModal && this.reassignModal.nativeElement) {
      $(this.reassignModal.nativeElement).modal('hide');
    }
  }

  setNewAssignedUser(user: IUser) {
    this.newAssignedUser = user;
  }

  assignUserDisplay(user: IUser) {
    return `${user.fname} ${user.lname}`;
  }

  assignUsersHandlerF(users: IUser[]) {
    this.h.leaveInArr(users, (u: IUser) => {
      return (u.cm_access_level > 19);
    });
  }
}

