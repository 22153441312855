import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";
import {Router} from '@angular/router';

@Component({
  selector: 'login',
  templateUrl: './login.html',
})
export class LoginCmp {
  public loading;
  public error = 0;
  public require2step = false;

  constructor(public session:Session, public http:HttpClient, public config:Config, public router:Router) {
  }

  clearErrors() {
    this.error = 0;
  }

  login(username, password, totp) {
    this.loading = true;
    this.error = 0;
    this.http.post(this.config.apiUrl + '/auth', JSON.stringify({
      username: username.value,
      password: password.value,
      totp_code: totp.value,
      app: 'crm'
    }), {responseType: 'text', observe: 'response'}).subscribe((response) => {
      username.value = '';
      password.value = '';
      totp.value = '';
      this.require2step = false;
      this.session.setToken(response.body);
      this.session.loadUser();
      this.router.navigate(['/']);
      this.loading = false;
    }, (response)=> {
      if (response.status === 417 || response.status === 424) {
        this.require2step = true;
        if (!totp.value) {
          this.error = 417;
        } else {
          this.error = response.status;
        }
        try {
          let totp_el = document.getElementById('totp');
          if (totp_el) {
            setTimeout(()=> {
              totp_el.focus();
            }, 0);
          }
        } catch (e) {}
      }
      this.loading = false;
    });
  }
}
