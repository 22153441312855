import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {User} from "../../services/User";
import {Locations} from "../../services/Locations";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";
import {IFile} from "../../interfaces/IFile";

@Component({
  selector: 'files',
  templateUrl: './files.html',
})
export class FilesCmp {
  @Input() files = [];
  @Input() userID = 0;
  @Input() locationID = 0;
  @ViewChild('modalMove') modalMove:ElementRef;

  public fileTypesMap = {};
  public fileTypesList = [];
  public loading = false;
  public newFileData:File;
  public newFileName:string;
  public newFileType:number;
  public uploadingFile:boolean = false;
  public generatingSanofiRx = false;
  public generatingGskRx = false;
  public generatingMerckRx = false;
  public fileToMove:IFile;
  public destLocationID;
  public moving = false;

  constructor(public users:User, public locations:Locations, private h:Helpers) {
    this.users.loadFileTypes(this.fileTypesMap, this.fileTypesList);
  }

  ngOnChanges() {
    this.loadFilesList();
  }

  loadFilesList() {
    if (this.loading) {
      return;
    }
    if (this.userID > 0) {
      this.loading = true;
      this.users.getFiles(this.userID).subscribe((files) => {
        this.files = files;
        this.loading = false;
      }, ()=> {
        this.loading = false;
      });
    } else {
      if (this.locationID > 0) {
        this.loading = true;
        this.locations.getFiles(this.locationID).subscribe((files) => {
          this.files = files;
          this.loading = false;
        }, ()=> {
          this.loading = false;
        });
      }
    }
  }

  addFile(files) {
    if (!files || !files[0]) {
      return;
    }
    this.newFileName = files[0].name;
    this.newFileData = files[0];
    if (this.fileTypesList && this.fileTypesList.length) {
      this.newFileType = this.fileTypesList[0].id;
    }
  }

  uploadFile() {
    if (!this.locationID || !this.newFileName || !this.newFileData) {
      return false;
    }
    this.uploadingFile = true;
    this.locations.uploadFile(this.locationID, this.newFileName, this.newFileData, this.newFileType).subscribe(()=> {
      this.uploadingFile = false;
      this.loadFilesList();
      let n = new Notify();
      n.success('Successfully!');
      this.resetFileUpload();
    }, (err)=> {
      this.uploadingFile = false;
      let n = new Notify();
      n.error('Error');
      console.error(err);
    });
  }

  resetFileUpload() {
    this.newFileData = null;
    this.newFileName = null;
  }

  generateSanofiRxPDF() {
    if (!this.locationID) {
      return;
    }
    this.generatingSanofiRx = true;
    let n = new Notify();
    let sanofi_err = 'Check if location has GPO ID';
    this.locations.generateSanofiRxPDF(this.locationID).subscribe((id)=> {
      this.generatingSanofiRx = false;
      if (id) {
        n.success('Sanofi PDF generated');
        this.loadFilesList();
      } else {
        n.error('Sanofi PDF has not been generated', sanofi_err);
      }
    }, ()=> {
      this.generatingSanofiRx = false;
      n.error('Sanofi PDF has not been generated', sanofi_err);
    });
  }

  generateGskRxPDF() {
    if (!this.locationID) {
      return;
    }
    this.generatingGskRx = true;
    let n = new Notify();
    let gsk_err = 'Check if location has GPO ID and GSK option was selected on Rx PA submission';
    this.locations.generateGskRxPDF(this.locationID).subscribe((ids)=> {
      this.generatingGskRx = false;
      if (ids && ids.length > 0) {
        n.success('GSK PDF generated');
        this.loadFilesList();
      } else {
        n.error('GSK PDF has not been generated', gsk_err);
      }
    }, ()=> {
      this.generatingGskRx = false;
      n.error('GSK PDF has not been generated', gsk_err);
    });
  }

  generateMerckRxPDF() {
    if (!this.locationID) {
      return;
    }
    this.generatingMerckRx = true;
    let n = new Notify();
    let merck_err = 'Check if location has GPO ID and Merck option was selected on Rx PA submission';
    this.locations.generateMerckRxPDF(this.locationID).subscribe((ids)=> {
      this.generatingMerckRx = false;
      if (ids && ids.length > 0) {
        n.success('Merck PDF generated');
        this.loadFilesList();
      } else {
        n.error('Merck PDF has not been generated', merck_err);
      }
    }, ()=> {
      this.generatingMerckRx = false;
      n.error('Merck PDF has not been generated', merck_err);
    });
  }

  openMoveDialog(fileToMove) {
    this.fileToMove = fileToMove;
    this.h.showModal(this.modalMove);
  }

  setDestLocation($event) {
    if ($event && $event.id) {
      this.destLocationID = $event.id;
    }
  }

  moveFile() {
    if (this.destLocationID && this.fileToMove) {
      this.moving = true;
      this.fileToMove.location_id = this.destLocationID;
      this.locations.updateFile(this.fileToMove).subscribe(()=> {
        this.moving = false;
        this.fileToMove = null;
        this.destLocationID = null;
        this.h.hideModal(this.modalMove);
        this.loadFilesList();
      }, (err)=> {
        this.moving = false;
        let n = new Notify();
        n.httpErr(err);
      });
    }
  }
}
