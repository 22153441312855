
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {IDeaSearchResult} from "../interfaces/IDeaSearchResult";
import {IDeaCachedResponse} from "../interfaces/IDeaCachedResponse";

@Injectable()
export class DeaSearch {
  private config:Config;
  private session:Session;
  private http:HttpClient;

  constructor(config:Config, http:HttpClient, session:Session) {
    this.config = config;
    this.http = http;
    this.session = session;
  }

  search(dea?:string, company?:string, zip?:string, state?:string):Observable<IDeaCachedResponse> {
    if (!dea || dea.length < 1) {
      dea = null;
    }
    if (!company || company.length < 1) {
      company = null;
    }
    if (!zip || zip.length < 1) {
      zip = null;
    }
    if (!state || state.length < 1) {
      state = null;
    }
    if (!dea && !company && !zip && !state) {
      return observableOf({
        response: null,
        cache_date: null,
        cache_id: null
      });
    }
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post<IDeaCachedResponse>(this.config.apiUrl + '/dea_search', JSON.stringify({
      dea: dea,
      company: company,
      zip: zip,
      state: state,
    }), {headers: headers}).pipe(map((r:IDeaCachedResponse) => {
      let t = r;
      if (t.response) {
        let x:any = t['response'];
        t.response = JSON.parse(<string>x);
      }
      return t;
    }));
  }

  removeCache(id:number):Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/dea_search/' + id, {headers: headers, responseType: 'text'});
  }
}
