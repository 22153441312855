import {Component} from "@angular/core";
import {ILocNewPharmaData} from "../../interfaces/IPharmaCheck";
import {ILocEmptyPharmaData} from "../../interfaces/IPharmaCheck";
import {IPopupQuery} from "../../interfaces/IPopupQuery";
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";

@Component({
  templateUrl: 'report.html',
  selector: 'pharma-check-report'
})
export class PharmaCheckReportCmp {
  public results:ILocEmptyPharmaData[] = [];
  public loading = true;
  public queries:IPopupQuery[] = [];
  private n = new Notify();

  constructor(private session:Session, private reports:Reports) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.results = [];
    this.loading = true;
    this.queries = [];
    this.reports.getPharmaCheckReport().subscribe((report)=> {
      this.results = report.report;
      this.queries = [{query: report.query, name: 'Query'}];
      this.loading = false;
    }, (err)=> {
      this.loading = false;
      this.n.error('Error', JSON.stringify(err));
    });
  }

  selectAll() {
    if (!this.results || this.results.length < 1) {
      return;
    }
    let found = false;
    this.results.forEach((r)=> {
      if (!r.__is_checked) {
        r.__is_checked = true;
        found = true;
      }
    });
    if (!found) {
      this.results.forEach((r)=> {
        r.__is_checked = false;
      });
    }
  }

  updateSelected() {
    if (!this.results || this.results.length < 1) {
      return;
    }
    let data:ILocNewPharmaData[] = [];
    this.results.forEach((r)=> {
      if (r.id && r.__is_checked) {
        data.push({id: r.id, hin: (r.loc_hin || r.fh_hin), pharma_eff_date: (r.loc_pharma_eff_date || r.fh_pharma_eff_date)});
      }
    });
    if (data.length > 0) {
      this.loading = true;
      this.reports.setPharmaEmptyLocs(data).subscribe(()=> {
        this.load();
        this.n.success('Updated');
      }, (err)=> {
        this.n.error('Update Error', JSON.stringify(err));
        this.load();
      });
    }
  }
}
