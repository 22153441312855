
import {of as observableOf, Observable} from 'rxjs';

import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from "./Config";
import {Session} from "./Session";
import {IManufacturer} from "../interfaces/IManufacturer";

@Injectable()
export class Manufacturers {
  private loadingObservable:Observable<any>;
  private list:Array<IManufacturer> = [];

  constructor(private http: HttpClient, private config:Config, private session:Session) {}

  load():Observable<Object> {
    if (this.list && this.list.length > 0) {
      return observableOf(this.list);
    }
    if (this.loadingObservable) {
      return this.loadingObservable;
    }
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    this.loadingObservable = this.http.get(this.config.apiUrl+'/manufacturer', {headers: headers}).pipe(
      share());
    this.loadingObservable.subscribe((items)=> {
      this.list = items;
    },()=>{});
    return this.loadingObservable;
  }

  update(m:IManufacturer):Observable<Object> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl+'/manufacturer', JSON.stringify(m), {headers: headers, responseType: 'text'});
  }
}
