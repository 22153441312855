import {ElementRef} from '@angular/core';
import {Notify} from "./Notify";

declare var ClipboardJS:any;
declare var $:any;

export class ClipboardHelper {
  private selectCopy = false;

  initCopyBtn(data:string, btnElement:ElementRef, container?:ElementRef) {
    setTimeout(() => {
      try {
        if (data && ClipboardJS && btnElement && btnElement.nativeElement) {
          let clp;
          if (container) {
            clp = new ClipboardJS(btnElement.nativeElement, {
              container: container.nativeElement
            });
          } else {
            clp = new ClipboardJS(btnElement.nativeElement);
          }
          clp.on('success', (e) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clp.on('error', () => {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
        }
      } catch (e) {}
    }, 60);
  }

  copyClick($event) {
    $event.stopPropagation();
    setTimeout(() => {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e) => {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(() => {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }
}
