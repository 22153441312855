import {Component, Input} from '@angular/core';
import {IReviewResultSubQuestion} from "../../interfaces/IReviewResultSubQuestion";

@Component({
  selector: 'answer-text',
  templateUrl: './answer_text.html'
})
export class AnswerTextCmp {
  @Input() answer:IReviewResultSubQuestion;
}
