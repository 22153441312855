export class ILocationDistributor {
  id:number = 0;
  type_id:number = 1;
  location_id:number = 0;
  distributor_id:number = 0;
  level:number = 0;
  bill_to:string = '';
  ship_to:string = '';
  sales_plan:string = '';
  rep_user_id:number = 0;
  __distr_name:string = '';
  __rep_name:string = '';
}
