import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CostStudy} from "../../services/CostStudy";
import {Session} from "../../services/Session";
import {ICsResult, ICsQuery, ICsResultsStats, ICsUploadInfo, IReqCsSkipMatch} from "../../interfaces/ICostStudy";
import {Notify} from "../../services/Notify";
import {IMissingUomConversion} from "../../interfaces/ICostStudy";
import {Helpers} from "../../services/Helpers";
declare var $:any;

@Component({
  selector: "cs-results",
  templateUrl: "results.html"
})
export class CsResultsCmp {
  @Input() csUploadID;
  @ViewChild('queriesModal') queriesModal:ElementRef;
  @ViewChild('manufacturerMoveModal') manufacturerMoveModal:ElementRef;
  @ViewChild('rerunModal') rerunModal:ElementRef;
  @ViewChild('askSkipMatch') askSkipMatch:ElementRef;
  @ViewChild('askKeepManualMatches') askKeepManualMatches:ElementRef;
  @ViewChild('noteModal') noteModal:ElementRef;
  @ViewChild('uomConversionModal') uomConversionModal:ElementRef;
  @ViewChild('uomPharmaModal') uomPharmaModal:ElementRef;
  @ViewChild('metaInfoModal') metaInfoModal:ElementRef;
  public exactResults:ICsResult[];
  public potentialResults:ICsResult[];
  public n = new Notify();
  public loaded = false;
  public searchStatus = '';
  public page = 0;
  public ppage = 0;
  public perPage = 300;
  public searchCount = 0;
  public searchPCount = 0;
  public stats:ICsResultsStats;
  public info:ICsUploadInfo;
  public tab:number = 1;
  public rerunning = false;
  public pageLoading = false;
  public pagePLoading = false;
  public queries:ICsQuery[];
  public exportUrl = '';
  public movingManufacturer = false;
  public manufacturerSrc = '';
  public manufacturerMatched = '';
  public skipMatchPr:ICsResult;
  public modalEditResult:ICsResult;
  public newVendorsFoundCnt;
  private skipMatchR:ICsResult;
  public modalUomConversionItem:IMissingUomConversion;
  public modalUomPharmaItem:ICsResult;
  public qErrors:string[] = [];
  private loadingThreads = 0;
  private metaInfoModalTriggered = false;

  constructor(private csSrv:CostStudy, private session:Session, private params:ActivatedRoute, private h:Helpers) {
    this.params.params.subscribe(params => {
      if (params && params['id']) {
        this.csUploadID = parseInt(params['id']);
        this.initLoad();
      }
    });
    this.session.addOnLoadHandler(()=> {
      this.initLoad();
    });
    this.session.redirectIfNotAuth();
  }

  initLoad() {
    if (!this.csUploadID) {
      return;
    }
    if (this.searchStatus == '') {
      this.searchStatus = 'In Progress';
      this.loadInfo();
      this.load();
    }
  }

  loadInfo() {
    this.csSrv.getCsUploadInfo(this.csUploadID).subscribe((response) => {
      this.info = response;
    }, ()=> {});
  }

  setTab(t) {
    this.tab = parseInt(t);
  }

  reset() {
    this.exactResults = null;
    this.potentialResults = null;
    this.loaded = false;
    this.searchStatus = '';
    this.page = 0;
    this.searchCount = 0;
    this.searchPCount = 0;
    this.stats = null;
    this.tab = 1;
    this.rerunning = false;
    this.pageLoading = false;
    this.pagePLoading = false;
    this.queries = null;
    this.exportUrl = '';
    this.manufacturerSrc = '';
    this.manufacturerMatched = '';
    this.movingManufacturer = false;
    this.skipMatchPr = null;
    this.skipMatchR = null;
  }

  fillPotentialResults(results:ICsResult[]) {
    if (!results || results.length < 1) {
      return;
    }
    this.potentialResults = [];
    results.forEach((r:ICsResult)=> {
      let found = false;
      this.potentialResults.forEach((pr:ICsResult)=> {
        if (r.cs_search_data_id > 0 && pr.cs_search_data_id == r.cs_search_data_id) {
          found = true;
          if (!pr.__potential) {
            pr.__potential = [r];
          } else {
            pr.__potential.push(r);
          }
        }
      });
      if (!found) {
        r.__potential = [JSON.parse(JSON.stringify(r))];
        this.potentialResults.push(r);
      }
    });
  }

  load() {
    if (!this.csUploadID) {
      return;
    }
    if (this.loadingThreads > 0) {
      return;
    }
    this.loadingThreads++;
    this.csSrv.getCsResults(this.csUploadID, this.page * this.perPage, this.perPage, "exact").subscribe((response) => {
        this.loadingThreads--;
        this.pageLoading = false;
        if (response && response.results && response.results.length > 0) {
          this.exportUrl = this.csSrv.getCsResultsCsvUrl(this.csUploadID);
        } else {
          this.exportUrl = '';
        }
        if (!this.exactResults || (response.results.length != this.exactResults.length) || (JSON.stringify(this.exactResults) != JSON.stringify(response.results))) {
          this.exactResults = response.results;
        }
        if (response.count) {
          this.searchCount = response.count;
        }
        if (response.stats) {
          this.stats = response.stats;
        }
        if (response.queries) {
          if (!this.queries || this.queries.length != response.queries.length || JSON.stringify(this.queries) != JSON.stringify(response.queries)) {
            this.queries = response.queries;
            this.checkForErrorInQueries();
          }
        }
        this.searchStatus = '';
        if (response.status) {
          this.searchStatus = response.status;
        } else {
          this.searchStatus = "Completed";
        }
        if (this.searchStatus != "Completed") {
          setTimeout(()=> {
            this.load();
          }, 3000);
        } else {
          this.loaded = true;
        }
      }, ()=> {
        this.loadingThreads--;
        this.pageLoading = false;
      }
    );
    this.csSrv.getCsResults(this.csUploadID, this.ppage * this.perPage, this.perPage, "potential").subscribe((response) => {
        this.pagePLoading = false;
        if (!this.potentialResults || (response.results.length != this.potentialResults.length) || (JSON.stringify(this.potentialResults) != JSON.stringify(response.results))) {
          this.fillPotentialResults(response.results);
        }
        if (response.count) {
          this.searchPCount = response.count;
        }
      }, ()=> {
        this.pagePLoading = false;
      }
    );
  }

  checkForErrorInQueries() {
    if (!this.queries || this.queries.length < 1) {
      return;
    }
    this.qErrors = [];
    this.queries.forEach((q:ICsQuery)=> {
      if (q.error_text) {
        this.qErrors.push(q.error_text);
      }
    });
  }

  rerun() {
    this.closeRerunModal();
    this.openRerunKeepManualModal();
  }

  rerunAnsw(keep) {
    this.closeRerunKeepManualModal();
    this.rerunning = true;
    this.csSrv.rerunCsSearch(this.csUploadID, keep > 0).subscribe(() => {
      this.rerunning = false;
      this.reset();
      this.initLoad();
    }, () => {
      this.rerunning = false;
    });
  }

  nextPage() {
    if (this.searchCount > 0 && ((this.page + 1) * this.perPage) >= this.searchCount) {
      return;
    }
    this.page++;
    this.pageLoading = true;
    if (this.loaded) {
      this.load();
    }
  }

  prevPage() {
    if (this.page < 1) {
      return;
    }
    this.page--;
    this.pageLoading = true;
    if (this.loaded) {
      this.load();
    }
  }

  nextPPage() {
    if (this.searchPCount > 0 && ((this.ppage + 1) * this.perPage) >= this.searchPCount) {
      return;
    }
    this.ppage++;
    this.pagePLoading = true;
    if (this.loaded) {
      this.load();
    }
  }

  prevPPage() {
    if (this.ppage < 1) {
      return;
    }
    this.ppage--;
    this.pagePLoading = true;
    if (this.loaded) {
      this.load();
    }
  }

  updateResult(result:ICsResult) {
    if (result.__updating || !result.id) {
      return;
    }
    result.__updating = true;
    this.csSrv.updateCsResult(result).subscribe(()=> {
      result.__updating = false;
      result.__edit = false;
      if (this.noteModal && this.noteModal.nativeElement) {
        $(this.noteModal.nativeElement).modal('hide');
      }
      this.n.success('Updated');
    }, (err)=> {
      result.__updating = false;
      this.n.error('Error', err._body);
    });
  }

  convertToManualMatch(result:ICsResult) {
    if (result.__updating || !result.id) {
      return;
    }
    result.__updating = true;
    result.match_type = "Manual";
    result.match_confidence = 100;
    this.csSrv.updateCsResult(result).subscribe(()=> {
      result.__updating = false;
      this.manufacturerSrc = result.manufacturer;
      this.manufacturerMatched = result.fi_manufacturer;
      this.openManufacturerMoveModal();
    }, (err)=> {
      result.__updating = false;
      this.n.error('Error', err._body);
    });
  }

  openQueriesModal() {
    if (this.queries && this.queriesModal && this.queriesModal.nativeElement) {
      $(this.queriesModal.nativeElement).modal('show');
    }
  }

  openManufacturerMoveModal() {
    if (this.manufacturerMoveModal && this.manufacturerMoveModal.nativeElement) {
      $(this.manufacturerMoveModal.nativeElement).modal('show');
    }
  }

  closeManufacturerMoveModal() {
    if (this.manufacturerMoveModal && this.manufacturerMoveModal.nativeElement) {
      $(this.manufacturerMoveModal.nativeElement).modal('hide');
    }
  }

  openRerunModal() {
    if (this.rerunModal && this.rerunModal.nativeElement) {
      $(this.rerunModal.nativeElement).modal('show');
    }
  }

  closeRerunModal() {
    if (this.rerunModal && this.rerunModal.nativeElement) {
      $(this.rerunModal.nativeElement).modal('hide');
    }
  }

  openRerunKeepManualModal() {
    if (this.askKeepManualMatches && this.askKeepManualMatches.nativeElement) {
      $(this.askKeepManualMatches.nativeElement).modal('show');
    }
  }

  closeRerunKeepManualModal() {
    if (this.askKeepManualMatches && this.askKeepManualMatches.nativeElement) {
      $(this.askKeepManualMatches.nativeElement).modal('hide');
    }
  }

  openSkipMatchModal() {
    if (this.skipMatchPr && this.askSkipMatch && this.askSkipMatch.nativeElement) {
      $(this.askSkipMatch.nativeElement).modal('show');
    }
  }

  closeSkipMatchModal() {
    if (this.askSkipMatch && this.askSkipMatch.nativeElement) {
      $(this.askSkipMatch.nativeElement).modal('hide');
      setTimeout(()=> {
        this.skipMatchPr = null;
        this.skipMatchR = null;
      }, 1);
    }
  }

  openUomConversionModal(item:ICsResult) {
    if (item && item.universal_item_number && this.uomConversionModal && this.uomConversionModal.nativeElement) {
      this.modalUomConversionItem = {uin: item.universal_item_number, quantity: item.quantity, fi_quantity: item.fi_quantity, cs_upload_id: item.cs_upload_id, description: item.description, fi_description: item.fi_description, uom: item.uom, fi_uom: item.fi_uom};
      $(this.uomConversionModal.nativeElement).modal('show');
    }
  }

  closeUomConversionModal() {
    if (this.uomConversionModal && this.uomConversionModal.nativeElement) {
      $(this.uomConversionModal.nativeElement).modal('hide');
    }
  }

  openUomPharmaModal(item:ICsResult) {
    if (item && item.ndc_mfr_catalog_number && this.uomPharmaModal && this.uomPharmaModal.nativeElement) {
      this.modalUomPharmaItem = item;
      $(this.uomPharmaModal.nativeElement).modal('show');
    }
  }

  closeUomPharmaModal() {
    if (this.uomPharmaModal && this.uomPharmaModal.nativeElement) {
      $(this.uomPharmaModal.nativeElement).modal('hide');
    }
  }

  updatedUomPharma() {
    this.closeUomPharmaModal();
    this.openRerunModal();
  }

  cancelManufacturerMove() {
    this.closeManufacturerMoveModal();
    this.reset();
    this.load();
  }

  cancelRerun() {
    this.closeRerunKeepManualModal();
    this.closeRerunModal();
    this.load();
  }

  moveManufacturerToGroup(manufacturer_src:string, manufacturer_matched:string) {
    if (!manufacturer_src || !manufacturer_matched) {
      return;
    }
    this.movingManufacturer = true;
    this.csSrv.moveManufacturerToGroup(manufacturer_src, manufacturer_matched).subscribe(()=> {
      this.movingManufacturer = false;
      this.closeManufacturerMoveModal();
      this.openRerunModal();
      this.n.success('Success!');
    }, (err)=> {
      this.movingManufacturer = false;
      this.n.error('Error', err._body);
    });
  }

  skipMatch(r:ICsResult, pr:ICsResult) {
    if (!r || !pr || !pr.universal_item_number) {
      return;
    }
    this.skipMatchR = r;
    this.skipMatchPr = pr;
    this.openSkipMatchModal();
  }

  skipMatchCancel() {
    this.closeSkipMatchModal();
  }

  skipMatchAnsw(a) {
    if (!this.skipMatchR || !this.skipMatchPr || !this.skipMatchPr.universal_item_number || this.skipMatchPr.__updating) {
      return;
    }
    let r = this.skipMatchR;
    let pr = this.skipMatchPr;
    pr.__updating = true;
    let req:IReqCsSkipMatch = {
      src_vendor: r.manufacturer,
      src_item: r.item,
      src_description: r.description,
      target_uin: pr.universal_item_number,
      target_ndc: pr.ndc_mfr_catalog_number,
      cs_search_data_id: r.cs_search_data_id,
      target_item: pr.fi_item,
      target_vendor: pr.fi_manufacturer,
      target_description: pr.fi_description,
      result_id: pr.id,
      per_cs_upload_id: (a > 0 ? null : this.csUploadID)
    };
    this.csSrv.skipMatch(req).subscribe(()=> {
      pr.__updating = false;
      if (this.skipMatchR && this.skipMatchR.__potential && this.skipMatchR.__potential.length) {
        let i = this.skipMatchR.__potential.indexOf(pr);
        if (i > -1) {
          this.skipMatchR.__potential.splice(i, 1);
        }
      }
      this.closeSkipMatchModal();
    }, (err)=> {
      pr.__updating = false;
      this.n.error('Error', err._body);
    });
  }

  foundVendorsLoaded(x) {
    this.newVendorsFoundCnt = parseInt(x);
  }

  editNote(r) {
    if (!r) {
      return;
    }
    this.modalEditResult = r;
    if (this.noteModal && this.noteModal.nativeElement) {
      $(this.noteModal.nativeElement).modal('show');
    }
  }

  openInfoModal() {
    if (this.metaInfoModalTriggered) {
      this.h.hideModal(this.metaInfoModal);
    }
    this.metaInfoModalTriggered = true;
    this.h.showModal(this.metaInfoModal);
  }

  closeMetaInfoModal() {
    this.h.hideModal(this.metaInfoModal);
  }
}
