import {Component, Input} from '@angular/core';
import {Session} from "../../services/Session";
import {Survey} from "../../services/Survey";

@Component({
  selector: 'survey-results',
  templateUrl: './survey_results.html',
})
export class SurveyResultsCmp {
  @Input() userID;
  public results;

  constructor(public surveyService:Survey, public session:Session) {}

  ngOnChanges() {
    if (!this.session.user || !this.session.user.id || !this.userID) {
      return;
    }
    this.surveyService.getSurveyResults(this.userID).subscribe(results => this.results = results, ()=>{});
  }
}
