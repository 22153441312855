
import {of as observableOf, Observable} from 'rxjs';

import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from './Config';
import {ITag} from "../interfaces/ITag";
import {Session} from "./Session";

@Injectable()
export class Tags {
  private loadingObservable: Observable<[ITag]>;
  private list: Array<ITag> = [];

  constructor(private http: HttpClient, private config: Config, private session: Session) {}

  load(): Observable<[ITag]> {
    if (this.list && this.list.length > 0) {
      return <Observable<[ITag]>>observableOf(this.list);
    }
    if (this.loadingObservable) {
      return this.loadingObservable;
    }
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    this.loadingObservable = this.http.get<[ITag]>(this.config.apiUrl + '/tag', {headers: headers}).pipe(share());
    this.loadingObservable.subscribe((items)=> {
      this.list = items;
    }, ()=> {});
    return this.loadingObservable;
  }

  loadToMap(map: {[id: number]: ITag;}, call?) {
    this.load().subscribe((Tags)=> {
      if (Tags && Tags.length > 0) {
        Tags.forEach((t)=> {
          map[t.id] = t;
        });
      }
      if (call) {
        call();
      }
    }, ()=> {});
  }

  loadToList(list: ITag[]) {
    this.load().subscribe((Tags)=> {
      if (Tags && Tags.length > 0) {
        Tags.forEach((t)=> {
          list.push(t);
        });
      }
    }, ()=> {});
  }
}
