import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Distributors} from "../../services/Distributors";
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Notify} from "../../services/Notify";
import {ILocation} from "../../interfaces/ILocation";
import {Locations} from "../../services/Locations";
import {IDistributor} from "../../interfaces/IDistributor";

@Component({
  selector: 'rep-card',
  templateUrl: './rep-card.html',
})
export class RepCardCmp {
  @Input() user: IUser = <IUser>{};
  @Input() id: number = 0;
  @Input() showEditButton = true;
  @Output() update = new EventEmitter();
  @Output() beforeUpdate = new EventEmitter();
  @Output() cancelSave = new EventEmitter();

  public editMode = false;
  public copy;
  public saving;
  public location: ILocation = <ILocation>{};
  public distributorsMap = {};
  public distributorsList: IDistributor[] = [];

  constructor(public users: User, public distributors: Distributors, public locations: Locations) {
    this.distributors.loadToMap(this.distributorsMap);
    this.distributors.loadToList(this.distributorsList);
  }

  ngOnChanges() {
    //noinspection TypeScriptValidateTypes
    if (this.showEditButton.toString() === 'false' || this.showEditButton.toString() === '0') {
      this.showEditButton = false;
    }
    if (this.user && !this.user.id && this.showEditButton) {
      this.editMode = true;
    }
    if ((!this.user || !this.user.id || this.id !== this.user.id) && this.id) {
      this.users.getUserInfo(this.id).then((user) => {
        this.user = <IUser>user;
        this.editMode = false;
      }, ()=> {});
    }
  }

  switchEditMode() {
    if (!this.editMode) {
      this.copy = JSON.stringify(this.user);
    } else {
      if (this.copy) {
        this.user = JSON.parse(this.copy);
      }
      this.cancelSave.emit(true);
    }
    this.editMode = !this.editMode;
  }

  save() {
    this.beforeUpdate.emit(this.user);
    if (this.user && !this.user.__cancelUpdate) {
      this.saving = true;
      this.users.updateUser(this.user).then((id:number)=> {
          this.saving = false;
          this.copy = null;
          this.editMode = false;
          this.update.emit(this.user);
          let new_id = id || this.user.id;
          if (new_id && this.location.facility_name) {
            this.location.primary_contact_user_id = new_id;
            this.location.__rep_user_id = new_id;
            this.locations.updateLocation(this.location).then(null, (err)=> {
              console.error(err);
              try {
                let n = new Notify();
                n.error("Can't create location record - server error");
              } catch (e) {}
            });
          }
        },
        (response)=> {
          console.error(response);
          this.saving = false;
          let notify = new Notify();
          switch (response.status) {
            case 409:
              notify.error(response._body, 'Duplicate value');
              break;
            case 400:
              notify.error('Non-numeric value used for only-numeric field, please fix it.', 'Wrong type');
              break;
            default:
              notify.error(response.status + ' ' + response._body, 'Error');
          }
        });
    }
  }
}
