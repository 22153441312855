import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Config} from './Config';
import {ITestimonialsSet} from "../interfaces/ITestimonialsSet";
import {Session} from "./Session";

@Injectable()
export class Testimonials {
  constructor(private http: HttpClient, private config:Config, private session:Session) {}

  getSets():Observable<ITestimonialsSet[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITestimonialsSet[]>(this.config.apiUrl + `/testimonials`, {headers: headers});
  }

  saveSet(ts:ITestimonialsSet):Observable<string> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + `/testimonials`, JSON.stringify(ts), {headers: headers, responseType: 'text'});
  }
}
