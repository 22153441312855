import {Component} from '@angular/core';
import {ISaverLandingUser} from "../../interfaces/ISaverLandingUser";
import {Session} from "../../services/Session";
import {SaverLanding} from "../../services/SaverLanding";
import {ActivatedRoute} from "@angular/router";
import {Notify} from "../../services/Notify";
import {ISaverLandingToggle} from "../../interfaces/ISaverLandingToggle";
import {Specialties} from "../../services/Specialties";
import {Cots} from "../../services/Cots";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {ICot} from "../../interfaces/ICot";
import {FDTickets} from "../../services/FDTickets";
import {Helpers} from "../../services/Helpers";
import {ISaverLandingDistributors} from "../../interfaces/ISaverLandingDistributors";

@Component({
  selector: 'saver-landing-page-mgma',
  templateUrl: './saver-landing-page.html',
})
export class SaverLandingPageMgmaCmp {
  public tag = 'mgma';
  public user:ISaverLandingUser = <ISaverLandingUser>{answers_comma_separated: '', tag: this.tag};
  public data:ISaverLandingDistributors = <ISaverLandingDistributors>{};
  public notify = new Notify();
  public saving = false;
  public loading = false;
  private fdID;
  private h = new Helpers();

  public constructor(public session:Session, public srv:SaverLanding, public params:ActivatedRoute, private fd:FDTickets) {
    this.session.redirectIfNotAuth();
    this.srv.getDistributors().subscribe((response)=> {
      this.data = response;
    }, ()=> {});
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params:any) {
    if (params['id']) {
      let id = parseInt(params['id']);
      if (id > 0) {
        this.loading = true;
        this.user = <ISaverLandingUser>{answers_comma_separated: '', tag: this.tag};
        this.srv.getUser(id).subscribe((user)=> {
          this.loading = false;
          if (!user.answers_comma_separated) {
            user.answers_comma_separated = '';
          }
          this.user = user;
        }, ()=> {
          this.loading = false;
        });
      }
    }
  }

  toggle(event:ISaverLandingToggle) {
    let arr = this.user.answers_comma_separated.split(',');
    let i = arr.indexOf(event.id.toString());
    if (!event.checked) {
      if (i > -1) {
        arr.splice(i, 1);
      }
    } else {
      if (i < 0) {
        arr.push(event.id.toString());
      }
    }
    this.user.answers_comma_separated = arr.join(',').trim();
    if (this.user.answers_comma_separated[0] == ',') {
      this.user.answers_comma_separated = this.user.answers_comma_separated.substring(1);
    }
  }

  save() {
    this.saving = true;
    this.user.tag = this.tag;
    this.srv.saveUser(this.user).subscribe((id)=> {
      if (!this.user.id) {
        this.user.id = id;
      }
      this.saving = false;
      this.notify.success('Saved');
      this.createTicket();
    }, (err)=> {
      this.saving = false;
      console.error(err);
      this.notify.error('Error');
    });
  }

  createTicket() {
    if (this.fdID) {
      return;
    }
    try {
      let from = this.user.email || this.session.user.email;
      if (!this.h.isValidEmail(from)) {
        from = 'purchaseguru@purchaseclinic.com';
      }
      let who = this.user.facility_name || this.user.name || '';
      if (who.trim().length > 0) {
        who = ': ' + who;
      } else {
        who = ' record';
      }
      let html = '<a href="https://crm.purchase.guru/saver/' + this.tag + '/' + this.user.id + '" target="_blank">View in CRM</a><br/><br/>';
      if (this.user.email) { html = html + '<b>Email:</b> ' + this.user.email + '<br/>';}
      if (this.user.name) { html = html + '<b>Name:</b> ' + this.user.name + '<br/>';}
      if (this.user.facility_name) { html = html + '<b>Facility:</b> ' + this.user.facility_name + '<br/>';}
      if (this.user.custom_medical_distributor) { html = html + '<b>Custom distributor:</b> ' + this.user.custom_medical_distributor + '<br/>';}
      if (this.user.office_contact) { html = html + '<b>Office contact:</b> ' + this.user.office_contact + '<br/>';}
      if (this.user.rep_name) { html = html + '<b>Rep:</b> ' + this.user.rep_name + '<br/>';}
      if (this.user.suppliers_of_interest) { html = html + '<b>Suppliers of interest:</b> ' + this.user.suppliers_of_interest + '<br/>';}

      if (this.user.notes) { html = html + '<hr><b>Notes:</b> ' + this.user.notes;}
      this.fd.create(from, 'New Biggest Saver' + who, html).subscribe((id) => {
        this.fdID = id;
      }, () => {});
    } catch (e) {}
  }
}
