import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ILocation} from "../../interfaces/ILocation";
import {Locations} from "../../services/Locations";
import {IDistributor} from "../../interfaces/IDistributor";
import {Distributors} from "../../services/Distributors";
import {Lifecycles} from "../../services/Lifecycles";
import {Notify} from "../../services/Notify";
import {ILifecycleRecord} from "../../interfaces/ILifecycleRecord";

@Component({
  selector: 'add-lifecycle-record',
  templateUrl: './add-lifecycle-record.html',
})
export class AddLifeCycleRecordCmp {
  @Input() location: ILocation;
  @Output() updated = new EventEmitter();
  public distributor_id: number = 0;
  public distributorsList: IDistributor[] = [];
  public distributorsMap = {};
  public typesList = [];
  public typesMap = {};
  public type_id: number = 0;
  public reasonsList = [];
  public reasonsMap = {};
  public reason_id: number = 0;
  public sending = false;
  public loading = false;
  public sent = false;
  public datetime: string;

  constructor(public locService: Locations, public distributors: Distributors, public lifecycles: Lifecycles) {
    this.distributors.loadToList(this.distributorsList);
    this.distributors.loadToMap(this.distributorsMap);
    this.lifecycles.loadTypesToList(this.typesList);
    this.lifecycles.loadTypesToMap(this.typesMap);
    this.loadReasons();
  }

  ngOnChanges() {
    if (this.location && this.location.__distributor_id && !this.distributor_id) {
      this.distributor_id = this.location.__distributor_id;
    }
  }

  send() {
    let n = new Notify();
    if (!this.location || !this.location.id) {
      n.warning('Location is required');
      return;
    }
    if (!this.distributor_id) {
      n.warning('Please select distributor');
      return false;
    }
    if (!this.type_id) {
      n.warning('Please select type');
      return false;
    }
    this.sending = true;
    let record = new ILifecycleRecord();
    record.location_id = this.location.id;
    record.distributor_id = parseInt(this.distributor_id.toString());
    record.type_id = parseInt(this.type_id.toString());
    if (this.reason_id) {
      record.reason_id = parseInt(this.reason_id.toString());
    }
    if (this.datetime && this.datetime.length > 6) {
      record.datetime = this.datetime;
    }

    this.locService.addLifecycleRecords([record]).subscribe(() => {
      this.sending = false;
      this.sent = true;
      this.updated.emit();
      n.success('Successfully!');
    }, (err) => {
      this.sending = false;
      this.sent = false;
      console.error(err);
      n.error('Error');
    });
  }

  setDate(date: Date) {
    this.datetime = date.toISOString().substring(0, 19).replace('T',' ');
  }

  loadReasons() {
    this.loading = true;
    this.locService.getLifecycleReasons().subscribe((reasons) => {
      this.loading = false;
      this.reasonsList = reasons;
      this.reasonsMap = {};
      if (reasons && reasons.length) {
        reasons.forEach((r) => {
          this.reasonsMap[r.id] = r;
        });
      }
    }, () => {
      this.loading = false;
    });
  }
}
