import {Component} from '@angular/core';
import {CostStudy} from "../../services/CostStudy";
import {Session} from "../../services/Session";
import {ICsManuallyMovedMatches} from "../../interfaces/ICostStudy";
import {UrlParser} from "../../services/UrlParser";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";

@Component({
  templateUrl: 'moved_matches.html',
})
export class CsMovedMatchesCmp {
  public loading = false;
  public results:ICsManuallyMovedMatches;
  public tab = 1;
  public approving = false;
  public removing = false;
  public n = new Notify();
  public h = new Helpers();
  public csvSkippedURL = '';
  public csvAddedURL = '';

  constructor(private cs:CostStudy, public session:Session, private urlParser:UrlParser) {
    this.session.addOnLoadHandler(()=> {this.load();});
    this.session.redirectIfNotAuth();
    this.parseUrlParams();
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.cs.getManuallyMovedMatches().subscribe((results)=> {
      this.results = results;
      this.loading = false;
    }, ()=> {
      this.loading = false;
    });
    this.csvSkippedURL = this.cs.getManuallySkippedMatchesCsvUrl();
    this.csvAddedURL = this.cs.getManuallyAddedMatchesCsvUrl();
  }

  setTab(t) {
    this.tab = parseInt(t);
    this.urlParser.setParameter('tab', this.tab);
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.tab) {
      this.tab = Math.max(parseInt(urlParams.tab), 1);
    }
  }

  approve() {
    if (this.approving || this.loading || !this.results) {
      return;
    }
    this.approving = true;
    setTimeout(()=> {this.approving = false}, 1000);
    let to_reload = false;
    if (this.tab == 1 && this.results.skipped && this.results.skipped.length > 0) {
      this.results.skipped.forEach((s)=> {
        if (s['__to_approve']) {
          this.cs.approveSkippedMatch(s.id).subscribe(()=> {
            let i = this.results.skipped.indexOf(s);
            if (i > -1) {
              this.results.skipped.splice(i, 1);
            } else {
              to_reload = true;
            }
          }, ()=> {
            this.n.error('DB Error');
          });
        }
      });
    }
    if (this.tab == 2 && this.results.added && this.results.added.length > 0) {
      this.results.added.forEach((s)=> {
        if (s['__to_approve']) {
          this.cs.approveAddedMatch(s.cs_result_id).subscribe(()=> {
            let i = this.results.added.indexOf(s);
            if (i > -1) {
              this.results.added.splice(i, 1);
            } else {
              to_reload = true;
            }
          }, ()=> {
            this.n.error('DB Error');
          });
        }
      });
    }
    if (to_reload) {
      this.load();
    }
  }

  remove() {
    if (this.removing || this.loading || !this.results) {
      return;
    }
    this.removing = true;
    setTimeout(()=> {this.removing = false}, 1000);
    let to_reload = false;
    if (this.tab == 1 && this.results.skipped && this.results.skipped.length > 0) {
      this.results.skipped.forEach((s)=> {
        if (s['__to_remove']) {
          this.cs.removeSkippedMatch(s.id).subscribe(()=> {
            let i = this.results.skipped.indexOf(s);
            if (i > -1) {
              this.results.skipped.splice(i, 1);
            } else {
              to_reload = true;
            }
          }, ()=> {
            this.n.error('DB Error');
          });
        }
      });
    }
    if (this.tab == 2 && this.results.added && this.results.added.length > 0) {
      this.results.added.forEach((s)=> {
        if (s['__to_remove']) {
          this.cs.removeAddedMatch(s.cs_result_id, s.manufacturer).subscribe(()=> {
            let i = this.results.added.indexOf(s);
            if (i > -1) {
              this.results.added.splice(i, 1);
            } else {
              to_reload = true;
            }
          }, ()=> {
            this.n.error('DB Error');
          });
        }
      });
    }
    if (to_reload) {
      this.load();
    }
  }
}
