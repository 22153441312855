import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from "rxjs";
import {IDcrRequest, IDcrResponse, IDcrType} from "../interfaces/IDataChangeRequest";

@Injectable()
export class DataChangeRequests {
  constructor(private http: HttpClient, private session: Session, private config: Config) {}

  getLog(req: IDcrRequest): Observable<IDcrResponse> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put<IDcrResponse>(this.config.apiUrl + '/dcr_log', JSON.stringify(req), {headers: headers});
  }

  getTypes():Observable<IDcrType[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IDcrType[]>(this.config.apiUrl + '/dcr_types', {headers: headers});
  }
}
