import {of as observableOf, Observable, Observer} from 'rxjs';

import {share, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {IInactivityReason, ILocation, ILocationExtraData} from '../interfaces/ILocation';
import {ItemsRange} from './ItemsRange';
import {ILocationDistributor} from "../interfaces/ILocationDistributor";
import {ILocationContact} from "../interfaces/ILocationContact";
import {ILocationSearchResult} from "../interfaces/ILocationSearchResult";
import {ILocationGroup} from "../interfaces/ILocationGroup";
import {ILifecycleData} from "../interfaces/ILifecycleData";
import {ILifecycleRecord} from "../interfaces/ILifecycleRecord";
import {ILifecycleReason} from "../interfaces/ILifecycleReason";
import {Helpers} from "./Helpers";
import {IItemsToDelete} from "../interfaces/IItemsToDelete";
import {IFile} from "../interfaces/IFile";
import {ILegacySearchQuery} from "../interfaces/ILegacySearchQuery";
import {ILegacyLocation} from "../interfaces/ILegacyLocation";
import {ITicket} from "../interfaces/ITicket";
import {IGroupLifecycleRecords} from "../interfaces/IGroupLifecycleRecord";
import {ITask} from "../interfaces/ITask";
import {ILocationGradeReportRow} from "../interfaces/ILocationGradeReportRow";
import {ILocDbLinks, ILocationsMergeReq} from "../interfaces/LocationsMerge";
import {IRxScreenshot} from "../interfaces/IRx";
import {IFileLines} from "../interfaces/IFile";
import {IHsFileParsingStatus} from "../interfaces/IHsFileParsingStatus";
import {IDistrImportPreCheckData} from "../interfaces/IDistributor";
import {ISignupDetails} from "../interfaces/ISignup";
import {HttpCache} from "./HttpCache";

declare var _: any;

export interface ILocsLoaded {
  items: ILocation[];
  search_count?: number;
}

export interface ILocGeoPoint {
  facility_name: string;
  id: number;
  latitude: number;
  longitude: number;
  city: string;
  state: string;
  channel: string;
  cm: string;
  distr: string;
  distr_reporting: string;
}

export interface ILocGeoPointMgmaReporting {
  latitude: number;
  longitude: number;
  label: string;
}

export interface ILocEnumFields {
  division_name: string[];
  market_name: string[];
  group_name: string[];
  company_name: string[];
}

export interface ISanofiResult {
  gpo_id: string;
  hin: string;
  pc_id: string;
  location_id: number;
  dea: string;
  sync_status: string;
  output: string;
  is_error: boolean;
}

@Injectable()
export class Locations {
  public types = {leads: 'leads', prospects: 'prospects', members: 'members', inactive: 'inactive', ineligible: 'ineligible', on_hold: 'on_hold', pending: 'pending', total: 'total'};
  public counters = {leads: 0, prospects: 0, prospects_secondary: 0, pending: 0, members: 0, members_secondary: 0, inactive: 0, ineligible: 0, on_hold: 0, total: 0, pending_filtered: {}};
  private list: { leads: Array<ILocation>, prospects: Array<ILocation>, pending: Array<ILocation>, members: Array<ILocation>, inactive: Array<ILocation>, ineligible: Array<ILocation>, on_hold: Array<ILocation>, total: Array<ILocation> } = {
    leads: [],
    prospects: [],
    members: [],
    inactive: [],
    ineligible: [],
    on_hold: [],
    pending: [],
    total: []
  };
  private loadingPromises = {};
  private distributorsMap = {};
  private countersPromise;
  public countersFetched = false;

  constructor(private config: Config, private http: HttpCache, private session: Session, private itemsRange: ItemsRange) {}

  /**
   * @param kind string
   * @param offset number
   * @param count number
   * @param search
   * @param filters
   * @param sort_field
   * @param sort_asc
   * @param countFetch
   * @returns Promise
   */
  load(kind: string, offset: number = 0, count: number = 0, search: string = "", filters?, sort_field?: String, sort_asc?: boolean, countFetch?: Function): Promise<ILocsLoaded> {
    if (!kind) {
      console.error('kind required');
      return new Promise((resolve, reject) => {reject();});
    }
    if (!search && !filters && !sort_field && this.list && this.list[kind] && this.list[kind].length > 0) {
      let items = this.itemsRange.getFromRange(this.list[kind], offset, count);
      if (items && items.length > 0) {
        if (countFetch) {
          countFetch(this.list[kind].length);
        }
        return new Promise((resolve) => {
          resolve({items: items});
        });
      }
    }
    let sort_order = sort_asc ? '' : '&sort_desc=1';
    let key = `k${kind}f${offset}c${count}s${search}o${sort_field}d${sort_order}`;
    if (filters) {
      key = key + 'f' + JSON.stringify(filters);
    }
    if (this.loadingPromises[key]) {
      return this.loadingPromises[key];
    }
    this.loadingPromises[key] = new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      let url = this.config.apiUrl + '/location?kind=' + kind + '&offset=' + offset + '&limit=' + count;
      if (typeof search !== 'undefined' && search !== null && search !== "") {
        url = url + '&search=' + search;
      }
      if (filters && filters.reviewed_by_user) {
        url = url + '&reviewed_by_user=1';
      }
      if (filters && filters.reviewed_by_manager) {
        url = url + '&reviewed_by_manager=' + filters.reviewed_by_manager;
      }
      if (filters && filters.unclaimed) {
        url = url + '&unclaimed=1';
      }
      if (filters && filters.to_be_reviewed) {
        url = url + '&to_be_reviewed=1';
      }
      if (filters && filters.filterCM) {
        url = url + '&filter_by_claimed_user_id=' + filters.filterCM;
      }
      if (filters && filters.filterRepMgr) {
        url = url + '&filter_by_rep_mgr_id=' + filters.filterRepMgr;
      }
      if (filters && filters.is_reviewed) {
        url = url + '&is_reviewed=' + filters.is_reviewed;
      }
      if (sort_field) {
        url = url + '&sort=' + sort_field + sort_order;
      }
      if (filters && filters.filter_by_tag) {
        url = url + '&filter_by_tag=' + filters.filter_by_tag;
      }
      if (filters && filters.show_with_tags) {
        url = url + '&show_with_tags=' + filters.show_with_tags;
      }
      if (filters && !(filters.filter_by_link === null && typeof filters.filter_by_link === 'object') && !(typeof filters.filter_by_link === 'undefined')) {
        url = url + '&filter_by_link=' + filters.filter_by_link;
      }
      if (filters && !Helpers.is_empty(filters.show_with_distributor)) {
        url = url + '&show_with_distributor=' + filters.show_with_distributor;
      }
      this.http.get(url, {headers: headers, observe: 'response'}).subscribe((r) => {
        let response = {items: r.body, search_count: r.headers.get('X-List-Size')};
        this.loadingPromises[key] = null;
        if (response.items instanceof Array) {
          if (!search && !filters && !sort_field) {
            if (!this.list[kind]) {
              this.list[kind] = [];
            }
            this.itemsRange.addToList(response.items, this.list[kind], offset);
          }
          resolve(response);
        } else {
          reject();
        }
      }, () => {
        this.loadingPromises[key] = null;
        reject();
      });
      if (countFetch) {
        this.http.get(url + '&return_count=1', {headers: headers, observe: 'response'}).subscribe((r) => {
          countFetch(r.headers.get('X-List-Size'));
        }, () => {});
      }
    });
    return this.loadingPromises[key];
  }

  getLocation(id: number, refresh?: boolean): Promise<ILocation> {
    return new Promise((resolve, reject) => {
      if (this.list && !refresh) {
        for (let kind in this.list) {
          if (this.list.hasOwnProperty(kind) && this.list[kind].length > 0) {
            let i = _.findIndex(this.list[kind], {id: id});
            if (i > -1 && i !== false && this.list[kind][i].id === id) {
              resolve(this.list[kind][i]);
              return;
            }
          }
        }
      }
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get<ILocation>(this.config.apiUrl + '/location/' + id, {headers: headers}).subscribe(resolve, reject);
    });
  }

  getLeads(offset = 0, count = 0) {
    return this.load(this.types.leads, offset, count);
  }

  getProspects(offset = 0, count = 0) {
    return this.load(this.types.prospects, offset, count);
  }

  getMembers(offset = 0, count = 0) {
    return this.load(this.types.members, offset, count);
  }

  getOnHold(offset = 0, count = 0) {
    return this.load(this.types.on_hold, offset, count);
  }

  getInactive(offset = 0, count = 0) {
    return this.load(this.types.inactive, offset, count);
  }

  getIneligible(offset = 0, count = 0) {
    return this.load(this.types.ineligible, offset, count);
  }

  getPending(offset = 0, count = 0) {
    return this.load(this.types.pending, offset, count);
  }

  getGeo(): Observable<ILocGeoPoint[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocGeoPoint[]>(this.config.apiUrl + '/locations_geo', {headers: headers});
  }

  getGeoMgmaReporting(): Observable<ILocGeoPointMgmaReporting[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocGeoPointMgmaReporting[]>(this.config.apiUrl + '/locations_geo_mgma_reporting', {headers: headers});
  }

  getLocationsCount(): Promise<any> {
    if (this.countersPromise) {
      return this.countersPromise;
    }
    this.countersFetched = true;
    this.countersPromise = new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get<any>(this.config.apiUrl + '/locations_count', {headers: headers}).subscribe((response) => {
        this.counters.leads = response.leads;
        this.counters.prospects = response.prospects;
        this.counters.members = response.members;
        this.counters.on_hold = response.on_hold;
        this.counters.inactive = response.inactive;
        this.counters.ineligible = response.ineligible;
        this.counters.pending = response.pending;
        this.counters.pending_filtered = response.pending_filtered;
        this.counters.total = response.total;
        this.countersPromise = null;
        resolve(response);
      }, (reason) => {
        this.countersPromise = null;
        reject(reason);
      });
    });
    return this.countersPromise;
  }

  updateLocation(location: ILocation) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'text/json');
      headers = this.session.setAuthHeader(headers);
      this.http.put(this.config.apiUrl + '/location', JSON.stringify(location), {headers: headers, responseType: 'text'}).subscribe((response) => {
        if (!location.id) {
          let id = parseInt(response);
          if (id > 0) {
            location.id = id;
          }
        }
        this.getLocationsCount();
        this.list.leads = [];
        this.list.prospects = [];
        this.list.members = [];
        this.list.pending = [];
        resolve(null);
      }, reject);
    });
  }

  getReps(id: number) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'text/json');
      headers = this.session.setAuthHeader(headers);
      this.http.get(this.config.apiUrl + '/location_reps/' + id, {headers: headers}).subscribe(resolve, reject);
    });
  }

  getRelatedLocations(id: number) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'text/json');
      headers = this.session.setAuthHeader(headers);
      this.http.get(this.config.apiUrl + '/locations_related/' + id, {headers: headers}).subscribe(resolve, reject);
    });
  }

  claim(id: number) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'text/json');
      headers = this.session.setAuthHeader(headers);
      this.http.put(this.config.apiUrl + '/location_claim/' + id, null, {headers: headers}).pipe(map(r => r)).subscribe(() => {
        this.getLocationsCount();
        this.list.leads = [];
        this.list.prospects = [];
        this.list.members = [];
        this.list.pending = [];
        resolve(null);
      }, reject);
    });
  }

  getRepLocationsCount(id: number): Promise<Object> {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get(this.config.apiUrl + '/locations_count_rep/' + id, {headers: headers}).subscribe((response) => {
        resolve(response);
      }, reject);
    });
  }

  getLocationGroupsOfManagerForRep(repID: number, managerID: number) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get(this.config.apiUrl + '/location_groups_of_manager_for_rep/' + repID + '/' + managerID, {headers: headers}).subscribe((response) => {
        resolve(response);
      }, reject);
    });
  }

  getLocationsOfGroup(groupID: number) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = this.session.setAuthHeader(headers);
      this.http.get(this.config.apiUrl + '/locations_group/' + groupID, {headers: headers}).subscribe((response) => {
        resolve(response);
      }, reject);
    });
  }

  getSignupLinks(id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + '/signup_link/location/' + id, {headers: headers});
  }

  reviewByManager(groupID: Number) {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/location_group/' + groupID + '/review_by_manager', '', {headers: headers, responseType: 'text'});
  }

  reviewByUser(groupID: Number) {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/location_group/' + groupID + '/review_by_user', '', {headers: headers, responseType: 'text'});
  }

  getFiles(locationID: Number): Observable<IFile[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IFile[]>(this.config.apiUrl + '/files_of_location/' + locationID, {headers: headers});
  }

  updateFile(f: IFile): Observable<any> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/file', JSON.stringify(f), {headers: headers, responseType: 'text'});
  }

  getDistributors(id, refresh?: boolean): Observable<ILocationDistributor[]> {
    if (!this.distributorsMap) {
      this.distributorsMap = {};
    }
    if (!refresh && this.distributorsMap[id]) {
      return observableOf(this.distributorsMap[id]);
    }

    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    let subscription = this.http.get<ILocationDistributor[]>(this.config.apiUrl + '/location/' + id + '/distributor', {headers: headers}).pipe(share());

    subscription.subscribe((distributors) => {
      this.distributorsMap[id] = distributors;
    }, () => {});
    return subscription;
  }

  saveDistributor(ld: ILocationDistributor): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let method = (ld.id > 0) ? 'put' : 'post';
    return this.http.request(method, this.config.apiUrl + '/location/distributor', {
      headers: headers,
      body: JSON.stringify(ld),
      responseType: 'text'
    });
  }

  deleteDistributor(id): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/location/distributor/' + id, {headers: headers, responseType: 'text'});
  }

  setLocationGroupPurchasingContact(location_group_id: number, user_id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + '/location_group/' + location_group_id + '/purchasing_contact',
      user_id.toString(),
      {headers: headers, responseType: 'text'});
  }

  getContactsOfGroup(location_group_id: number): Observable<ILocationContact[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocationContact[]>(this.config.apiUrl + '/location_group/' + location_group_id + '/contacts', {headers: headers});
  }

  getGradesOfGroup(location_group_id: number): Observable<[ILocationGradeReportRow]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<[ILocationGradeReportRow]>(this.config.apiUrl + '/location_group/' + location_group_id + '/grades', {headers: headers});
  }

  find(searchTerm: string, offset?: number, limit?: number): Observable<ILocationSearchResult[]> {
    let url = this.config.apiUrl + '/location_search/' + searchTerm;
    if (offset > 0) {
      url = url + '?offset=' + offset.toString();
      if (limit > 0) {
        url = url + '&limit=' + limit.toString();
      }
    } else {
      if (limit > 0) {
        url = url + '?limit=' + limit.toString();
      }
    }

    return this.http.getc<ILocationSearchResult[]>(url);
  }

  getPreDeleteInfo(location_id): Observable<IItemsToDelete> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IItemsToDelete>(this.config.apiUrl + '/location_pre_delete/' + location_id, {headers: headers});
  }

  deleteLocation(location_id) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/location/' + location_id, {headers: headers, responseType: 'text'});
  }

  deleteLocationWithoutRels(location_id) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/location/' + location_id + '/without_rels', {headers: headers, responseType: 'text'});
  }

  saveGroup(lg: ILocationGroup): Observable<number> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/location_group', JSON.stringify(lg), {headers: headers, responseType: 'text'}).pipe(map(r => parseInt(r)));
  }

  getLifeCycleData(locationID: number): Observable<ILifecycleData> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILifecycleData>(this.config.apiUrl + '/location_lifecycle/' + locationID, {headers: headers});
  }

  addLifecycleRecords(records: Array<ILifecycleRecord>): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    records.forEach((r) => {
      if (!r.datetime) {
        r.datetime = '';
      }
    });
    return this.http.post(this.config.apiUrl + '/location_lifecycle', JSON.stringify(records), {headers: headers, responseType: 'text'});
  }

  addLifecycleRecord(locationID: number, distributor_id: number, type_id: number, reason_id?: number, datetime?: string, cb_success?, cb_error?) {
    if (!locationID) {
      return;
    }
    if (!distributor_id) {
      return false;
    }
    if (!type_id) {
      return false;
    }
    let record = new ILifecycleRecord();
    record.location_id = locationID;
    record.distributor_id = distributor_id;
    record.type_id = type_id;
    if (reason_id) {
      record.reason_id = reason_id;
    }
    if (datetime && datetime.length > 6) {
      record.datetime = datetime;
    }

    this.addLifecycleRecords([record]).subscribe(() => {
      if (cb_success) {
        cb_success();
      }
    }, () => {
      if (cb_error) {
        cb_error();
      }
    });
  }

  getLifecycleReasons(): Observable<Array<ILifecycleReason>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<Array<ILifecycleReason>>(this.config.apiUrl + '/location_lifecycle_reason', {headers: headers});
  }

  addLifecycleRecordsByZimmerIds(zimmer_ids: Array<string>, distributor_id: number, type_id?: number, reason_id?: number, datetime?: string): Observable<string[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post<string[]>(this.config.apiUrl + '/location_zimmer_id_lifecycle', JSON.stringify({
      zimmer_ids: zimmer_ids,
      distributor_id: distributor_id,
      type_id: type_id,
      reason_id: reason_id,
      datetime: datetime
    }), {headers: headers});
  }

  uploadFile(locationID: number, filename: string, data: File, fileTypeID?: number): Observable<any> {
    let fileNameSanitized = filename.replace(/[^a-z0-9.]/gi, '_');
    let url = '/location/file/' + locationID + '/' + fileNameSanitized;
    if (fileTypeID) {
      url = url + '/' + fileTypeID;
    }
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      r.open("POST", this.config.apiUrl + url, true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        observer.next(oEvent);
        observer.complete();
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data);
    });
    return s;
  }

  parseFileXLS(data: File, limit: number, dateColumns?: number[]): Observable<IFileLines> {
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      let url = this.config.apiUrl + "/file/lines/xls/" + limit;
      if (dateColumns && dateColumns.length > 0) {
        url += "?date_columns=" + (dateColumns.join(","));
      }
      r.open("POST", url, true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        if (oEvent && oEvent.target && oEvent.target['response']) {
          try {
            let r = JSON.parse(oEvent.target['response']);
            observer.next(r);
            observer.complete();
          } catch (e) {
            observer.error(e);
          }
        } else {
          observer.error(oEvent);
        }
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data);
    });
    return s;
  }

  parseFileHeadersXLS(data: File): Observable<any> {
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      r.open("POST", this.config.apiUrl + "/file/headers/xls", true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        observer.next(oEvent);
        observer.complete();
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data);
    });
    return s;
  }

  parseFileHeadersCSV(data: File): Observable<any> {
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      r.open("POST", this.config.apiUrl + "/file/headers/csv", true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        observer.next(oEvent);
        observer.complete();
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data.slice(0, 10241));
    });
    return s;
  }

  uploadGpoFile(filename: string, data, headersIndex: number, iZimmer: number, iZimmerCo: number, iHin: number, iEffDate: number, iDea: number, iPCID: number): Observable<number> {
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      let url = this.config.apiUrl + '/location/file_zimmer/' + filename + '?';
      if (headersIndex) {
        url = url + 'h=' + headersIndex + '&';
      }
      if (iZimmer) {
        url = url + 'iz=' + iZimmer + '&';
      }
      if (iZimmerCo) {
        url = url + 'icz=' + iZimmerCo + '&';
      }
      if (iHin) {
        url = url + 'ih=' + iHin + '&';
      }
      if (iEffDate) {
        url = url + 'im=' + iEffDate + '&';
      }
      if (iDea) {
        url = url + 'idea=' + iDea + '&';
      }
      if (iPCID) {
        url = url + 'ipc=' + iPCID + '&';
      }
      r.open("PUT", url, true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        observer.next(oEvent);
        observer.complete();
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data);
    });
    return s;
  }

  uploadSanofiResultsFile(data: string): Observable<any> {
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      r.open("POST", this.config.apiUrl + '/file_sanofi_results', true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        observer.next(oEvent);
        observer.complete();
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data);
    });
    return s;
  }

  uploadHsFile(filename: string, data, datetime?): Observable<string> {
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      let url = this.config.apiUrl + '/hs_file/' + filename;
      if (datetime) {
        url = url + '?dt=' + datetime;
      }
      r.open("PUT", url, true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        if (oEvent && oEvent.target && oEvent.target['response']) {
          try {
            let r = oEvent.target['response'];
            observer.next(r);
            observer.complete();
          } catch (e) {
            observer.error(e);
          }
        } else {
          observer.error(oEvent);
        }
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data);
    });
    return s;
  }

  getDistrImportPreCheckData(distrID): Observable<IDistrImportPreCheckData> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IDistrImportPreCheckData>(this.config.apiUrl + '/file/import_distr/' + distrID + '/pre_check_data', {headers: headers});
  }

  uploadRxNewMembersFile(data: string): Observable<any> {
    let s = Observable.create((observer: Observer<any>) => {
      let r = new XMLHttpRequest();
      r.open("POST", this.config.apiUrl + '/file_rx_new_members', true);
      r.setRequestHeader('Authorization', 'Bearer ' + this.session.getToken());
      r.onload = (oEvent) => {
        observer.next(oEvent);
        observer.complete();
      };
      r.onerror = (err) => {
        observer.error(err);
      };
      r.send(data);
    });
    return s;
  }

  getPendingManagers(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/locations_pending_cm', {headers: headers});
  }

  getHsFileParsingStatus(cache_id): Observable<IHsFileParsingStatus> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IHsFileParsingStatus>(this.config.apiUrl + '/hs_file/parsing_status/' + cache_id, {headers: headers});
  }

  getPendingRepManagers(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc(this.config.apiUrl + '/locations_pending_rep_mgrs', {headers: headers});
  }

  getEnumFields(): Observable<ILocEnumFields> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<ILocEnumFields>(this.config.apiUrl + '/location_enum_field', {headers: headers});
  }

  findLegacy(req: ILegacySearchQuery): Observable<ILegacyLocation[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post<ILegacyLocation[]>(this.config.apiUrl + '/legacy_search', JSON.stringify(req), {headers: headers});
  }

  getLocGroupLifecycle(id: number): Observable<IGroupLifecycleRecords> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IGroupLifecycleRecords>(this.config.apiUrl + '/location_group_lifecycle/' + (id.toString()), {headers: headers});
  }

  getLocGroupTickets(id: number): Observable<ITicket[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITicket[]>(this.config.apiUrl + '/ticket/location_group/' + (id.toString()), {headers: headers});
  }

  getLocationGroupTasks(id: number): Observable<ITask[]> {
    let headers = new HttpHeaders();
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ITask[]>(this.config.apiUrl + `/task/location_group/${id}`, {headers: headers});
  }

  generateSanofiRxPDF(location_id: number): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/rx/generate_sanofi_pdf/' + location_id, '', {headers: headers, responseType: 'text'});
  }

  generateGskRxPDF(location_id: number): Observable<[number]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post<[number]>(this.config.apiUrl + '/rx/generate_gsk_pdf/' + location_id, '', {headers: headers});
  }

  generateMerckRxPDF(location_id: number): Observable<[number]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post<[number]>(this.config.apiUrl + '/rx/generate_merck_pdf/' + location_id, '', {headers: headers});
  }

  getLocationDbLinks(id: number): Observable<ILocDbLinks> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ILocDbLinks>(this.config.apiUrl + `/merge_locations/${id}/db_links`, {headers: headers});
  }

  mergeLocations(req: ILocationsMergeReq): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/merge_locations', JSON.stringify(req), {headers: headers, responseType: 'text'});
  }

  getRxScreenshotsForReview(): Observable<IRxScreenshot[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IRxScreenshot[]>(this.config.apiUrl + `/rx/screenshot`, {headers: headers});
  }

  getLocationSignupDetails(locationID: number): Observable<ISignupDetails> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ISignupDetails>(this.config.apiUrl + `/signup_details/location/${locationID}`, {headers: headers});
  }

  setRxScreenshotReviewed(rxID: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/rx/screenshot/${rxID}/reviewed`, '', {headers: headers, observe: 'response'});
  }

  deleteRxScreenshot(fileID: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + `/rx/screenshot/${fileID}`, {headers: headers, observe: 'response'});
  }

  uspsifyLocation(locID: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/location/${locID}/uspsify`, '', {headers: headers, observe: 'response'});
  }

  getExtraData(locID: number, filter?: string): Observable<ILocationExtraData> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + `/location/${locID}/extra_data`;
    if (filter) {
      url = url + '?filter=' + filter;
    }
    return this.http.get<ILocationExtraData>(url, {headers: headers});
  }

  writeExtraData(locID: number, data: ILocationExtraData): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.put(this.config.apiUrl + `/location/${locID}/extra_data`, JSON.stringify(data), {headers: headers, responseType: 'text'});
  }

  getInactivityReasons(): Observable<IInactivityReason[]> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.getc<IInactivityReason[]>(this.config.apiUrl + '/location/inactivity_reason', {headers: headers});
  }

}
