import {Component} from '@angular/core';
import {IEmailCampaignData} from "../../interfaces/IEmailCampaignData";
import {Newsletter} from "../../services/Newsletter";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";

@Component({
  templateUrl: 'email_campaigns_list.html',
  selector: 'email-campaigns-list'
})
export class EmailCampaignsListCmp {
  public campaigns:IEmailCampaignData[];
  public loading = false;
  public initPeriod = true;
  public campaign:IEmailCampaignData;
  public campaignID;
  private n = new Notify();

  constructor(private newsSrv:Newsletter, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    if (this.loading) {
      return;
    }
    this.initPeriod = false;
    this.loading = true;
    this.newsSrv.getEmailCampaigns().subscribe((campaigns)=> {
      this.loading = false;
      this.campaigns = campaigns;
    }, (err)=> {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  setCampaign(id) {
    for (let c of this.campaigns) {
      if (c.id == id) {
        this.campaignID = id;
        this.campaign = c;
        return;
      }
    }
  }
}
