import {Component,Input} from '@angular/core';

@Component({
  selector: 'user-type',
  template: `<span [ngSwitch]="typeID">
            <span *ngSwitchCase="2">Client Manager</span>
            <span *ngSwitchCase="3">Rep</span>
            <span *ngSwitchDefault>User</span>
          </span>`
})
export class UserTypeCmp {
  @Input() typeID;
}
