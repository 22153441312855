import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {CostStudy} from "../../services/CostStudy";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {ContractConnections} from "../../services/ContractConnections";
declare var $:any;

@Component({
  selector: "cc-uploads-list",
  templateUrl: "cc-uploads.html"
})
export class CcUploadsListCmp {
  public loading = false;
  public uploads;
  private n = new Notify();
  private loadingThreads = 0;

  constructor(private ccSrv:ContractConnections, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.initLoad();
    });
    this.session.redirectIfNotAuth();
  }

  initLoad() {
    this.loading = true;
    this.load();
  }

  load() {
    if (this.loadingThreads > 0) {
      return;
    }
    let myurl = window.location.href;
    this.loadingThreads++;
    this.ccSrv.getCcUploads().subscribe((r)=> {
      this.loading = false;
      this.loadingThreads--;
      if (r.uploads) {
        this.uploads = r.uploads;
      }
      setTimeout(()=> {
        if (window.location.href === myurl) {
          this.load();
        }
      }, 2000);
    }, (err)=> {
      this.loading = false;
      this.loadingThreads--;
      this.n.error('Error - Can\'t load list', err._body);
      setTimeout(()=> {
        this.load();
      }, 7000);
    });
  }

  deleteUpload(ccu, $event) {
    if ($event) {
      try {
        if ($event.stopPropagation) {
          $event.stopPropagation();
        }
      }
      catch (e) {}
    }
    if (!ccu || !ccu.id || ccu.__deleting) {
      return false;
    }
    ccu.__deleting = true;
    this.ccSrv.deleteCcUpload(ccu.id).subscribe(()=> {
      this.n.success('Removed');
      this.load();
    }, (err)=> {
      ccu.__deleting = false;
      this.n.error('Error', err._body);
    });
    return false;
  }
}
