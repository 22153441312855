import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from "./Config";
import {Observable} from "rxjs";

@Injectable()
export class FDTickets {
  constructor(private config:Config, private http:HttpClient) {}

  create(from:string, subject:string, body:string, customFields?:Object):Observable<string> {
    return this.http.post(this.config.apiUrl + '/fd_ticket', JSON.stringify({
      requester_email: from,
      subject: subject,
      body: body,
      custom_fields: customFields,
    }), {responseType: 'text'});
  }
}
