import {Session} from "../../services/Session";
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {User} from "../../services/User";

@Component({
  selector: 'settings',
  templateUrl: './settings.html',
  viewProviders: [User],
})
export class SettingsCmp {
  public error = 0;
  public totpSecret = '';
  public loading = false;
  public regenerateMode = false;

  constructor(public session:Session, public user:User, public router:Router) {
    this.session.redirectIfNotAuth();
  }

  clearErrors() {
    this.error = 0;
  }

  turnOnTotp(password) {
    if (!password || !password.value) {
      return;
    }
    this.loading = true;
    this.user.getNewTotpSecret(password.value).subscribe((response)=> {
      if (response.status > 199 && response.status < 300 && response.body) {
        this.totpSecret = response.body;
        if (this.session && this.session.user) {
          this.session.user.__is_totp = true;
        }
        this.regenerateMode = false;
      } else {
        this.error = Math.round(response.status);
      }
      this.loading = false;
      password.value = '';
    }, (response)=> {
      this.error = parseInt(response.status);
      this.loading = false;
      password.value = '';
    });
  }

  checkTotp(totp) {
    if (!totp || !totp.value) {
      return false;
    }
    this.user.checkTotpCode(totp.value).subscribe((response)=> {
      if (response.status > 199 && response.status < 300) {
        this.totpSecret = '';
        this.router.navigate(['']);
      } else {
        this.error = Math.round(response.status);
      }
      this.loading = false;
      totp.value = '';
    }, (response)=> {
      this.error = parseInt(response.status);
      this.loading = false;
      totp.value = '';
    });
  }

  regenerateBarcode() {
    this.totpSecret = '';
    this.clearErrors();
    this.regenerateMode = true;
  }
}
