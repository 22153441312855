import {Component, Input} from '@angular/core';
import {IMissingLocationsReport} from "../../interfaces/IMissingLocationsReport";

@Component({
  templateUrl: 'missing_locations.html',
  selector: 'report-missing-locations',
})
export class ReportMissingLocationsCmp {
  @Input('report') report:IMissingLocationsReport;
}
