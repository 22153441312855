import {Component, Input} from '@angular/core';

@Component({
  templateUrl: 'unsent_reports.html',
  selector: 'unsent-reports',
})
export class ReportUnsentReportsCmp {
  @Input('report') report;
  public sortDesc = false;
  public orderField:string = '';

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }
}
