import {Component, EventEmitter} from '@angular/core';
import {EditRequests} from "../../services/EditRequests";
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {IEditRequest} from "../../interfaces/IEditRequest";
import {IDistributor} from "../../interfaces/IDistributor";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {Distributors} from "../../services/Distributors";
import {Specialties} from "../../services/Specialties";
import {Cots} from "../../services/Cots";
import {TaxStatuses} from "../../services/TaxStatuses";
import {YearlySpendTypes} from "../../services/YearlySpendTypes";
import {Themes} from "../../services/Themes";
import {ICot} from "../../interfaces/ICot";
import {ITheme} from "../../interfaces/ITheme";
import {Observable} from "rxjs";
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";

@Component({
  templateUrl: './edit-requests-list.html',
  selector: 'edit-requests-list',
})
export class EditRequestsListCmp {
  public loading = false;
  public requests:IEditRequest[] = [];
  public requestsLists:IEditRequest[] = [];
  public distributorsMap:{[id: number]: IDistributor;} = {};
  public specialtiesMap;
  public cotsMap:{[id: number]: ICot;};
  public taxStatusesMap;
  public yearlySpendMap;
  public themes:Array<ITheme>;
  public valuesConverter;
  public onlyMine = false;
  private usersInfoCache = {};
  private usersInfoLoadingCache = {};
  private notify:Notify;

  constructor(private reqSrv:EditRequests, private session:Session, public distributors:Distributors, public specialties:Specialties,
    public cots:Cots, public taxStatus:TaxStatuses, public yearlySpendSrv:YearlySpendTypes, public themesSrv:Themes, private usrSrv:User) {
    this.notify = new Notify();
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
    this.valuesConverter = (fieldName, value)=> {
      return this.getFieldValueHumanReadable(fieldName, value);
    };
  }

  load() {
    if (this.loading) {
      return;
    }
    this.requests = [];
    this.requestsLists = [];
    this.loading = true;
    this.reqSrv.getRequests().subscribe((reqs)=> {
      this.loading = false;
      if (reqs && reqs.length) {
        reqs.forEach((r)=> {
          r.__approveCall = new EventEmitter();
        });
      }
      this.requests = reqs;
      this.requestsLists = reqs.slice();
    }, (err)=> {
      this.loading = false;
      this.notify.error('Loading error');
      console.error(err);
    });
  }

  update(req:IEditRequest) {
    if (!req) {
      return false;
    }
    if (req.is_new_record) {
      this.requests.splice(this.requests.indexOf(req), 1);
    } else {
      this.load();
    }
  }

  getFieldValueHumanReadable(fieldName, value):Observable<any> {
    return Observable.create((observer)=> {
      switch (fieldName) {
        case 'primary_contact_user_id':
          if (this.usersInfoCache[value]) {
            observer.next(this.usersInfoCache[value]);
          } else {
            if (!this.usersInfoLoadingCache[value]) {
              this.usersInfoLoadingCache[value] = true;
              this.usrSrv.getUserInfo(value).then((i:IUser)=> {
                if (i && i.fname) {
                  this.usersInfoCache[value] = i.fname + ' ' + i.lname;
                  observer.next(this.usersInfoCache[value]);
                } else {
                  observer.next(value);
                }
              }, ()=> {
                observer.next(value);
              });
            }
          }
          break;
        case 'purchasing_contact_user_id':
          if (this.usersInfoCache[value]) {
            observer.next(this.usersInfoCache[value]);
          } else {
            if (!this.usersInfoLoadingCache[value]) {
              this.usersInfoLoadingCache[value] = true;
              this.usrSrv.getUserInfo(value).then((i:IUser)=> {
                if (i && i.fname) {
                  this.usersInfoCache[value] = i.fname + ' ' + i.lname;
                  observer.next(this.usersInfoCache[value]);
                } else {
                  observer.next(value);
                }
              }, ()=> {
                observer.next(value);
              });
            }
          }
          break;
        case 'specialty_id':
          if (this.specialtiesMap) {
            if (this.specialtiesMap[value]) {
              observer.next(this.specialtiesMap[value].name);
            } else {
              observer.next(value);
            }
          } else {
            this.specialtiesMap = {};
            this.specialties.loadToMap(this.specialtiesMap, ()=> {
              if (this.specialtiesMap[value]) {
                observer.next(this.specialtiesMap[value].name);
              } else {
                observer.next(value);
              }
            });
          }
          break;
        case 'class_of_trade_id':
        case 'cot_id':
          if (this.cotsMap) {
            if (this.cotsMap[value]) {
              observer.next(this.cotsMap[value].name);
            } else {
              observer.next(value);
            }
          } else {
            this.cotsMap = {};
            this.cots.loadToMap(this.cotsMap, ()=> {
              if (this.cotsMap[value]) {
                observer.next(this.cotsMap[value].name);
              } else {
                observer.next(value);
              }
            });
          }
          break;
        case '__distributor_id':
          if (this.distributorsMap) {
            if (this.distributorsMap[value]) {
              observer.next(this.distributorsMap[value].name);
            } else {
              observer.next(value);
            }
          } else {
            this.distributorsMap = {};
            this.distributors.loadToMap(this.distributorsMap, ()=> {
              if (this.distributorsMap[value]) {
                observer.next(this.distributorsMap[value].name);
              } else {
                observer.next(value);
              }
            });
          }
          break;
        case 'tax_status_id':
          if (this.taxStatusesMap) {
            if (this.taxStatusesMap[value]) {
              observer.next(this.taxStatusesMap[value].name);
            } else {
              observer.next(value);
            }
          } else {
            this.taxStatusesMap = {};
            this.taxStatus.loadToMap(this.taxStatusesMap, ()=> {
              if (this.taxStatusesMap[value]) {
                observer.next(this.taxStatusesMap[value].name);
              } else {
                observer.next(value);
              }
            });
          }
          break;
        case 'yearly_spend_type_id':
          if (this.yearlySpendMap) {
            if (this.yearlySpendMap[value]) {
              observer.next(this.yearlySpendMap[value].label);
            } else {
              observer.next(value);
            }
          } else {
            this.yearlySpendMap = {};
            this.yearlySpendSrv.loadToMap(this.yearlySpendMap, ()=> {
              if (this.yearlySpendMap[value]) {
                observer.next(this.yearlySpendMap[value].label);
              } else {
                observer.next(value);
              }
            });
          }
          break;
        case 'theme_id':
          if (this.themes && this.themes.length) {
            let item;
            this.themes.forEach((theme)=> {
              if (theme.id.toString() == value.toString()) {
                item = theme;
              }
            });
            if (item) {
              observer.next(item.name);
            } else {
              observer.next(value);
            }
          } else {
            this.themesSrv.getThemes().subscribe((t)=> {
              this.themes = t;
              let item;
              this.themes.forEach((theme)=> {
                if (theme.id.toString() == value.toString()) {
                  item = theme;
                }
              });
              if (item) {
                observer.next(item.name);
              } else {
                observer.next(value);
              }
            }, ()=> {});
          }
          break;
        default:
          observer.next(value);
      }
    });
  }

  toggleOnlyMine() {
    if (this.onlyMine) {
      this.onlyMine = false;
      this.requestsLists = this.requests.slice();
    } else {
      this.requestsLists = [];
      if (this.requests && this.requests.length) {
        let id = this.session.user.id;
        this.requests.forEach((r)=> {
          if (r.cm_user_id == id) {
            this.requestsLists.push(r);
          }
        });
      }
      this.onlyMine = true;
    }
  }

  toggleSelection() {
    if (this.requestsLists && this.requestsLists.length) {
      let thereIsSelected = false;
      let thereIsNotSelected = false;
      this.requestsLists.forEach((r)=> {
        if (r.__selected) {
          thereIsSelected = true;
        } else {
          thereIsNotSelected = true;
        }
      });
      if (thereIsSelected && !thereIsNotSelected) {
        this.requestsLists.forEach((r)=> {
          r.__selected = false;
        });
      } else {
        this.requestsLists.forEach((r)=> {
          r.__selected = true;
        });
      }
    }
  }

  approveSelected() {
    let calls = [];
    this.requestsLists.forEach((r)=> {
      if (r.__selected && r.__approveCall) {
        r.__approveCall.emit();
      }
    });
  }
}
