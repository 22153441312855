import {Component, ViewChild, ElementRef} from '@angular/core';
import {Notify} from "../../services/Notify";
import {IStContract} from "../../interfaces/IStContract";
import {Reports} from "../../services/Reports";
import {TypeaheadHelper} from "../../services/TypeaheadHelper";
import {Session} from "../../services/Session";
import {ISalesReportingSummary} from "../../interfaces/ISalesReportingSummary";

@Component({
  selector: 'sales-reporting-report',
  templateUrl: 'sales_reporting.html'
})
export class SalesReportingReportCmp {
  @ViewChild('contract') inputContract:ElementRef;

  public contracts:number[] = [];
  public allContracts:string[] = [];
  public csvUrl:string;
  public csvGpoidsUrl:string;
  public interval = 6;
  public active = 3;
  public refreshing = false;
  public loadingSummary = false;
  public summary:ISalesReportingSummary;
  public loadingContracts = false;
  public locsCount:number = 0;

  constructor(private reports:Reports, private th:TypeaheadHelper, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.loadContracts();
    });
    this.session.redirectIfNotAuth();
  }

  loadContracts() {
    if (this.loadingContracts) {
      return;
    }
    if (this.allContracts.length > 0) {
      return;
    }
    this.loadingContracts = true;
    this.reports.getSt2Contracts().subscribe((allContracts)=> {
      this.loadingContracts = false;
      this.allContracts = [];
      allContracts.forEach(c => this.allContracts.push(c.contract_number.toString()));
      this.th.setup(this.allContracts, this.inputContract);
    }, ()=> {
      this.loadingContracts = false;
    });
  }

  addContract() {
    if (this.refreshing || this.loadingSummary || this.loadingContracts) {
      return;
    }
    let c = parseInt(this.th.getVal(this.inputContract));
    if (c > 0) {
      if (this.contracts.indexOf(c) < 0) {
        this.contracts.push(c);
        this.refresh();
        this.th.setVal(this.inputContract, null);
      }
    }
  }

  removeContract(c) {
    if (this.refreshing || this.loadingSummary) {
      return;
    }
    let i = this.contracts.indexOf(c);
    if (i > -1) {
      this.contracts.splice(i, 1);
      this.refresh();
    }
  }

  refresh() {
    if (this.refreshing || this.loadingSummary) {
      return;
    }
    if (!this.active) {
      this.active = 3;
    }
    if (!this.interval) {
      this.interval = 6;
    }
    this.refreshing = true;
    this.summary = null;
    this.locsCount = 0;
    this.reports.getSalesReportingLocsCount(this.contracts, this.interval, this.active).subscribe((cnt)=> {
      this.refreshing = false;
      let x = parseInt(cnt);
      if (x > 0) {
        this.locsCount = x;
      }
    }, ()=> {
      this.refreshing = false;
    });
    this.csvGpoidsUrl = this.reports.getSalesReportingGpoidsCsvUrl(this.contracts, this.interval, this.active);
  }

  loadSummary() {
    if (this.refreshing || this.loadingSummary) {
      return;
    }
    if (!this.active) {
      this.active = 3;
    }
    if (!this.interval) {
      this.interval = 6;
    }
    this.loadingSummary = true;
    this.csvUrl = null;
    this.summary = null;
    this.repeatedlyLoadSummary();
  }

  repeatedlyLoadSummary() {
    this.reports.getSalesReportingSummary(this.contracts, this.interval, this.active).subscribe((summary)=> {
      if (summary && summary.status == "Error") {
        this.loadingSummary = false;
        let n = new Notify();
        n.error('Server-side error');
        return;
      }
      if (summary && summary.status == "Done") {
        this.loadingSummary = false;
        this.summary = summary;
        this.csvUrl = this.reports.getSalesReportingCsvUrl(this.contracts, this.interval, this.active);
      } else {
        setTimeout(()=> {
          this.repeatedlyLoadSummary();
        }, 700);
      }
    }, (err)=> {
      this.loadingSummary = false;
      this.csvUrl = null;
      this.summary = null;
      let n = new Notify();
      n.httpErr(err);
    });
  }
}
