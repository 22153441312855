import {Component, Input, Output, EventEmitter} from '@angular/core';
import {CostStudy} from "../../services/CostStudy";
import {IMissingUomConversion} from "../../interfaces/ICostStudy";
import {Notify} from "../../services/Notify";
declare var $:any;

@Component({
  selector: "cs-uom-conversion",
  templateUrl: "conversion.html"
})
export class CsUomConversionCmp {
  @Input('item') r:IMissingUomConversion;
  @Input() hideUploadLink:boolean;
  @Output('update') update = new EventEmitter();
  public adding = false;
  public total = 0;
  private n = new Notify();

  constructor(private csSrv:CostStudy) {}

  add(r:IMissingUomConversion) {
    if (this.adding || !r.uin || !r.uom || !r.fi_uom || !r.quantity || !r.fi_quantity) {
      return;
    }
    this.adding = true;
    this.csSrv.addUomConversion({uin: r.uin, uom: r.uom, fi_uom: r.fi_uom, quantity: r.quantity, fi_quantity: r.fi_quantity}).subscribe(()=> {
      this.adding = false;
      this.n.success('Added');
      this.update.emit();
    }, (err)=> {
      this.adding = false;
      this.n.error('Error', err._body);
    });
  }
}
