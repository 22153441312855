import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Distributors} from "../../services/Distributors";
import {IDistributor} from "../../interfaces/IDistributor";
import {Session} from "../../services/Session";

@Component({
  templateUrl: './distributor.html',
})
export class DistributorPageCmp {
  public id;
  public distributor: IDistributor;
  public loading: boolean = false;

  constructor(public distributors: Distributors, public params: ActivatedRoute, public router: Router, private session: Session) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    if (!this.id) {
      this.id = parseInt(params['id']);
    }
    if (!this.id) {
      this.router.navigate(['']);
      return;
    }
    this.load();
  }

  load() {
    this.loading = true;
    this.distributors.load().then((list)=> {
      this.loading = false;
      list.forEach((d)=> {
        if (d.id === parseInt(this.id)) {
          this.distributor = d;
        }
      });
    }, ()=> {
      this.loading = false;
    });
  }

}
