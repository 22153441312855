import {Component, Input, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {ILocation} from "../../interfaces/ILocation";
import {IUser} from "../../interfaces/IUser";
import {ArrayJoinNotEmptyPipe} from "../../services/ArrayJoinNotEmptyPipe";
import {User} from "../../services/User";
import {Notify} from "../../services/Notify";
import {IRepLocInfo} from "../../services/User";
import {Helpers} from "../../services/Helpers";

declare var _: any;
declare var ClipboardJS: any;
declare var $: any;

@Component({
  selector: 'rep-locations',
  templateUrl: './locations.html',
})
export class RepLocationsCmp {
  @Input() locs: ILocation[] = [];
  @Input() locsInfo: IRepLocInfo[] = [];
  @Input() repID = 0;
  @ViewChild('copyLocsInfoBtn') copyLocsInfoBtn: ElementRef;
  @ViewChild('tbl') tbl: ElementRef;
  @Output() toggleExpand = new EventEmitter();
  public displayInactive = false;
  public displayColumnsAddress = true;
  public displayColumnsLC = true;
  public displayColumnsRX = true;
  public exporting = false;
  public locsInfoLoading = 1;
  public selectCopy = false;
  public sortDesc = false;
  public orderField: string = '';
  public expanded = false;

  constructor(private users: User, private arrJoin: ArrayJoinNotEmptyPipe, private h: Helpers) {}

  ngOnChanges() {
    if (this.locs && this.locs.length > 0) {
      this.fillAdditionalInfoFields();
      this.initCopyLocsInfoBtn();
    }
  }

  fillAdditionalInfoFields() {
    this.locs.forEach((loc) => {
      if (loc.is_inactive > 0) {
        this.displayInactive = true;
      }
      let i = _.find(this.locsInfo, {id: loc.id});
      if (i) {
        loc['___info'] = i;
      }
    });
  }

  setOrderField(f: string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  initCopyLocsInfoBtn() {
    setTimeout(() => {
      if (!this.locs || this.locs.length < 1) {
        return;
      }
      try {
        if (this.copyLocsInfoBtn && ClipboardJS) {
          let clipboard = new ClipboardJS(this.copyLocsInfoBtn.nativeElement);
          this.locsInfoLoading = this.locs.length;
          let info = '';
          this.locs.forEach((loc) => {
            if (loc.is_inactive || loc.is_on_hold) {
              this.locsInfoLoading--;
              return;
            }
            if (loc.primary_contact_user_id) {
              this.users.getUserInfo(loc.primary_contact_user_id).then((c) => {
                info += "\n\n" + this.getInfoForCopy(loc, c);
                this.locsInfoLoading--;
                this.copyLocsInfoBtn.nativeElement.setAttribute('data-clipboard-text', info.trim());
              }, () => {
                info += "\n\n" + this.getInfoForCopy(loc);
                this.locsInfoLoading--;
                this.copyLocsInfoBtn.nativeElement.setAttribute('data-clipboard-text', info.trim());
              });
            } else {
              info += "\n\n" + this.getInfoForCopy(loc);
              this.locsInfoLoading--;
            }
          });
          this.copyLocsInfoBtn.nativeElement.setAttribute('data-clipboard-text', info.trim());
          clipboard.on('success', (e) => {
            try {
              let notify = new Notify();
              notify.success('Copied!');
              e.clearSelection();
            } catch (e) {}
          });
          clipboard.on('error', () => {
            try {
              let notify = new Notify();
              notify.info('Press &#8984;+C to copy');
              this.selectCopy = true;
            } catch (e) {}
          });
        }
      } catch (e) {}
    }, 60);
  }

  copyLocsInfo($event) {
    $event.stopPropagation();
    setTimeout(() => {
      if (this.selectCopy) {
        try {
          let els = $("textarea[readonly]");
          if (els.length) {
            let t = els[(els.length - 1)];
            $(t).on('keydown', (e) => {
              if (e.keyCode === 67) {
                let notify = new Notify();
                notify.success('Copied!');
                setTimeout(() => {
                  $(els).remove();
                }, 1);
              }
            });
          }
        } catch (e) {}
      }
    }, 1);
    return false;
  }

  getInfoForCopy(f: ILocation, contact?: IUser): string {
    let info = `Legal Name: ${f.facility_name}`;
    if (f.dba) {
      info += `\nDBA name: ${f.dba}`;
    }
    if (f.address || f.address2 || f.city || f.state || f.zip) {
      info += "\nAddress: " + this.arrJoin.transform([f.address, f.address2, f.city, f.state, f.zip]);
    }
    if (f.zimmer_id) {
      info += `\nGPOID: ${f.zimmer_id}`;
    }
    if (f.zimmer_co_id) {
      info += `\nCOID: ${f.zimmer_co_id}`;
    }
    if (contact) {
      let c = contact;
      info += `\nContact name: ${c.fname} ${c.lname}\nContact Email: ${c.email}`;
      if (c.phone) {
        info += `\nContact Phone #: ${c.phone}`;
      }
    }
    return info;
  }

  exportCsv() {
    let prevOrderField = this.orderField;
    let prevSortDesc = this.sortDesc;
    this.orderField = 'pa_date';
    this.sortDesc = false;
    this.exporting = true;
    setTimeout(() => {
      this.h.exportCsv(this.tbl, 'rep_' + (this.repID || '') + '_locations.csv', () => {
        this.orderField = prevOrderField;
        this.sortDesc = prevSortDesc;
        this.exporting = false;
      }, (t) => {
        return t.replace('signal_cellular_no_sim', '+').replace('pause_circle_filled', '+').replace('done_all', '+').replace('done', '+').replace('sentiment_very_dissatisfied', '+').trim();
      });
    }, 2);
  }

  toggleExpandBtn() {
    this.expanded = !this.expanded;
    this.toggleExpand.emit(this.expanded);
  }
}
