
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Config} from './Config';
import {Session} from "./Session";

export interface ICConnsUploadReq {
  file_id: number;
  src_filename: string;
  coid_ci: number;
  contract_number_ci: number;
  tier_ci: number;
  submit_date_ci: number;
  price_start_date_ci: number;
  loc_status_ci: number;
  headers_index?: number;
}

export interface ICcResult {
  id: number;
  coid: string;
  locations_cnt: number;
  loc_impl_contracts_cnt: number;
  missing_contract?: number;
  missing_contract_src?: string;
  //
  __updating?:boolean;
  __edit?:boolean;
  __potential?:any;
}

export interface ICcResults {
  results: ICcResult[];
  status: string;
  count?: number;
}

export interface ICcUploadInfo {
  source_rows_count: number;
  uploader_name: string;
  uploader_id: number;
  upload_datetime: string;
  file_url: string;
  file_title: string;
}

@Injectable()
export class ContractConnections {
  constructor(private http: HttpClient, private config:Config, private session:Session) {}

  parseFile(req:ICConnsUploadReq):Observable<number> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.post(this.config.apiUrl + '/contract_connections/parse_file', JSON.stringify(req), {headers: headers, responseType: 'text'}).pipe(map(r => parseInt(r)));
  }

  getCcResults(ccUploadID:number, offset:number, limit:number):Observable<ICcResults> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICcResults>(this.config.apiUrl + `/contract_connections/results/${ccUploadID}/${offset}/${limit}`, {headers: headers});
  }

  getCcUploadInfo(ccUploadID:number):Observable<ICcUploadInfo> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<ICcUploadInfo>(this.config.apiUrl + `/contract_connections/upload_info/${ccUploadID}`, {headers: headers});
  }

  rerunCcSearch(ccUploadID:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    let url = this.config.apiUrl + `/contract_connections/rerun/${ccUploadID}`;
    return this.http.post(url, '', {headers: headers, responseType: 'text'});
  }

  getCcUploads():Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get(this.config.apiUrl + `/contract_connections/uploads`, {headers: headers});
  }

  deleteCcUpload(id:number):Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.delete(this.config.apiUrl + '/contract_connections/upload/' + id, {headers: headers, responseType: 'text'});
  }
}
