import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Tickets} from "../../services/Tickets";
import {ITicket} from "../../interfaces/ITicket";
import {Session} from "../../services/Session";

declare var $: any;

@Component({
  selector: 'tickets',
  templateUrl: './tickets.html',
})
export class TicketsCmp {
  @Input() locationID: number = 0;
  @Input() userID: number = 0;
  @Input() userRelatedID: number = 0;
  @ViewChild('addNoteModal') addNoteModal: ElementRef;

  public loading = false;
  public tickets: ITicket[] = [];
  public newNote: ITicket = <ITicket>{};
  public creatingNote = false;

  constructor(public params: ActivatedRoute, public ticketService: Tickets, public router: Router, public session: Session) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    if (this.locationID || this.userID) {
      this.load();
    } else {
      this.params.params.subscribe(params => {
        this.init(params);
      });
    }
  }

  ngOnChanges() {
    this.load();
  }

  init(params) {
    if (parseInt(params['id'])) {
      if (params['source'] === 'user') {
        this.userID = parseInt(params['id']);
      } else {
        if (params['source'] === 'user_related') {
          this.userRelatedID = parseInt(params['id']);
        } else {
          this.locationID = parseInt(params['id']);
        }
      }
    }
    this.load();
  }

  load() {
    if (!this.userID && !this.locationID || this.loading) {
      return;
    }
    this.loading = true;
    if (this.userID > 0) {
      this.ticketService.loadByUserID(this.userID).subscribe((tickets) => {
        this.tickets = tickets;
        this.refreshTickets();
        this.loading = false;
      }, () => {
        this.loading = false;
      });
    } else {
      if (this.userRelatedID > 0) {
        this.ticketService.loadByUserRelatedID(this.userRelatedID).subscribe((tickets) => {
          this.tickets = tickets;
          this.refreshTickets();
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      } else {
        this.ticketService.loadByLocationID(this.locationID).subscribe((tickets) => {
          this.tickets = tickets;
          this.refreshTickets();
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      }
    }
  }

  refreshTickets() {
    if (!this.tickets || !this.tickets.length) {
      return;
    }

    this.tickets.sort((a, b) => {
      // Convert the date strings to Date objects for comparison
      const dateA = new Date(a.date_created);
      const dateB = new Date(b.date_created);

      // Compare the dates in descending order
      return dateB.getTime() - dateA.getTime();
    });

    for (let k in this.tickets) {
      let ticket = this.tickets[k];
      if (ticket.freshdesk_ticket && ticket.type_id == 1) {
        ticket['___is_refreshing'] = true;
        this.ticketService.refreshFDTicket(ticket.freshdesk_ticket).subscribe((t) => {
          if (t) {
            this.tickets[k] = t;
          }
        }, () => {
          let index = this.tickets.indexOf(ticket);
          if (index > -1) {
            this.tickets.splice(index, 1);
          }
        });
      }
    }
  }

  addNote() {
    let note = new ITicket();
    note.type_id = 2;
    note.__type = 'Note';
    note.location_id = this.locationID;
    note.requestor_user_id = this.userID;
    if (this.session && this.session.user && this.session.user.id) {
      note.cm_user_id = this.session.user.id;
    }
    this.newNote = note;
    $(this.addNoteModal.nativeElement).modal('show');
  }

  createNote() {
    this.creatingNote = true;
    this.ticketService.save(this.newNote).subscribe((id) => {
      this.load();
      $(this.addNoteModal.nativeElement).modal('hide');
      this.creatingNote = false;
    }, (err) => {
      console.error(err);
      this.creatingNote = false;
    });
  }

  openTicket(ticket: ITicket) {
    if (ticket.type_id === 1 || ticket.type_id === 5) {
      this.router.navigate(['ticket', ticket.id]);
    }
  }
}
