import {Component, Input, EventEmitter, Output} from '@angular/core';
import {User, IUserAlias} from "../../services/User";
import {Observable} from "rxjs";
import {Notify} from "../../services/Notify";
import {IJobType} from "../../interfaces/IJobType";
import {JobTypes} from "../../services/JobTypes";

@Component({
  selector: 'user-aliases-list',
  templateUrl: 'aliases.html'
})
export class UserAliasesListCmp {
  @Input() userID:number;
  @Output() update = new EventEmitter();
  public aliases:IUserAlias[] = [];
  public loading = false;
  public jobTypesList:IJobType[] = [];

  constructor(private usrSrv:User, private jobTypes:JobTypes) {
    this.jobTypes.loadToList(this.jobTypesList);
  }

  ngOnChanges() {
    if (this.userID && (!this.aliases || this.aliases.length < 1)) {
      this.load();
    }
  }

  newAlias() {
    this.aliases.push({user_id: this.userID});
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.usrSrv.getAliases(this.userID).subscribe((aliases)=> {
      this.loading = false;
      if (aliases && aliases.length > 0) {
        this.aliases = aliases;
      } else {
        this.aliases = [];
      }
    }, ()=> {
      this.loading = false;
    });
  }

  save(a:IUserAlias) {
    if (!a) {
      return;
    }
    if (a.id) {
      this.updateAlias(a);
    } else {
      this.createAlias(a);
    }
  }

  createAlias(a:IUserAlias) {
    let n = new Notify();
    if (!a.fname || a.fname.trim().length < 1) {
      n.warning('First Name is required');
      return;
    }
    a.___updating = true;
    this.usrSrv.createAlias(a).subscribe(()=> {
      n.success('Success!');
      a.___updating = true;
      this.load();
      this.update.emit();
    }, (err)=> {
      a.___updating = true;
      n.httpErr(err);
    });
  }

  updateAlias(a:IUserAlias) {
    let n = new Notify();
    if (!a.fname || a.fname.trim().length < 1) {
      n.warning('First Name is required');
      return;
    }
    a.___updating = true;
    this.usrSrv.updateAlias(a).subscribe(()=> {
      n.success('Success!');
      a.___updating = true;
      this.load();
      this.update.emit();
    }, (err)=> {
      a.___updating = true;
      n.httpErr(err);
    });
  }

  remove(a:IUserAlias) {
    if (!a.id) {
      let i = this.aliases.indexOf(a);
      if (i > -1) {
        this.aliases.splice(i, 1);
      }
    } else {
      if (a.___removing) {
        return;
      }
      a.___removing = true;
      this.usrSrv.removeAlias(a.id).subscribe(()=> {
        a.___removing = false;
        let i = this.aliases.indexOf(a);
        if (i > -1) {
          this.aliases.splice(i, 1);
        } else {
          this.load();
        }
        this.update.emit();
      }, (err)=> {
        a.___removing = false;
        let n = new Notify();
        n.httpErr(err);
      });
    }
  }

  apply(alias:IUserAlias) {
    if (alias.___updating || !alias.id) {
      return;
    }
    alias.___updating = true;
    this.usrSrv.setAliasAsMain(alias.id).subscribe(()=> {
      alias.___updating = false;
      this.update.emit();
      this.load();
    }, (err)=> {
      alias.___updating = false;
      let n = new Notify();
      n.httpErr(err);
    });
  }
}
