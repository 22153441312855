import {Component, Input} from '@angular/core';
import {IReviewResult} from "../../interfaces/IReviewResult";

@Component({
  selector: 'answer-select',
  templateUrl: './answer_select.html'
})
export class AnswerSelectCmp {
  @Input() answers:Array<IReviewResult>;
}
