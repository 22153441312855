import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";
import {IPocSuppressedReport} from "../../interfaces/IPocSuppressed";

@Component({
  templateUrl: 'report.html',
  selector: 'report-poc-suppressed'
})
export class ReportPocSuppressedCmp {
  public report:IPocSuppressedReport;
  public loading = false;
  @ViewChild('tbl') tbl:ElementRef;
  private n = new Notify();

  constructor(private session:Session, private reports:Reports) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  reload() {
    setTimeout(()=> {
      this.load();
    }, 1);
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.reports.getPocSuppressed().subscribe((report)=> {
      this.report = report;
      this.loading = false;
    }, (err)=> {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  exportCSV() {
    if (!this.report || !this.report.records) {
      return;
    }
    let h = new Helpers();
    h.exportCsv(this.tbl, 'poc_suppressed_report.csv', null, (celltext)=> {
      return celltext.replace('check_circle', 'X');
    });
  }
}
