import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {ActivatedRoute} from '@angular/router';
import {Reports} from "../../services/Reports";
import {IPendingLocationReport} from "../../interfaces/IPendingLocationReport";
import {Notify} from "../../services/Notify";
import {Locations} from "../../services/Locations";
import {IHSReport} from "../../interfaces/IHSReport";
import {ICMLocGroupStats, ICMLocGroupsReport, ICMLocGroupsSummary} from "../../interfaces/ICMLocGroupStats";
import {ICMSales} from "../../interfaces/ICMSales";
import {ICmRegionsReport} from "../../interfaces/ICmRegionsReport";
import {Implementations} from "../../services/Implementations";
import {IImplementationContract} from "../../interfaces/IImplementationContract";
import {ISalesFeeSupplierReport} from "../../interfaces/ISalesFeeSupplierReport";
import {ISalesFeeContractReport} from "../../interfaces/ISalesFeeContractReport";
import {IChannelLocGroupStats} from "../../interfaces/IChannelLocGroupStats";
import {ReportSnapshots} from "../../services/ReportSnapshots";
import {UrlParser} from "../../services/UrlParser";
import {IPopupQuery} from "../../interfaces/IPopupQuery";
import {Newsletter} from "../../services/Newsletter";
import { HttpParams } from '@angular/common/http';
declare var $:any;

@Component({
  templateUrl: './reports.html',
  selector: 'crm-reports',
})
export class ReportsCmp {
  @ViewChild('tbl') tbl:ElementRef;
  public report_id:string;
  public report;
  public reportSummary:ICMLocGroupsSummary;
  public reportTab2;
  public loading = false;
  public loadingTab2 = false;
  public cm_loading = false;
  public zimmerSending = false;
  public exportFileUrl = '';
  public exportFileUrlTab1 = '';
  public exportFileUrlTab2 = '';
  public notify = new Notify();
  public stats:any;
  public rosterDate = '';
  public currentItem:IHSReport = <IHSReport>{};
  public page = 1;
  public rowsPerPage = 15;
  public offset = 15;
  public absentMonthsInput = 5;
  public report_paginated = [];
  public cm_report:ICMSales[];
  public cm_regions_report:ICmRegionsReport[];
  public channels_loc_report:IChannelLocGroupStats[];
  public cmLocSum:ICMLocGroupStats;
  public unassigned_loc_count:number;
  public tagFilterChannels:string[] = [];
  public channelsFiltered:string[] = [];
  public cmFiltered:string[] = [];
  public tagChannels:string[] = [];
  public tagTypes:string[] = [];
  public tagFilterTypes:string[] = [];
  public sortDesc = false;
  public orderField:string = '';
  public implContracts:IImplementationContract[];
  public cnContract:IImplementationContract;
  public tagSpecialties:string[] = [];
  public tagFilterSpecialties:string[] = [];
  public tagCMs:string[] = [];
  public tagFilterCMs:string[] = [];
  public tagCots:string[] = [];
  public tagFilterCots:string[] = [];
  public tagFilterConnected:string[] = [];
  public argLength = 5;
  public argExcludeExact = false;
  public argExcludeInactive = false;
  public emcID = 1;
  public removingCache = false;
  public fromSnapshot:number = 0;
  public tab = 1;
  public filterDateMinMonth:number;
  public filterDateMinYear:number;
  public filterDateMinYearPossible:number;
  public filterDateMaxMonth:number;
  public filterDateMaxYear:number;
  public filterDateMaxYearPossible:number;
  public dateMin;
  public dateMax;
  public patId: string = '';
  public gradeId: string = '';
  public medicareId: string = '';
  public implementationId: string = '';
  private queries:IPopupQuery[];

  constructor(public session:Session, public params:ActivatedRoute, public reports:Reports,
    public locSrv:Locations, public implSrv:Implementations, private snapshots:ReportSnapshots,
    private urlParser:UrlParser, private newsSrv:Newsletter) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    this.report_id = params['id'];
    this.fromSnapshot = 0;
    if (params['snapshot_id']) {
      this.fromSnapshot = parseInt(params['snapshot_id']);
    }
    if (this.report_id) {
      this.tab = 1;
      this.exportFileUrl = '';

      this.parseUrlParams();
      this.loadReport();
    }
    if (this.report_id === 'roster_pc') {
      this.rosterPCApplyFilters();
    }
  }

  loadReport() {
    switch (this.report_id) {
      case 'pending_for_membership':
        this.loading = true;
        this.exportFileUrl = this.reports.getPendingLocsExportUrl();
        this.reports.getPendingLocsReport().subscribe((report) => {
          this.report = report;
          this.loading = false;
        }, () => {
          this.report = null;
          this.exportFileUrl = '';
          this.loading = false;
        });
        break;
      case 'emc':
        this.report = null;
        this.exportFileUrl = null;
        this.newsSrv.getEmailCampaigns().subscribe((r)=> {
          if (r && r.length > 0) {
            this.report = r;
            this.emcID = r[(r.length - 1)].id;
            this.emcRefresh();
          }
        }, ()=> {});
        break;
      case 'mgma_signups':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getMgmaSignupsCsvUrl();
        this.reports.getMGMASignups().subscribe((report) => {
          this.loading = false;
          this.report = report['records'];
          this.queries = [{name: "Query", query: report['query']}];
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'rx_signups':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = '';
        this.reports.getRxSignups().subscribe((report) => {
          this.loading = false;
          this.report = report;
          this.queries = [{name: "Query", query: report['query']}];
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'aoa_users':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getAoaUsersCsvUrl();
        this.reports.getAoaUsers().subscribe((report) => {
          this.loading = false;
          this.report = report['records'];
          this.queries = [{name: "Query", query: report['query']}];
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'aad_users':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getAadUsersCsvUrl();
        this.reports.getAadUsers().subscribe((report) => {
          this.loading = false;
          this.report = report['records'];
          this.queries = [{name: "Query", query: report['query']}];
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'rx_xref':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getRxXrefsCsvUrl();
        this.reports.getRxXrefs().subscribe((report) => {
          this.loading = false;
          this.report = report['records'];
          this.queries = [{name: "Query", query: report['query']}];
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'loc_roster_non_med':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getLocRosterNonMedExportUrl();
        this.reports.getLocRosterNonMed().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'rx_tasks':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = '';
        this.reports.getRxTasks().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'rx_orphans':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = '';
        this.reports.getRxOrphans().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'rebate_chart':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = '';
        this.reports.getRebateChart().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'missing_locations':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = '';
        this.reports.getMissingLocations().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'not_included_contracts':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = '';
        this.reports.getNotIncludedContracts().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'unsent_reports':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = '';
        this.reports.getUnsentReports().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'channels':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getChannelsEmailsUrl();
        this.reports.getChannelsEmails().subscribe((report) => {
          this.loading = false;
          if (!report.amp || !report.cap || !report.mgma) {
            return;
          }
          let r = [];
          let to = Math.max(report.amp.length, report.cap.length, report.mgma.length);
          for (let i = 0; i < to; i++) {
            r.push({
              pc: report.pc[i] || '',
              amp: report.amp[i] || '',
              cap: report.cap[i] || '',
              mgma: report.mgma[i] || '',
              tenet: report.tenet[i] || '',
              aoa: report.aoa[i] || '',
              aad: report.aad[i] || '',
            });
          }
          this.report = r;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'sfs':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getSalesFeeSupplierUrl();
        this.orderField = '';
        this.sortDesc = false;
        this.tagFilterChannels = [];
        this.tagChannels = [];
        this.tagFilterSpecialties = [];
        this.tagSpecialties = [];
        this.tagFilterCots = [];
        this.tagCots = [];
        this.reports.getSalesFeeSupplierReport().subscribe((report) => {
          this.loading = false;
          this.report = report;
          if (report && report.length) {
            report.forEach((r) => {
              if (r.channels) {
                let rcs = r.channels.split(',');
                if (rcs && rcs.length) {
                  rcs.forEach((rcsi) => {
                    let nc = rcsi.trim();
                    if (this.tagChannels.indexOf(nc) < 0) {
                      this.tagChannels.push(nc);
                    }
                  });
                }
              }
              if (r.specialty) {
                let rspecs = r.specialty.split(',');
                if (rspecs && rspecs.length) {
                  rspecs.forEach((spec) => {
                    let nc = spec.trim();
                    if (this.tagSpecialties.indexOf(nc) < 0) {
                      this.tagSpecialties.push(nc);
                    }
                  });
                }
              }
              if (r.cot) {
                let rcots = r.cot.split(',');
                if (rcots && rcots.length) {
                  rcots.forEach((cot) => {
                    let nc = cot.trim();
                    if (this.tagCots.indexOf(nc) < 0) {
                      this.tagCots.push(nc);
                    }
                  });
                }
              }
            });
          }
          this.sfsCalculateTotals();
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'sales_wo_distributor':
        this.loading = true;
        this.report = null;
        if (!this.filterDateMinYear) {
          this.filterDateMinYear = 2017;
        }
        let dt = new Date();
        this.filterDateMaxYearPossible = dt.getFullYear();
        this.filterDateMinYearPossible = 2016;
        this.queries = [];
        this.exportFileUrlTab1 = this.reports.getSalesWithoutDistributorUrl(this.filterDateMinYear);
        this.reports.getSalesWithoutDistributor(this.filterDateMinYear).subscribe((report) => {
          this.loading = false;
          this.report = report.report;
          this.queries = [{name: "Query", query: report.query}];
        }, (err) => {
          this.loading = false;
          this.notify.error(err);
        });
        break;
      case 'pa_wo_sales':
        this.loading = true;
        this.report = null;
        if (!this.filterDateMinYear) {
          this.filterDateMinYear = 2017;
        }
        let dtx = new Date();
        this.filterDateMaxYearPossible = dtx.getFullYear();
        this.filterDateMinYearPossible = 2015;
        this.queries = [];
        this.exportFileUrlTab1 = this.reports.getPaWithoutSalesUrl(this.filterDateMinYear);
        this.reports.getPaWithoutSales(this.filterDateMinYear).subscribe((report) => {
          this.loading = false;
          this.report = report.report;
          this.queries = [{name: "Query", query: report.query}];
        }, (err) => {
          this.loading = false;
          this.notify.error(err);
        });
        break;
      case 'sfc':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getSalesFeeContractUrl();
        this.orderField = '';
        this.sortDesc = false;
        this.tagFilterTypes = [];
        this.tagTypes = [];
        this.tagFilterSpecialties = [];
        this.tagSpecialties = [];
        this.tagFilterCots = [];
        this.tagCots = [];

        this.filterDateMinMonth = null;
        this.filterDateMinYear = null;
        this.filterDateMinYearPossible = null;
        this.filterDateMaxMonth = null;
        this.filterDateMaxYear = null;
        this.filterDateMaxYearPossible = null;
        this.dateMin = null;
        this.dateMax = null;

        this.reports.getSalesFeeContractReport().subscribe((report) => {
          this.loading = false;
          this.report = report;
          if (report.date_min) {
            let dt = report.date_min.split('-');
            this.filterDateMinYearPossible = parseInt(dt[0]);
            this.dateMin = dt[0] + '-' + dt[1];
          }
          if (report.date_max) {
            let dt = report.date_max.split('-');
            this.filterDateMaxYearPossible = parseInt(dt[0]);
            this.dateMax = dt[0] + '-' + dt[1];
          }
          if (report && report.lines && report.lines.length) {
            report.lines.forEach((r) => {
              if (r.type_) {
                let rcs = r.type_.split(',');
                if (rcs && rcs.length) {
                  rcs.forEach((rcsi) => {
                    let nc = rcsi.trim();
                    if (this.tagTypes.indexOf(nc) < 0) {
                      this.tagTypes.push(nc);
                    }
                  });
                }
              }
              if (r.specialty) {
                let rspecs = r.specialty.split(',');
                if (rspecs && rspecs.length) {
                  rspecs.forEach((spec) => {
                    let nc = spec.trim();
                    if (this.tagSpecialties.indexOf(nc) < 0) {
                      this.tagSpecialties.push(nc);
                    }
                  });
                }
              }
              if (r.cot) {
                let rcots = r.cot.split(',');
                if (rcots && rcots.length) {
                  rcots.forEach((cot) => {
                    let nc = cot.trim();
                    if (this.tagCots.indexOf(nc) < 0) {
                      this.tagCots.push(nc);
                    }
                  });
                }
              }
            });
          }
          this.sfcCalculateTotals();
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'sfs_at':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getSalesFeeSupplierUrlAT();
        this.orderField = '';
        this.sortDesc = false;
        this.tagFilterChannels = [];
        this.tagChannels = [];
        this.tagFilterSpecialties = [];
        this.tagSpecialties = [];
        this.tagFilterCots = [];
        this.tagCots = [];
        this.reports.getSalesFeeSupplierReportAT().subscribe((report) => {
          this.loading = false;
          this.report = report;
          if (report && report.length) {
            report.forEach((r) => {
              if (r.channels) {
                let rcs = r.channels.split(',');
                if (rcs && rcs.length) {
                  rcs.forEach((rcsi) => {
                    let nc = rcsi.trim();
                    if (this.tagChannels.indexOf(nc) < 0) {
                      this.tagChannels.push(nc);
                    }
                  });
                }
              }
              if (r.specialty) {
                let rspecs = r.specialty.split(',');
                if (rspecs && rspecs.length) {
                  rspecs.forEach((spec) => {
                    let nc = spec.trim();
                    if (this.tagSpecialties.indexOf(nc) < 0) {
                      this.tagSpecialties.push(nc);
                    }
                  });
                }
              }
              if (r.cot) {
                let rcots = r.cot.split(',');
                if (rcots && rcots.length) {
                  rcots.forEach((cot) => {
                    let nc = cot.trim();
                    if (this.tagCots.indexOf(nc) < 0) {
                      this.tagCots.push(nc);
                    }
                  });
                }
              }
            });
          }
          this.sfsATCalculateTotals();
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'sfc_at':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getSalesFeeContractUrlAT();
        this.orderField = '';
        this.sortDesc = false;
        this.tagFilterTypes = [];
        this.tagTypes = [];
        this.tagFilterSpecialties = [];
        this.tagSpecialties = [];
        this.tagFilterCots = [];
        this.tagCots = [];

        this.filterDateMinMonth = null;
        this.filterDateMinYear = null;
        this.filterDateMinYearPossible = null;
        this.filterDateMaxMonth = null;
        this.filterDateMaxYear = null;
        this.filterDateMaxYearPossible = null;
        this.dateMin = null;
        this.dateMax = null;

        this.reports.getSalesFeeContractReportAT().subscribe((report) => {
          this.loading = false;
          this.report = report;
          if (report.date_min) {
            let dt = report.date_min.split('-');
            this.filterDateMinYearPossible = parseInt(dt[0]);
            this.dateMin = dt[0] + '-' + dt[1];
          }
          if (report.date_max) {
            let dt = report.date_max.split('-');
            this.filterDateMaxYearPossible = parseInt(dt[0]);
            this.dateMax = dt[0] + '-' + dt[1];
          }
          if (report && report.lines && report.lines.length) {
            report.lines.forEach((r) => {
              if (r.type_) {
                let rcs = r.type_.split(',');
                if (rcs && rcs.length) {
                  rcs.forEach((rcsi) => {
                    let nc = rcsi.trim();
                    if (this.tagTypes.indexOf(nc) < 0) {
                      this.tagTypes.push(nc);
                    }
                  });
                }
              }
              if (r.specialty) {
                let rspecs = r.specialty.split(',');
                if (rspecs && rspecs.length) {
                  rspecs.forEach((spec) => {
                    let nc = spec.trim();
                    if (this.tagSpecialties.indexOf(nc) < 0) {
                      this.tagSpecialties.push(nc);
                    }
                  });
                }
              }
              if (r.cot) {
                let rcots = r.cot.split(',');
                if (rcots && rcots.length) {
                  rcots.forEach((cot) => {
                    let nc = cot.trim();
                    if (this.tagCots.indexOf(nc) < 0) {
                      this.tagCots.push(nc);
                    }
                  });
                }
              }
            });
          }
          this.sfcATCalculateTotals();
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'roster':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = this.reports.getRosterReportUrl();
        this.rosterDate = '';
        this.reports.getRosterReport().subscribe((report) => {
          this.loading = false;
          this.report = report;
          this.setPage(1);
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        this.reports.getRosterReportDate().subscribe((response) => {
          this.rosterDate = response;
        }, () => {})
        break;
      case 'roster_pc':
        this.loading = false;
        this.report = null;
        this.exportFileUrl = this.reports.getRosterReportPCUrl();
        this.reports.getRosterPcReportQuery().subscribe((q)=> {
          this.queries = [{name: "Query", query: q}];
        }, ()=> {});
        break;
      case 'cost_studies':
        this.loading = false;
        this.report = null;
        this.exportFileUrl = this.reports.getCostStudiesReportUrl() + '/' + this.argLength;
        break;
      case 'cost_data_run':
        this.loading = false;
        this.report = null;
        this.exportFileUrl = this.reports.getCostStudiesRunUrl() + '/' + this.argLength;
        break;
      case 'cap':
        this.loading = false;
        this.report = null;
        this.exportFileUrl = this.reports.getCAPReportUrl();
        break;
      case 'top10sales':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getTop10Sales().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        this.cm_report = null;
        this.cm_loading = true;
        this.reports.getCmSales().subscribe((report) => {
          this.cm_loading = false;
          this.cm_report = report;
        }, () => {
          this.cm_loading = false;
        });
        break;
      case 'topsalesdr':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getTopSalesByDistrRep().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'topsalesnr':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getTopSalesWithUnknownRep().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'si':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getStaplesInterested().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'si_ns':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getStaplesInterestedNotSent().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'si_nr':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getStaplesInterestedNotReporting().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'hs_nc':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getHSNotConnected().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'mck_nc':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getMcKNotConnected().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'hs_c':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getHSConnected().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'mck_c':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getMcKConnected().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'fo':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getFailingOff().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'reporting_by_distr':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.reports.getReportingByDistributor().subscribe((report) => {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'phones':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.queries = [];
        this.reports.getPhonesReport().subscribe((report) => {
          this.loading = false;
          this.report = report.report;
          this.queries = [{name: "Query", query: report.query}];
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'locsd':
        this.loading = false;
        this.report = null;
        this.exportFileUrl = this.reports.getLocSdCsvUrl();
        this.queries = [];
        break;
      case 'connections':
        this.implContracts = [];
        this.cnContract = null;
        this.report = null;
        this.implSrv.getContracts().subscribe((contracts) => {
          if (contracts) {
            contracts.forEach((c) => {
              if (c.contract_number) {
                this.implContracts.push(c);
                if (!this.cnContract) {
                  this.setCnContract(c);
                }
              }
            });
          }
        }, (response) => {
          this.loading = false;
          console.error(response);
        });
        break;
      case 'cm_loc_groups':
        this.loading = true;
        this.report = null;
        this.exportFileUrl = null;
        this.cmLocSum = null;
        let q;
        if (this.fromSnapshot) {
          q = this.snapshots.getSnapshot(this.fromSnapshot);
        } else {
          q = this.reports.getCmLocGroupStats();
        }
        q.subscribe((report:ICMLocGroupsReport) => {
          this.loading = false;
          this.report = report.by_cm;
          this.reportSummary = report.summary;
          if (this.report && this.report.length > 0) {
            this.cmLocSum = <ICMLocGroupStats>{};
            this.unassigned_loc_count = report.unassigned_loc_count;
            this.report.forEach((r) => {
              this.cmLocSum.goal = this.addNum(this.cmLocSum.goal, r.goal);
              this.cmLocSum.locations_count = this.addNum(this.cmLocSum.locations_count, r.locations_count);
              this.cmLocSum.groups_count = this.addNum(this.cmLocSum.groups_count, r.groups_count);
              this.cmLocSum.single_locations = this.addNum(this.cmLocSum.single_locations, r.single_locations);
              this.cmLocSum.multi_locations = this.addNum(this.cmLocSum.multi_locations, r.multi_locations);
              this.cmLocSum.annualized_sales = this.addNum(this.cmLocSum.annualized_sales, r.annualized_sales);
              this.cmLocSum.last_full_qrt = this.addNum(this.cmLocSum.last_full_qrt, r.last_full_qrt);
              this.cmLocSum.locations_reporting = this.addNum(this.cmLocSum.locations_reporting, r.locations_reporting);
              if (r.distr_connect) {
                try {
                  if (!this.cmLocSum.distr_connect) {
                    this.cmLocSum.distr_connect = $.extend(true, {}, r.distr_connect);
                  } else {
                    this.cmLocSum.distr_connect.connected.mck.primary = this.addNum(this.cmLocSum.distr_connect.connected.mck.primary, r.distr_connect.connected.mck.primary);
                    this.cmLocSum.distr_connect.connected.mck.secondary = this.addNum(this.cmLocSum.distr_connect.connected.mck.secondary, r.distr_connect.connected.mck.secondary);
                    this.cmLocSum.distr_connect.connected.hs.primary = this.addNum(this.cmLocSum.distr_connect.connected.hs.primary, r.distr_connect.connected.hs.primary);
                    this.cmLocSum.distr_connect.connected.hs.secondary = this.addNum(this.cmLocSum.distr_connect.connected.hs.secondary, r.distr_connect.connected.hs.secondary);
                    this.cmLocSum.distr_connect.connected.other.primary = this.addNum(this.cmLocSum.distr_connect.connected.other.primary, r.distr_connect.connected.other.primary);
                    this.cmLocSum.distr_connect.connected.other.secondary = this.addNum(this.cmLocSum.distr_connect.connected.other.secondary, r.distr_connect.connected.other.secondary);
                    this.cmLocSum.distr_connect.count.mck.primary = this.addNum(this.cmLocSum.distr_connect.count.mck.primary, r.distr_connect.count.mck.primary);
                    this.cmLocSum.distr_connect.count.mck.secondary = this.addNum(this.cmLocSum.distr_connect.count.mck.secondary, r.distr_connect.count.mck.secondary);
                    this.cmLocSum.distr_connect.count.hs.primary = this.addNum(this.cmLocSum.distr_connect.count.hs.primary, r.distr_connect.count.hs.primary);
                    this.cmLocSum.distr_connect.count.hs.secondary = this.addNum(this.cmLocSum.distr_connect.count.hs.secondary, r.distr_connect.count.hs.secondary);
                    this.cmLocSum.distr_connect.count.other.primary = this.addNum(this.cmLocSum.distr_connect.count.other.primary, r.distr_connect.count.other.primary);
                    this.cmLocSum.distr_connect.count.other.secondary = this.addNum(this.cmLocSum.distr_connect.count.other.secondary, r.distr_connect.count.other.secondary);
                    this.cmLocSum.distr_connect.reporting.mck.primary = this.addNum(this.cmLocSum.distr_connect.reporting.mck.primary, r.distr_connect.reporting.mck.primary);
                    this.cmLocSum.distr_connect.reporting.mck.secondary = this.addNum(this.cmLocSum.distr_connect.reporting.mck.secondary, r.distr_connect.reporting.mck.secondary);
                    this.cmLocSum.distr_connect.reporting.hs.primary = this.addNum(this.cmLocSum.distr_connect.reporting.hs.primary, r.distr_connect.reporting.hs.primary);
                    this.cmLocSum.distr_connect.reporting.hs.secondary = this.addNum(this.cmLocSum.distr_connect.reporting.hs.secondary, r.distr_connect.reporting.hs.secondary);
                    this.cmLocSum.distr_connect.reporting.other.primary = this.addNum(this.cmLocSum.distr_connect.reporting.other.primary, r.distr_connect.reporting.other.primary);
                    this.cmLocSum.distr_connect.reporting.other.secondary = this.addNum(this.cmLocSum.distr_connect.reporting.other.secondary, r.distr_connect.reporting.other.secondary);
                    this.cmLocSum.distr_connect.sales_dist.mck.primary = this.addNum(this.cmLocSum.distr_connect.sales_dist.mck.primary, r.distr_connect.sales_dist.mck.primary);
                    this.cmLocSum.distr_connect.sales_dist.mck.secondary = this.addNum(this.cmLocSum.distr_connect.sales_dist.mck.secondary, r.distr_connect.sales_dist.mck.secondary);
                    this.cmLocSum.distr_connect.sales_dist.hs.primary = this.addNum(this.cmLocSum.distr_connect.sales_dist.hs.primary, r.distr_connect.sales_dist.hs.primary);
                    this.cmLocSum.distr_connect.sales_dist.hs.secondary = this.addNum(this.cmLocSum.distr_connect.sales_dist.hs.secondary, r.distr_connect.sales_dist.hs.secondary);
                    this.cmLocSum.distr_connect.sales_dist.other.primary = this.addNum(this.cmLocSum.distr_connect.sales_dist.other.primary, r.distr_connect.sales_dist.other.primary);
                    this.cmLocSum.distr_connect.sales_dist.other.secondary = this.addNum(this.cmLocSum.distr_connect.sales_dist.other.secondary, r.distr_connect.sales_dist.other.secondary);
                    this.cmLocSum.distr_connect.sales_total.mck.primary = this.addNum(this.cmLocSum.distr_connect.sales_total.mck.primary, r.distr_connect.sales_total.mck.primary);
                    this.cmLocSum.distr_connect.sales_total.mck.secondary = this.addNum(this.cmLocSum.distr_connect.sales_total.mck.secondary, r.distr_connect.sales_total.mck.secondary);
                    this.cmLocSum.distr_connect.sales_total.hs.primary = this.addNum(this.cmLocSum.distr_connect.sales_total.hs.primary, r.distr_connect.sales_total.hs.primary);
                    this.cmLocSum.distr_connect.sales_total.hs.secondary = this.addNum(this.cmLocSum.distr_connect.sales_total.hs.secondary, r.distr_connect.sales_total.hs.secondary);
                    this.cmLocSum.distr_connect.sales_total.other.primary = this.addNum(this.cmLocSum.distr_connect.sales_total.other.primary, r.distr_connect.sales_total.other.primary);
                    this.cmLocSum.distr_connect.sales_total.other.secondary = this.addNum(this.cmLocSum.distr_connect.sales_total.other.secondary, r.distr_connect.sales_total.other.secondary);
                  }
                } catch (e) {
                  console.error(e);
                }
              }
              if (r.locs_by_channel) {
                try {
                  if (!this.cmLocSum.locs_by_channel) {
                    this.cmLocSum.locs_by_channel = [];
                    r.locs_by_channel.forEach((rc) => {
                      this.cmLocSum.locs_by_channel.push($.extend(true, {}, rc));
                    });
                  } else {
                    r.locs_by_channel.forEach((rc) => {
                      this.cmLocSum.locs_by_channel.forEach((sc) => {
                        if (rc.channel === sc.channel) {
                          sc.connected = this.addNum(sc.connected, rc.connected);
                          sc.count = this.addNum(sc.count, rc.count);
                          sc.reporting = this.addNum(sc.reporting, rc.reporting);
                          sc.sales = this.addNum(sc.sales, rc.sales);
                        } else {
                          let newChannel = true;
                          this.cmLocSum.locs_by_channel.every((scc) => {
                            if (scc.channel === rc.channel) {
                              newChannel = false;
                              return false;
                            }
                            return true;
                          });
                          if (newChannel) {
                            this.cmLocSum.locs_by_channel.push($.extend(true, {}, rc));
                          }
                        }
                      });
                    });
                  }
                } catch (e) {
                  console.error(e);
                }
              }
            });
          }
        }, (err) => {
          this.loading = false;
          this.notify.error(`Can't load report - server error`);
          console.error(err);
        });
        this.cm_regions_report = null;
        this.reports.getCmRegions().subscribe((report) => {
          this.cm_regions_report = report;
        }, () => {});
        this.channels_loc_report = null;
        this.reports.getChannelLocGroupStats().subscribe((report) => {
          this.channels_loc_report = report;
        }, ()=> {});
        break;
      case 'absent':
        this.reloadAbsent();
        break;
      case 'newly':
        this.loading = true;
        this.report = null;
        this.exportFileUrlTab1 = this.reports.getNewlyReportingUrl();
        this.reports.getNewlyReporting().subscribe((report)=> {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(JSON.stringify(err), `Can't load report - server error`);
          console.error(err);
        });
        this.loadingTab2 = true;
        this.reportTab2 = null;
        this.exportFileUrlTab2 = this.reports.getNewlyReportingEarningCycleUrl();
        this.reports.getNewlyReportingEarningCycle().subscribe((report)=> {
          this.loadingTab2 = false;
          this.reportTab2 = report;
        }, (err) => {
          this.loadingTab2 = false;
          this.notify.error(JSON.stringify(err), `Can't load report - server error`);
          console.error(err);
        });
        break;
      case 'tbs':
        this.loading = true;
        this.report = null;
        this.exportFileUrlTab1 = this.reports.getTrendsBySupplierUrl();
        this.reports.getTrendsBySupplier().subscribe((report)=> {
          this.loading = false;
          this.report = report;
        }, (err) => {
          this.loading = false;
          this.notify.error(JSON.stringify(err), `Can't load report - server error`);
          console.error(err);
        });
        this.loadingTab2 = true;
        this.reportTab2 = null;
        this.exportFileUrlTab2 = this.reports.getTrendsBySupplierEarningCycleUrl();
        this.reports.getTrendsBySupplierEarningCycle().subscribe((report)=> {
          this.loadingTab2 = false;
          this.reportTab2 = report;
        }, (err) => {
          this.loadingTab2 = false;
          this.notify.error(JSON.stringify(err), `Can't load report - server error`);
          console.error(err);
        });
        break;
    }
  }

  reloadAbsent() {
    this.loading = true;
    this.report = null;
    this.exportFileUrlTab1 = this.reports.getAbsentUrl(this.absentMonthsInput);
    this.reports.getAbsent(this.absentMonthsInput).subscribe((report)=> {
      this.loading = false;
      this.report = report;
    }, (err) => {
      this.loading = false;
      this.notify.error(JSON.stringify(err), `Can't load report - server error`);
      console.error(err);
    });
    this.loadingTab2 = true;
    this.reportTab2 = null;
    this.exportFileUrlTab2 = this.reports.getAbsentEarningCycleUrl(this.absentMonthsInput);
    this.reports.getAbsentEarningCycle(this.absentMonthsInput).subscribe((report)=> {
      this.loadingTab2 = false;
      this.reportTab2 = report;
    }, (err) => {
      this.loadingTab2 = false;
      this.notify.error(JSON.stringify(err), `Can't load report - server error`);
      console.error(err);
    });
  }

  setCnContract(contract:IImplementationContract) {
    if (!contract) {
      this.cnContract = null;
      this.report = null;
      return;
    }
    if (this.cnContract && contract.id == this.cnContract.id) {
      return;
    }
    this.loading = true;
    this.report = null;
    this.orderField = '';
    this.sortDesc = false;
    this.tagFilterChannels = [];
    this.tagChannels = [];
    this.tagFilterSpecialties = [];
    this.tagSpecialties = [];
    this.tagFilterCMs = [];
    this.tagCMs = [];
    this.cnContract = contract;
    this.exportFileUrl = this.reports.getConnectionsReportUrl(this.cnContract.contract_number);
    this.reports.getConnectionsReport(this.cnContract.contract_number).subscribe((report) => {
      this.loading = false;
      this.report = report;
      if (report && report.length) {
        report.forEach((r) => {
          if (r.channel) {
            if (this.tagChannels.indexOf(r.channel) < 0) {
              this.tagChannels.push(r.channel);
            }
          }
          if (r.specialty) {
            if (this.tagSpecialties.indexOf(r.specialty) < 0) {
              this.tagSpecialties.push(r.specialty);
            }
          }
          if (r.client_manager) {
            if (this.tagCMs.indexOf(r.client_manager) < 0) {
              this.tagCMs.push(r.client_manager);
            }
          }
        });
      }
    }, (err) => {
      this.loading = false;
      this.notify.error(`Can't load report - server error`);
      console.error(err);
    });
  }

  addNum(a, b) {
    // viva strict type converstions!
    return ((a || 0) - 0) + ((b || 0) - 0);
  }

  getEventUserTypeString(id:number) {
    switch (id) {
      case 1:
        return 'Independent physician';
      case 2:
        return 'Work for a hospital';
      case 3:
        return 'Part of a University';
      default:
        return id.toString();
    }
  }

  markZimmerSent() {
    if (!this.report) {
      return;
    }
    let pc_ids = [];
    this.report.forEach((r:IPendingLocationReport) => {
      if (r.pc_id && pc_ids.indexOf(r.pc_id) < 0) {
        pc_ids.push(r.pc_id);
      }
    });
    this.zimmerSending = true;
    this.reports.markZimmerSent(pc_ids).subscribe(() => {
      this.zimmerSending = false;
      this.notify.success('Sent');
      this.init([]);
    }, () => {
      this.zimmerSending = false;
      this.notify.error('Error');
    });
  }

  filterSearch(item, searchTerm):boolean {
    if (!searchTerm) {
      return false;
    }
    switch (this.report_id) {
      case 'sfs':
        return !(this.searchMatch(item.supplier, searchTerm));
      case 'sfc':
        return !(this.searchMatch(item.contract_number, searchTerm) || this.searchMatch(item.vendor, searchTerm) || this.searchMatch(item.contract_description, searchTerm));
      case 'sfs_at':
        return !(this.searchMatch(item.supplier, searchTerm));
      case 'sfc_at':
        return !(this.searchMatch(item.contract_number, searchTerm) || this.searchMatch(item.vendor, searchTerm) || this.searchMatch(item.contract_description, searchTerm));
      case 'connections':
        return !(this.searchMatch(item.facility, searchTerm));
      default:
        false;
    }
  }

  searchMatch(where, what):boolean {
    if (!where || !what) {
      return false;
    }
    return (where.toString().toLowerCase().indexOf(what.toString().toLowerCase()) > -1);
  }

  setPage(page) {
    this.page = Math.ceil(page) || 1;
    this.offset = (this.page - 1) * this.rowsPerPage;
    this.report_paginated = this.report.slice(this.offset, this.offset + this.rowsPerPage);
  }

  locGroupsRel(r:ICMLocGroupStats):string {
    let locs_total = r.single_locations + r.multi_locations;
    if (locs_total > 0) {
      return (Math.round((r.locations_count / locs_total) * 100) / 100).toFixed(2);
    } else {
      return (r.locations_count > 0 ? 100 : 0).toString();
    }
  }

  locAnnualizedPercent(r:ICMLocGroupStats):number {
    return Math.round((r.annualized_sales / r.goal) * 100);
  }

  locQrtPercent(r:ICMLocGroupStats):number {
    return Math.round((r.last_full_qrt / r.goal) * 100);
  }

  locReportingLocsPercent(r:ICMLocGroupStats):number {
    if (r.locations_count > 0) {
      return Math.round((r.locations_reporting / r.locations_count) * 100);
    } else {
      return r.locations_reporting > 0 ? 100 : 0;
    }
  }

  locAverageSales(r:ICMLocGroupStats):number {
    if (r.locations_reporting > 0) {
      return Math.round(r.annualized_sales / r.locations_reporting);
    } else {
      return r.annualized_sales;
    }
  }

  getRoundPercentOf(val:number, total:number):number {
    if (!val) {
      return 0;
    }
    if (total !== 0) {
      return Math.round((val / total) * 100);
    } else {
      return val > 0 ? 100 : 0;
    }
  }

  getRoundAPerB(a:number, b:number) {
    if (b > 0) {
      return Math.round(a / b);
    } else {
      return a;
    }
  }

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  toggleFilterUnconnected() {
    if (this.tagFilterConnected && this.tagFilterConnected.length > 0) {
      this.tagFilterConnected = [];
    } else {
      this.tagFilterConnected = ['Connected'];
    }
  }

  setCSHLength(length) {
    if (this.report_id === 'cost_data_run') {
      this.exportFileUrl = this.reports.getCostStudiesRunUrl() + '/' + length + (this.argExcludeExact ? '/1' : '');
    } else {
      this.exportFileUrl = this.reports.getCostStudiesReportUrl() + '/' + length;
    }
    this.argLength = length;
  }

  toggleExcludeExact($event) {
    this.exportFileUrl = this.reports.getCostStudiesRunUrl() + '/' + this.argLength + ($event ? '/1' : '');
  }

  sfsApplyFilters() {
    this.loading = true;
    this.reports.getSalesFeeSupplierReport(this.tagFilterSpecialties, this.tagFilterCots, this.tagFilterChannels).subscribe((report) => {
      this.loading = false;
      this.report = report;
      this.sfsCalculateTotals();
    }, (err) => {
      this.loading = false;
      this.notify.error(`Can't load report - server error`);
      console.error(err);
    });
  }

  sfsCalculateTotals() {
    this.stats = {sales_total: 0, fees_total: 0};
    if (this.report && this.report.length) {
      this.report.forEach((r:ISalesFeeSupplierReport) => {
        this.stats.sales_total = this.addNum(this.stats.sales_total, r.sales);
        this.stats.fees_total = this.addNum(this.stats.fees_total, r.fee_amount);
      });
    }
  }

  sfcApplyFilters() {
    this.loading = true;
    let dateMin:string;
    if (this.filterDateMinYear) {
      dateMin = this.filterDateMinYear + '-' + (this.filterDateMinMonth || 1) + '-01';
    }
    let dateMax:string;
    if (this.filterDateMaxYear) {
      dateMax = this.filterDateMaxYear + '-' + (this.filterDateMaxMonth || 1) + '-01';
    }
    this.reports.getSalesFeeContractReport(this.tagFilterSpecialties, this.tagFilterCots, this.tagFilterTypes, dateMin, dateMax).subscribe((report) => {
      this.loading = false;
      this.report = report;
      this.sfcCalculateTotals();
    }, (err) => {
      this.loading = false;
      this.notify.error(`Can't load report - server error`);
      console.error(err);
    });
  }

  sfcCalculateTotals() {
    this.stats = {sales_total: 0, fees_total: 0};
    if (this.report && this.report.length) {
      this.report.forEach((r:ISalesFeeContractReport) => {
        this.stats.sales_total = this.addNum(this.stats.sales_total, r.sales);
        this.stats.fees_total = this.addNum(this.stats.fees_total, r.fees);
      });
    }
  }

  sfsATApplyFilters() {
    this.loading = true;
    this.reports.getSalesFeeSupplierReportAT(this.tagFilterSpecialties, this.tagFilterCots, this.tagFilterChannels).subscribe((report) => {
      this.loading = false;
      this.report = report;
      this.sfsATCalculateTotals();
    }, (err) => {
      this.loading = false;
      this.notify.error(`Can't load report - server error`);
      console.error(err);
    });
  }

  sfsATCalculateTotals() {
    this.stats = {sales_total: 0, fees_total: 0};
    if (this.report && this.report.length) {
      this.report.forEach((r:ISalesFeeSupplierReport) => {
        this.stats.sales_total = this.addNum(this.stats.sales_total, r.sales);
        this.stats.fees_total = this.addNum(this.stats.fees_total, r.fee_amount);
      });
    }
  }

  sfcATApplyFilters() {
    this.loading = true;
    let dateMin:string;
    if (this.filterDateMinYear) {
      dateMin = this.filterDateMinYear + '-' + (this.filterDateMinMonth || 1) + '-01';
    }
    let dateMax:string;
    if (this.filterDateMaxYear) {
      dateMax = this.filterDateMaxYear + '-' + (this.filterDateMaxMonth || 1) + '-01';
    }
    this.reports.getSalesFeeContractReportAT(this.tagFilterSpecialties, this.tagFilterCots, this.tagFilterTypes, dateMin, dateMax).subscribe((report) => {
      this.loading = false;
      this.report = report;
      this.sfcATCalculateTotals();
    }, (err) => {
      this.loading = false;
      this.notify.error(`Can't load report - server error`);
      console.error(err);
    });
  }

  sfcATCalculateTotals() {
    this.stats = {sales_total: 0, fees_total: 0};
    if (this.report && this.report.length) {
      this.report.forEach((r:ISalesFeeContractReport) => {
        this.stats.sales_total = this.addNum(this.stats.sales_total, r.sales);
        this.stats.fees_total = this.addNum(this.stats.fees_total, r.fees);
      });
    }
  }

  emcPause(campaign_id:number, pause:boolean) {
    if (!campaign_id) {
      return;
    }
    this.cm_loading = true;
    this.reports.setEmailCampaignPause(campaign_id, pause).subscribe(()=> {
      this.stats.is_paused = pause;
      this.cm_loading = false;
    }, (err)=> {
      this.cm_loading = false;
      console.error(err);
    });
  }

  emcRefresh(id?:number) {
    if (id > 0) {
      this.emcID = id;
    }
    this.loading = true;
    this.stats = null;
    this.reports.getEmailCampaign(this.emcID).subscribe((report) => {
      this.stats = report;
      this.stats.campaign_id = this.emcID;
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  removeCacheKey(key:string, reload?:boolean) {
    this.removingCache = true;
    this.reports.removeCacheKey(key).subscribe(()=> {
      this.removingCache = false;
      if (reload) {
        this.init({id: this.report_id});
      }
    }, ()=> {
      this.removingCache = false;
    });
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.tab) {
      this.tab = Math.max(parseInt(urlParams.tab), 1);
    }
  }

  setTab(i) {
    this.tab = Math.max(parseInt(i), 1);
    this.urlParser.setParameter('tab', this.tab);
  }

  exportCsv() {
    if (!this.tbl || !this.tbl.nativeElement) {
      return;
    }
    setTimeout(()=> {
      let csv = '';
      let delimitter = ',';
      for (let i = 0, row; row = this.tbl.nativeElement.rows[i]; i++) {
        let line = '';
        for (let j = 0, col; col = row.cells[j]; j++) {
          line = line + '"' + col.innerText.replace('drag_handle', '').replace('filter_list', '') + '"' + delimitter;
          if (col.colSpan > 0) {
            for (let c = 1; c < col.colSpan; c++) {
              line = line + delimitter;
            }
          }
        }
        csv = csv + line.replace(/,(\s+)?$/, '').replace(/;(\s+)?$/, '') + "\n";
      }
      let a = this.getCsvExportLink(csv, 'report.csv');
      a.click();
    }, 1);
  }

  getCsvExportLink(csv, fileName):HTMLElement {
    let pom = document.createElement('a');
    var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', fileName);
    return pom;
  }

  rosterPCApplyFilters() {
    const params = {
      pat_id: this.patId,
      grade_id: this.gradeId,
      medicare_id: this.medicareId,
      implementation_id: this.implementationId
    };

    const httpParams = new HttpParams({ fromObject: params });

    this.exportFileUrl = `${this.reports.getRosterReportPCUrl()}?${httpParams}`;
  }
}
