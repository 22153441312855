import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'filters-selector',
  templateUrl: 'selector.html',
})
export class FiltersSelectorCmp {
  @Input() words:string[];
  @Input() selectedWords:string[] = [];
  @Output('selectedWordsChange') selectedWordsChange = new EventEmitter();
  @Input() wordsFromField:string; //optional - use list of objects as source of words
  @Input() wordsFromList:any[]; //optional - use list of objects as source of words
  @Input() inclusive = true;
  @Input() splitBy = '';
  public uniqueWords:string[] = [];

  toggleWord(word) {
    this.handleWordClick(word);
    this.selectedWordsChange.emit(this.selectedWords);
  }

  ngOnChanges() {
    if (!this.words && this.wordsFromList && this.wordsFromList.length > 0 && this.wordsFromField) {
      this.words = [];
      this.wordsFromList.forEach((i)=> {
        if (i[this.wordsFromField]) {
          if (this.splitBy) {
            i[this.wordsFromField].split(this.splitBy).forEach((j)=> {
              this.words.push(j);
            });
          } else {
            this.words.push(i[this.wordsFromField]);
          }
        }
      });
    }
    if (this.words && this.words.length > 1) {
      this.words.sort();
      this.uniqueWords = [];
      let filtered = {};
      this.words.forEach((w)=> {
        let wl = w.toString().toLowerCase();
        if (!filtered[wl]) {
          filtered[wl] = 1;
          this.uniqueWords.push(w);
          if (!this.inclusive) {
            this.selectedWords.push(w);
          }
        }
      });
    }
  }

  handleWordClick(tag) {
    if (!this.selectedWords) {
      this.selectedWords = [tag];
      return;
    }
    let i = this.selectedWords.indexOf(tag);
    if (i < 0) {
      this.selectedWords.push(tag);
    } else {
      this.selectedWords.splice(i, 1);
    }
  }

  reset() {
    this.selectedWords = [];
    this.selectedWordsChange.emit(this.selectedWords);
  }
}
