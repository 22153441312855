
import {of as observableOf, Observable} from 'rxjs';

import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from './Config';
import {ITaxStatus} from "../interfaces/ITaxStatus";

@Injectable()
export class TaxStatuses {
  private loadingObservable:Observable<any>;
  private list:Array<ITaxStatus> = [];

  constructor(private http: HttpClient, private config:Config) {}

  load():Observable<any> {
    if (this.list && this.list.length > 0) {
      return observableOf(this.list);
    }
    if (this.loadingObservable) {
      return this.loadingObservable;
    }
    this.loadingObservable = this.http.get(this.config.apiUrl+'/tax_status').pipe(share());
    this.loadingObservable.subscribe((items)=> {
      this.list = items;
    }, ()=> {});
    return this.loadingObservable;
  }

  loadToMap(map:{[id:number]:ITaxStatus;}, callback?) {
    this.load().subscribe((taxStatuses)=> {
      if (taxStatuses && taxStatuses.length > 0) {
        taxStatuses.forEach((t)=> {
          map[t.id] = t;
        });
        if (callback) {callback();}
      }
    }, ()=> {});
  }

  loadToList(list:ITaxStatus[]) {
    this.load().subscribe((taxStatuses)=> {
      if (taxStatuses && taxStatuses.length > 0) {
        taxStatuses.forEach((t)=> {
          list.push(t);
        });
      }
    }, ()=> {});
  }
}
