import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Locations} from "../../services/Locations";
import {IFileHeaders} from "../../interfaces/IFile";
import {ILocation} from "../../interfaces/ILocation";
import {Distributors} from "../../services/Distributors";
import {Specialties} from "../../services/Specialties";
import {Cots} from "../../services/Cots";
import {TaxStatuses} from "../../services/TaxStatuses";
import {YearlySpendTypes} from "../../services/YearlySpendTypes";
import {Themes} from "../../services/Themes";
import {IDistributor} from "../../interfaces/IDistributor";
import {ICot} from "../../interfaces/ICot";
import {ICotInternal} from "../../interfaces/ICotInternal";
import {ITheme} from "../../interfaces/ITheme";
import {ISubTheme} from "../../interfaces/ISubTheme";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {ITaxStatus} from "../../interfaces/ITaxStatus";
import {IYearlySpendType} from "../../interfaces/IYearlySpendType";
import {Helpers} from "../../services/Helpers";
import {Notify} from "../../services/Notify";

@Component({
  templateUrl: 'bulk_upload.html',
  selector: 'bulk-upload'
})
export class BulkUploadCmp {
  public newFileData:File;
  public newFileName:string;
  public uploadingFile:boolean = false;
  public parsingHeaders:boolean = false;
  public afterParsingHeaders:boolean = false;
  public afterParsingFile:boolean = false;
  public fileHeadersList:{index: number, columns_str: string}[];
  public headersIndex:number = 0;
  public parsing = false;
  public columnsList;
  public columnsMap;
  public parentLoc:ILocation;
  public req = this.getEmptyReq();
  public info = {lines_inserted: 0, errors: 0, status: ''};
  public distributorsList:IDistributor[] = [];
  public specialtiesMap = {};
  public specialtiesList:ISpecialty[] = [];
  public cotsList:ICot[] = [];
  public cotsInternalList:ICotInternal[] = [];
  public taxStatusesList:ITaxStatus[] = [];
  public yearlySpendList:IYearlySpendType[] = [];
  public themes:Array<ITheme> = [];
  public subThemes:Array<ISubTheme> = [];
  private fileHeaders:{[row:number]:{[column:number]:string}};
  private fileType:number;
  private n = new Notify();

  constructor(private session:Session, private locations:Locations, public distributors:Distributors, public specialties:Specialties,
    public cots:Cots, public taxStatus:TaxStatuses, public yearlySpendSrv:YearlySpendTypes, public themesSrv:Themes, private h:Helpers) {
    this.session.redirectIfNotAuth();
    this.distributors.loadToList(this.distributorsList);
    this.specialties.loadToList(this.specialtiesList);
    this.cots.loadToList(this.cotsList);
    this.cots.loadInternalToList(this.cotsInternalList);
    this.taxStatus.loadToList(this.taxStatusesList);
    this.yearlySpendSrv.loadToList(this.yearlySpendList);
    this.themesSrv.getThemes().subscribe((t)=> {this.themes = t;}, ()=> {});
    this.themesSrv.getSubThemes().subscribe((t)=> {this.subThemes = t;}, ()=> {});
  }

  getEmptyReq() {
    return {
      hin: 0,
      pa_date: 2,
      facility_name: 3,
      dba: 4,
      address: 5,
      address2: 6,
      city: 7,
      state: 8,
      zip: 9,
      phone: 10,
      class_of_trade: 11,
      class_of_trade_internal_primary: 12,
      class_of_trade_internal_sub: 13,
      tax_status: 14,
      dea: 19,
      dea_name: 20,
      dea_address: 21,
      specialty: 23,
      ht_specialty: 24,
      yearly_spend_type: 25,
      membership_comments: 26,
      distributor: 27,
      distributor_secondary: 28,
      website: 30,
      doctors_count: 31,
      group_name: 32,
      division_name: 33,
      market_name: 34,
      theme: 35,
      sub_theme: 36,
      gln: 1
    };
  }

  addFile(files) {
    if (!files || !files[0]) {
      return;
    }
    this.newFileName = files[0].name;
    this.newFileData = files[0];
    this.afterParsingHeaders = false;
    this.req = this.getEmptyReq();
    this.parseFile();
  }

  parseFile() {
    if (!this.newFileData) {
      return false;
    }
    this.parsingHeaders = true;
    this.afterParsingHeaders = false;
    this.columnsList = null;
    this.columnsMap = null;
    this.locations.parseFileXLS(this.newFileData, 1000, [this.req.pa_date]).subscribe((resp:any)=> {
      this.parsingHeaders = false;
      this.afterParsingHeaders = true;
      if (resp && resp.lines) {
        this.fileHeaders = resp.lines;
        this.fileHeadersList = [];
        for (let hi in this.fileHeaders) {
          this.fileHeadersList.push({index: parseInt(hi), columns_str: Object.values(this.fileHeaders[hi]).join("; ")});
        }
        this.selectHeadersIndex(resp.headers_index);
        this.checkTextToIDConversions();
      }
    }, (err)=> {
      this.parsingHeaders = false;
      console.error(err);
    });
  }

  selectHeadersIndex(i) {
    this.headersIndex = i;
    this.columnsMap = this.fileHeaders[this.headersIndex];
    this.columnsList = Object.values(this.fileHeaders[this.headersIndex]);
  }

  setParentLocation(loc:ILocation) {
    if (!loc || !loc.id) {
      return;
    }
    this.parentLoc = loc;
  }

  resetFileUpload() {
    this.info = {lines_inserted: 0, errors: 0, status: ''};
    this.newFileData = null;
    this.newFileName = null;
    this.columnsList = null;
    this.columnsMap = null;
    this.headersIndex = null;
    this.fileHeaders = null;
    this.fileHeadersList = null;
    this.afterParsingHeaders = false;
    this.afterParsingFile = false;
  }

  addFileContent() {
    if (!this.newFileData || !this.parentLoc) {
      return;
    }
    this.parsing = true;
    this.afterParsingFile = false;
    this.info = {lines_inserted: 0, errors: 0, status: ''};
    let inserted = 0;
    let queries = 0;
    let errors = 0;
    let c = ()=> {
      if (queries <= 0) {
        this.info = {lines_inserted: inserted, errors: errors, status: 'Completed'};
        this.parsing = false;
        this.afterParsingFile = true;
      } else {
        this.info = {lines_inserted: inserted, errors: errors, status: 'In progress'};
      }
    };

    this.locations.parseFileXLS(this.newFileData, 1000, [this.req.pa_date]).subscribe((resp:any)=> {
      if (resp && resp.lines) {
        this.fileHeaders = resp.lines;
        this.fileHeadersList = [];
        for (let hi in this.fileHeaders) {
          this.fileHeadersList.push({index: parseInt(hi), columns_str: Object.values(this.fileHeaders[hi]).join("; ")});
        }
      } else {
        c();
        this.n.error('No records parsed');
        return;
      }

      Object.entries(this.fileHeaders).forEach(([i, line]) => {
          if (parseInt(i) <= this.headersIndex) {
            return;
          }
          let loc_name = line[this.req.facility_name];
          if (!loc_name || loc_name.length < 2) {
            return;
          }
          let loc = {
            location_group_id: this.parentLoc.location_group_id,
            primary_contact_user_id: this.parentLoc.primary_contact_user_id,
            __rep_user_id: this.parentLoc.__rep_user_id,
            __rep_secondary_id: this.parentLoc.__rep_secondary_id,
            hin: line[this.req.hin],
            pa_date: line[this.req.pa_date] || this.parentLoc.pa_date,
            facility_name: line[this.req.facility_name],
            dba: line[this.req.dba],
            address: line[this.req.address],
            address2: line[this.req.address2],
            city: line[this.req.city],
            state: line[this.req.state],
            zip: line[this.req.zip],
            phone: line[this.req.phone],
            class_of_trade_id: this.h.findObjIdByField(this.cotsList, 'name', line[this.req.class_of_trade]) || this.parentLoc.class_of_trade_id,
            class_of_trade_internal_primary_id: this.h.findObjIdByField(this.cotsInternalList, 'label', line[this.req.class_of_trade_internal_primary]) || this.parentLoc.class_of_trade_internal_primary_id,
            class_of_trade_internal_sub_id: this.h.findObjIdByField(this.cotsInternalList, 'label', line[this.req.class_of_trade_internal_sub]) || this.parentLoc.class_of_trade_internal_sub_id,
            tax_status_id: this.h.findObjIdByField(this.taxStatusesList, 'name', line[this.req.tax_status]) || this.parentLoc.tax_status_id,
            dea: line[this.req.dea],
            dea_name: line[this.req.dea_name],
            dea_address: line[this.req.dea_address],
            specialty_id: this.h.findObjIdByField(this.specialtiesList, 'name', line[this.req.specialty]) || this.parentLoc.specialty_id,
            zimmer_specialty_id: this.h.findObjIdByField(this.specialtiesList, 'name', line[this.req.ht_specialty]) || this.parentLoc.zimmer_specialty_id,
            yearly_spend_type_id: this.h.findObjIdByField(this.yearlySpendList, 'label', line[this.req.yearly_spend_type]) || this.parentLoc.yearly_spend_type_id,
            membership_comments: line[this.req.membership_comments],
            __distributor_id: this.h.findObjIdByField(this.distributorsList, 'name', line[this.req.distributor]) || this.parentLoc.__distributor_id,
            __distributor_secondary_id: this.h.findObjIdByField(this.distributorsList, 'name', line[this.req.distributor_secondary]) || this.parentLoc.__distributor_secondary_id,
            website: line[this.req.website],
            doctors_count: parseInt(line[this.req.doctors_count]),
            group_name: line[this.req.group_name] || this.parentLoc.group_name,
            division_name: line[this.req.division_name] || this.parentLoc.division_name,
            market_name: line[this.req.market_name] || this.parentLoc.market_name,
            theme_id: this.h.findObjIdByField(this.themes, 'name', line[this.req.theme]) || this.parentLoc.theme_id,
            sub_theme_id: this.h.findObjIdByField(this.themes, 'name', line[this.req.sub_theme]) || this.parentLoc.sub_theme_id,
            gln: line[this.req.gln],
          };
          queries++;
          this.locations.updateLocation(<any>loc).then(()=> {
            inserted++;
            queries--;
            c();
          }, ()=> {
            errors++;
            queries--;
            c();
          });
        }
      );
    }, (err)=> {
      this.parsing = false;
      this.afterParsingFile = true;
      c();
      this.n.error('File can\'t be parsed');
    });
  }

  checkTextToIDConversions(calledExplicitly?) {
    let cot = 0;
    let cotInt = 0;
    let cotIntSub = 0;
    let taxStatus = 0;
    let specialty = 0;
    let htSpecialty = 0;
    let yearlySpendType = 0;
    let distr = 0;
    let distrSec = 0;
    let theme = 0;
    let subTheme = 0;
    let total = 0;

    Object.entries(this.fileHeaders).forEach(([i, line]) => {
      if (parseInt(i) <= this.headersIndex) {
        return;
      }
      total++;
      if (line[this.req.class_of_trade] && !this.h.findObjByField(this.cotsList, 'name', line[this.req.class_of_trade])) {
        cot++;
      }
      if (line[this.req.class_of_trade_internal_primary] && !this.h.findObjByField(this.cotsInternalList, 'label', line[this.req.class_of_trade_internal_primary])) {
        cotInt++;
      }
      if (line[this.req.class_of_trade_internal_sub] && !this.h.findObjByField(this.cotsInternalList, 'label', line[this.req.class_of_trade_internal_sub])) {
        cotIntSub++;
      }
      if (line[this.req.tax_status] && !this.h.findObjByField(this.taxStatusesList, 'name', line[this.req.tax_status])) {
        taxStatus++;
      }
      if (line[this.req.specialty] && !this.h.findObjByField(this.specialtiesList, 'name', line[this.req.specialty])) {
        specialty++;
      }
      if (line[this.req.ht_specialty] && !this.h.findObjByField(this.specialtiesList, 'name', line[this.req.ht_specialty])) {
        htSpecialty++;
      }
      if (line[this.req.yearly_spend_type] && !this.h.findObjByField(this.yearlySpendList, 'label', line[this.req.yearly_spend_type])) {
        yearlySpendType++;
      }
      if (line[this.req.distributor] && !this.h.findObjByField(this.distributorsList, 'name', line[this.req.distributor])) {
        distr++;
      }
      if (line[this.req.distributor_secondary] && !this.h.findObjByField(this.distributorsList, 'name', line[this.req.distributor_secondary])) {
        distrSec++;
      }
      if (line[this.req.theme] && !this.h.findObjByField(this.themes, 'name', line[this.req.theme])) {
        theme++;
      }
      if (line[this.req.sub_theme] && !this.h.findObjByField(this.subThemes, 'name', line[this.req.sub_theme])) {
        subTheme++;
      }
    });

    if (total > 0) {
      let warnings = 0;
      let threshold = 0.25;
      if (cot / total > threshold) {
        this.n.warning('Values for ' + Math.round((cot / total) * 100) + '% rows of <b>`Class of Trade`</b> column can\'t be found.');
        warnings++;
      }
      if (cotInt / total > threshold) {
        this.n.warning('Values for ' + Math.round((cotInt / total) * 100) + '% rows of <b>`Class of Trade Int. Primary`</b> column can\'t be found.');
        warnings++;
      }
      if (cotIntSub / total > threshold) {
        this.n.warning('Values for ' + Math.round((cotIntSub / total) * 100) + '% rows of <b>`Class of Trade Int. Sub`</b> column can\'t be found.');
        warnings++;
      }
      if (taxStatus / total > threshold) {
        this.n.warning('Values for ' + Math.round((taxStatus / total) * 100) + '% rows of <b>`Tax Status`</b> column can\'t be found.');
        warnings++;
      }
      if (specialty / total > threshold) {
        this.n.warning('Values for ' + Math.round((specialty / total) * 100) + '% rows of <b>`Specialty`</b> column can\'t be found.');
        warnings++;
      }
      if (htSpecialty / total > threshold) {
        this.n.warning('Values for ' + Math.round((htSpecialty / total) * 100) + '% rows of <b>`HT Specialty`</b> column can\'t be found.');
        warnings++;
      }
      if (yearlySpendType / total > threshold) {
        this.n.warning('Values for ' + Math.round((yearlySpendType / total) * 100) + '% rows of <b>`Yearly Spend Type`</b> column can\'t be found.');
        warnings++;
      }
      if (distr / total > threshold) {
        this.n.warning('Values for ' + Math.round((distr / total) * 100) + '% rows of <b>`Distributor`</b> column can\'t be found.');
        warnings++;
      }
      if ((distrSec / total) > threshold) {
        this.n.warning('Values for ' + Math.round((distrSec / total) * 100) + '% rows of <b>`Distributor Secondary`</b> column can\'t be found.');
        warnings++;
      }
      if (theme / total > threshold) {
        this.n.warning('Values for ' + Math.round((theme / total) * 100) + '% rows of <b>`Theme`</b> column can\'t be found.');
        warnings++;
      }
      if (subTheme / total > threshold) {
        this.n.warning('Values for ' + Math.round((subTheme / total) * 100) + '% rows of <b>`Sub Theme`</b> column can\'t be found.');
        warnings++;
      }
      if (warnings == 0 && calledExplicitly) {
        this.n.success('Checked successfully!');
      }
    } else {
      if (calledExplicitly) {
        this.n.warning('No records parsed');
      }
    }
  }
}
