import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Locations} from "../../services/Locations";
import {Session} from "../../services/Session";
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";

@Component({
  selector: 'rep-manager-loc-groups',
  templateUrl: './groups.html',
})
export class RepManagerLocGroupsCmp {
  public repID;
  public managerID;
  public groups = [];
  public loading = false;
  public rep: IUser;
  public manager: IUser;

  constructor(public session: Session, public params: ActivatedRoute, public router: Router,
    public locations: Locations, public users: User) {
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    this.repID = parseInt(params['rep_id']);
    if (!this.repID) {
      this.router.navigate(['']);
      return;
    }
    this.managerID = parseInt(params['manager_id']);
    if (!this.managerID) {
      this.router.navigate(['user', this.repID]);
      return;
    }
    this.session.redirectIfNotAuth();
    this.loadNames();
    this.load();
  }

  loadNames() {
    this.users.getUserInfo(this.repID).then(rep => this.rep = <IUser>rep, ()=> {});
    this.users.getUserInfo(this.managerID).then(manager => this.manager = <IUser>manager, ()=> {});
  }

  load() {
    this.loading = true;
    this.locations.getLocationGroupsOfManagerForRep(this.repID, this.managerID).then((groups) => {
      this.groups = <[any]>groups;
      this.loading = false;
    }, () => this.loading = false);
  }
}
