import {Component, Input} from '@angular/core';

@Component({
  template: `
        <span [ngSwitch]="sortOrder" *ngIf="sortField==field">
          <span *ngSwitchCase="'asc'" class="material-icons">arrow_drop_down</span>
          <span *ngSwitchCase="'desc'" class="material-icons">arrow_drop_up</span>
        </span>
        <span *ngIf="sortField!=field" class="material-icons">drag_handle</span>
  `,
  styles: ['.material-icons{ line-height: 9px; top: 7px; cursor: pointer }'],
  selector: 'sort-arrow'
})
export class SortArrowCmp {
  @Input() sortField;
  @Input() field;
  @Input() sortOrder;
}
