import {Component, Input} from '@angular/core';
import {Session} from "../../../services/Session";
import {Reports} from "../../../services/Reports";
import {IHisciSyncReport} from "../../../interfaces/IHisciSyncReport";
import {Locations} from "../../../services/Locations";
import {ILocation} from "../../../interfaces/ILocation";
import {Notify} from "../../../services/Notify";

@Component({
  templateUrl: 'hisci_to_crm.html',
  selector: 'hisci-to-crm-rx-eff-date',
})
export class HisciToCrmRxEffDateCmp {
  public report: IHisciSyncReport;
  public loading = false;
  public updating = 0;
  public rxDaysDiffFiltered = [];
  private n = new Notify();

  constructor(private session: Session, private reports: Reports, private locSrv: Locations) {
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  reload() {
    setTimeout(() => {
      this.load();
    }, 1);
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.reports.getHisciSync_RxEffDate_Report().subscribe((report) => {
      this.report = report;
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  toggleSelectAll() {
    if (this.report && this.report.records && this.report.records.length) {
      if (this.rxDaysDiffFiltered && this.rxDaysDiffFiltered.length > 0) {
        let has_non_selected = false;
        let has_selected = false;
        this.report.records.forEach((r) => {
          if (this.rxDaysDiffFiltered.indexOf(r.rx_days_diff) > -1) {
            if (r.__is_selected) {
              has_selected = true;
            } else {
              has_non_selected = true;
            }
          } else {
            r.__is_selected = false;
          }
        });
        this.report.records.forEach((r) => {
          if (this.rxDaysDiffFiltered.indexOf(r.rx_days_diff) > -1) {
            if (has_selected && has_non_selected) {
              r.__is_selected = true;
            } else {
              if (!has_non_selected) {
                r.__is_selected = false;
              } else {
                r.__is_selected = !r.__is_selected;
              }
            }
          } else {
            r.__is_selected = false;
          }
        });
      } else {
        let has_non_selected = false;
        let has_selected = false;
        this.report.records.forEach((r) => {
          if (r.__is_selected) {
            has_selected = true;
          } else {
            has_non_selected = true;
          }
        });
        this.report.records.forEach((r) => {
          if (has_selected && has_non_selected) {
            r.__is_selected = true;
          } else {
            if (!has_non_selected) {
              r.__is_selected = false;
            } else {
              r.__is_selected = !r.__is_selected;
            }
          }
        });
      }
    }
  }

  applyChanges() {
    if (this.updating > 0) {
      return;
    }
    if (this.report && this.report.records && this.report.records.length) {
      let reloadAfter = () => {
        if (!this.loading && this.updating == 0) {
          setTimeout(() => {
            this.reload();
            this.rxDaysDiffFiltered = [];
          }, 500);
        }
      };
      this.report.records.forEach((r) => {
        if (r.__is_selected && r.new_rx_eff_date) {
          r.__is_updated = false;
          r.__is_update_failed = false;
          this.updating++;
          let loc = <ILocation>{id: r.loc_id};
          loc.pharma_eff_date = r.new_rx_eff_date;
          if (r.prim_distr_id) {
            if (!r.current_rx_eff_date || r.current_rx_eff_date === '' || r.current_rx_eff_date.indexOf('0000') > -1) {
              this.locSrv.addLifecycleRecord(r.loc_id, r.prim_distr_id, 11, 7);
            }
          }
          this.locSrv.updateLocation(loc).then(() => {
            this.updating--;
            r.__is_updated = true;
            reloadAfter();
          }, () => {
            this.updating--;
            r.__is_update_failed = false;
            reloadAfter();
          });
        }
      });
    }
  }
}
