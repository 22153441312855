import { Injectable } from "@angular/core";
@Injectable()
export class UrlParser {
  getParameter(key) {
    return this.getParameters()[key];
  }

  getParameters():any {
    // http://stackoverflow.com/a/2880929/680786
    var match,
      pl = /\+/g,  // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function (s) { return decodeURIComponent(s.replace(pl, ' ')); },
      query = window.location.search.substring(1);

    let urlParams = {};
    while (match = search.exec(query)) {
      urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
  }

  setParameter(key:string, value) {
    let params = this.getParameters() || {};
    if ((!value && !params[key]) || params[key]===value) {
      return true;
    }
    if (!value) {
      delete params[key];
    } else {
      params[key] = value;
    }
    let search = [];
    for (let k in params) {
      if (params.hasOwnProperty(k)) {
        search.push(encodeURIComponent(k)+'='+encodeURIComponent(params[k]));
      }
    }
    let query = '?'+search.join('&');
    let history = window.history;
    if (history && history.replaceState) {
      history.replaceState(null, '', location.pathname+query);
    } else {
      return false;
    }
    return true;
  }
}
