import {Component, Input, Output, EventEmitter} from '@angular/core';
import {User} from "../../services/User";
import {IUser} from "../../interfaces/IUser";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'users-merge-window',
  templateUrl: './users-merge.html',
})
export class UsersMergeWindowCmp {
  @Input() userID;
  @Output() merged = new EventEmitter();
  public users:IUser[] = [];

  constructor(public usersSrv:User) {}

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    if (this.userID) {
      this.usersSrv.getUsersForMerge(this.userID).subscribe((users)=> {
        this.users = users;
      }, ()=> {
        this.users = [];
      });
    }
  }

  merge(user:IUser) {
    if (this.userID && user.id) {
      this.usersSrv.mergeUser(user.id, this.userID).subscribe(()=> {
        this.merged.emit(true);
        let i = this.users.indexOf(user);
        if (i > -1) {
          this.users.splice(i, 1);
        }
        try {
          let notify = new Notify();
          notify.success('Merged');
        } catch (e) {}
      }, (r)=> {
        try {
          let notify = new Notify();
          let message = '';
          switch (r.status) {
            case 409:
              message = 'Password of the target user is empty';
              break;
            case 500:
              message = 'Database error';
              break;
            case 406:
              message = 'Password of the source user is not empty - should not be used as a source';
              break;
            case 410:
              message = 'Destination user not found';
              break;
            case 404:
              message = 'Source user not found';
              break;
          }
          notify.error(message, 'Error');
          console.error(r);
        } catch (e) {}
      });
    }
  }
}
