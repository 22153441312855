import {Component} from '@angular/core';
import {Session} from "../../services/Session";
import {Locations} from "../../services/Locations";

@Component({
  selector: 'home',
  templateUrl: './home.html',
  styleUrls: ['./home.css'],
})
export class HomeCmp {
  public loading;

  constructor(public session:Session, public locations:Locations) {
  }

  ngOnInit() {
    if (!this.locations.countersFetched) {
      this.loading = true;
    }
    this.session.addOnLoadHandler(()=> {
      this.locations.getLocationsCount().then(()=> {
        this.loading = false;
      }, ()=> {
        this.loading = false;
      });
    });
    this.session.redirectIfNotAuth();
  }
}
