import {Component, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Reports} from "../../services/Reports";
import {Notify} from "../../services/Notify";
import {Helpers} from "../../services/Helpers";
import {IDistrProcessFlowReport} from "../../interfaces/ReportsInterfaces";
import {UrlParser} from "../../services/UrlParser";

@Component({
  templateUrl: 'report.html',
  selector: 'report-distr-process-flow'
})
export class ReportDistrProcessFlowCmp {
  public report:IDistrProcessFlowReport;
  public loading = false;
  @ViewChild('tbl') tbl:ElementRef;
  public page = 1;
  public itemsLimit = 70;
  private itemsLimitPre = 70;
  public exporting = false;
  public rendering = false;
  private n = new Notify();

  constructor(private session:Session, private reports:Reports, private urlParser:UrlParser, private h:Helpers) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.setPage(parseInt(this.urlParser.getParameter('page')));
    this.session.redirectIfNotAuth();
  }

  reload() {
    setTimeout(()=> {
      this.load();
    }, 1);
  }

  load() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.reports.getDistrProcessFlowReport().subscribe((report)=> {
      this.report = report;
      this.loading = false;
    }, (err)=> {
      this.loading = false;
      this.n.httpErr(err);
    });
  }

  exportCSV() {
    if (!this.report || !this.report.records || !this.report.records.length || this.exporting) {
      return;
    }
    this.rendering = true;
    this.exporting = true;
    let itemsLimitPre = this.itemsLimit;
    let prePage = this.page;
    this.page = 1;
    this.itemsLimit = 100000;
    setTimeout(()=> {
      let d = new Date();
      this.h.exportCsv(this.tbl, 'hs-process-flow-' + d.toISOString().substring(0, 10).replace(' ', '-') + '.csv', () => {
        this.exporting = false;
        this.itemsLimit = itemsLimitPre;
        this.page = prePage;
        this.rendered();
      }, (celltext) => {
        return celltext.replace('check_circle', 'X');
      });
    }, 120);
  }

  rendered() {
    setTimeout(()=> {
      this.rendering = false;
    }, 60);
  }

  setPage($event) {
    let newPage = Math.max(0, parseInt($event) || 1);
    this.urlParser.setParameter('page', newPage);
    if (this.page !== newPage) {
      this.page = newPage;
    }
  }
}
