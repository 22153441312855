import {Injectable} from '@angular/core';
import {ISubTheme} from "../interfaces/ISubTheme";
import {HttpClient} from '@angular/common/http';
import {ITheme} from "../interfaces/ITheme";

@Injectable()
export class Config {
  // API version required: 39.36.0
  public version = 867;
  public apiUrl = '/api';
  public savingsRedirAuthURL = 'http://savings.purchaseclinic.com/redir_auth?token=';
  public signupAmpUrlPrefix = 'https://accessmedicalpurchasing.com/enroll/';
  public TASK_CUSTOM_TYPE_EMAIL = 15; // it's not `task_type`, it's `task`, special record
  public TASK_CUSTOM_TYPE_DONE = 16; // it's not `task_type`, it's `task`, special record
  public TASK_TYPE_EMAIL = 1;
  public TASK_TYPE_DONE = 2;
  public TASK_TYPE_AUTOSEND = 3;
  public defaultThemeID = 1;
  public ampThemeID = 2;
  public capThemeID = 5;
  public mgmaThemeID = 4;
  public commThemeID = 11;
  public implStepSent = 2;
  public implStepConnected = 3;
  public implStepDoesntUseEthicon = 5;
  public implStepNotValidatedID = 6;
  public userTypeRep = 3;
  public userTypeCM = 2;
  public ticketTypeEmail = 5;
  public is_dev = false;
  public themesMap:any = {
    1: {"id":1,"main_logo_url":"//purchaseclinic.s3.amazonaws.com/logo/advantage-trust-powered-by-purchase-clinic-50.png","phone":"877.659.2159","footer_logo_url":"","welcome_message":"Welcome to Purchase Clinic.","client_manager_id":0,"contact_email":"","facebook_url":"https://www.facebook.com/pages/Purchase-Clinic/745938055463808","linkedin_url":"https://www.linkedin.com/company/purchase-clinic","header_logo_link":"https://purchaseclinic.com","window_title":"Purchase Clinic","faq_url":"","signup_url":"https://savings.purchaseclinic.com/signup","analytics_js_id":"vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI","olark_id":"5695-386-10-5381","name":"Purchase Clinic","signup_target_id":0,"email_from_name":"Purchase Clinic","email_from_address":"support@purchaseclinic.com","login_heading":"","landing_domain":"savings.purchaseclinic.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":20524,"tos":null,"slug":"pc"},
    2:{"id":2,"main_logo_url":"//ampurchasing.s3.amazonaws.com/img/amp-logo-white.png","phone":"855.267.8700","footer_logo_url":"","welcome_message":"Welcome to Access Medical Purchasing.","client_manager_id":0,"contact_email":"amp@plasticsurgery.org","facebook_url":"https://www.facebook.com/accessmedicalpurchasing","linkedin_url":"https://www.linkedin.com/company/10176273?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A10176273%2Cidx%3A2-1-2%2CtarId%3A1454431670507%2Ctas%3Aaccess%20medical%20purchasing","header_logo_link":"https://accessmedicalpurchasing.com","window_title":"Access Medical Purchasing","faq_url":"https://accessmedicalpurchasing.com/faq","signup_url":"https://accessmedicalpurchasing.com/enroll","analytics_js_id":"tVwQa67JzkQdCeXhjxd9iuZVNPkYqj7r","olark_id":"1955-492-10-6536","name":"AMP","signup_target_id":1,"email_from_name":"Access Medical Purchasing","email_from_address":"amp@plasticsurgery.org","login_heading":"","landing_domain":"savings.ampurchasing.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":20142,"tos":"AMP utilizes Expansion, LLC and Purchase Clinic technology to help administer Access Medical Purchasing, a member benefit provided through the American Society of Plastic Surgeons.","slug":"amp"},
    3:{"id":3,"main_logo_url":"//purchaseclinic.s3.amazonaws.com/logo/pc-logo-white-50.png","phone":"","footer_logo_url":"","welcome_message":"","client_manager_id":0,"contact_email":"","facebook_url":"","linkedin_url":"","header_logo_link":"","window_title":"Purchase Clinic","faq_url":"","signup_url":"","analytics_js_id":"vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI","olark_id":"5695-386-10-5381","name":"Purchase Clinic","signup_target_id":0,"email_from_name":"Purchase Clinic","email_from_address":"support@purchaseclinic.com","login_heading":"","landing_domain":"savings.purchaseclinic.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":0,"tos":null,"slug":"pc3"},
    4:{"id":4,"main_logo_url":"//mgmabestprice.s3.amazonaws.com/logo.svg","phone":"844.234.6462 ","footer_logo_url":"","welcome_message":"MGMA + Purchase Clinic","client_manager_id":0,"contact_email":"support@mgmabestprice.org","facebook_url":"","linkedin_url":"","header_logo_link":"https://mgmabestprice.org","window_title":"MGMA BestPrice","faq_url":"https://mgmabestprice.org/faq/","signup_url":"https://mgmabestprice.org/","analytics_js_id":"Mm68n94Tmc9NbpFGZCszmEDQB0mI71Pn","olark_id":"5695-386-10-5381","name":"MGMA BestPrice","signup_target_id":0,"email_from_name":"MGMA BestPrice","email_from_address":"support@mgmabestprice.org","login_heading":"","landing_domain":"savings.mgmabestprice.org","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":41960,"tos":"MGMA BestPrice is provided as a free member benefit through membership in the MGMA. MGMA BestPrice accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, MGMA BestPrice leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.","slug":"mgma"},
    5:{"id":5,"main_logo_url":"//cap-purchasing-alliance.s3.amazonaws.com/cap-logo.png","phone":"855.907.9227","footer_logo_url":"","welcome_message":"Welcome to CAP Purchasing Alliance.","client_manager_id":0,"contact_email":"support@cappurchasingalliance.com","facebook_url":"","linkedin_url":"","header_logo_link":"https://cappurchasingalliance.com","window_title":"CAP Purchasing Alliance","faq_url":"https://cappurchasingalliance.com/faq/","signup_url":"https://savings.cappurchasingalliance.com/signup","analytics_js_id":"Lip1GcVnqRsaAVsKjW4LWS6NDuGr5QGk","olark_id":"5695-386-10-5381","name":"CAP","signup_target_id":2,"email_from_name":"CAP Purchasing Alliance","email_from_address":"support@cappurchasingalliance.com","login_heading":"CAP Purchasing Alliance","landing_domain":"savings.cappurchasingalliance.com","welcome_subject_for_facility":"CAP Purchasing Alliance Login for","specs_widget_caption":"CAP Purchasing Alliance","login_heading_img_url":"//cap-purchasing-alliance.s3.amazonaws.com/cap_heading_black.svg","specs_widget_img_url":"//cap-purchasing-alliance.s3.amazonaws.com/cap_heading_black.svg","task_from_user_id":41962,"tos":"CAP Purchasing Alliance (CPA) is provided as a free member benefit through membership in the Cooperative of American Physicians. CPA accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, CPA leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.","slug":"cap"},
    6:{"id":6,"main_logo_url":"//purchaseclinic.s3.amazonaws.com/logo/advantage-trust-powered-by-purchase-clinic-50.png","phone":"","footer_logo_url":"","welcome_message":"Welcome to Purchase Clinic.","client_manager_id":0,"contact_email":"","facebook_url":"","linkedin_url":"","header_logo_link":"","window_title":"","faq_url":"","signup_url":"https://savings.purchaseclinic.com/signup","analytics_js_id":"vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI","olark_id":"5695-386-10-5381","name":"Purchase Clinic","signup_target_id":0,"email_from_name":"Purchase Clinic","email_from_address":"support@purchaseclinic.com","login_heading":"","landing_domain":"savings.purchaseclinic.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":0,"tos":null,"slug":"pc6"},
    7:{"id":7,"main_logo_url":"//purchaseclinic.s3.amazonaws.com/logo/advantage-trust-powered-by-purchase-clinic-50.png","phone":"","footer_logo_url":"","welcome_message":"Welcome to MedResults Network Med/Surg Supply Buying Program","client_manager_id":0,"contact_email":"","facebook_url":"https://www.facebook.com/pages/Purchase-Clinic/745938055463808","linkedin_url":"https://www.linkedin.com/company/purchase-clinic","header_logo_link":"","window_title":"MedResults Network Med/Surg Supply Buying Program","faq_url":"","signup_url":"https://savings.purchaseclinic.com/signup","analytics_js_id":"vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI","olark_id":"5695-386-10-5381","name":"MedResults Network Med/Surg Supply Buying Program","signup_target_id":0,"email_from_name":"MedResults Network Med/Surg Supply Buying Program","email_from_address":"medresults@purchaseclinic.com","login_heading":"","landing_domain":"savings.purchaseclinic.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":20524,"tos":"MedResults Network utilizes the AdvantageTrust portfolio, Expansion, LLC staff and Purchase Clinic technology to help administer the MedResults Network Buying Group, a member benefit provided through the MedResults Network.","slug":"medresults"},
    8:{"id":8,"main_logo_url":"//tenet-aco.s3-us-west-2.amazonaws.com/email/tenet-group-purchasing-program.png","phone":"855.836.3838","footer_logo_url":"","welcome_message":"","client_manager_id":0,"contact_email":"acosupport@tenetgpo.com","facebook_url":"","linkedin_url":"","header_logo_link":"https://savings.tenetgpo.com","window_title":"Tenet’s group purchasing program","faq_url":"","signup_url":"https://savings.tenetgpo.com/signup","analytics_js_id":"","olark_id":"5695-386-10-5381","name":"Tenet’s group purchasing program","signup_target_id":0,"email_from_name":"Tenet’s group purchasing program","email_from_address":"acosupport@tenetgpo.com","login_heading":"Tenet's group purchasing program","landing_domain":"savings.tenetgpo.com","welcome_subject_for_facility":"Your credentials for Tenet's group purchasing program -- for ","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":53994,"tos":null,"slug":"tenet"},
    9:{"id":9,"main_logo_url":"//aoapurchase.s3.amazonaws.com/images/aoa-purchase-power-white-wide.png","phone":"844.802.9262","footer_logo_url":"","welcome_message":"Welcome to AOA Purchase Power","client_manager_id":0,"contact_email":"aoasupport@aoapurchasepower.com","facebook_url":"","linkedin_url":"","header_logo_link":"https://aoapurchasepower.com","window_title":"AOA Purchase Power","faq_url":"","signup_url":"http://aoapurchasepower.com","analytics_js_id":"","olark_id":"5695-386-10-5381","name":"AOA Purchase Power","signup_target_id":0,"email_from_name":"AOA Purchase Power","email_from_address":"aoasupport@aoapurchasepower.com","login_heading":"AOA Purchase Power","landing_domain":"savings.aoapurchasepower.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":55823,"tos":"AOA Purchase Power is provided as a free member benefit through membership in the AOA. AOA Purchase Power accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, AOA Purchase Power leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.","slug":"aoa"},
    10:{"id":10,"main_logo_url":"//purchaseclinic.s3.amazonaws.com/logo/advantage-trust-powered-by-purchase-clinic-50.png","phone":"","footer_logo_url":"","welcome_message":"Welcome to Purchase Clinic.","client_manager_id":0,"contact_email":"","facebook_url":"https://www.facebook.com/pages/Purchase-Clinic/745938055463808","linkedin_url":"https://www.linkedin.com/company/purchase-clinic","header_logo_link":"","window_title":"Purchase Clinic","faq_url":"","signup_url":"https://savings.purchaseclinic.com/signup","analytics_js_id":"vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI","olark_id":"5695-386-10-5381","name":"Purchase Clinic","signup_target_id":0,"email_from_name":"Purchase Clinic","email_from_address":"support@purchaseclinic.com","login_heading":"","landing_domain":"savings.purchaseclinic.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":20524,"tos":null,"slug":"pc10"},
    11:{"id":11,"main_logo_url":"//s3-us-west-2.amazonaws.com/communitypurchasing/cpp-white-logo.png","phone":"866.436.1277","footer_logo_url":"","welcome_message":"Welcome to Community Purchasing Program","client_manager_id":0,"contact_email":"support@communitypurchasingprogram.com","facebook_url":"","linkedin_url":"","header_logo_link":"https://communitypurchasingprogram.com","window_title":"Community Purchasing Program","faq_url":"","signup_url":"https://communitypurchasingprogram.com","analytics_js_id":"vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI","olark_id":"5695-386-10-5381","name":"Community Purchasing Program","signup_target_id":0,"email_from_name":"Community Purchasing Program","email_from_address":"support@communitypurchasingprogram.com","login_heading":"Community Purchasing Program","landing_domain":"savings.communitypurchasingprogram.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":94200,"tos":"Community Purchasing Program is provided as a free member benefit. Community Purchasing Program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Community Purchasing Program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.","slug":"comm"},
    12:{"id":12,"main_logo_url":"//purchasingplus.s3.amazonaws.com/service-line.png","phone":"877.659.2159","footer_logo_url":"","welcome_message":"Welcome to Purchasing Plus","client_manager_id":0,"contact_email":"purchasingplus@purchaseclinic.com","facebook_url":"","linkedin_url":"","header_logo_link":"","window_title":"Purchasing Plus","faq_url":"","signup_url":"https://purchasing.plus/","analytics_js_id":"vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI","olark_id":"5695-386-10-5381","name":"Purchase Clinic (Purchasing Plus)","signup_target_id":0,"email_from_name":"Purchasing Plus","email_from_address":"purchasingplus@purchaseclinic.com","login_heading":"","landing_domain":"savings.purchasing.plus","welcome_subject_for_facility":"Your Purchasing Plus Program credentials","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":0,"tos":"Purchasing Plus is provided as a free member benefit. Purchasing Plus accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, Purchasing Plus leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.","slug":"plus"},
    16:{"id":16,"main_logo_url":"//aadmbp.s3.amazonaws.com/aad-member-buying-program.jpg","phone":"855.962.3376","footer_logo_url":"","welcome_message":"Welcome to AAD Member Buying Program","client_manager_id":0,"contact_email":"support@aadmemberbuyingprogram.com","facebook_url":"","linkedin_url":"","header_logo_link":"https://aadmemberbuyingprogram.com","window_title":"AAD Member Buying Program","faq_url":"","signup_url":"https://aadmemberbuyingprogram.com","analytics_js_id":"vTCdDP3gAhYS0UNoorEErs3o8CxxjTXI","olark_id":"5695-386-10-5381","name":"AAD Member Buyng Program","signup_target_id":0,"email_from_name":"AAD Member Buying Program","email_from_address":"support@aadmemberbuyingprogram.com","login_heading":"AAD Member Buying Program","landing_domain":"savings.aadmemberbuyingprogram.com","welcome_subject_for_facility":"","specs_widget_caption":"","login_heading_img_url":"","specs_widget_img_url":"","task_from_user_id":0,"tos":"AAD Member Buying Program is provided as a free member benefit through membership in the AAD. AAD Member Buying Program accesses committed contract pricing through the HealthTrust (HPG), AdvantageTrust contract portfolio. In addition, AAD Member Buying Program leverages Expansion, LLC and Purchase Clinic technology in the administration of the program.","slug":"aadmbp"}
  };
  public fileTypeCSID = 2;
  public fileTypeUsage = 3;
  public fileTypeCC = 11;
  public fileTypeCSPharmaID = 8;

  constructor(private http: HttpClient) {
    if (location.host === 'localhost:4200') {
      this.apiUrl = 'http://dev-rest.purchase.guru';
      this.is_dev = true;
    }
    if (location.host === 'dev-crm.purchase.guru') {
      this.is_dev = true;
    }
    // if (location.host==='localhost:4200') {
    //   this.apiUrl = 'http://localhost:3000';
    //   this.is_dev = true;
    // }
    this.getActualThemes();
  }

  getSignupUrl(code: string, theme_id?: number, signup_template_url?: string): string {
    if (!code) {
      return 'https://savings.purchaseclinic.com/signup/';
    }
    if (signup_template_url && signup_template_url.length > 1) {
      return signup_template_url.replace('{code}', code);
    }
    if (theme_id > 0 && this.themesMap[theme_id] && this.themesMap[theme_id].landing_domain && this.themesMap[theme_id].landing_domain.length > 1) {
      return 'https://' + this.themesMap[theme_id].landing_domain + '/signup/' + code;
    }
    return 'https://savings.purchaseclinic.com/signup/' + code;
  }

  getRxUrl(code: string, theme_id?: number): string {
    if (!code) {
      return '';
    }
    if (theme_id > 0 && this.themesMap[theme_id] && this.themesMap[theme_id].landing_domain && this.themesMap[theme_id].landing_domain.length > 1) {
      return 'https://' + this.themesMap[theme_id].landing_domain + '/rx/' + code;
    }
    return 'https://savings.purchaseclinic.com/rx/' + code;
  }

  getSiimUrl(code: string, theme_id?: number): string {
    if (!code) {
      return '';
    }
    if (theme_id > 0 && this.themesMap[theme_id] && this.themesMap[theme_id].landing_domain && this.themesMap[theme_id].landing_domain.length > 1) {
      return 'https://' + this.themesMap[theme_id].landing_domain + '/siim/' + code;
    }
    return 'https://savings.purchaseclinic.com/siim/' + code;
  }

  getThemeDomain(theme_id: number, subTheme?: ISubTheme): string {
    let domain = 'savings.purchaseclinic.com';
    if (theme_id > 0 && this.themesMap[theme_id] && this.themesMap[theme_id].landing_domain && this.themesMap[theme_id].landing_domain.length > 1) {
      domain = this.themesMap[theme_id].landing_domain;
      if (subTheme && subTheme.label && subTheme.label.length > 0) {
        domain = domain.replace("savings.", subTheme.label + ".");
      }
    }
    return domain;
  }

  getActualThemes() {
    this.http.get<ITheme[]>(this.apiUrl + '/theme').subscribe((themes) => {
      if (themes) {
        let map = {};
        themes.forEach((t: ITheme) => {
          map[t.id] = t;
        });
        this.themesMap = map;
      }
    }, () => {});
  }
}
