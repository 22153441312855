import { Component, Input } from '@angular/core';
import {ILegacySearchQuery} from "../../interfaces/ILegacySearchQuery";
import {ILegacyLocation} from "../../interfaces/ILegacyLocation";
import {ILocation} from "../../interfaces/ILocation";
import {Session} from "../../services/Session";
import {Locations} from "../../services/Locations";
import {Notify} from "../../services/Notify";

@Component({
  selector: 'legacy-search',
  templateUrl: './legacy-search.component.html',
  styleUrls: ['./legacy-search.component.css']
})
export class LegacySearchCmp {
  @Input() location:ILocation;
  public req:ILegacySearchQuery = <ILegacySearchQuery>{};
  public loading = false;
  public results:ILegacyLocation[];
  public searchCompleted = false;
  public filterCot = '';
  public cots = [];
  private notify;

  constructor(private session:Session, private locSrv:Locations) {
    this.session.redirectIfNotAuth();
    this.notify = new Notify();
  }

  ngOnChanges() {
    if (this.location) {
      this.req = {
        facility_name: this.location.facility_name,
        address: [this.location.address, this.location.address2].join(' '),
        city: this.location.city,
        state: this.location.state,
        zip: this.location.zip,
        dea: this.location.dea,
        hin: this.location.hin
      };
    }
  }

  find() {
    if (!this.req.facility_name && !this.req.address && !this.req.city && !this.req.state && !this.req.zip && !this.req.dea && !this.req.hin) {
      return;
    }
    this.loading = true;
    this.searchCompleted = false;
    this.results = [];
    this.cots = [];
    this.locSrv.findLegacy(this.req).subscribe((results)=> {
      this.results = results;
      this.searchCompleted = true;
      this.loading = false;
      if (results && results.length) {
        results.forEach((r)=> {
          if (this.cots.indexOf(r.class_of_trade) < 0) {
            this.cots.push(r.class_of_trade);
          }
        });
      }
    }, (err)=> {
      this.searchCompleted = false;
      this.loading = false;
      this.notify.error(JSON.stringify(err), 'Error');
    });
  }

  toggleFilterCot(c) {
    if (this.filterCot && this.filterCot == c) {
      this.filterCot = '';
    } else {
      this.filterCot = c;
    }
  }

  clearAll() {
    this.req = <ILegacySearchQuery>{};
  }
}
