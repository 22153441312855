import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {startWith, map, debounceTime} from 'rxjs/operators';
import {Component, EventEmitter} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Session} from "../../services/Session";
import {Router, ActivatedRoute} from '@angular/router';
import {Locations} from "../../services/Locations";
import {Distributors} from "../../services/Distributors";
import {UrlParser} from "../../services/UrlParser";
import {ILocation} from "../../interfaces/ILocation";
import {Specialties} from "../../services/Specialties";
import {ItemsRange} from '../../services/ItemsRange';
import {Config} from "../../services/Config";
import {Tags} from "../../services/Tags";
import {Helpers} from "../../services/Helpers";
import {ITag} from "../../interfaces/ITag";
import {Implementations} from "../../services/Implementations";
import {IImplementationContract} from "../../interfaces/IImplementationContract";

@Component({
  selector: 'leads',
  templateUrl: './locations.html',
  styleUrls: ['./locations.css']
})
export class LocationsCmp {
  public page = 1;
  public itemsLimit = 15;
  public items:Array<ILocation>;
  public distributorsMap = {};
  public distributorsPopList = [];
  public specialtiesMap = {};
  public loading = false;
  public kind;
  public searchInput:UntypedFormControl;
  public pageSwitchEvent:EventEmitter<any>;
  public searchTerm:string = "";
  public filterReviewedByUser = false;
  public filterReviewedByManager:number = 0;
  public filterUnclaimed = false;
  public filterToBeReviewed = false;
  public sort_order = '';
  public sort_field = '';
  public itemsCount:number = 0;
  public hiddenTags = [];
  public includableTags = [];
  public tagsMap = {};
  public tagsList:ITag[] = [];
  public filterByLink = null;
  public showWithDistributor = null;
  public filterIsReviewed:number = 0;
  public claimedManagers = [];
  public repManagers = [];
  public filterCM = 0;
  public filterRepMgr = 0;
  public implContracts:IImplementationContract[] = [];
  public searchGroup = new UntypedFormGroup({
    searchInput: new UntypedFormControl()
  });
  public token:string;

  constructor(public locations:Locations, public session:Session, public distributors:Distributors,
    public urlParser:UrlParser, public router:Router, public params:ActivatedRoute, public tagsService:Tags,
    public specialties:Specialties, public itemsRange:ItemsRange, public config:Config, private implSrv:Implementations) {
    this.session.addOnLoadHandler(()=> {
      this.token = this.session.getToken();
      this.implContracts = [];
      this.implSrv.getContracts().subscribe((contracts) => {
        if (contracts) {
          contracts.forEach((c) => {
            if (c.contract_number && c.is_exportable && c.is_featured) {
              this.implContracts.push(c);
            }
          });
        }
      }, (response) => {
        console.error(response);
      });
    });
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    this.parseUrlParams(params);
    if (!this.kind) {
      this.kind = 'total';
    }
    if (this.kind === 'pending' && !this.sort_field) {
      this.sort_order = 'desc';
      this.sort_field = 'pa_date';
    }
    this.session.redirectIfNotAuth();
    // setup events
    this.pageSwitchEvent = new EventEmitter();
    this.searchInput = <UntypedFormControl>this.searchGroup.controls['searchInput'];
    this.searchInput.valueChanges.pipe(debounceTime(150), map((val) => {
      return val.toLowerCase();
    }), startWith('')).subscribe((val)=> {
      if (!this.searchTerm && !val) {
        return false;
      }
      this.searchTerm = val;
      this.setItemsLimit(100);
    }, ()=> {});

    observableCombineLatest(this.pageSwitchEvent).subscribe((results) => {
      this.items = <[ILocation]>results[0];
      this.loading = false;
      this.recount();
    }, ()=> {this.loading = false;});

    this.setPage(parseInt(this.urlParser.getParameter('page')), true);
    let searchArg = params['search'];
    if (searchArg) {
      this.searchInput.setValue(searchArg);
    }
    this.distributors.loadToMap(this.distributorsMap);
    this.specialties.loadToMap(this.specialtiesMap);
    this.setItemsCountFromCounters();
    this.distributors.load().then((distributors)=> {
      if (distributors && distributors.length > 0) {
        distributors.forEach((d)=> {
          if (d.is_popular) {
            this.distributorsPopList.push(d);
          }
        });
      }
    }, ()=> {});
    this.tagsService.loadToMap(this.tagsMap);
    this.tagsService.loadToList(this.tagsList);
    this.locations.getPendingManagers().subscribe(l=>this.claimedManagers = l, ()=> {});
    this.locations.getPendingRepManagers().subscribe(l=>this.repManagers = l, ()=> {});
  }

  setItemsCountFromCounters() {
    this.itemsCount = this.locations.counters[this.kind];
    this.recount();
  }

  toggleReviewedByUser() {
    this.filterReviewedByManager = 0;
    this.filterToBeReviewed = false;
    this.filterUnclaimed = false;
    this.filterIsReviewed = 0;
    this.filterReviewedByUser = !this.filterReviewedByUser;
    this.setPage(1, true);
    this.urlParser.setParameter('filter', this.filterReviewedByUser ? 'ReviewedByUser' : '');
  }

  setReviewedByManager(v) {
    this.filterReviewedByUser = false;
    this.filterToBeReviewed = false;
    this.filterIsReviewed = 0;
    this.filterUnclaimed = false;
    this.filterReviewedByManager = v;
    this.setPage(1, true);
    if (this.filterReviewedByManager === 1) {
      this.urlParser.setParameter('filter', 'ReviewedByManager');
    } else {
      if (this.filterReviewedByManager == 2) {
        this.urlParser.setParameter('filter', 'NotReviewedByManager');
      } else {
        this.urlParser.setParameter('filter', '');
      }
    }
  }

  setIsReviewed(v) {
    this.filterReviewedByUser = false;
    this.filterToBeReviewed = false;
    this.filterUnclaimed = false;
    this.filterReviewedByManager = 0;
    this.filterIsReviewed = parseInt(v);
    this.setPage(1, true);
    if (this.filterIsReviewed === 1) {
      this.urlParser.setParameter('filter', 'IsReviewed');
    } else {
      if (this.filterIsReviewed == 2) {
        this.urlParser.setParameter('filter', 'IsNotReviewed');
      } else {
        this.urlParser.setParameter('filter', '');
      }
    }
  }

  toggleUnclaimed() {
    this.filterReviewedByManager = 0;
    this.filterToBeReviewed = false;
    this.filterReviewedByUser = false;
    this.filterIsReviewed = 0;
    this.filterUnclaimed = !this.filterUnclaimed;
    this.setPage(1, true);
    this.urlParser.setParameter('filter', this.filterUnclaimed ? 'Unclaimed' : '');
  }

  toggleToBeReviewed() {
    this.filterReviewedByManager = 0;
    this.filterReviewedByUser = false;
    this.filterUnclaimed = false;
    this.filterIsReviewed = 0;
    this.filterToBeReviewed = !this.filterToBeReviewed;
    this.setPage(1, true);
    this.urlParser.setParameter('filter', this.filterToBeReviewed ? 'ToBeReviewed' : '');
  }

  loadLocations() {
    this.updateCounters();
    if (!this.locations.counters.total) {
      return this.locations.getLocationsCount().then(()=> {
        this.updateCounters(false);
      }, () => {
        this.loading = false;
      });
    }
  }

  updateCounters(loading:boolean = true) {
    let showLoading = setTimeout(()=> {
      this.loading = loading;
      this.items = [];
    }, 250);
    let total = this.locations.counters[this.kind];
    let q;
    let filters = null;
    if (this.filterReviewedByUser) {
      filters = {reviewed_by_user: 1};
    }
    if (this.filterReviewedByManager) {
      if (!filters) {
        filters = {};
      }
      filters.reviewed_by_manager = this.filterReviewedByManager;
    }
    if (this.filterUnclaimed) {
      if (!filters) {
        filters = {};
      }
      filters.unclaimed = 1;
    }
    if (this.filterToBeReviewed) {
      if (!filters) {
        filters = {};
      }
      filters.to_be_reviewed = 1;
    }
    if (this.filterIsReviewed) {
      if (!filters) {
        filters = {};
      }
      filters.is_reviewed = this.filterIsReviewed;
    }
    if (this.filterCM) {
      if (!filters) {
        filters = {};
      }
      filters.filterCM = this.filterCM;
    }
    if (this.filterRepMgr) {
      if (!filters) {
        filters = {};
      }
      filters.filterRepMgr = this.filterRepMgr;
    }
    if (this.hiddenTags && this.hiddenTags.length > 0) {
      if (!filters) {
        filters = {};
      }
      filters.filter_by_tag = this.hiddenTags.join(',');
    }
    if (this.includableTags && this.includableTags.length > 0) {
      if (!filters) {
        filters = {};
      }
      filters.show_with_tags = this.includableTags.join(',');
    }
    if (!(this.filterByLink === null && typeof this.filterByLink === 'object')) {
      if (!filters) {
        filters = {};
      }
      filters.filter_by_link = this.filterByLink;
    }
    if (!(this.showWithDistributor === null && typeof this.showWithDistributor === 'object')) {
      if (!filters) {
        filters = {};
      }
      filters.show_with_distributor = this.showWithDistributor;
    }
    let countFetch = null;
    if (filters || this.searchTerm) {
      countFetch = (count:number)=> {
        if (count > 0) {
          this.itemsCount = count;
        }
      };
    }
    if (!this.searchTerm) {
      q = this.locations.load(this.kind, this.itemsRange.getFetchOffset(this.page, this.itemsLimit),
        this.itemsRange.getFetchLimit(total, this.page, this.itemsLimit), '', filters, this.sort_field, this.sort_order === 'asc', countFetch);
    } else {
      q = this.locations.load(this.kind, this.itemsRange.getFetchOffset(this.page, this.itemsLimit),
        this.itemsLimit, this.searchTerm, filters, this.sort_field, this.sort_order === 'asc', countFetch);
    }
    q.then((response)=> {
      if (showLoading && clearTimeout && typeof clearTimeout === 'function') {
        //noinspection TypeScriptUnresolvedFunction
        clearTimeout(showLoading);
      }
      if (response.search_count && response.search_count > 0) {
        this.itemsCount = response.search_count;
      } else {
        this.setItemsCountFromCounters();
      }
      this.pageSwitchEvent.emit(response.items);
      if (total && !this.searchTerm && !this.sort_field) {
        this.locations.load(this.kind, this.itemsRange.getPreloadOffset(this.page, this.itemsLimit),
          this.itemsRange.getPreloadLimit(this.page, total, this.itemsLimit));
      }
    }, (err)=> {
      console.error(err);
      this.loading = false;
    });
  }

  setPage($event, searchReset = false) {
    let newPage = Math.max(0, parseInt($event) || 1);
    this.urlParser.setParameter('page', newPage);
    if (this.page !== newPage || searchReset) {
      this.page = newPage;
      this.loadLocations();
    }
    this.recount();
  }

  recount() {
    if (this.page > 1 && this.items && this.items.length) {
      let cnt = this.items.length + ((this.page - 1) * this.itemsLimit);
      let min = this.itemsLimit + ((this.page - 1) * this.itemsLimit);
      if (cnt < min) {
        if (this.itemsCount > cnt) {
          this.itemsCount = cnt;
        }
      }
    }
  }

  expandLocation(loc, $event) {
    if (loc.location_group_id) {
      loc.__expanded = !loc.__expanded;
    } else {
      this.router.navigate(['location', loc.id]);
    }
    if ($event && $event.stopPropagation) {
      $event.stopPropagation();
    }
    if ($event && $event.preventDefault) {
      $event.preventDefault();
    }
  }

  sort(field) {
    if (this.sort_field === field) {
      if (this.sort_order === 'asc') {
        this.sort_order = 'desc';
      } else {
        this.sort_order = '';
        this.sort_field = '';
      }
    } else {
      this.sort_order = 'asc';
      this.sort_field = field;
    }
    this.setPage(this.page, true);
    this.urlParser.setParameter('sortField', this.sort_field);
    this.urlParser.setParameter('sortOrder', this.sort_order);
  }

  setItemsLimit(n) {
    let x = parseInt(n);
    if (x < 1 || !x) {
      x = 15;
    }
    this.itemsLimit = x;
    this.setPage(1, true);
    this.urlParser.setParameter('items', this.itemsLimit);
  }

  displayPendingLocsReport() {
    this.router.navigate(['/reports', {id: 'pending_for_membership'}]);
  }

  toggleDisplayingLinkTarget(id) {
    let i = this.hiddenTags.indexOf(id);
    if (i > -1) {
      this.hiddenTags.splice(i, 1);
    } else {
      this.hiddenTags.push(id);
    }
    this.urlParser.setParameter('filterTags', this.hiddenTags.join(','));
    this.setPage(1, true);
  }

  setEmptyHiddenTags() {
    this.hiddenTags = [];
    this.urlParser.setParameter('filterTags', false);
  }

  setEmptyIncludableTags() {
    this.includableTags = [];
    this.urlParser.setParameter('includeTags', false);
  }

  toggleIncludableTags(id) {
    let i = this.includableTags.indexOf(id);
    if (i > -1) {
      this.includableTags.splice(i, 1);
    } else {
      this.setEmptyHiddenTags();
      this.includableTags.push(id);
    }
    this.urlParser.setParameter('includeTags', this.includableTags.join(','));
    this.setPage(1, true);
  }

  parseUrlParams(params) {
    this.kind = params['kind'];
    let urlParams = this.urlParser.getParameters();
    if (urlParams.filter) {
      switch (urlParams.filter) {
        case 'ReviewedByUser':
          this.filterReviewedByUser = true;
          break;
        case 'ReviewedByManager':
          this.filterReviewedByManager = 1;
          break;
        case 'NotReviewedByManager':
          this.filterReviewedByManager = 2;
          break;
        case 'Unclaimed':
          this.filterUnclaimed = true;
          break;
        case 'ToBeReviewed':
          this.filterToBeReviewed = true;
          break;
        case 'IsReviewed':
          this.filterIsReviewed = 1;
          break;
        case 'IsNotReviewed':
          this.filterIsReviewed = 2;
          break;
      }
    }
    if (urlParams.filterCM) {
      this.filterCM = parseInt(urlParams.filterCM);
    }
    if (urlParams.filterRepMgr) {
      this.filterRepMgr = parseInt(urlParams.filterRepMgr);
    }
    if (urlParams.sortField) {
      this.sort_field = urlParams.sortField;
    }
    if (urlParams.sortOrder) {
      this.sort_order = urlParams.sortOrder;
    }
    if (urlParams.items) {
      this.itemsLimit = Math.max(parseInt(urlParams.items), 1);
    }
    if (urlParams.filterTags && (!this.hiddenTags || !this.hiddenTags.length)) {
      this.hiddenTags = urlParams.filterTags.split(',').map(t => parseInt(t));
    }
    if (urlParams.includeTags && (!this.includableTags || !this.includableTags.length)) {
      this.includableTags = urlParams.includeTags.split(',').map(t => parseInt(t));
    }
    if (urlParams.filterByLink) {
      let filter = parseInt(urlParams.filterByLink);
      if (filter > 0) {
        this.filterByLink = (filter - 1);
      } else {
        this.filterByLink = null;
      }
    }
    if (!Helpers.is_empty(urlParams.showWithDistributor)) {
      let filter = parseInt(urlParams.showWithDistributor);
      if (filter > 0) {
        this.showWithDistributor = 1;
      } else {
        this.showWithDistributor = 0;
      }
    }
  }

  setFilterByLink(filter:number) {
    if (filter > 0) {
      this.filterByLink = (filter - 1);
    } else {
      this.filterByLink = null;
    }
    this.setPage(1, true);
    this.urlParser.setParameter('filterByLink', filter);
  }

  setShowWithDistributor(filter?:number) {
    if (filter > 0) {
      this.showWithDistributor = (filter - 1);
      this.urlParser.setParameter('showWithDistributor', (filter - 1).toString());
    } else {
      this.showWithDistributor = null;
      this.urlParser.setParameter('showWithDistributor', false);
    }
    this.setPage(1, true);
  }

  setFilterByCM(id) {
    this.filterCM = id;
    this.setPage(1, true);
    this.urlParser.setParameter('filterCM', this.filterCM);
  }

  setFilterByRepMgr(id) {
    this.filterRepMgr = id;
    this.setPage(1, true);
    this.urlParser.setParameter('filterRepMgr', this.filterRepMgr);
  }

  setShowAllMode() {
    this.setEmptyHiddenTags();
    this.setEmptyIncludableTags();
    this.loadLocations();
  }

  setHideAllMode() {
    this.setEmptyHiddenTags();
    this.setEmptyIncludableTags();
    this.tagsList.forEach((t)=> {
      this.hiddenTags.push(t.id);
    });
    this.items = [];
    this.itemsCount = 0;
  }
}
