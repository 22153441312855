import {Component, Input, ViewChild, ElementRef} from '@angular/core';
import {Session} from "../../services/Session";
import {Notify} from "../../services/Notify";
import {ISearchDistrReportinghQuery} from "../../interfaces/IDistributor";
import {ISearchDistrReportingResult} from "../../interfaces/IDistributor";
import {Distributors} from "../../services/Distributors";
import {IPopupQuery} from "../../interfaces/IPopupQuery";
declare var $:any;

@Component({
  templateUrl: 'search.html',
  selector: 'search-distributors-reporting'
})
export class SearchDistrReportingCmp {
  public distrsFiltered = [];
  public contractsFiltered = [];
  public statusesFiltered = [];
  public vendorsFiltered = [];
  public orderField = '';
  public sortDesc;
  public popupListLength = 50;
  public query:ISearchDistrReportinghQuery = {contract_number: null, category: null, vendor: null, item_description: null, invoice_date_from: null, invoice_date_to: null, gpoid: null, all_gpoid_locations: null};
  public searching = false;
  public exporting = false;
  public counting = false;
  public loading = true;
  public found = false;
  public notfound = false;
  public results:ISearchDistrReportingResult[];
  public n = new Notify();
  public queries:IPopupQuery[] = [];
  private vendorNames = [];
  @ViewChild('vendor') vendor:ElementRef;
  public totalCount = 0;

  constructor(private distrSrv:Distributors, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.loading = true;
    this.distrSrv.getSearchReportingPrefetch().subscribe((data)=> {
      this.loading = false;
      if (data && data.vendors) {
        this.vendorNames = data.vendors;
        this.setupVendorsAutoSuggest();
      }
    }, ()=> {
      this.loading = false;
    });
  }

  resetFilters() {
    this.distrsFiltered = [];
    this.contractsFiltered = [];
    this.statusesFiltered = [];
    this.vendorsFiltered = [];
    this.orderField = '';
    this.sortDesc = null;
  }

  setupVendorsAutoSuggest() {
    setTimeout(()=> {
      if (this.vendorNames && this.vendorNames.length && this.vendor && this.vendor.nativeElement) {
        try {
          let substringMatcher = function (strs) {
            return function findMatches(q, cb) {
              let substringRegex;
              let matches = [];
              let substrRegex = new RegExp(q, 'i');
              $.each(strs, function (i, str) {
                if (substrRegex.test(str)) {
                  matches.push(str);
                }
              });
              cb(matches);
            };
          };
          $(this.vendor.nativeElement).typeahead({
            highlight: true,
            classNames: {
              menu: 'dropdown-menu',
            }
          }, {name: 'vendors', source: substringMatcher(this.vendorNames)});
        } catch (e) {
          console.error(e);
        }
      }
    }, 1);
  }

  isInputValid() {
    if (this.vendor && this.vendor.nativeElement) {
      try {
        this.query.vendor = $(this.vendor.nativeElement).typeahead('val');
      } catch (e) {}
    }
    if (!this.query.contract_number && !this.query.category && !this.query.vendor && !this.query.item_description && !this.query.invoice_date_from && !this.query.invoice_date_to && !this.query.gpoid) {
      this.n.warning('At least one input field should be filled');
      return false;
    }
    return true;
  }

  search() {
    this.notfound = false;
    if (!this.isInputValid()) {
      return;
    }
    this.results = [];
    this.resetFilters();
    this.found = false;
    this.searching = true;
    this.distrSrv.getSearchReporting(this.query).subscribe((result)=> {
      this.searching = false;
      if (result && result.query) {
        this.queries = [{name: 'Query', query: result.query, commentary: 'Params: ' + result.query_params.join(', ')}];
      }
      if (!result || !result.results || result.results.length < 1) {
        this.notfound = true;
      } else {
        this.found = true;
        this.results = result.results;
      }
    }, (err)=> {
      this.searching = false;
      this.n.error(JSON.stringify(err), 'Server error');
      console.error(err);
    });
    this.counting = true;
    this.totalCount = 0;
    this.distrSrv.getSearchReportingCount(this.query).subscribe((result)=> {
      this.counting = false;
      this.totalCount = result;
    }, (err)=> {
      this.counting = false;
      console.error(err);
    });
  }

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  exportCSV() {
    if (!this.isInputValid()) {
      return;
    }
    this.exporting = true;
    this.distrSrv.getSearchReportingCSV(this.query).subscribe(()=> {
      this.exporting = false;
      this.n.success("Report will be send to your email, it may take a few minutes.");
    }, (err)=> {
      this.exporting = false;
      this.n.error(JSON.stringify(err), "Error");
    });
  }
}
