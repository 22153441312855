import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Tickets} from "../../services/Tickets";
import {ITicket} from "../../interfaces/ITicket";
import {Session} from "../../services/Session";
import {Config} from "../../services/Config";

@Component({
  selector: '[ticket]',
  templateUrl: './ticket.html',
})
export class TicketCmp {
  @Input() id;
  @Input() ticket: ITicket;
  public children: Array<ITicket> = [];
  public displayReturnBtn = false;

  constructor(public ticketService: Tickets, public params: ActivatedRoute, public router: Router, private session: Session, public config: Config) {
    this.session.redirectIfNotAuth();
  }

  init(params) {
    let id = parseInt(params['id']);
    if (id > 0) {
      this.id = id;
    }
    this.ngOnChanges();
  }

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  ngOnChanges() {
    if ((!this.ticket || !this.ticket.id) && this.id) {
      let t = this.ticketService.getFromLoaded(this.id);
      if (!t) {
        this.ticketService.getByID(this.id).subscribe((t) => {
          if (t) {
            this.ticket = t;
          }
          if (this.ticket && this.ticket.id) {
            this.ticketService.loadChildren(this.ticket.id).subscribe(children => this.children = children, () => {});
          }
        }, () => {});
      } else {
        this.displayReturnBtn = true;
        this.ticket = t;
        if (this.ticket && this.ticket.id) {
          this.ticketService.loadChildren(this.ticket.id).subscribe(children => this.children = children, () => {});
        }
      }
    }
  }

  openTicket(ticket: ITicket) {
    if (ticket.type_id === 1 || ticket.type_id === 5) {
      this.router.navigate(['ticket', ticket.id]);
    }
  }

  back() {
    window.history.back();
  }
}
