import {Component} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {Vendors} from "../../services/Vendors";
import {UrlParser} from "../../services/UrlParser";
import {IVendorReport} from "../../interfaces/IVendor";
import {Session} from "../../services/Session";
import {IQuarterReport} from "../../interfaces/IVendor";
import {IPopupQuery} from "../../interfaces/IPopupQuery";
import {IQuarterReportLine} from "../../interfaces/IVendor";

@Component({
  templateUrl: './vendor-quarter.html',
})
export class VendorQuarterPageCmp {
  public id = 0;
  public data:IQuarterReport;
  public loading:boolean = false;
  public name:string = '';
  public orderField;
  public sortDesc;
  public tab = 1;
  public quarter:string = '';
  public queries:IPopupQuery[];

  constructor(public vendors:Vendors, public params:ActivatedRoute, public router:Router, public urlParser:UrlParser, private session:Session) {}

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    if (!this.id) {
      this.id = parseInt(params['id']);
    }
    if (!this.quarter) {
      this.quarter = params['quarter'];
    }
    if (!this.id || !this.quarter) {
      this.router.navigate(['']);
      return;
    }
    this.parseUrlParams();
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.loading = true;
    this.vendors.getVendorQuarterReport(this.id, this.quarter).subscribe((data)=> {
      this.data = data;
      if (data.vendors) {
        data.vendors.forEach((v)=> {
          if (v.name && v.is_friendly_name) {
            this.name = v.name;
          }
          if (!this.name) {
            this.name = v.name;
          }
        })
      }
      if (data.report && data.report.length > 0) {
        data.report.forEach((r:IQuarterReportLine)=> {
          r.__difference = (r.sales_distr - r.sales_ht);
        });
      }
      if (data.report_at && data.report_at.length > 0) {
        data.report_at.forEach((r:IQuarterReportLine)=> {
          r.__difference = (r.sales_distr - r.sales_ht);
        });
      }
      this.queries = [
        {name: 'Report PC', query: data.query_report, commentary: 'Quarter argument expected in format "2018 Q1"'},
        {name: 'Report AT', query: data.query_report_at}
      ];
      this.loading = false;
    }, ()=> {
      this.loading = false;
    });
  }

  setOrderField(f:string) {
    if (!f) {
      this.orderField = '';
      this.sortDesc = false;
    }
    if (this.orderField == f) {
      if (this.sortDesc) {
        this.orderField = '';
        this.sortDesc = false;
      } else {
        this.sortDesc = true;
      }
    } else {
      this.sortDesc = false;
      this.orderField = f;
    }
  }

  setTab(i) {
    this.tab = Math.max(parseInt(i), 1);
    this.urlParser.setParameter('tab', this.tab);
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.tab) {
      this.tab = Math.max(parseInt(urlParams.tab), 1);
    }
  }
}
