import {Component} from "@angular/core";
import {ActivatedRoute} from '@angular/router';
import {Reports} from "../../services/Reports";
import {Session} from "../../services/Session";
import {Locations} from "../../services/Locations";
import {ILocGeoPoint} from "../../services/Locations";
import {UrlParser} from "../../services/UrlParser";
import {ILocGeoPointMgmaReporting} from "../../services/Locations";
declare var ol:any;
declare var $:any;

@Component({
  selector: 'locations-map',
  templateUrl: 'map.html',
})
export class LocationsMapZoomCmp {
  public loading = false;
  public tab = 1;
  public channels:string[] = [];
  public visibleChannels:string[] = [];
  public CMs:string[] = [];
  public visibleCMs:string[] = [];
  public distrs:string[] = [];
  public reporting:string[] = [];
  public reportingMgma:string[] = [];
  public reportingMgmaCounters = {};
  public visibleDistrs:string[] = [];
  public visibleReporting:string[] = [];
  public visibleReportingMgma:string[] = [];
  public channelColors = {};
  public cmColors = {};
  public distrColors = {};
  public reportingColors = {};
  public reportingMgmaColors = {};
  private locations:ILocGeoPoint[] = [];
  private locationsMgmaReporting:ILocGeoPointMgmaReporting[] = [];
  private statesColor = "rgb(105,105,105)";
  private pointColors = [
    "rgb(0,255,255)", "rgb(224,239,41)", "rgb(21, 179, 233)", "rgb(32,178,170)", "rgb(255,255,224)",
    "rgb(255,110,64)", "rgb(255,205,210)", "rgb(248,187,208)", "rgb(225,190,231)", "rgb(187,222,251)", "rgb(178,223,219)", "rgb(200,230,201)",
    "rgb(197,225,165)", "rgb(230,238,156)", "rgb(255,245,157)", "rgb(255,224,130)", "rgb(255,224,178)",
    "rgb(255,171,145)", "rgb(188,170,164)", "rgb(224,224,224)", "rgb(255,138,128)", "rgb(255,128,171)", "rgb(234,128,252)",
    "rgb(179,136,255)", "rgb(140,158,255)", "rgb(130,177,255)", "rgb(64,196,255)", "rgb(24,255,255)", "rgb(100,255,218)",
    "rgb(105,240,174)", "rgb(178,255,89)", "rgb(238,255,65)", "rgb(255,234,0)", "rgb(255,196,0)", "rgb(255,145,0)",
  ];//colors should be light to be visible (and to be contrast) on the black background
  private reportingColorsMap = ["#FF6E40", "rgb(21, 179, 233)", "rgb(255,205,210)", "rgb(225,190,231)", "rgb(197,202,233)",
    "rgb(187,222,251)", "rgb(178,235,242)", "rgb(178,223,219)", "rgb(230,238,156)", "rgb(255,245,157)", "rgb(255,224,130)",
    "rgb(255,224,178)", "rgb(255,171,145)", "rgb(188,170,164)", "rgb(224,224,224)", "rgb(255,138,128)", "rgb(255,128,171)",
    "rgb(234,128,252)", "rgb(179,136,255)", "rgb(140,158,255)", "rgb(64,196,255)", "rgb(24,255,255)", "rgb(100,255,218)",
    "rgb(105,240,174)", "rgb(178,255,89)", "rgb(238,255,65)", "rgb(255,234,0)", "rgb(255,196,0)", "rgb(255,145,0)"];
  private layersChannels = {};
  private layersCMs = {};
  private layersDistrs = {};
  private layersReporting = {};
  private layersReportingMgma = {};
  private isChannelsDrawn = false;
  private isCMsDrawn = false;
  private isDistrsDrawn = false;
  private isReportingDrawn = false;
  private isReportingMgmaDrawn = false;

  constructor(private urlParser:UrlParser, private locSrv:Locations, private session:Session, private params:ActivatedRoute) {
  }

  ngOnInit() {
    if (!window['ol']) {
      let el = document.createElement('script');
      el.setAttribute('src', 'https://cdn.rawgit.com/openlayers/openlayers.github.io/master/en/v5.3.0/build/ol.js');
      document.body.appendChild(el);
    }
    this.params.params.subscribe(params => {
      this.init(params);
    });
  }

  init(params) {
    this.tab = 1;
    this.parseUrlParams();
    this.session.addOnLoadHandler(() => {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  parseUrlParams() {
    let urlParams = this.urlParser.getParameters();
    if (urlParams.tab) {
      this.tab = Math.max(parseInt(urlParams.tab), 1);
    }
  }

  load() {
    this.locations = [];
    this.loading = true;
    this.locSrv.getGeo().subscribe((locs)=> {
      this.loading = false;
      this.locations = locs;
      let chci = 0;
      let cmci = 0;
      let dsci = 0;
      let rpci = 0;
      this.locations.forEach((loc:ILocGeoPoint)=> {
        if (this.channels.indexOf(loc.channel) < 0) {
          this.channels.push(loc.channel);
          this.visibleChannels.push(loc.channel);
          this.channelColors[loc.channel] = this.pointColors[chci];
          chci++;
          if (chci > this.pointColors.length) {
            chci = 0;
          }
        }
        if (this.CMs.indexOf(loc.cm) < 0) {
          this.CMs.push(loc.cm);
          this.visibleCMs.push(loc.cm);
          this.cmColors[loc.cm] = this.pointColors[cmci];
          cmci++;
          if (cmci > this.pointColors.length) {
            cmci = 0;
          }
        }
        if (loc.distr && this.distrs.indexOf(loc.distr) < 0) {
          this.distrs.push(loc.distr);
          this.visibleDistrs.push(loc.distr);
          this.distrColors[loc.distr] = this.pointColors[dsci];
          dsci++;
          if (dsci > this.pointColors.length) {
            dsci = 0;
          }
        }
        if (loc.distr_reporting && this.reporting.indexOf(loc.distr_reporting) < 0) {
          this.reporting.push(loc.distr_reporting);
          this.visibleReporting.push(loc.distr_reporting);
          this.reportingColors[loc.distr_reporting] = this.reportingColorsMap[rpci];
          rpci++;
          if (rpci > this.reportingColorsMap.length) {
            rpci = 0;
          }
        }
      });
      this.draw();
    }, ()=> {
      this.loading = false;
    });
    this.locSrv.getGeoMgmaReporting().subscribe((locs)=> {
      this.locationsMgmaReporting = locs;
      let rpci = 0;
      this.locationsMgmaReporting.forEach((loc:ILocGeoPointMgmaReporting)=> {
        if (!loc.label) {
          return;
        }
        if (this.reportingMgma.indexOf(loc.label) < 0) {
          this.reportingMgma.push(loc.label);
          this.reportingMgmaCounters[loc.label] = 0;
          this.visibleReportingMgma.push(loc.label);
          this.reportingMgmaColors[loc.label] = this.pointColors[rpci];
          rpci++;
          if (rpci > this.pointColors.length) {
            rpci = 0;
          }
        } else {
          this.reportingMgmaCounters[loc.label]++;
        }

      });
      this.draw();
    }, ()=> {});
  }

  draw() {
    switch (this.tab) {
      case 1:
        if (!this.isChannelsDrawn) {
          this.isChannelsDrawn = this.drawChannels();
        }
        break;
      case 2:
        if (!this.isCMsDrawn) {
          this.isCMsDrawn = this.drawCMs();
        }
        break;
      case 3:
        if (!this.isDistrsDrawn) {
          this.isDistrsDrawn = this.drawDistrs();
        }
        break;
      case 4:
        if (!this.isReportingDrawn) {
          this.isReportingDrawn = this.drawReporting();
        }
        break;
      case 5:
        if (!this.isReportingMgmaDrawn) {
          this.isReportingMgmaDrawn = this.drawReportingMgma();
        }
        break;
    }
  }

  drawChannels():boolean {
    if (this.loading || !this.locations || this.locations.length < 1) {
      return false;
    }
    setTimeout(()=> {
      $("#ol-locs-zoom-map-ch").empty();
      var vectorSource = new ol.source.Vector({
        format: new ol.format.GeoJSON(),
        url: '/assets/us-states.json',
        strategy: ol.loadingstrategy.bbox
      });

      this.layersChannels = {
        map_: new ol.layer.Vector({
          source: vectorSource,
          style: new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: 'white',
              width: 1
            }),
            fill: new ol.style.Fill({color: this.statesColor})
          })
        })
      };
      let markerSources = {};
      this.channels.forEach((channel)=> {
        let color = this.channelColors[channel];
        let markerSource = new ol.source.Vector();
        let markerStyle = new ol.style.Style({
          image: new ol.style.Icon(({
            imgSize: [8, 8],
            scale: 1,
            src: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 8 8" xml:space="preserve" height="8px" width="8px"><g><circle cx="4" cy="4" r="4" style="fill: ' + color + '; opacity: 0.85;"></circle></g></svg>'
          }))
        });
        let markerLayer = new ol.layer.Vector({
          source: markerSource,
          style: markerStyle,
        });
        if (this.visibleChannels.indexOf(channel) < 0) {
          markerLayer.setVisible(false);
        }
        this.layersChannels[channel] = markerLayer;
        markerSources[channel] = markerSource;
      });

      let map = new ol.Map({
        interactions: ol.interaction.defaults({
          mouseWheelZoom: true,
          altShiftDragRotate: false,
          keyboard: false,
          shiftDragZoom: false,
          dragPan: true,
          pinchRotate: false,
          pinchZoom: false
        }),
        target: 'ol-locs-zoom-map-ch',
        layers: Object.values(this.layersChannels),
        view: new ol.View({
          center: ol.proj.fromLonLat([-100, 37]),
          zoom: 5
        })
      });

      let iconFeatures = {};
      let catch_reported = false;
      this.locations.forEach((loc:ILocGeoPoint) => {
        if (loc.longitude === 0 || loc.latitude === 0) {
          return;
        }
        try {
          if (!iconFeatures[loc.channel]) {
            iconFeatures[loc.channel] = [];
          }
          iconFeatures[loc.channel].push(new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.transform([loc.longitude, loc.latitude], 'EPSG:4326', 'EPSG:3857'))
          }));
        } catch (e) {
          if (!catch_reported) {
            catch_reported = true;
            console.error(e);
          }
        }
      });
      for (let channel in iconFeatures) {
        markerSources[channel].addFeatures(iconFeatures[channel]);
      }
    }, 1);
    return true;
  }

  drawCMs():boolean {
    if (this.loading || !this.locations || this.locations.length < 1) {
      return false;
    }
    setTimeout(()=> {
      $("#ol-locs-zoom-map-cm").empty();
      var vectorSource = new ol.source.Vector({
        format: new ol.format.GeoJSON(),
        url: '/assets/us-states.json',
        strategy: ol.loadingstrategy.bbox
      });

      this.layersCMs = {
        map_: new ol.layer.Vector({
          source: vectorSource,
          style: new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: 'white',
              width: 1
            }),
            fill: new ol.style.Fill({color: this.statesColor})
          })
        })
      };
      let markerSources = {};
      this.CMs.forEach((cm)=> {
        let color = this.cmColors[cm];
        let markerSource = new ol.source.Vector();
        let markerStyle = new ol.style.Style({
          image: new ol.style.Icon(({
            imgSize: [8, 8],
            scale: 1,
            src: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 8 8" xml:space="preserve" height="8px" width="8px"><g><circle cx="4" cy="4" r="4" style="fill: ' + color + '; opacity: 0.85;"></circle></g></svg>'
          }))
        });
        let markerLayer = new ol.layer.Vector({
          source: markerSource,
          style: markerStyle,
        });
        if (this.visibleCMs.indexOf(cm) < 0) {
          markerLayer.setVisible(false);
        }
        this.layersCMs[cm] = markerLayer;
        markerSources[cm] = markerSource;
      });

      let map = new ol.Map({
        interactions: ol.interaction.defaults({
          mouseWheelZoom: true,
          altShiftDragRotate: false,
          keyboard: false,
          shiftDragZoom: false,
          dragPan: true,
          pinchRotate: false,
          pinchZoom: false
        }),
        target: 'ol-locs-zoom-map-cm',
        layers: Object.values(this.layersCMs),
        view: new ol.View({
          center: ol.proj.fromLonLat([-100, 37]),
          zoom: 5
        })
      });

      let iconFeatures = {};
      let catch_reported = false;
      this.locations.forEach((loc:ILocGeoPoint) => {
        if (loc.longitude === 0 || loc.latitude === 0) {
          return;
        }
        try {
          if (!iconFeatures[loc.cm]) {
            iconFeatures[loc.cm] = [];
          }
          iconFeatures[loc.cm].push(new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.transform([loc.longitude, loc.latitude], 'EPSG:4326', 'EPSG:3857'))
          }));
        } catch (e) {
          if (!catch_reported) {
            catch_reported = true;
            console.error(e);
          }
        }
      });
      for (let cm in iconFeatures) {
        markerSources[cm].addFeatures(iconFeatures[cm]);
      }
    }, 1);
    return true;
  }

  drawDistrs():boolean {
    if (this.loading || !this.locations || this.locations.length < 1) {
      return false;
    }
    setTimeout(()=> {
      $("#ol-locs-zoom-map-distrs").empty();
      var vectorSource = new ol.source.Vector({
        format: new ol.format.GeoJSON(),
        url: '/assets/us-states.json',
        strategy: ol.loadingstrategy.bbox
      });

      this.layersDistrs = {
        map_: new ol.layer.Vector({
          source: vectorSource,
          style: new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: 'white',
              width: 1
            }),
            fill: new ol.style.Fill({color: this.statesColor})
          })
        })
      };
      let markerSources = {};
      this.distrs.forEach((distr)=> {
        let color = this.distrColors[distr];
        let markerSource = new ol.source.Vector();
        let markerStyle = new ol.style.Style({
          image: new ol.style.Icon(({
            imgSize: [8, 8],
            scale: 1,
            src: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 8 8" xml:space="preserve" height="8px" width="8px"><g><circle cx="4" cy="4" r="4" style="fill: ' + color + '; opacity: 0.85;"></circle></g></svg>'
          }))
        });
        let markerLayer = new ol.layer.Vector({
          source: markerSource,
          style: markerStyle,
        });
        if (this.visibleDistrs.indexOf(distr) < 0) {
          markerLayer.setVisible(false);
        }
        this.layersDistrs[distr] = markerLayer;
        markerSources[distr] = markerSource;
      });

      let map = new ol.Map({
        interactions: ol.interaction.defaults({
          mouseWheelZoom: true,
          altShiftDragRotate: false,
          keyboard: false,
          shiftDragZoom: false,
          dragPan: true,
          pinchRotate: false,
          pinchZoom: false
        }),
        target: 'ol-locs-zoom-map-distrs',
        layers: Object.values(this.layersDistrs),
        view: new ol.View({
          center: ol.proj.fromLonLat([-100, 37]),
          zoom: 5
        })
      });

      let iconFeatures = {};
      let catch_reported = false;
      this.locations.forEach((loc:ILocGeoPoint) => {
        if (loc.longitude === 0 || loc.latitude === 0 || !loc.distr) {
          return;
        }
        try {
          if (!iconFeatures[loc.distr]) {
            iconFeatures[loc.distr] = [];
          }
          iconFeatures[loc.distr].push(new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.transform([loc.longitude, loc.latitude], 'EPSG:4326', 'EPSG:3857'))
          }));
        } catch (e) {
          if (!catch_reported) {
            catch_reported = true;
            console.error(e);
          }
        }
      });
      for (let distr in iconFeatures) {
        markerSources[distr].addFeatures(iconFeatures[distr]);
      }
    }, 1);
    return true;
  }

  drawReporting():boolean {
    if (this.loading || !this.locations || this.locations.length < 1) {
      return false;
    }
    setTimeout(()=> {
      $("#ol-locs-zoom-map-reporting").empty();
      var vectorSource = new ol.source.Vector({
        format: new ol.format.GeoJSON(),
        url: '/assets/us-states.json',
        strategy: ol.loadingstrategy.bbox
      });

      this.layersReporting = {
        map_: new ol.layer.Vector({
          source: vectorSource,
          style: new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: 'white',
              width: 1
            }),
            fill: new ol.style.Fill({color: this.statesColor})
          })
        })
      };
      let markerSources = {};
      this.reporting.forEach((r)=> {
        let color = this.reportingColors[r];
        let markerSource = new ol.source.Vector();
        let markerStyle = new ol.style.Style({
          image: new ol.style.Icon(({
            imgSize: [8, 8],
            scale: 1,
            src: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 8 8" xml:space="preserve" height="8px" width="8px"><g><circle cx="4" cy="4" r="4" style="fill: ' + color + '; opacity: 0.85;"></circle></g></svg>'
          }))
        });
        let markerLayer = new ol.layer.Vector({
          source: markerSource,
          style: markerStyle,
        });
        if (this.visibleReporting.indexOf(r) < 0) {
          markerLayer.setVisible(false);
        }
        this.layersReporting[r] = markerLayer;
        markerSources[r] = markerSource;
      });

      let map = new ol.Map({
        interactions: ol.interaction.defaults({
          mouseWheelZoom: true,
          altShiftDragRotate: false,
          keyboard: false,
          shiftDragZoom: false,
          dragPan: true,
          pinchRotate: false,
          pinchZoom: false
        }),
        target: 'ol-locs-zoom-map-reporting',
        layers: Object.values(this.layersReporting),
        view: new ol.View({
          center: ol.proj.fromLonLat([-100, 37]),
          zoom: 5
        })
      });

      let iconFeatures = {};
      let catch_reported = false;
      this.locations.forEach((loc:ILocGeoPoint) => {
        if (loc.longitude === 0 || loc.latitude === 0 || !loc.distr_reporting) {
          return;
        }
        try {
          if (!iconFeatures[loc.distr_reporting]) {
            iconFeatures[loc.distr_reporting] = [];
          }
          iconFeatures[loc.distr_reporting].push(new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.transform([loc.longitude, loc.latitude], 'EPSG:4326', 'EPSG:3857'))
          }));
        } catch (e) {
          if (!catch_reported) {
            catch_reported = true;
            console.error(e);
          }
        }
      });
      for (let r in iconFeatures) {
        markerSources[r].addFeatures(iconFeatures[r]);
      }
    }, 1);
    return true;
  }

  drawReportingMgma():boolean {
    if (!this.locationsMgmaReporting || this.locationsMgmaReporting.length < 1) {
      return false;
    }
    setTimeout(()=> {
      $("#ol-locs-zoom-map-reporting-mgma").empty();
      var vectorSource = new ol.source.Vector({
        format: new ol.format.GeoJSON(),
        url: '/assets/us-states.json',
        strategy: ol.loadingstrategy.bbox
      });

      this.layersReportingMgma = {
        map_: new ol.layer.Vector({
          source: vectorSource,
          style: new ol.style.Style({
            stroke: new ol.style.Stroke({
              color: 'white',
              width: 1
            }),
            fill: new ol.style.Fill({color: this.statesColor})
          })
        })
      };
      let markerSources = {};
      this.reportingMgma.forEach((r)=> {
        let color = this.reportingMgmaColors[r];
        let markerSource = new ol.source.Vector();
        let markerStyle = new ol.style.Style({
          image: new ol.style.Icon(({
            imgSize: [8, 8],
            scale: 1,
            src: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 8 8" xml:space="preserve" height="8px" width="8px"><g><circle cx="4" cy="4" r="4" style="fill: ' + color + '; opacity: 0.85;"></circle></g></svg>'
          }))
        });
        let markerLayer = new ol.layer.Vector({
          source: markerSource,
          style: markerStyle,
        });
        if (this.visibleReportingMgma.indexOf(r) < 0) {
          markerLayer.setVisible(false);
        }
        this.layersReportingMgma[r] = markerLayer;
        markerSources[r] = markerSource;
      });

      let map = new ol.Map({
        interactions: ol.interaction.defaults({
          mouseWheelZoom: true,
          altShiftDragRotate: false,
          keyboard: false,
          shiftDragZoom: false,
          dragPan: true,
          pinchRotate: false,
          pinchZoom: false
        }),
        target: 'ol-locs-zoom-map-reporting-mgma',
        layers: Object.values(this.layersReportingMgma),
        view: new ol.View({
          center: ol.proj.fromLonLat([-100, 37]),
          zoom: 5
        })
      });

      let iconFeatures = {};
      let catch_reported = false;
      this.locationsMgmaReporting.forEach((loc:ILocGeoPointMgmaReporting) => {
        if (loc.longitude === 0 || loc.latitude === 0 || !loc.label) {
          return;
        }
        try {
          if (!iconFeatures[loc.label]) {
            iconFeatures[loc.label] = [];
          }
          iconFeatures[loc.label].push(new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.transform([loc.longitude, loc.latitude], 'EPSG:4326', 'EPSG:3857'))
          }));
        } catch (e) {
          if (!catch_reported) {
            catch_reported = true;
            console.error(e);
          }
        }
      });
      for (let r in iconFeatures) {
        markerSources[r].addFeatures(iconFeatures[r]);
      }
    }, 1);
    return true;
  }

  setTab(i) {
    this.tab = Math.max(parseInt(i), 1);
    this.urlParser.setParameter('tab', this.tab);
    this.draw();
  }

  toggleChannel(channel) {
    let i = this.visibleChannels.indexOf(channel);
    if (i < 0) {
      this.visibleChannels.push(channel);
      if (this.layersChannels[channel]) {
        this.layersChannels[channel].setVisible(true);
      }
    } else {
      this.visibleChannels.splice(i, 1);
      if (this.layersChannels[channel]) {
        this.layersChannels[channel].setVisible(false);
      }
    }
  }

  toggleCM(cm) {
    let i = this.visibleCMs.indexOf(cm);
    if (i < 0) {
      this.visibleCMs.push(cm);
      if (this.layersCMs[cm]) {
        this.layersCMs[cm].setVisible(true);
      }
    } else {
      this.visibleCMs.splice(i, 1);
      if (this.layersCMs[cm]) {
        this.layersCMs[cm].setVisible(false);
      }
    }
  }

  toggleDistr(distr) {
    let i = this.visibleDistrs.indexOf(distr);
    if (i < 0) {
      this.visibleDistrs.push(distr);
      if (this.layersDistrs[distr]) {
        this.layersDistrs[distr].setVisible(true);
      }
    } else {
      this.visibleDistrs.splice(i, 1);
      if (this.layersDistrs[distr]) {
        this.layersDistrs[distr].setVisible(false);
      }
    }
  }

  toggleReporting(r) {
    let i = this.visibleReporting.indexOf(r);
    if (i < 0) {
      this.visibleReporting.push(r);
      if (this.layersReporting[r]) {
        this.layersReporting[r].setVisible(true);
      }
    } else {
      this.visibleReporting.splice(i, 1);
      if (this.layersReporting[r]) {
        this.layersReporting[r].setVisible(false);
      }
    }
  }

  toggleReportingMgma(r) {
    let i = this.visibleReportingMgma.indexOf(r);
    if (i < 0) {
      this.visibleReportingMgma.push(r);
      if (this.layersReportingMgma[r]) {
        this.layersReportingMgma[r].setVisible(true);
      }
    } else {
      this.visibleReportingMgma.splice(i, 1);
      if (this.layersReportingMgma[r]) {
        this.layersReportingMgma[r].setVisible(false);
      }
    }
  }

  hideAllChannels() {
    this.visibleChannels.forEach((channel)=> {
      this.layersChannels[channel].setVisible(false);
    });
    this.visibleChannels = [];
  }

  hideAllCMs() {
    this.visibleCMs.forEach((cm)=> {
      this.layersCMs[cm].setVisible(false);
    });
    this.visibleCMs = [];
  }

  hideAllDistrs() {
    this.visibleDistrs.forEach((distr)=> {
      this.layersDistrs[distr].setVisible(false);
    });
    this.visibleDistrs = [];
  }

  hideAllReporting() {
    this.visibleReporting.forEach((r)=> {
      this.layersReporting[r].setVisible(false);
    });
    this.visibleReporting = [];
  }

  hideAllReportingMgma() {
    this.visibleReportingMgma.forEach((r)=> {
      this.layersReportingMgma[r].setVisible(false);
    });
    this.visibleReportingMgma = [];
  }
}
