import { ElementRef, Injectable } from '@angular/core';
import {Notify} from "./Notify";

declare var $: any;
declare var ClipboardJS: any;

@Injectable()
export class Helpers {
  static is_empty(v) {
    if (typeof v === 'undefined') {
      return true;
    }
    if (v === null && typeof v === 'object') {
      return true;
    }
    return false;
  }

  exportCsv(tbl: ElementRef, filename?: string, cbAfter?, cbCellTextHandler?) {
    if (!tbl || !tbl.nativeElement) {
      return;
    }
    return this.exportCsvForElement(tbl.nativeElement, filename, cbAfter, cbCellTextHandler);
  }

  exportCsvBySelector(selector, filename?: string, cbAfter?, cbCellTextHandler?) {
    let element = $(selector)[0];
    return this.exportCsvForElement(element, filename, cbAfter, cbCellTextHandler);
  }

  getCellValueRecursive(cell, level?: number): String {
    if (!cell || level > 40) {
      return '';
    }
    let lvl = level || 1;
    try {
      if (cell.innerText) {
        return cell.innerText;
      } else {
        if (cell.value) {
          return cell.value;
        } else {
          if (cell.firstElementChild) {
            return this.getCellValueRecursive(cell.firstElementChild, lvl + 1);
          } else {
            return '';
          }
        }
      }
    } catch (e) { return '';}
  }

  exportCsvForElement(element, filename?: string, cbAfter?, cbCellTextHandler?) {
    setTimeout(() => {
      let csv = '';
      let delimitter = ',';
      for (let i = 0, row; row = element.rows[i]; i++) {
        let line = '';
        for (let j = 0, col; col = row.cells[j]; j++) {
          let t = this.getCellValueRecursive(col);
          let cellText = t.replace('drag_handle', '').replace('filter_list', '').replace('remove_circle_outline', '');
          if (cbCellTextHandler) {
            cellText = cbCellTextHandler(cellText, col);
          }
          line = line + '"' + cellText + '"' + delimitter;
          if (col.colSpan > 0) {
            for (let c = 1; c < col.colSpan; c++) {
              line = line + delimitter;
            }
          }
        }
        csv = csv + line.replace(/,(\s+)?$/, '').replace(/;(\s+)?$/, '') + "\n";
      }
      if (!filename || filename.length < 1) {
        filename = 'report.csv';
      }
      let a = this.getCsvExportLink(csv, filename);
      a.click();
      if (cbAfter) {
        setTimeout(() => {
          cbAfter();
        }, 2);
      }
    }, 1);
  }

  getCsvExportLink(csv, fileName): HTMLElement {
    // https://stackoverflow.com/a/23922475/680786
    let pom = document.createElement('a');
    var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', fileName);
    return pom;
  }

  isValidEmail(email: string): boolean {
    if (!email || email.length < 6) {
      return false;
    }
    let ati = email.indexOf('@');
    if (ati < 1) {
      return false;
    }
    let doti = email.lastIndexOf('.');
    if (doti < 2 || doti < ati || doti == (email.length - 1)) {
      return false;
    }
    return true;
  }

  csvToArray(csv: string, settings?): string[][] {
    // https://code.google.com/archive/p/csv-to-array/
    let a = [['']];
    try {
      let o = settings;
      var od = {
        'fSep': ',',
        'rSep': '\n',
        'quot': '"',
        'head': false,
        'trim': true
      }
      if (o) {
        for (var i in od) {
          if (!o[i]) {
            o[i] = od[i];
          }
        }
      } else {
        o = od;
      }
      var r = 0;
      var f = 0;
      var p = 0;
      var q = 0;
      for (; p < csv.length; p++) {
        var c;
        switch (c = csv.charAt(p)) {
          case o.quot:
            if (q && csv.charAt(p + 1) == o.quot) {
              a[r][f] += o.quot;
              ++p;
            } else {
              q ^= 1;
            }
            break;
          case o.fSep:
            if (!q) {
              if (o.trim) {
                a[r][f] = a[r][f].replace(/^\s\s*/, '').replace(/\s\s*$/, '');
              }
              a[r][++f] = '';
            } else {
              a[r][f] += c;
            }
            break;
          case o.rSep.charAt(0):
            if (!q && (!o.rSep.charAt(1) || (o.rSep.charAt(1) && o.rSep.charAt(1) == csv.charAt(p + 1)))) {
              if (o.trim) {
                a[r][f] = a[r][f].replace(/^\s\s*/, '').replace(/\s\s*$/, '');
              }
              a[++r] = [''];
              a[r][f = 0] = '';
              if (o.rSep.charAt(1)) {
                ++p;
              }
            } else {
              a[r][f] += c;
            }
            break;
          default:
            a[r][f] += c;
        }
      }
      if (o.head) {
        a.shift()
      }
      if (a[a.length - 1].length < a[0].length) {
        a.pop()
      }
      return a;
    } catch (e) {
      console.error(e);
      return a;
    }
  }

  arrayToCsvString(arr, headersLine?: string[]): string {
    let csv = '';
    if (arr && arr.length) {
      let lines = [];
      if (headersLine) {
        let header = this.arrToCsvLine(headersLine);
        if (header) {
          lines.push(header);
        }
      }
      for (let l of arr) {
        let line = this.arrToCsvLine(l);
        if (line) {
          lines.push(line);
        }
      }
      csv = lines.join('\n');
    }
    return csv;
  }

  arrToCsvLine(l: string[]): string {
    if (l && l.length) {
      let cells = [];
      for (let c of l) {
        cells.push('"' + c.replace('"', '\\"') + '"');
      }
      return cells.join(',');
    } else {
      return null;
    }
  }

  arrayToCsvFile(arr, filename, headersLine?: string[]): File {
    let str = this.arrayToCsvString(arr, headersLine);
    return new File([str], filename);
  }

  copyObject(obj) {
    return $.extend(true, {}, obj);
  }

  clone(obj) {
    return this.copyObject(obj);
  }

  // remove item i of array arr, when f(i) = true
  removeFromArr(arr: any[], f) {
    if (!arr || !f || !arr.length) {
      return;
    }
    for (let i = 0; i < arr.length; i++) {
      if (f(arr[i])) {
        arr.splice(i, 1);
        i--;
      }
    }
  }

  // remove item i of array arr, when f(i) = false
  leaveInArr(arr: any[], f) {
    if (!arr || !f || !arr.length) {
      return;
    }
    for (let i = 0; i < arr.length; i++) {
      if (!f(arr[i])) {
        arr.splice(i, 1);
        i--;
      }
    }
  }

  hideModal(el: ElementRef, $event?) {
    if ($event) {
      this.preventBubbling($event);
    }
    if (el && el.nativeElement) {
      $(el.nativeElement).modal('hide');
    }
  }

  showModal(el: ElementRef, $event?) {
    if ($event) {
      this.preventBubbling($event);
    }
    if (el && el.nativeElement) {
      $(el.nativeElement).modal('show');
    }
  }

  isUndefined(x) {
    return (typeof x === 'undefined');
  }

  findObjByField(arr, field, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][field] == value) {
        return arr[i];
      }
    }
    return false;
  }

  findObjIdByField(arr, field, value, idField = 'id') {
    let obj = this.findObjByField(arr, field, value);
    if (!obj) {
      return null;
    }
    return obj[idField];
  }

  preventBubbling($event?) {
    try {
      if ($event) {
        if ($event.stopPropagation) {
          $event.stopPropagation();
        }
        if ($event.preventDefault) {
          $event.preventDefault();
        }
        if ($event.stopImmediatePropagation) {
          $event.stopImmediatePropagation();
        }
      }
    } catch (e) {
    }
  }

  initClipboardAttrButtons(prevInstance?, container?): any {
    if (prevInstance && prevInstance.destroy) {
      try {
        prevInstance.destroy();
      } catch (e) {}
    }
    try {
      if (Clipboard) {
        let allClipboards;
        if (container) {
          let c;
          if (container.addEventListener) {
            c = container;
          } else {
            if (container.nativeElement) {
              c = container.nativeElement;
            } else {
              c = $(container);
            }
          }
          allClipboards = new ClipboardJS('.clipboard-btn', {
            container: c
          });
        } else {
          allClipboards = new ClipboardJS('.clipboard-btn');
        }
        allClipboards.on('success', (e) => {
          try {
            let notify = new Notify();
            notify.success('Copied!');
            e.clearSelection();
          } catch (e) {}
        });
        return allClipboards;
      }
    } catch (e) {}
  }

  getMapFromList(arr: any[], idField?: string) {
    let k = idField || 'id';
    let map = {};
    for (let x of arr) {
      map[x[k]] = x;
    }
    return map;
  }
}
