import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Locations} from "../../services/Locations";
import {Notify} from "../../services/Notify";
import {ILifecycleData} from "../../interfaces/ILifecycleData";
import {Observable} from "rxjs";
import {ILifecycleRecord} from "../../interfaces/ILifecycleRecord";
import {ILocation} from "../../interfaces/ILocation";

@Component({
  selector: 'location-life-cycle',
  templateUrl: './life_cycle.html',
})
export class LocationFileCycleCmp {
  @Input() locationID;
  @Output() update = new EventEmitter();
  @Input() refresh: Observable<any>;
  public lc: ILifecycleData = <ILifecycleData>{};
  public sentCustomDistributors = [];
  public connectedCustomDistributors = [];
  public savingConnected = false;
  public primaryConnected: ILifecycleRecord = <ILifecycleRecord>{};
  public secondaryConnected: ILifecycleRecord = <ILifecycleRecord>{};
  public reasonsMap;
  public reasonsList;
  public loading = false;
  public loadingData = false;
  public refreshSubscribed = false;
  public dateEditMode = false;
  public sending_lcr = false;
  private location: ILocation;

  constructor(public locations: Locations) {
    this.loadReasons();
  }

  ngAfterViewInit() {
    if (this.refresh && !this.refreshSubscribed) {
      this.refresh.subscribe(() => {
        this.loadData();
      }, () => {});
      this.refreshSubscribed = true;
    }
  }

  ngOnChanges() {
    if (this.locationID) {
      this.loadData();
    }
  }

  loadData() {
    if (this.loadingData) {
      return;
    }
    this.loadingData = true;
    this.locations.getLifeCycleData(this.locationID).subscribe((response) => {
      this.loadingData = false;
      this.lc = response;
      if (this.lc.pa_date) {
        this.lc.pa_date = <any>Date.parse(this.lc.pa_date);
      }
      if (this.lc.zimmer_sent_date) {
        this.lc.zimmer_sent_date = <any>Date.parse(this.lc.zimmer_sent_date);
      }
      if (this.lc.medsurg_eff_date) {
        this.lc.medsurg_eff_date = <any>Date.parse(this.lc.medsurg_eff_date);
      }
      if (this.lc.pharma_eff_date) {
        this.lc.pharma_eff_date = <any>Date.parse(this.lc.pharma_eff_date);
      }
    }, (response) => {
      this.loadingData = false;
      let notify = new Notify();
      notify.error('Can\'t load Lifecycle data');
      console.error(response);
    });
  }

  loadReasons() {
    this.loading = true;
    this.locations.getLifecycleReasons().subscribe((reasons) => {
      this.loading = false;
      this.reasonsList = reasons;
      this.reasonsMap = {};
      if (reasons && reasons.length) {
        reasons.forEach((r) => {
          this.reasonsMap[r.id] = r;
        });
      }
    }, () => {
      this.loading = false;
    });
  }

  changeDateField(date: Date, field) {
    this.lc[field] = date.toISOString().substring(0, 19).replace('T', ' ');
    if (!this.location) {
      this.location = <ILocation>{id: this.locationID};
    }
    this.location[field] = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  updateLocation() {
    if (this.location && this.location.id) {
      let n = new Notify();
      this.locations.updateLocation(this.location).then(() => {
        this.dateEditMode = false;
        n.success('Saved');
      }, (err) => {
        this.dateEditMode = false;
        n.httpErr(err);
      });
    }
  }

  addLcRecord(distributor_id: number, type_id: number, reason_id?: number, datetime?: string) {
    let n = new Notify();
    if (!this.locationID) {
      n.warning('Location is required');
      return;
    }
    if (!distributor_id) {
      n.warning('Please select distributor');
      return false;
    }
    if (!type_id) {
      n.warning('Please select type');
      return false;
    }
    if (this.sending_lcr) {
      return;
    }
    this.sending_lcr = true;
    let record = new ILifecycleRecord();
    record.location_id = this.locationID;
    record.distributor_id = distributor_id;
    record.type_id = type_id;
    if (reason_id) {
      record.reason_id = reason_id;
    }
    if (datetime && datetime.length > 6) {
      record.datetime = datetime;
    }

    this.locations.addLifecycleRecords([record]).subscribe(() => {
      this.sending_lcr = false;
      n.success('Successfully!');
      this.loadData();
    }, (err) => {
      this.sending_lcr = false;
      console.error(err);
      n.error('Error');
    });
  }

  resendRxDate() {
    if (!this.lc || !this.lc.distributor_primary_id) {
      return;
    }
    this.addLcRecord(this.lc.distributor_primary_id, 11);
    this.loadData();
  }
}
