import {Component} from '@angular/core';
import {Notify} from "../../services/Notify";
import {Implementations} from "../../services/Implementations";
import {Session} from "../../services/Session";

@Component({
  selector: 'write-implementation-steps',
  templateUrl: './write_impl_steps.html',
})
export class WriteImplementationStepsCmp {
  public zimmer_ids:string;
  public reasonsList = [];
  public reasonsMap = {};
  public stepsList = [];
  public stepsMap = {};
  public reason_id:number = 0;
  public step_id:number = 0;
  public sending = false;
  public notFoundList:string;
  public loading = false;
  public sent = false;
  public datetime:string = '';

  constructor(public implSrv:Implementations, private session:Session) {
    this.session.addOnLoadHandler(()=> {
      this.loadSteps();
    });
    this.session.redirectIfNotAuth();
  }

  loadSteps() {
    this.implSrv.getSteps().subscribe((steps)=> {
      if (steps && steps.length) {
        this.stepsList = [];
        this.stepsMap = {};
        steps.forEach((s:any)=> {
          if (s.is_selectable) {
            this.stepsMap[s.id] = s;
            this.stepsList.push(s);
          }
        });
      }
    });
  }

  send() {
    let n = new Notify();
    if (!this.zimmer_ids) {
      n.warning('Please input IDs');
      return false;
    }
    if (!this.step_id) {
      n.warning('Please select step');
      return false;
    }
    this.sending = true;
    this.implSrv.setStepsByZimmerIDs(this.zimmer_ids.split("\n"), this.step_id, this.datetime).subscribe((not_found)=> {
      if (not_found && not_found.length > 0) {
        this.notFoundList = not_found.join(', ');
      }
      this.sending = false;
      this.sent = true;
      n.success('Successfully!');
    }, (err)=> {
      this.sending = false;
      this.sent = false;
      console.error(err);
      n.error('Error');
    });
  }

  setDate(date:Date) {
    this.datetime = date.toISOString().substring(0, 19).replace('T', ' ');
  }

  resetForm() {
    this.sent = false;
    this.notFoundList = null;
  }
}
