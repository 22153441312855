import {Directive, HostListener, Output, EventEmitter, Input} from "@angular/core";

@Directive({
  selector: '[mouse-down-long]',
})
export class MouseDownLongCmp {
  private pressing = false;
  private timeout: any;
  @Input() frequency: number = 97;
  @Output() holding = new EventEmitter();

  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    if (event.which !== 1) {
      return;
    }
    if (!this.pressing) {
      this.pressing = true;
      this.loop(event);
    }
  }

  loop(event) {
    if (this.pressing) {
      this.holding.emit(event);
      this.timeout = setTimeout(() => {
        this.loop(event);
      }, this.frequency);
    }
  }

  endPress() {
    clearTimeout(this.timeout);
    this.pressing = false;
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.endPress();
  }
}
