import {Component, Input, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'unsafe-text',
  template: `
    <div #target style="white-space: pre-wrap;"></div>`
})
export class UnsafeTextCmp {
  @Input() text;
  @Input() maxLength;
  @ViewChild('target') target: ElementRef;

  ngAfterViewInit() {
    this.bind();
  }

  ngOnChanges() {
    this.bind();
  }

  bind() {
    try {
      if (this.text && this.target && this.target.nativeElement) {
        let t = this.text;
        if (this.maxLength && this.text.length > this.maxLength) {
          t = t.substring(0, this.maxLength) + '&#8230;';
        }
        this.target.nativeElement.innerHTML = t;
      }
    } catch (e) {}
  }
}
