import {Component} from '@angular/core';
import {ISaverLandingUser} from "../../interfaces/ISaverLandingUser";
import {Notify} from "../../services/Notify";
import {Session} from "../../services/Session";
import {SaverLanding} from "../../services/SaverLanding";
import {IAspsInfo} from "../../interfaces/IAspsInfo";
import {Router, ActivatedRoute} from "@angular/router";

@Component({
  selector: 'saver-landing-list',
  templateUrl: './saver-landing-list.html',
})
export class SaverLandingListCmp {
  public users:Array<ISaverLandingUser> = <ISaverLandingUser[]>[];
  public notify = new Notify();
  public loading = false;
  public selected:IAspsInfo;
  public copying = false;
  public tag = '';

  public constructor(public session:Session, public srv:SaverLanding, public router:Router, public params:ActivatedRoute) {
    this.session.addOnLoadHandler(()=> {
      this.load();
    });
    this.session.redirectIfNotAuth();
  }

  load() {
    this.params.params.subscribe(params => {
      if (params && params['tag']) {
        this.tag = params['tag'];
        this.loading = true;
        this.srv.getUsers(this.tag).subscribe((response)=> {
          this.users = response;
          this.loading = false;
        }, ()=> {
          this.loading = false;
        });
      }
    });
  }

  selectUser($event) {
    this.selected = $event;
  }

  copyUser() {
    if (!this.selected) {
      return;
    }
    let s = this.selected;
    let n:ISaverLandingUser = <ISaverLandingUser>{
      email: s.user.email,
      name: s.user.fname + ' ' + s.user.lname,
      facility_name: s.location.facility_name,
      specialty_id: s.user.specialty_id,
      notes: s.location.membership_comments,
      tag: this.tag,
    };
    this.copying = true;
    this.srv.saveUser(n).subscribe((id)=> {
      this.copying = false;
      this.notify.success('Saved');
      this.router.navigate(['/saver/' + this.tag, id]);
    }, (err)=> {
      this.copying = false;
      console.error(err);
      this.notify.error('Error');
    });
  }
}
