import { ElementRef, Injectable } from '@angular/core';

declare var $:any;

@Injectable()
export class TypeaheadHelper {
  getStringsMatcher() {
    let substringMatcher = function (strs) {
      return function findMatches(q, cb) {
        try {
          let substringRegex;
          let matches = [];
          let substrRegex = new RegExp(q, 'i');
          $.each(strs, function (i, str) {
            try {
              if (substrRegex.test(str)) {
                matches.push(str);
              }
            } catch (e) {}
          });
          cb(matches);
        } catch (e) {}
      };
    };
    return substringMatcher;
  }

  setup(data:Array<string>, elementRef:ElementRef, matcher?) {
    setTimeout(()=> {
      if (data && data.length && elementRef && elementRef.nativeElement) {
        try {
          if (!matcher) {
            matcher = this.getStringsMatcher();
          }
          $(elementRef.nativeElement).typeahead({highlight: true, classNames: {menu: 'dropdown-menu'}}, {source: matcher(data)});
        } catch (e) {
          console.error(e);
        }
      }
    }, 1);
  }

  getVal(el:ElementRef):string {
    try {
      return $(el.nativeElement).typeahead('val');
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  setVal(el:ElementRef, val):boolean {
    try {
      $(el.nativeElement).typeahead('val', val);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  whenSelected(el:ElementRef, cb) {
    if (!el || !el.nativeElement || !cb) {
      return;
    }
    try {
      $(el.nativeElement).bind('typeahead:select', (event, suggestion)=> {
        cb(event, suggestion);
      });
    } catch (e) {
      console.error(e);
    }
  }
}
