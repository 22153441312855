import {Pipe} from '@angular/core';

// http://plnkr.co/edit/W7ZhUaoc2TGJvAZbz1iU?p=preview
const filter = (list, search) => {
  if (list && list.length > 0 && search && search.length && search.length > 0) {
    let s = search.toLowerCase();
    let filtered = list.filter((obj)=> {
      if (typeof obj === 'object' && obj.hasOwnProperty) {
        for (let k in obj) {
          if (obj.hasOwnProperty(k)) {
            if (obj[k]) {
              if (obj[k].toString().toLowerCase().indexOf(s) > -1) {
                return true;
              }
            }
          }
        }
      }
      return false;
    });
    return filtered;
  } else {
    return list;
  }
};

@Pipe({
  name: 'filter'
})
export class FilterPipe {
  transform(list, args: string[]): any {
    return filter(list, args[0]);
  }

  filter(list, search) {
    return filter(list, search);
  }
}
