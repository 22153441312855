import {Component} from '@angular/core';
import {Notify} from "../../services/Notify";
import {Locations} from "../../services/Locations";
import {Session} from "../../services/Session";

@Component({
  selector: 'rx-new-members-upload',
  templateUrl: 'rx_new_members.html',
})
export class RxNewMembersUploadCmp {
  public uploading = false;
  public results:any[];

  constructor(public locations:Locations, private session: Session) {
    this.session.redirectIfNotAuth();
  }

  addResultsFile(files) {
    if (!files || !files[0]) {
      return;
    }
    let fileData = files[0];
    this.uploading = true;
    this.locations.uploadRxNewMembersFile(fileData).subscribe((response)=> {
      let responseText = '';
      if (response && response.currentTarget && response.currentTarget.responseText) {
        responseText = response.currentTarget.responseText;
      }
      this.uploading = false;
      this.setResults(responseText);
      let n = new Notify();
      n.success('Successfully!');
    }, (err)=> {
      this.uploading = false;
      let n = new Notify();
      n.error('Error');
      console.error(err);
    });
  }

  setResults($event) {
    try {
      this.results = JSON.parse($event);
    } catch (e) {
      this.results = [];
    }
  }
}
