
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from './Session';
import {Config} from './Config';
import {Observable} from 'rxjs';
import {IReviewSession} from "../interfaces/IReviewSession";
import {IReviewAnswer} from "../interfaces/IReviewAnswer";
import {IReviewResult} from "../interfaces/IReviewResult";
import {IReviewResultQuestion} from "../interfaces/IReviewResultQuestion";
import {IReviewResultSubQuestion} from "../interfaces/IReviewResultSubQuestion";
import {IBenefitReviews} from "../interfaces/IBenefitReview";
declare var _:any;

@Injectable()
export class Questions {
  public sessions:{[id:number]:IReviewSession;} = {};

  constructor(private http:HttpClient, private session:Session, private config:Config) {}

  loadPages():Observable<any> {
    return this.http.get(this.config.apiUrl + '/questions_page');
  }

  saveAnswers(answers:Array<IReviewAnswer>):Observable<any> {
    return this.http.post(this.config.apiUrl + '/review_answers',
      JSON.stringify({answers: answers}));
  }

  getNewReviewAnswersSession(session:IReviewSession):Observable<number> {
    return this.http.post(this.config.apiUrl + '/review_answers_session', JSON.stringify(session), {responseType: 'text'}).pipe(map(r => parseInt(r)));
  }

  saveReviewSession(session:IReviewSession):Observable<any> {
    this.sessions[session.id] = session;
    return this.http.put(this.config.apiUrl + '/review_answers_session',
      JSON.stringify(session));
  }

  getReviewResults(user_id:number):Observable<Array<IReviewResult>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<any>(this.config.apiUrl + '/review_answers/' + user_id, {headers: headers}).pipe(map((r) => {
      let map = r;
      let answers = {};
      if (map && map.length) {
        map.forEach((a:IReviewResult) => {
          if (!answers[a.q_id]) {
            let q = new IReviewResultQuestion();
            q.description = a.description;
            let sq = new IReviewResultSubQuestion();
            sq.title = a.sq_title;
            sq.id = a.sq_id;
            sq.question_type_id = a.question_type_id;
            sq.__options = [a];
            q.__questions = [sq];
            answers[a.q_id] = q;
          } else {
            let q = answers[a.q_id];
            let existing_sq:IReviewResultSubQuestion = _.find(q.__questions, {id: a.sq_id});
            if (existing_sq) {
              existing_sq.__options.push(a);
            } else {
              let sq = new IReviewResultSubQuestion();
              sq.title = a.sq_title;
              sq.question_type_id = a.question_type_id;
              sq.__options = [a];
              q.__questions.push(sq);
            }
          }
        });
      }
      return _.values(answers);
    }));
  }

  getLastReviews():Observable<IBenefitReviews> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'text/json');
    headers = this.session.setAuthHeader(headers);
    return this.http.get<IBenefitReviews>(this.config.apiUrl + '/review_session', {headers: headers});
  }
}
