import {Component, Input} from "@angular/core";
import {EditRequests} from "../../services/EditRequests";
import {IEditRequest} from "../../interfaces/IEditRequest";

@Component({
  selector: 'user-edit-requests',
  templateUrl: './log.html',
})
export class UserEditRequestsCmp {
  @Input() userID:number;
  public log:IEditRequest[] = [];
  public loading = false;

  constructor(private editReqSrv:EditRequests) {}

  ngOnChanges() {
    if (this.userID) {
      this.log = [];
      this.loading = true;
      this.editReqSrv.getUserLogRequests(this.userID).subscribe((log)=> {
        this.loading = false;
        if (log) {
          this.log = log;
          this.log.forEach((l)=> {
            l.__status = l.review_time ? (l.is_approved ? 'Approved' : 'Declined') : 'Under review';
          });
        }
      }, (err)=> {
        this.loading = false;
        console.error(err);
      });
    }
  }
}
