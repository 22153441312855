import {Component, Input, ElementRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Locations} from "../../services/Locations";
import {Distributors} from "../../services/Distributors";
import {Specialties} from "../../services/Specialties";
import {IDistributor} from "../../interfaces/IDistributor";
import {ISpecialty} from "../../interfaces/ISpecialty";
import {ILocation} from "../../interfaces/ILocation";
import {Session} from "../../services/Session";

@Component({
  selector: '[locations-group]',
  templateUrl: './locations_group.html',
})
export class LocationsGroupCmp {
  @Input() group: number = 0;
  @Input() itemsBy = 8;
  @Input() headless = false;
  @Input() host: Node;
  public page = 1;
  public lastLoadedGroup = 0;
  public locs = [];
  public loading = false;
  public distributorsList:IDistributor[] = [];
  public distributorsMap: {[id: number]: IDistributor;} = {};
  public specialtiesList:ISpecialty[] = [];
  public specialtiesMap: {[id: number]: ISpecialty;} = {};

  constructor(public el: ElementRef, public locations: Locations, public distributors: Distributors,
    public specialties: Specialties, public params: ActivatedRoute, private session: Session) {
    this.session.redirectIfNotAuth();
  }

  ngOnInit() {
    if (this.group) {
      this.init([]);
    } else {
      this.params.params.subscribe(params => {
        this.init(params);
      });
    }
  }

  init(params) {
    if (!this.group && parseInt(params['id'])) {
      this.group = parseInt(params['id']);
    }
    this.load();
    this.distributors.loadToList(this.distributorsList);
    this.distributors.loadToMap(this.distributorsMap);
    this.specialties.loadToList(this.specialtiesList);
    this.specialties.loadToMap(this.specialtiesMap);
  }

  load() {
    this.loading = true;
    if (this.group > 0 && this.group !== this.lastLoadedGroup) {
      let groupID = this.group;
      this.locations.getLocationsOfGroup(this.group).then((locs)=> {
        this.locs = <[ILocation]>locs;
        this.lastLoadedGroup = groupID;
        this.loading = false;
      }, ()=> this.loading = false);
    }
  }

  ngAfterViewInit() {
    if (this.host && this.el && this.el.nativeElement) {
      let tr = document.createElement('tr');
      tr.appendChild(this.el.nativeElement);
      this.host.parentNode.insertBefore(tr, this.host.nextSibling);
      this.host.addEventListener('DOMNodeRemovedFromDocument', ()=> {
        try {
          tr.remove();
        } catch (e) {}
      });
    }
  }

  ngOnChanges() {
    this.load();
  }

  setPage(page) {
    this.page = parseInt(page);
  }
}
