
import {of as observableOf, Observable} from 'rxjs';

import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from './Config';
import {IAccountSize} from "../interfaces/IAccountSize";

@Injectable()
export class AccountSizes {
  private loadingObservable:Observable<any>;
  private list:Array<IAccountSize> = [];

  constructor(private http: HttpClient, private config:Config) {}

  load():Observable<IAccountSize[]> {
    if (this.list && this.list.length > 0) {
      return observableOf(this.list);
    }
    if (this.loadingObservable) {
      return this.loadingObservable;
    }
    this.loadingObservable = this.http.get(this.config.apiUrl+'/location_account_size').pipe(share());
    this.loadingObservable.subscribe((items)=> {
      this.list = items;
    }, ()=> {});
    return this.loadingObservable;
  }

  loadToMap(map:{[id:number]:IAccountSize;}, callback?) {
    this.load().subscribe((types)=> {
      if (types && types.length > 0) {
        types.forEach((t)=> {
          map[t.id] = t;
        });
        if (callback) {callback();}
      }
    },()=>{});
  }

  loadToList(list:IAccountSize[]) {
    this.load().subscribe((sizes)=> {
      if (sizes && sizes.length > 0) {
        sizes.forEach((t)=> {
          list.push(t);
        });
      }
    },()=>{});
  }
}
